import { Component, OnInit } from '@angular/core';

import { ClaimService } from '../../claim.service';

@Component({
  selector: 'report-management',
  templateUrl: './report-management.component.html'
})

export class ReportManagementComponent implements OnInit {
  constructor(
    private claimService: ClaimService
  ) { }
  public title = 'Reports';
  public reportManagementAccess: boolean;
  public reportAccess: boolean;

  ngOnInit() {
    this.reportManagementAccess = this.claimService.checkClaimContains('aml.reportman', 'r');
    this.reportAccess = this.claimService.checkClaimContains('aml.report', 'r');
  }
}
