<div class="overview-wrapper">
  <div class="overview-header">
    {{ 'Fidelity.Chart' | translate }}
  </div>
  <div class="control-buttons">
    <button *ngIf="createAccess" class="button-blue" (click)="addFidelitySession()">{{ 'Fidelity.AddSession' | translate }}</button>
    <button *ngIf="updateAccess" class="button-blue" (click)="editFidelityChart()">{{ 'Settings' | translate }}</button>
    <button class="button-blue" (click)="backToList()">{{ 'Overview' | translate }}</button>
  </div>
</div>
<div class="overview-wrapper">
  <fidelity-chart-view-chart *ngIf="selectedFidelitySession" [fidelitySessionId]="selectedFidelitySession.id" [fidelitySession]="selectedFidelitySession" [fidelityChart]="fidelityChart"></fidelity-chart-view-chart>
</div>
<fidelity-session [fidelityChartId]="id" [dataChange]="dataChange" (selectedFidelitySessionChanged)="setSelectedFidelitySession($event)"></fidelity-session>
<custom-dialog [visible]="editDialog" *ngIf="editDialog" (editModeChanged)="dialogChanged($event)">
  <div class="header">
    <h1>{{ 'Chart.Settings' | translate }}</h1>
  </div>
  <fidelity-chart-overview [fidelityChartId]="fidelityChart.id" [instrumentId]="fidelityChart.instrumentId" (editModeChanged)="dialogChanged($event)"></fidelity-chart-overview>
</custom-dialog>
<custom-dialog [visible]="sessionDialog" *ngIf="sessionDialog" (editModeChanged)="sessionDialogChanged($event)">
  <fidelity-session-overview [fidelitySession]="sessionDialogSession" (editModeChanged)="sessionDialogChanged($event)"></fidelity-session-overview>
</custom-dialog>
