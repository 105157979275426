import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { HistoricalChartEvent } from './historical-chart-event';

@Component({
  selector: 'historical-chart-event-overview',
  templateUrl: './historical-chart-event-overview.component.html'
})
export class HistoricalChartEventOverviewComponent implements OnInit {
  constructor() { }
  @Input() public historicalChartEvent: HistoricalChartEvent;
  @Input() public initiatedByReport: boolean;
  @Output() public dialogChanged = new EventEmitter<boolean>();
  public validationSessionId: number;
  public controlChartSetId: number;
  public maintenanceRecordId: number;
  public equipmentId: number;
  public controlLimitId: number;
  public referenceMaterialId: number;
  public sampleSizeChange: boolean;

  ngOnInit() {
    if (this.historicalChartEvent) { this.getHistoricalChartEventDetails(); }
  }

  getHistoricalChartEventDetails() {
    this.validationSessionId = null;
    this.controlChartSetId = null;
    this.maintenanceRecordId = null;
    this.equipmentId = null;
    this.controlLimitId = null;
    this.referenceMaterialId = null;

    switch (this.historicalChartEvent.eventType) {
      case 'Chart.Events.ReferenceMaterialChanged':
        this.referenceMaterialId = this.historicalChartEvent.id;
        break;
      case 'Chart.Events.ControlLimitsChanged':
        this.controlLimitId = this.historicalChartEvent.id;
        break;
      case 'Chart.Events.InstrumentAdjusted':
        this.maintenanceRecordId = this.historicalChartEvent.id;
        this.equipmentId = this.historicalChartEvent.helperId;
        break;
      case 'Chart.Events.ValidationData':
        this.validationSessionId = this.historicalChartEvent.id;
        this.controlChartSetId = this.historicalChartEvent.helperId;
        break;
      case 'Chart.Events.SampleSizeChanged':
        this.sampleSizeChange = true;
        break;
      default:
        break;
    }
  }

  cancel() {
    this.dialogChanged.emit(false);
  }
}
