<div class="header">
  <h1>{{ title | translate }}</h1>
</div>
<div class="overview-wrapper">
  <div class="overview-header">{{ title | translate }}</div>
  <div class="navItem" *ngIf="reportManagementAccess">
    <i class="material-icons">chevron_right</i>
    <a routerLink="/administration/report/type">{{ 'ReportTypes' | translate }}</a>
  </div>
  <div class="navItem" *ngIf="reportAccess">
    <i class="material-icons">chevron_right</i>
    <a routerLink="/administration/report/userdefined">{{ 'ReportType.UserDefined' | translate }}</a>
  </div>
  <div class="navItem" *ngIf="reportManagementAccess">
    <i class="material-icons">chevron_right</i>
    <a routerLink="/administration/report/settings">{{ 'Report.Settings' | translate }}</a>
  </div>
</div>
