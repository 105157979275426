
import {map, filter} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { RouterModule, Routes, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';

import { Breadcrumb } from './breadcrumb';
import { AppConfig } from '../app.config';
import { SidebarService } from '../sidebar/sidebar.service';

@Injectable()
export class BreadcrumbService {
  private apiHost: string;
  constructor(
    private appConfig: AppConfig,
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sidebarService: SidebarService
  ) {
    this.apiHost = this.appConfig.getConfig('apiHost');
    if (router.events !== undefined) {
      this.breadcrumbs = router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => this.buildBreadcrumb(activatedRoute.root)));
    }
  }

  private breadcrumbs: Observable<Breadcrumb[]>;
  private informationLevelBreadcrumbs: Breadcrumb[] = [];
  private breadcrumbs1: Breadcrumb[] = [];
  private breadcrumbs2: Breadcrumb[] = [];
  private paramId;

  public getBreadcrumbs(): Observable<Breadcrumb[]> {
    return this.breadcrumbs;
  }

  public getInformationLevelBreadcrumbs(): Observable<Breadcrumb[]> {
    return of(this.informationLevelBreadcrumbs);
  }

  public getBreadcrumbs2(): Observable<Breadcrumb[]> {
    return of(this.breadcrumbs2);
  }

  public getInformationLevelItems(id): void {
    // Retrieve the breadcrumbs for the information level and it's parents from the backend.
    const url = this.apiHost + 'navigation/breadcrumbs?id=' + id;
    this.http.get<Breadcrumb[]>(url)
      .toPromise()
      .then(data => {
        this.informationLevelBreadcrumbs.splice(0);
        if (data !== null && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            this.informationLevelBreadcrumbs.push(data[i]);
          }
        }
        const allBreadcrumbs: Breadcrumb[] = [];
        allBreadcrumbs.push.apply(allBreadcrumbs, this.breadcrumbs1);
        allBreadcrumbs.push.apply(allBreadcrumbs, this.informationLevelBreadcrumbs);
        allBreadcrumbs.push.apply(allBreadcrumbs, this.breadcrumbs2);
        this.sidebarService.expandCurrentBreadcrumbItems(allBreadcrumbs);
      });
  }

  public buildBreadcrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<Breadcrumb> = [], hasInformationLevel: boolean = false, testPath: string[] = null): Array<Breadcrumb> {
    if (url === '') {
      this.breadcrumbs1.splice(0);
      this.breadcrumbs2.splice(0);
    }

    if (route.snapshot !== undefined && route.snapshot.paramMap.get('id')) {
      const activePath = (this.router.url !== undefined) ? this.router.url.split('/') : testPath;
      if (activePath[1] === 'area' || activePath[1] === 'instrument') {
        this.paramId = route.snapshot.paramMap.get('id');
        this.getInformationLevelItems(this.paramId);
      }
    }

    let nextUrl = '/';
    if (route.routeConfig && route.routeConfig.data && route.routeConfig.data.title) {
      const title = route.routeConfig.data.title;
      const path = (this.paramId) ? route.routeConfig.path.replace(':id', this.paramId) : route.routeConfig.path;
      nextUrl = `${url}${path}/`;
      const breadcrumb = {
        title: title,
        path: nextUrl
      };

      if (title !== 'InformationLevel') {
        if (hasInformationLevel === false) {
          if (breadcrumb.title === 'Instruments' && breadcrumb.path === '//instrument/') {
            breadcrumb.title = 'Areas';
            breadcrumb.path = '//area/';
          }
          breadcrumbs.push(breadcrumb);
          this.breadcrumbs1.push(breadcrumb);
        } else {
          this.breadcrumbs2.push(breadcrumb);
        }
      } else {
        hasInformationLevel = true;
      }
    }

    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb
      return this.buildBreadcrumb(route.firstChild, nextUrl, breadcrumbs, hasInformationLevel, testPath);
    }

    if (hasInformationLevel === false) {
      this.informationLevelBreadcrumbs.splice(0);
    }

    return breadcrumbs;
  }
}
