import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../../../core/app.config';
import { Criticality } from './criticality';

@Injectable()
export class CriticalityService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getCriticalities(filterArguments: string): Observable<Criticality[]> { return this.http.get<Criticality[]>(this.apiHost + 'criticality/get' + filterArguments); }

  public countCriticalities(filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'criticality/count' + filterArguments); }

  public getCriticality(id: number): Observable<Criticality> { return this.http.get<Criticality>(this.apiHost + 'criticality/get/' + id); }

  public getCriticalityCount(filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'criticality/count' + filterArguments); }

  public postCriticality(criticality: Criticality) { return this.http.post(this.apiHost + 'criticality/post', criticality); }

  public putCriticality(criticality: Criticality) { return this.http.put(this.apiHost + 'criticality/put', criticality); }

  public deleteCriticality(id: number) { return this.http.delete(this.apiHost + 'criticality/delete/' + id); }

  public resequenceCriticalities(criticalities: Criticality[]) { return this.http.put(this.apiHost + 'criticality/resequence', criticalities); }

  public newCriticality(): Criticality {
    return { id: -1, name: '', sequenceNumber: 1 };
  }
}
