import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared/shared.module';

import { DiagnosticDisplayComponent } from './diagnostic-display.component';

@NgModule({
  declarations: [
    DiagnosticDisplayComponent
  ],
  exports: [
    DiagnosticDisplayComponent
  ],
  imports: [
    SharedModule
  ]
})
export class DiagnosticDisplayModule { }
