import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class ClaimService {
  constructor(private oauthService: OAuthService) { }
  private static useCustomClaims: boolean;
  private static customClaims: { [type: string]: any } = {};

  public isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  /* Check whether a claim is present in the current access token, and has a certain value. */
  public checkClaim(type: string, value: string): boolean {
    if (ClaimService.useCustomClaims) {
      if (type === 'scope') {
        for (let i = 0; i < ClaimService.customClaims[type].length; i++) {
          if (ClaimService.customClaims[type][i] === value) {
            return true;
          }
        }
      }
      return ClaimService.customClaims[type] === value;
    }

    try {
      const claims = this.parseJwt(this.oauthService.getAccessToken());
      const claim = claims[type];
      if (claim === value) {
        return true;
      }

      // Perhaps there are multiple values for the same type, attempt iterating through them.
      for (let i = 0; i < claim.length; i++) {
        if (claim[i] === value) {
          return true;
        }
      }

      return false;
    } catch (e) {
      return false;
    }
  }

  /* Check whether a claim is present in the current access token, and contains a certain value. */
  public checkClaimContains(type: string, value: string): boolean {
    if (ClaimService.useCustomClaims) {
      return (ClaimService.customClaims[type] && ClaimService.customClaims[type].includes(value));
    }

    try {
      const claims = this.parseJwt(this.oauthService.getAccessToken());
      const claim: string = claims[type];
      if (claim.includes(value)) {
        return true;
      }

      // Perhaps there are multiple values for the same type, attempt iterating through them.
      for (let i = 0; i < claim.length; i++) {
        if (claim[i].includes(value)) {
          return true;
        }
      }

      return false;
    } catch (e) {
      return false;
    }
  }

/* Get all current claims. */
  public getAllClaims(): { [type: string]: string } {
    return this.parseJwt(this.oauthService.getAccessToken());
  }

  /* Retrieves the sub claim, containing the userId. */
  public getUserId(): string {
    try {
      const claims = this.parseJwt(this.oauthService.getAccessToken());
      return claims.sub;
    } catch (e) {
      return null;
    }
  }

  public setCustomClaims(customClaims: { [type: string]: string }) {
    ClaimService.useCustomClaims = true;
    ClaimService.customClaims = customClaims;
  }

  /* Parses the access token to retrieve scopes and claims. */
  private parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
}
