<div class="overview-wrapper">
  <div class="overview-header">{{ 'Configuration' | translate }}</div>
  <loader *ngIf="loading"></loader>
  <div class="grid-wrapper">
    <div class="grid-header">
      {{ this.filter.filterOption | translate }}
      <button *ngIf="filterOptions" class="button-blue" (click)="configureFilters()"><i class="material-icons">filter_list</i> {{ 'Filters' | translate }}</button>
    </div>
    <data-filter [filterList]="filterList" [editMode]="false"></data-filter>
    <form (ngSubmit)="submit(listFilterForm)" #listFilterForm="ngForm">
      <grid-table [data]="data" [count]="count" [visibleColumns]="visibleColumns" [showActionColumn]="false" [showSelectionColumn]="true"
                  [showAutoRefresh]="false" [dataArguments]="dataArguments" [selectedIds]="selectedIds" (refreshEvent)="refresh()"></grid-table>
      <error-message [error]="error"></error-message>
      <div class="control-buttons">
        <button type="reset" class="button-gray" (click)="cancel()">Cancel</button>
        <button class="button-blue" type="submit" [ngClass]="{'disabled': listFilterForm.invalid}">{{ 'Save' | translate }}</button>
      </div>
    </form>
  </div>
</div>
<custom-dialog [visible]="filterDialog" *ngIf="filterDialog" (editModeChanged)="filterDialogChanged($event)">
  <data-filter [viewAsDialog]="true" [filterOptions]="filterOptions" [filterList]="filterList" [editMode]="true" (filterChangedEvent)="refresh()" (editModeChanged)="filterDialogChanged($event)"></data-filter>
</custom-dialog>
