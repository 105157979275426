import { Component, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { TreeItem } from './tree-item';
import { SidebarService } from './sidebar.service';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit, OnDestroy {
  constructor(
    private sidebarService: SidebarService,
    private router: Router
  ) { }

  @Output() toggle = new EventEmitter<void>();
  private mouseX: number = 0;
  private sliderPosition: number = 0;
  public sidebarWidth = '18vw';
  public sidebarSliderLeft = '18vw';
  public mainLeft = 'calc(18vw + 9px)';
  public mainWidth = 'calc(82vw - 9px)';
  public treeItems: TreeItem[];
  private subscription: Subscription;

  ngOnInit() {
    this.getTree();
    this.subscription = this.router.events.subscribe(event => { if (event instanceof NavigationStart && !this.router.navigated) { this.getTree(); } });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getTree() {
    this.sidebarService.getTree().subscribe(items => this.treeItems = items);
  }

  onClick(event: MouseEvent) {
    this.mouseX = event.clientX - this.sliderPosition;
  }

  onDragStart(event: DragEvent, path: string) { // Allows path to be transferred to area overview for drag and drop.
    event.dataTransfer.setData('amlPath', path);
  }

  dragSidebarSlider(event: MouseEvent) {
    if (event.clientX > 0) {
      this.sliderPosition = event.clientX - this.mouseX;
      this.sidebarWidth = 'calc(18vw + ' + this.sliderPosition + 'px)';
      this.sidebarSliderLeft = 'calc(18vw + ' + this.sliderPosition + 'px)';
      this.mainLeft = 'calc(18vw + 9px + ' + this.sliderPosition + 'px)';
      this.mainWidth = 'calc(82vw - 9px - ' + this.sliderPosition + 'px)';
    }
  }

  toggleTreeItem(item: TreeItem) {
    item.expand = !item.expand;
  }

  toggleSidebar() {
    this.toggle.emit();
  }
}
