<form id="search" [ngClass]="smallScreenClass" (submit)="navigateToFirstResult()">
  <input id="search-input" name="search-input" type="text" [(ngModel)]="searchInput" [ngClass]="{ 'no-results': results && resultKeys && resultKeys.length === 0 }" autocomplete="off" #searchInputElRef />
  <i id="search-icon" class="material-icons" (click)="navigateToFirstResult()" title="{{ 'Search' | translate }}">search</i>
  <div id="search-results-wrapper" *ngIf="results && resultKeys && resultKeys.length > 0">
    <div id="search-results">
      <div *ngFor="let key of resultKeys">
        <div class="search-category">{{ key | translate }}</div>
        <ul>
          <li *ngFor="let item of results[key]">
            <a routerLink="{{item.path}}" (click)="reset()">{{item.title}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</form>
<span class="no-big-screen" [ngClass]="smallScreenIconClass" (click)="toggleSmallScreenClass()">
  <i class="material-icons">search</i>
</span>
