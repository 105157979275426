<form (ngSubmit)="submitItem(itemForm)" #itemForm="ngForm">
  <table *ngIf="item && properties" class="overview">
    <tr *ngFor="let property of properties; let i=index">
      <td>{{ property.header | translate }}:</td>
      <td *ngIf="editMode">
        <data-form-item [item]="item" [property]="property" [informationLevelId]="informationLevelId"></data-form-item>
      </td>
      <td *ngIf="!editMode">{{ getContent(property) }}</td>
    </tr>
  </table>
  <error-message [error]="error"></error-message>
  <div class="control-buttons" *ngIf="item && !editMode && !embedded && updateAccess">
    <button class="button-blue" type="button" (click)="editItem()">{{ 'Edit' | translate }}</button>
  </div>
  <div class="control-buttons" *ngIf="item && editMode && !embedded">
    <button class="button-gray" type="reset" (click)="cancelClicked()">{{ 'Cancel' | translate }}</button>
    <submit-button name="Save" [disabled]="itemForm.invalid"></submit-button>
  </div>
</form>
