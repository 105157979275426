import { Injectable } from '@angular/core';
import { Route, Router, CanLoad } from '@angular/router';

import { ClaimService } from '../../claim.service';
import { RouterExtService } from '../../router-ext.service';

@Injectable()
export class ReportManagementGuard implements CanLoad {
  constructor(private claimService: ClaimService, private router: Router, private routerExtService: RouterExtService) { }

  canLoad(route: Route): boolean {
    if (this.claimService.isLoggedIn()) {
      /* Process route and claims to reach a decision. */
      let authorized = false;
      switch (route.path) {
        case 'userdefined':
          authorized = this.claimService.checkClaim('scope', 'report') && (this.claimService.checkClaimContains('aml.report', 'r'));
          break;
        case 'settings':
        case 'type':
          authorized = this.claimService.checkClaim('scope', 'reportman') && (this.claimService.checkClaimContains('aml.reportman', 'r'));
          break;
        default:
          break;
      }

      if (authorized) {
        return true;
      } else {
        this.routerExtService.setRedirectedRoute(route);
        this.router.navigate(['401']);
        return false;
      }
    } else {
      return false;
    }
  }
}
