import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ClaimService } from '../../../../../core/claim.service';
import { ActivatedRoute, Router } from '@angular/router';

import { ControlChartSet } from '../../control-chart-set';
import { ControlChartService } from '../control-chart.service';
import { DataSerie } from '../../../../../shared/chart/data-serie';
import { Comp } from '../../../stream/comp/comp';
import { CompService } from '../../../stream/comp/comp.service';
import { ErrorMessage } from '../../../../../shared/error/error-message';
import { AppConfig } from '../../../../../core/app.config';

@Component({
  selector: 'control-chart-report-precision-chart',
  templateUrl: './control-chart-report-precision-chart.component.html',
  styleUrls: ['./control-chart-report-precision-chart.component.css']
})
export class ControlChartReportPrecisionChartComponent implements OnInit {
  constructor(
    private controlChartService: ControlChartService,
    private compService: CompService,
    private claimService: ClaimService,
    private route: ActivatedRoute,
    private router: Router,
    private appConfig: AppConfig
  ) { }
  @Input() id = -1;
  @Output() public chartInitialized: EventEmitter<void> = new EventEmitter();
  public controlChartSet: ControlChartSet;
  private component: Comp;
  private colors: any;
  public valDataDialog = false;
  private precisionChartPoints: DataSerie;
  private validationSessionId = -1;
  private index: number;
  private unit: string;
  public error: ErrorMessage;
  private dateTimeFormat: string;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.getControlChartSet();
  }

  getControlChartSet() {
    this.controlChartService.getControlChartSet(this.id, true).subscribe(ccs => {
      this.controlChartSet = ccs;
      this.compService.getComp(ccs.componentId).subscribe(comp => {
        this.colors = [{ name: comp.tag, value: '#000000' }];
        this.component = comp;
        this.index = 0;
        this.precisionChartPoints = {
          name: comp.tag, connected: true, shape: 'circle', series: ccs.precisionChart.validationResults.map(valResult => {
            let color;
            let stroke;
            switch (valResult.worstResult) {
              case 'Bad':
                color = '#FF2222';
                break;
              case 'Alert':
                color = '#FFAA22';
                break;
              default:
                color = '#8000BD';
                stroke = '#000000';
                break;
            }
            return (<any>{
              value: valResult.reading, name: this.index++, params: {
                color: color, stroke: stroke, value: valResult.reading, unit: valResult.unit, validationSessionId: valResult.validationSessionId,
                validationStart: valResult.validationStartDateTime, validationEnd: valResult.validationEndDateTime, validationProcedure: valResult.validationMethod
              }
            });
          })
        };
        this.chartInitialized.emit();
      });
    });
  }

  valDataDialogChanged(event: boolean) {
    this.valDataDialog = event;
    this.getControlChartSet();
  }

  viewDetails(event: any) {
    this.validationSessionId = event.params.validationSessionId;
    this.valDataDialog = true;
  }
}
