import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../../../core/app.config';
import { OperationalState } from './operational-state';
import { ClaimService } from '../../../core/claim.service';

@Injectable()
export class OperationalStateService {
  constructor(
    private appConfig: AppConfig,
    private http: HttpClient,
    private claimService: ClaimService
  ) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getOperationalStates(filterArguments: string): Observable<OperationalState[]> {
    return this.http.get<OperationalState[]>(this.apiHost + 'operationalstate/get' + filterArguments);
  }

  public getOperationalStatesByEquipment(equipmentId: number, filterArguments: string): Observable<OperationalState[]> {
    return this.http.get<OperationalState[]>(this.apiHost + 'operationalstate/getbyequipment/' + equipmentId + filterArguments);
  }

  public getOperationalStateCountByEquipment(equipmentId: number, filterArguments: string): Observable<number> {
    return this.http.get<number>(this.apiHost + 'operationalstate/countbyequipment/' + equipmentId + filterArguments);
  }

  public getCurrentOperationalStateByEquipment(equipmentId: number): Observable<OperationalState> {
    return this.http.get<OperationalState>(this.apiHost + 'operationalstate/getcurrentbyequipment/' + equipmentId);
  }

  public getOperationalStateTypes(): Observable<string[]> { return this.http.get<string[]>(this.apiHost + 'operationalstate/getoperationalstatetypes'); }

  public getOperationalState(id: number): Observable<OperationalState> { return this.http.get<OperationalState>(this.apiHost + 'operationalstate/get/' + id); }

  public requestOperationalStateChange(opState: OperationalState): Observable<string> {
    return this.http.post<string>(this.apiHost + 'operationalstate/requeststatechange', opState, { responseType: 'text' as 'json' });
  }

  public allowUtilisedOnEquipment(id: number): Observable<boolean> { return this.http.get<boolean>(this.apiHost + 'operationalstate/allowutilisedonequipment/' + id); }

  public hasPendingMaintenanceRequest(id: number): Observable<boolean> { return this.http.get<boolean>(this.apiHost + 'operationalstate/haspendingmaintenancerequest/' + id); }

  public cancelMaintenanceRequest(id: number) { return this.http.delete(this.apiHost + 'operationalstate/cancelmaintenancerequest/' + id); }

  public newOperationalState(equipmentId: number, startDateTime: string = ''): OperationalState {
    return { id: -1, state: '', startDateTime, equipmentId, initiatedBy: '', initiatedById: this.claimService.getUserId() };
  }

  public getOperationalStateClass(state: string): string {
    switch (state) {
      case 'Utilised':
        return 'os-utl';
      case 'RoutineMaintenance':
        return 'os-rtn-mnt';
      case 'NonRoutineMaintenance':
        return 'os-nrt-mnt';
      case 'Fault':
        return 'os-flt';
      default:
        return 'os-nrq';
    }
  }
}
