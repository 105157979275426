<div class="charts-block overview-wrapper">
  <table style="width: 100%;">
    <tr>
      <td>
        <chart *ngIf="chart"
               (select)="viewDetails($event)"
               (selectSeries)="selectSeries($event)"
               [title]="'GaussianChart.GaussianChart' | translate"
               [allowTypeChange]="false"
               [chartType]="'verbarchart'"
               [dataPoints]="chart"
               [scheme]="{ 'domain': ['#71B4D5'] }"
               [xAxis]="'Deviation' | translate">
        </chart>
      </td>
    </tr>
  </table>
  <div>
    <span class="timepicker">
      {{ 'StartDateTime' | translate }}: <input [owlDateTime]="dtStartDateTime" [owlDateTimeTrigger]="dtStartDateTime" [(ngModel)]="start" (ngModelChange)="getChartData(start, end)" />
      <owl-date-time #dtStartDateTime></owl-date-time>
    </span>
    <span class="timepicker">
      {{ 'EndDateTime' | translate }}: <input [owlDateTime]="dtEndDateTime" [owlDateTimeTrigger]="dtEndDateTime" [(ngModel)]="end" (ngModelChange)="getChartData(start, end)" />
      <owl-date-time #dtEndDateTime></owl-date-time>
    </span>
    <button class="button-blue" (click)="matchControlChart()">{{ 'ControlChart.Match' | translate }}</button>
  </div>
</div>
