import { NgModule } from '@angular/core';

import { SharedModule } from '../../../../shared/shared.module';

import { LinearityChartViewChartComponent } from './linearity-chart-view-chart.component';
import { LinearityChartService } from './linearity-chart.service';
import { LinearitySessionService } from './linearity-session.service';

@NgModule({
  declarations: [
    LinearityChartViewChartComponent
  ],
  exports: [
    LinearityChartViewChartComponent
  ],
  imports: [
    SharedModule
  ],
  providers: [
    LinearityChartService,
    LinearitySessionService
  ]
})
export class LinearityChartModule { }
