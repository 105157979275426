import { Component, Input, OnInit } from '@angular/core';

import { HistoricalChartEventService } from './historical-chart-event.service';
import { ControlLimitsChanged } from './control-limits-changed';
import { AppConfig } from '../../../../core/app.config';

@Component({
  selector: 'control-limits-changed',
  templateUrl: './control-limits-changed.component.html',
  styleUrls: ['./control-limits-changed.component.css']
})
export class ControlLimitsChangedComponent implements OnInit {
  constructor(
    private historicalChartEventService: HistoricalChartEventService,
    private appConfig: AppConfig
  ) { }
  @Input() public controlLimitId: number;
  public controlLimitsChanged: ControlLimitsChanged;
  public title = 'ControlLimitsChanged';
  private dateTimeFormat: string;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.getControlLimitsChanged();
  }

  getControlLimitsChanged() {
    this.historicalChartEventService.getControlLimitsChanged(this.controlLimitId).subscribe(clc => this.controlLimitsChanged = clc);
  }
}
