<div *ngIf="instruments && instruments.length" style="margin-top:-15px;">
  <grid #grid [genericDataRetrieval]="false" [title]="title" [endpoint]="endpoint" [headers]="headers" [count]="count" [showFilters]="false" [showHeader]="false" [showFooter]="false" [initialPageSize]="10" [areaId]="areaId" [sortColumn]="'Criticality.Criticality'" (filterEvent)="filterEv($event)">
    <tr *ngFor="let instrument of instruments; let i = index; let odd = odd" [class.odd]="odd">
      <td [title]="instrument.parentName">{{ instrument.parentName }}</td>
      <td [title]="instrument.tag"><a routerLink="/instrument/{{ instrument.id }}" routerLinkActive="active">{{ instrument.tag }}</a></td>
      <td [title]="instrument.criticality ? instrument.criticality : ''">{{ instrument.criticality }}</td>
      <td [title]="instrument.availabilityRate">{{ instrument.availabilityRate }}</td>
      <td [title]="instrument.reliabilityRate">{{ instrument.reliabilityRate }}</td>
    </tr>
  </grid>
</div>
<div *ngIf="!instruments || instruments.length === 0">{{ 'NoData' | translate }}</div>
