import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../core/app.config';
import { IoServer } from './io-server';
import { IoStatus } from './io-status';

@Injectable()
export class IoServerService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getIoServers(filterArguments: string): Observable<IoServer[]> { return this.http.get<IoServer[]>(this.apiHost + 'ioserver/get' + filterArguments); }

  public getIoServer(id: number): Observable<IoServer> { return this.http.get<IoServer>(this.apiHost + 'ioserver/get/' + id); }

  public getIoServerCount(filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'ioserver/count' + filterArguments); }

  public deleteIoServer(id: number) { return this.http.delete(this.apiHost + 'ioserver/delete/' + id); }

  public addOrUpdateIoServers(ioServers: IoServer[]) { return this.http.post(this.apiHost + 'ioserver/addorupdaterange', ioServers); }

  public getIoStatus(): Observable<IoStatus> { return this.http.get<IoStatus>(this.apiHost + 'io/iotest'); }

  public newIoServer(): IoServer {
    return { id: -1, name: '', url: '', status: null, port: null };
  }
}
