import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { ChartComponent } from './chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { ShapeSeriesComponent } from './common/shape-series.component';
import { LineSeriesComponent } from './common/line-series.component';
import { ShapeComponent } from './common/shape.component';
import { YAxisComponent } from './common/y-axis.component';
import { YAxisTicksComponent } from './common/y-axis-ticks.component';
import { TimelineChartComponent } from './timeline-chart/timeline-chart.component';

@NgModule({
  declarations: [
    ChartComponent,
    ShapeSeriesComponent,
    LineSeriesComponent,
    LineChartComponent,
    ShapeComponent,
    YAxisComponent,
    YAxisTicksComponent,
    TimelineChartComponent
  ],
  exports: [
    ChartComponent,
    ShapeSeriesComponent,
    LineSeriesComponent,
    LineChartComponent,
    ShapeComponent,
    YAxisComponent,
    YAxisTicksComponent,
    TimelineChartComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NgxChartsModule
  ],
  providers: []
})

export class ChartModule { }
