<ng-container *ngIf="filter && filter.type && filter.type === 'perfindic' && filter.filterDetails && editMode">
  <select #performanceIndicatorElement [(ngModel)]="filter.filterDetails.performanceIndicatorId" (change)="changedPI($event)" name="performanceIndicator">
    <option [value]="null"></option>
    <option *ngFor="let performanceIndicator of performanceIndicators" [value]="performanceIndicator.id">{{ performanceIndicator.name | translate }}</option>
  </select>
  <select #operatorElement [(ngModel)]="filter.filterDetails.operator" (change)="changedOperator($event)" name="performanceIndicatorFilterOperator">
    <option></option>
    <option [value]="'=='">{{ 'Comparison.eq' | translate }}</option>
    <option [value]="'<'">{{ 'Comparison.st' | translate }}</option>
    <option [value]="'>'">{{ 'Comparison.gt' | translate }}</option>
  </select>
  <input #filterTextElement type="number" [(ngModel)]="filter.filterText" (change)="changedFilterText($event)" name="performanceIndicatorFilter" />
</ng-container>
<ng-container *ngIf="filter && filter.type && filter.type === 'perfindic' && filter.filterDetails && !editMode">
  <ng-container *ngIf="filter.filterDetails.name">{{ filter.filterDetails.name }}</ng-container>
  <ng-container *ngIf="filter.filterDetails.operator === '=='"> {{ 'Comparison.eq' | translate }} </ng-container>
  <ng-container *ngIf="filter.filterDetails.operator === '<'"> {{ 'Comparison.st' | translate }} </ng-container>
  <ng-container *ngIf="filter.filterDetails.operator === '>'"> {{ 'Comparison.gt' | translate }} </ng-container>
  <span>{{ filter.filterText }}</span>
</ng-container>
