import { Component, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { GridColumn } from './grid-column';
import { ErrorMessage } from '../error/error-message';

@Component({
  selector: 'grid-configuration',
  templateUrl: './grid-configuration.component.html'
})
export class GridConfigurationComponent implements OnInit {
  constructor() { }
  @Input() public columns: GridColumn[] = [];
  @Output() public editModeChanged = new EventEmitter<boolean>();
  public editMode: boolean = false;
  public error: ErrorMessage;
  public columnsChanged: GridColumn[] = [];

  ngOnInit() {
    this.getHeaders();
  }

  getHeaders() {
    this.error = null;
    this.columnsChanged = JSON.parse(JSON.stringify(this.columns)); //Creates a deep copy.
  }

  submit(form: NgForm) {
    if (form.valid) {
      this.error = null;

      for (let i = 0; i < this.columns.length; i++) {
        this.columns[i].enabled = this.columnsChanged[i].enabled;
      }

      this.changeEditMode(false);
    } else {
      for (const i of Object.keys(form.controls)) {
        form.controls[i].markAsTouched();
      }
    }
  }

  cancel() {
    this.changeEditMode(false);
  }

  changeEditMode(editMode: boolean) {
    this.editMode = editMode;
    this.editModeChanged.emit(editMode);
  }
}
