import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { PerformanceIndicatorFilterComponent } from './perf-indic-filter.component';
import { PerformanceIndicatorService } from '../perf-indic.service';

@NgModule({
  declarations: [PerformanceIndicatorFilterComponent],
  exports: [PerformanceIndicatorFilterComponent],
  imports: [CommonModule, TranslateModule],
  providers: [PerformanceIndicatorService]
})
export class PerformanceIndicatorFilterModule { }
