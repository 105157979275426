import { Component, Input, OnInit } from '@angular/core';
import { ClaimService } from '../../../../core/claim.service';
import { ActivatedRoute, Router } from '@angular/router';

import { LinearityChart } from './linearity-chart';
import { LinearityChartService } from './linearity-chart.service';
import { LinearitySession } from './linearity-session';
import { LinearitySessionService } from './linearity-session.service';
import { DataPoint } from '../../../../shared/chart/data-point';
import { DataSerie } from '../../../../shared/chart/data-serie';
import { ErrorMessage } from '../../../../shared/error/error-message';
import { AppConfig } from '../../../../core/app.config';

@Component({
  selector: 'linearity-chart-view',
  templateUrl: './linearity-chart-view.component.html'
})
export class LinearityChartViewComponent implements OnInit {
  constructor(
    private linearityChartService: LinearityChartService,
    private linearitySessionService: LinearitySessionService,
    private claimService: ClaimService,
    private route: ActivatedRoute,
    private router: Router,
    private appConfig: AppConfig
  ) { }
  @Input() id: number;
  private linearityChart: LinearityChart;
  public selectedLinearitySession: LinearitySession;
  private linearitySessions: LinearitySession[];
  private chartData: DataSerie[];
  public dataChange: boolean;
  public createAccess: boolean;
  public updateAccess: boolean;
  public editDialog = false;
  private sessionDialogSession: LinearitySession;
  public sessionDialog = false;
  private args = '';
  private dateTimeFormat: string;
  public error: ErrorMessage;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.createAccess = this.claimService.checkClaimContains('aml.valsession', 'c');
    this.updateAccess = this.claimService.checkClaimContains('aml.valsession', 'u');

    if (!this.id) {
      this.id = +this.route.snapshot.paramMap.get('id');
    }

    this.selectFirstSession();
  }

  selectFirstSession() {
    this.linearitySessionService.getLinearitySessionsByChart(this.id, '').subscribe(linearitySessions => {
      this.linearitySessions = linearitySessions;
      if (linearitySessions.length > 0) {
        this.selectedLinearitySession = linearitySessions[0];
      }
      this.getLinearityChart();
    });
  }

  getLinearityChart() {
    this.linearityChartService.getLinearityChart(this.id).subscribe(linearityChart => {
      this.linearityChart = linearityChart;
      this.getLinearityChartData();
    }, error => this.error = error.error);
  }

  getLinearityChartData() {
    if (this.selectedLinearitySession) {
      this.chartData = this.selectedLinearitySession.linearityReadings.map(lr => ({
        connected: true,
        name: lr.componentType.description,
        series: [
          ({ name: 'Lean', params: lr.componentType, value: lr.leanAreaValue / lr.leanReadingValue * lr.calReadingValue }) as DataPoint,
          ({ name: 'Cal', params: lr.componentType, value: lr.calAreaValue }) as DataPoint,
          ({ name: 'Rich', params: lr.componentType, value: lr.richAreaValue / lr.richReadingValue * lr.calReadingValue }) as DataPoint
        ],
        shape: 'circle'
      }) as DataSerie);
    }
  }

  addLinearitySession() {
    this.error = null;
    this.linearitySessionService.newLinearitySession(this.linearityChart).subscribe(linearitySession => {
      this.sessionDialogSession = linearitySession;
      this.sessionDialog = true;
    }, error => this.error = error.error);
  }

  backToList() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  editLinearityChart() {
    this.editDialog = true;
  }

  dialogChanged(event: boolean) {
    this.editDialog = event;
    this.getLinearityChart();
  }

  sessionDialogChanged(event: boolean) {
    this.sessionDialog = event;
    if (this.selectedLinearitySession) {
      this.getLinearityChart();
    } else {
      this.selectFirstSession();
    }
    this.dataChange = !this.dataChange; // Invoke ngOnChanges
  }

  setSelectedLinearitySession(linearitySession: LinearitySession) {
    this.selectedLinearitySession = linearitySession;
    this.getLinearityChartData();
  }
}
