import { Component, Input, EventEmitter, Output, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { Io } from '../io';
import { IoService } from '../io.service';

@Component({
  selector: 'io-selection',
  templateUrl: './io-selection.component.html',
  styleUrls: ['./io-selection.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class IoSelectionComponent implements OnInit, OnChanges, OnDestroy {
  constructor(
    private ioService: IoService
  ) { }
  @Input() public ioId: number;
  @Output() public ioIdChange = new EventEmitter<number>();
  @Input() public informationLevelId: number;
  @Input() public informationLevelIdChanged: Observable<void>;
  private lastInformationLevelId: number;
  @Input() public dataType = '';
  @Input() public required: boolean;
  @Input() public disabled: boolean;
  @Output() public change: EventEmitter<void> = new EventEmitter();
  public rawIos: Io[];
  public calcedIos: Io[];
  private informationLevelIdChangedSubscription: Subscription;

  ngOnInit() {
    if (this.informationLevelIdChanged !== undefined) {
      this.informationLevelIdChangedSubscription = this.informationLevelIdChanged.subscribe(() => this.ngOnChanges());
    }
    this.lastInformationLevelId = this.informationLevelId;
    this.getIos();
  }

  ngOnChanges() {
    if (this.informationLevelId !== this.lastInformationLevelId) { // Only react to informationLevelId changes.
      this.lastInformationLevelId = this.informationLevelId;
      this.getIos();
    }
  }

  ngOnDestroy() {
    if (this.informationLevelIdChangedSubscription !== undefined) {
      this.informationLevelIdChangedSubscription.unsubscribe();
    }
  }

  getIos() {
    this.ioService.getAllIosByInformationLevel(this.informationLevelId, this.dataType).subscribe(ios => {
      this.rawIos = ios.filter(io => io.raw);
      this.calcedIos = ios.filter(io => !io.raw);
    });
  }

  ioChange(ioId: number) {
    this.ioIdChange.emit(ioId);
    this.change.emit();
  }
}
