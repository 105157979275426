<div class="header">
  <h1>{{ 'Diagnostic.Diagnostic' | translate }}</h1>
</div>
<form (ngSubmit)="submitDiag(diagForm)" #diagForm="ngForm">
  <table class="overview">
    <tr>
      <td>{{ 'AreaOrInstrument' | translate }}:</td>
      <td>
        <select [(ngModel)]="selectedLevelId" name="selectedLevel">
          <option [ngValue]="null"></option>
          <option *ngFor="let level of levels" [ngValue]="level.id">{{ level.name }}</option>
        </select>
      </td>
    </tr>
    <tr>
      <td>{{ 'Diagnostic.Diagnostic' | translate }}:</td>
      <td>
        <diagnostic-selection [(diagnosticId)]="selectedDiagnosticId" [informationLevelId]="selectedLevelId" [required]="true" [disabled]="!selectedLevelId" (change)="diagnosticIdChange.emit(selectedDiagnosticId)"></diagnostic-selection>
      </td>
    </tr>
  </table>
  <div class="control-buttons">
    <button class="button-gray" type="reset" (click)="cancel()">{{ 'Cancel' | translate }}</button>
    <submit-button name="Save" [disabled]="diagForm.invalid || !selectedLevelId"></submit-button>
  </div>
</form>
