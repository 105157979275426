import { NgModule } from '@angular/core';
import { OwlDateTimeModule } from '@shtian/ng-pick-datetime';

import { LinearityChartModule } from './linearity-chart.module';
import { SharedModule } from '../../../../shared/shared.module';

import { LinearityChartViewComponent } from './linearity-chart-view.component';
import { LinearitySessionComponent } from './linearity-session.component';
import { LinearityChartOverviewComponent } from './linearity-chart-overview.component';
import { LinearitySessionOverviewComponent } from './linearity-session-overview.component';
import { LinearityChartService } from './linearity-chart.service';
import { LinearitySessionService } from './linearity-session.service';
import { StreamService } from '../../stream/stream.service';

@NgModule({
  declarations: [
    LinearityChartViewComponent,
    LinearitySessionComponent,
    LinearityChartOverviewComponent,
    LinearitySessionOverviewComponent
  ],
  exports: [
    LinearityChartViewComponent,
    LinearityChartOverviewComponent
  ],
  imports: [
    LinearityChartModule,
    SharedModule,
    OwlDateTimeModule
  ],
  providers: [
    LinearityChartService,
    LinearitySessionService,
    StreamService
  ]
})
export class LinearityModule { }
