import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { AuditActionFilterComponent } from './audit-action-filter.component';

@NgModule({
  declarations: [AuditActionFilterComponent],
  exports: [AuditActionFilterComponent],
  imports: [CommonModule, TranslateModule]
})
export class AuditActionFilterModule { }
