import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../../../core/app.config';
import { CompType } from './comp-type';

@Injectable()
export class CompTypeService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getCompTypes(filterArguments: string): Observable<CompType[]> { return this.http.get<CompType[]>(this.apiHost + 'componenttype/get' + filterArguments); }

  public getCompType(id: number): Observable<CompType> { return this.http.get<CompType>(this.apiHost + 'componenttype/get/' + id); }

  public getCompTypeCount(filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'componenttype/count' + filterArguments); }

  public deleteCompType(id: number) { return this.http.delete(this.apiHost + 'componenttype/delete/' + id); }

  public addOrUpdateCompTypes(compTypes: CompType[]) { return this.http.post(this.apiHost + 'componenttype/addorupdaterange', compTypes); }

  public newCompType(): CompType {
    return { id: -1, description: '', symbol: '', unit: '', molWeight: null, componentTypeLimits: null };
  }
}
