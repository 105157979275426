<div id="sidebar" class="no-print" [ngStyle]="{'width': sidebarWidth}">
  <div class="content">
    <ul>
      <ng-template #recursiveList let-treeItems>
        <li *ngFor="let item of treeItems" [ngClass]="item.children.length > 0 ? 'has-children' : ''">
          <i class="material-icons no-children">chevron_right</i>
          <i class="material-icons expand-more" *ngIf="!item.expand" (click)="toggleTreeItem(item)">add</i>
          <i class="material-icons expand-less" *ngIf="item.expand" (click)="toggleTreeItem(item)">remove</i>
          <a [routerLink]="[item.path]" routerLinkActive="active" (click)="toggleSidebar()" (dragstart)="onDragStart($event, item.path)">{{ item.title | translate }}</a>
          <ul *ngIf="item.children.length > 0 && item.expand" [ngClass]="item.expand ? 'show' : 'hide'">
            <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item.children }"></ng-container>
          </ul>
        </li>
      </ng-template>
      <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: treeItems }"></ng-container>
    </ul>
    <i id="sidebar-hide-button" class="material-icons" (click)="toggleSidebar()">close</i>
  </div>
</div>
<div id="sidebar-slider" (mousedown)="onClick($event)" (drag)="dragSidebarSlider($event)" [ngStyle]="{'left': sidebarSliderLeft}" draggable="true" class="no-print"></div>
<div id="main" [ngStyle]="{'left': mainLeft, 'width': mainWidth}">
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
