import { Component, Input, EventEmitter, Output, OnInit, OnChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { Diagnostic } from '../diagnostic';
import { DiagnosticService } from '../diagnostic.service';

@Component({
  selector: 'diagnostic-selection',
  templateUrl: './diagnostic-selection.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class DiagnosticSelectionComponent implements OnInit, OnChanges {
  constructor(
    private diagnosticService: DiagnosticService
  ) { }
  @Input() public diagnosticId: number;
  @Output() public diagnosticIdChange = new EventEmitter<number>();
  @Input() public informationLevelId: number;
  private lastInformationLevelId: number;
  @Input() public required: boolean;
  @Input() public disabled: boolean;
  @Output() public change: EventEmitter<void> = new EventEmitter();
  public diagnostics: Diagnostic[];

  ngOnInit() {
    this.lastInformationLevelId = this.informationLevelId;
    this.getDiagnostics();
  }

  ngOnChanges() {
    if (this.informationLevelId !== this.lastInformationLevelId) { // Only react to informationLevelId changes.
      this.lastInformationLevelId = this.informationLevelId;
      this.getDiagnostics();
    }
  }

  getDiagnostics() {
    this.diagnosticService.getDiagnosticsByInformationLevel(this.informationLevelId, '?size=9999').subscribe(diagnostics => this.diagnostics = diagnostics);
  }
}
