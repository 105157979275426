<ng-container *ngIf="filter && filter.type && filter.type === 'validationstate'">
  <select #validationStateElement [(ngModel)]="filter.filterText" (change)="changed($event)" name="validationStateFilter">
    <option [value]="null"></option>
    <option value="InProgress">{{ 'InProgress' | translate }}</option>
    <option value="Halted">{{ 'Halted' | translate }}</option>
    <option value="Completed">{{ 'Completed' | translate }}</option>
    <option value="Aborted">{{ 'Aborted' | translate }}</option>
    <option value="Faulted">{{ 'Faulted' | translate }}</option>
    <option value="Stopped">{{ 'Stopped' | translate }}</option>
    <option value="Started">{{ 'Started' | translate }}</option>
  </select>
</ng-container>
