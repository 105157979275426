import { Component, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { SearchService } from './search.service';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})

export class SearchComponent implements AfterViewInit, OnDestroy {
  constructor(private searchService: SearchService, private router: Router) { }

  @ViewChild('searchInputElRef') searchInputElement: ElementRef;
  private searchInputSubscription: Subscription;
  public smallScreenClass: string = 'no-small-screen';
  public smallScreenIconClass: string = '';
  public searchInput: string = '';
  public results: any = null;
  public resultKeys: string[] = [];

  ngAfterViewInit() {
    this.searchInputSubscription = fromEvent(this.searchInputElement.nativeElement, 'keyup').pipe(
      debounceTime(1000),
      tap(() => this.search())
    ).subscribe();
  }

  ngOnDestroy() {
    if (this.searchInputSubscription) { this.searchInputSubscription.unsubscribe(); }
  }

  search() {
    const date = Date.now();
    this.searchService.concurrencyCheck = date;
    if (this.searchInput.length > 1) {
      this.searchService.search(this.searchInput).then(results => {
        if (this.searchService.concurrencyCheck === date) {
          this.results = results;
          if (this.results !== null) {
            this.resultKeys = Object.keys(this.results);
          } else {
            this.resultKeys = [];
          }
        } else {
          //Ignore the results, because a new search has been called before the previous search was ready.
        }
      });
    } else {
      this.results = null;
      this.resultKeys = [];
    }
  }

  reset() {
    this.results = null;
    this.resultKeys = [];
    this.searchInput = '';
    this.toggleSmallScreenClass();
  }

  navigateToFirstResult() {
    if (this.results !== null && this.resultKeys.length > 0) {
      const path = this.results[this.resultKeys[0]][0].path;
      this.reset();
      this.router.navigate([path]);
    } else {
      this.reset();
    }
  }

  toggleSmallScreenClass() {
    if (this.smallScreenClass === 'no-small-screen') {
      this.smallScreenClass = '';
      this.smallScreenIconClass = 'no-small-screen';
    } else {
      this.smallScreenClass = 'no-small-screen';
      this.smallScreenIconClass = '';
    }
  }
}
