import { FidelityComp } from './fidelity-comp';
import { FidelityGroup } from './fidelity-group';

export class FidelityChart {
  id: number;
  logNumber: number;
  componentStreamId: number;
  instrumentId: number;
  fidelityGroups: FidelityGroup[];
}
