import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared/shared.module';

import { ReportManagementRoutingModule } from './report-management-routing.module';
import { ReportManagementService } from './report-management.service';
import { ReportManagementComponent } from './report-management.component';
import { ReportUserDefinedComponent } from './report-user-defined.component';
import { ReportTypeComponent } from './report-type.component';
import { ReportTypeOverviewComponent } from './report-type-overview.component';
import { ReportTypeDataEditorComponent } from './report-type-data-editor.component';
import { ReportTypeLayoutEditorComponent } from './report-type-layout-editor.component';
import { ReportSettingsComponent } from './report-settings.component';
import { ReportManagementGuard } from './report-management-guard';
import { ReportViewPageService } from '../report-view-page.service';
import { ReportViewService } from '../report-view.service';
import { GridService } from '../../../shared/grid/grid.service';

@NgModule({
  declarations: [
    ReportManagementComponent,
    ReportUserDefinedComponent,
    ReportTypeComponent,
    ReportTypeOverviewComponent,
    ReportTypeDataEditorComponent,
    ReportTypeLayoutEditorComponent,
    ReportSettingsComponent
  ],
  imports: [
    ReportManagementRoutingModule,
    SharedModule
  ],
  providers: [
    ReportManagementGuard,
    ReportManagementService,
    ReportViewPageService,
    ReportViewService,
    GridService
  ]
})

export class ReportManagementModule { }
