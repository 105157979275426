<span *ngIf="diagnostic" [ngStyle]="diagnosticStyle(diagnostic)">
  <ng-container *ngIf="diagnostic.io && diagnostic.processValue != null">
    <ng-container [ngSwitch]="diagnostic.io.dataType">
      <ng-container *ngSwitchCase="'boolean'">{{ booleanDiagnosticOutput(diagnostic) }}</ng-container>
      <ng-container *ngSwitchCase="'int'">{{ diagnostic.processValue !== 'N.A.' ? (diagnostic.processValue | number) : diagnostic.processValue }}</ng-container>
      <ng-container *ngSwitchCase="'float'">{{ diagnostic.processValue !== 'N.A.' ? (diagnostic.processValue | number:numberPipeArguments(diagnostic)) : diagnostic.processValue }}</ng-container>
      <ng-container *ngSwitchCase="'text'">{{ diagnostic.processValue }}</ng-container>
    </ng-container>
  </ng-container>
</span>
