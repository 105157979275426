import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppConfig } from '../../core/app.config';
import { Favorite } from './favorite';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {
  constructor(
    private appConfig: AppConfig,
    private http: HttpClient
  ) {
    this.apiHost = this.appConfig.getConfig('apiHost');
    this.initialize();
  }
  private apiHost: string;
  private favorites: Favorite[];
  public favoritesChanged = new EventEmitter<Favorite[]>();

  public initialize() {
    this.http.get<Favorite[]>(this.apiHost + 'favorite/get').subscribe(favorites => {
      this.favorites = favorites;
      this.favoritesChanged.emit(this.favorites);
    });
  }

  public getFavorites(): Favorite[] {
    return this.favorites;
  }

  public postFavorite(informationLevelId: number) {
    return this.http.post(this.apiHost + 'favorite/post', informationLevelId).subscribe(() => this.initialize());
  }

  public deleteFavorite(informationLevelId: number) {
    return this.http.delete(this.apiHost + 'favorite/delete/' + informationLevelId).subscribe(() => this.initialize());
  }

  public isFavorite(id: number): boolean {
    const existing = (this.favorites) ? this.favorites.filter(item => item.informationLevelId === id) : [];
    return (existing.length > 0);
  }
}
