<div class="grid-wrapper">
  <grid [name]="'Reports'" [title]="title" [genericDataRetrieval]="false" [headers]="headers" [filterOptions]="filterOptions" [count]="count" [exportData]="reports" (filterEvent)="getReports($event)">
    <tr *ngFor="let report of reports; let i = index; let odd = odd" [class.odd]="odd">
      <td><a routerLink="{{ report.id }}">{{ report.description }}</a></td>
      <td>{{ report.reportTypeDescription | translate }}</td>
      <td>{{ report.updateDateTime | date:dateTimeFormat }}</td>
      <td>{{ report.updatedByUser }}</td>
      <td>
        <button class="button-blue" routerLink="{{ report.id }}">{{ 'Report.View' | translate }}</button>
        <button *ngIf="updateAccess" class="button-blue" (click)="editReport(report)">{{ 'Edit' | translate }}</button>
        <button *ngIf="report.savedDatasets && report.savedDatasets.length > 0" class="button-blue" (click)="openHistory(report)">{{ 'History' | translate }}</button>
        <button *ngIf="deleteAccess" class="button-red" (click)="deleteReport(report.id)">{{ 'Delete' | translate }}</button>
      </td>
    </tr>
  </grid>
  <error-message [error]="error"></error-message>
  <div *ngIf="createAccess" class="control-buttons">
    <button class="button-blue" (click)="addReport()">{{ 'Report.Add' | translate }}</button>
  </div>
</div>
<delete-dialog [visible]="deleteDialog" [error]="error" (confirmDelete)="confirmDelete($event)"></delete-dialog>
<custom-dialog [visible]="editDialog" *ngIf="editDialog" (editModeChanged)="dialogChanged($event)">
  <report-overview [report]="report" [informationLevelId]="informationLevelId" (editModeChanged)="dialogChanged($event)"></report-overview>
</custom-dialog>
<custom-dialog [visible]="historyDialog" *ngIf="historyDialog" (editModeChanged)="historyDialogChanged($event)">
  <report-history [report]="report" (viewModeChanged)="historyDialogChanged($event)"></report-history>
</custom-dialog>
