<div *ngIf="!embedded" class="header">
  <h1>{{ title | translate }}</h1>
</div>
<div *ngIf="!area">
  <loader *ngIf="!error"></loader>
  <error-message [error]="error"></error-message>
</div>
<form *ngIf="area" (ngSubmit)="submitArea(areaForm)" #areaForm="ngForm">
  <table class="overview">
    <tr>
      <td>{{ 'Name' | translate }}:</td>
      <td *ngIf="editMode">
        <input type="text" name="name" required [(ngModel)]="area.name" #name="ngModel" />
        <form-alert [field]="name" name="Name"></form-alert>
      </td>
      <td *ngIf="!editMode">{{ area.name }}</td>
    </tr>
    <tr>
      <td>{{ 'Area.Type' | translate }}:</td>
      <td *ngIf="editMode">
        <select required [(ngModel)]="area.type" name="type" #type="ngModel">
          <option *ngFor="let type of areaTypes" [ngValue]="type">{{ type | translate }}</option>
        </select>
        <form-alert [field]="type" name="Area.Type"></form-alert>
      </td>
      <td *ngIf="!editMode">{{ area.type | translate }}</td>
    </tr>
    <tr *ngIf="updateAccess">
      <td>{{ 'IoServer.IoServer' | translate }}:</td>
      <td *ngIf="editMode">
        <input type="hidden" name="ioServerId" [(ngModel)]="area.ioServerId" #ioServerId="ngModel" />
        <list-input [name]="'ioServer'" [model]="ioServerId" [endpoint]="'ioserver'" [selectedItem]="{ 'name': area.ioServer, 'id': area.ioServerId }"></list-input>
      </td>
      <td *ngIf="!editMode">{{ area.ioServer }}</td>
    </tr>
    <tr>
      <td>{{ 'Area.Containing' | translate }}:</td>
      <td *ngIf="editMode">
        <select [(ngModel)]="area.parentId" name="areaParent">
          <option></option>
          <option *ngFor="let a of areas" [ngValue]="a.id">{{ a.name }}</option>
        </select>
      </td>
      <td *ngIf="!editMode">{{ area.parent }}</td>
    </tr>
  </table>
  <error-message [error]="error"></error-message>
  <div class="control-buttons" *ngIf="!editMode && updateAccess">
    <button class="button-blue" type="button" (click)="editArea()">{{ 'Edit' | translate }}</button>
  </div>
  <div class="control-buttons" *ngIf="editMode">
    <button class="button-gray" type="reset" (click)="cancel()">{{ 'Cancel' | translate }}</button>
    <submit-button name="Save" [disabled]="areaForm.invalid"></submit-button>
  </div>
</form>
