import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Moment } from 'moment';

import { HistoricalChartEventService } from './historical-chart-event.service';
import { HistoricalChartEvent } from './historical-chart-event';
import { DataPoint } from '../../../../shared/chart/data-point';
import { DataSerie } from '../../../../shared/chart/data-serie';
import { StatisticalTestResult } from './statistical-test-result';
import { AppConfig } from '../../../../core/app.config';
import { Statistics } from '../control-chart/validation-data/statistics';

@Component({
  selector: 'historical-chart-event-chart',
  templateUrl: './historical-chart-event-chart.component.html',
  styleUrls: ['./historical-chart-event-chart.component.css']
})
export class HistoricalChartEventChartComponent implements OnInit, OnChanges {
  constructor(
    private historicalChartEventService: HistoricalChartEventService,
    private appConfig: AppConfig
  ) { }
  @Input() public controlChartSetId: number;
  @Input() public dataChange: boolean; // Used for triggering ngOnChanges.
  @Input() public onlyIncluded: boolean;
  @Input() public initiatedByReport: boolean = false;
  @Input() public start: string = '';
  @Input() public end: string = '';
  @Output() public dataChanges: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public chartInitialized: EventEmitter<void> = new EventEmitter();
  public historicChartEvent: HistoricalChartEvent;
  public colors: any = [{ name: 'Chart.Events.ValidationData', value: '#000000' }];
  public chart: DataSerie[];
  public statisticalTestResults: StatisticalTestResult[];
  private statistics: Statistics;
  private min: number;
  private unit: string;
  public detailDialog: boolean = false;
  private dateTimeFormat: string;
  private includeCLC = true;
  private includeIA = true;
  private includeRMC = true;
  private includeSSC = true;
  private includeVD = true;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    if (!this.initiatedByReport) {
      const date = new Date();
      this.end = date.toISOString();
      date.setFullYear(date.getFullYear() - 1);
      this.start = date.toISOString();
    }
    this.historicalChartEventService.setAddArgs('');
    this.getChartData();
  }

  ngOnChanges() {
    this.getChartData();
  }

  getChartData(start: Moment = null, end: Moment = null, force: boolean = false) {
    if (typeof (start) !== 'string' && start !== null) {
      this.start = start.toISOString();
    }
    if (typeof (end) !== 'string' && end !== null) {
      this.end = end.toISOString();
    }

    this.historicalChartEventService.getHistoricalChartEventChartData(this.controlChartSetId, this.start, this.end, this.onlyIncluded, force).subscribe(data => {
      this.chart = [];
      this.min = 0.0;
      if (data) {
        if (data.validationData) {
          data.validationData.map(event => {
            const value = event.plottedValue;
            this.min = value < this.min ? value : this.min;
          });
        }

        this.chart.push(<DataSerie>{ name: 'Chart.Events.ControlLimitsChanged', connected: false, shape: 'star', series: data.controlLimitsChanged ? this.parseHistoricalChartEvents(data.controlLimitsChanged) : [] });
        this.chart.push(<DataSerie>{ name: 'Chart.Events.InstrumentAdjusted', connected: false, shape: 'triangle', series: data.instrumentAdjusted ? this.parseHistoricalChartEvents(data.instrumentAdjusted) : [] });
        this.chart.push(<DataSerie>{ name: 'Chart.Events.ReferenceMaterialChanged', connected: false, shape: 'square', series: data.referenceMaterialChange ? this.parseHistoricalChartEvents(data.referenceMaterialChange) : [] });
        this.chart.push(<DataSerie>{ name: 'Chart.Events.SampleSizeChanged', connected: false, shape: 'diamond', series: data.sampleSizeChange ? this.parseHistoricalChartEvents(data.sampleSizeChange) : [] });
        this.chart.push(<DataSerie>{ name: 'Chart.Events.ValidationData', connected: true, shape: 'circle', series: data.validationData ? this.parseValidationData(data.validationData) : [] });
        this.statisticalTestResults = data.statisticalTestResults;
        this.statistics = data.statistics;
        this.chartInitialized.emit();
      }
    });
  }

  viewDetails(event: DataPoint) {
    this.historicChartEvent = <HistoricalChartEvent>{
      endDateTime: '', eventDescriptionsList: event.params.eventDescriptionsList, eventType: event.params.eventType, excluded: false, helperId: event.params.helperId,
      id: event.params.id, outlier: '', plottedValue: 0, unit: '', startDateTime: '', statistics: null, userName: ''
    };
    this.detailDialog = true;
  }

  selectSeries(serieName: string) {
    switch (serieName) {
      case 'Chart.Events.ControlLimitsChanged':
        this.includeCLC = !this.includeCLC;
        break;
      case 'Chart.Events.InstrumentAdjusted':
        this.includeIA = !this.includeIA;
        break;
      case 'Chart.Events.ReferenceMaterialChanged':
        this.includeRMC = !this.includeRMC;
        break;
      case 'Chart.Events.SampleSizeChanged':
        this.includeSSC = !this.includeSSC;
        break;
      case 'Chart.Events.ValidationData':
        this.includeVD = !this.includeVD;
        break;
    }
    this.historicalChartEventService.setAddArgs('&includeclc=' + this.includeCLC + '&includeia=' + this.includeIA + '&includermc=' + this.includeRMC + '&includessc=' + this.includeSSC + '&includevd=' + this.includeVD);
    this.dataChanges.emit(!this.dataChange);
    this.getChartData(null, null, true);
  }

  private parseValidationData(events: HistoricalChartEvent[]): DataPoint[] {
    if (events && events[0]) {
      const pv = events[0].plottedValue;
      this.unit = events[0].unit;
    }
    return events.map(event => <DataPoint>{
      name: new Date(event.startDateTime), params: {
        color: event.excluded ? '#AAAAAA' : '#8000BD', stroke: event.excluded ? '' : '#000000', id: event.id, helperId: event.helperId, eventType: event.eventType,
        start: event.startDateTime, end: event.endDateTime, value: event.plottedValue.toFixed(2) + ' [' + event.unit + ']'
      },
      value: event.plottedValue
    });
  }

  private parseHistoricalChartEvents(events: HistoricalChartEvent[]): DataPoint[] {
    return events.map(event => <DataPoint>{
      name: new Date(event.startDateTime), params: {
        color: '#0000FF', stroke: '#000000', id: event.id, helperId: event.helperId, eventType: event.eventType,
        start: event.startDateTime, end: event.endDateTime, value: event.plottedValue, radius: 5,
        eventDescriptionsList: event.eventType === 'Chart.Events.SampleSizeChanged' ? event.eventDescriptionsList : []
      },
      value: 0.95 * this.min
    });
  }

  dialogChanged(event: boolean) {
    this.detailDialog = event;
    this.getChartData();
  }
}
