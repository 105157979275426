import { NgModule } from '@angular/core';

import { SharedModule } from '../../../../../shared/shared.module';
import { NoteModule } from '../../../../../shared/note/note.module';

import { ValidationDataComponent } from './validation-data.component';
import { ValidationDataOverviewComponent } from './validation-data-overview.component';
import { ValidationDataService } from './validation-data.service';
import { ControlChartService } from '../control-chart.service';

@NgModule({
  declarations: [
    ValidationDataComponent,
    ValidationDataOverviewComponent
  ],
  exports: [
    ValidationDataComponent,
    ValidationDataOverviewComponent
  ],
  imports: [
    SharedModule,
    NoteModule
  ],
  providers: [
    ValidationDataService,
    ControlChartService
  ]
})

export class ValidationDataModule { }
