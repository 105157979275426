<!--<div class="header">
  <h1>{{ 'Chart.Settings' | translate }}</h1>
</div>
<div *ngIf="!fidelityChart">
  <error-message [error]="error"></error-message>
</div>-->
<form *ngIf="fidelityChart" (ngSubmit)="submitFidelityChart(fidelityChartForm)" #fidelityChartForm="ngForm">
  <table class="overview">
    <tr>
      <td>{{ 'Stream.Stream' | translate }}:</td>
      <td>
        <select [(ngModel)]="fidelityChart.componentStreamId" name="componentStreamId" required>
          <option [ngValue]="null"></option>
          <option *ngFor="let stream of streams" [ngValue]="stream.id">{{ stream.tag }}</option>
        </select>
      </td>
    </tr>
    <!--<tr>
      <td>{{ 'LogNumber' | translate }}:</td>
      <td><input type="number" [(ngModel)]="fidelityChart.logNumber" name="logNumber" required /></td>
    </tr>-->
  </table>
  <div *ngFor="let fidelityGroup of fidelityGroups">
    <hr />
    <table class="component-table">
      <thead>
        <tr>
          <td>{{ 'Description' | translate }}</td>
          <td>{{ 'Symbol' | translate }}</td>
          <td>{{ 'UnitOfMeasurement' | translate }}</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let fidelityComponent of fidelityGroup.fidelityComponents">
          <td>{{ fidelityComponent.componentType.description }}</td>
          <td>{{ fidelityComponent.componentType.symbol }}</td>
          <td>{{ fidelityComponent.componentType.unit }}</td>
        </tr>
      </tbody>
    </table>
    <div class="control-buttons">
      <button type="button" class="button-blue" (click)="editFidelityGroup(fidelityGroup)">{{ 'Edit' | translate }}</button>
      <button type="button" class="button-red" (click)="deleteFidelityGroup(fidelityGroup)">{{ 'Delete' | translate }}</button>
    </div>
  </div>
  <ng-container *ngIf="editMode">
    <div class="control-buttons">
      <button type="button" class="button-blue margin-right" [disabled]="!(fidelityChart.componentStreamId)" (click)="addFidelityGroup()">{{ 'Fidelity.AddGroup' | translate }}</button>
      <button class="button-gray" type="reset" (click)="cancel()">{{ 'Cancel' | translate }}</button>
      <submit-button name="Save" [disabled]="fidelityChartForm.invalid"></submit-button>
    </div>
  </ng-container>
</form>
<custom-dialog [visible]="groupDialog" *ngIf="groupDialog" (editModeChanged)="dialogChanged($event)">
  <fidelity-group-overview [fidelityGroup]="selectedFidelityGroup" [componentStreamId]="fidelityChart.componentStreamId" (editModeChanged)="dialogChanged($event)"></fidelity-group-overview>
</custom-dialog>
