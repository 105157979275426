import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { OwlDateTimeModule } from '@shtian/ng-pick-datetime';

import { DataFilterComponent } from './data-filter.component';
import { DataListFilterSelectComponent } from './data-list-filter-select.component';
import { DataListFilterViewComponent } from './data-list-filter-view.component';
import { ErrorMessageModule } from '../error/error-message.module';
import { DialogModule } from '../dialog/dialog.module';
import { GridTableModule } from '../grid/grid-table.module';

//Custom filters.

import { AreaTypeFilterModule } from '../../area/area-type-filter/area-type-filter.module';
import { AuditActionFilterModule } from '../../change-man/audit-trail/audit-action-filter/audit-action-filter.module';
import { ClientTypeFilterModule } from '../../area/io/io-client/client-type-filter/client-type-filter.module';
import { CriticalityFilterModule } from '../../area/instrument/criticality/criticality-filter/criticality-filter.module';
import { DecisionRuleFilterModule } from '../../area/instrument/decision-rule/decision-rule-filter/decision-rule-filter.module';
import { EventTypeFilterModule } from '../../event/event-type-filter/event-type-filter.module';
import { IoServerFilterModule } from '../../io-server/io-server-filter/io-server-filter.module';
import { MaterialStatusFilterModule } from '../../area/instrument/reference-material/material-status-filter/material-status-filter.module';
import { MaterialTypeFilterModule } from '../../area/instrument/reference-material/material-type/material-type-filter/material-type-filter.module';
import { OperationalStateFilterModule } from '../../area/instrument/operational-state/operational-state-filter/operational-state-filter.module';
import { PerformanceIndicatorFilterModule } from '../../perf-indic/perf-indic-filter/perf-indic-filter.module';
import { ReportLevelFilterModule } from '../../core/report/report-level-filter/report-level-filter.module';
import { TimelineObjectTypeFilterModule } from '../../timeline/timeline-object-type-filter/timeline-object-type-filter.module';
import { ValidationMethodFilterModule } from '../../area/instrument/validation/validation-procedure/validation-method-filter/validation-method-filter.module';
import { ValidationProcedureFilterModule } from '../../area/instrument/validation/validation-procedure/validation-procedure-filter/validation-procedure-filter.module';
import { ValidationResultFilterModule } from '../../area/instrument/validation/validation-result-filter/validation-result-filter.module';
import { ValidationStateFilterModule } from '../../area/instrument/validation/validation-state-filter/validation-state-filter.module';

@NgModule({
  declarations: [
    DataFilterComponent,
    DataListFilterSelectComponent,
    DataListFilterViewComponent
  ],
  providers: [],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    OwlDateTimeModule,
    ErrorMessageModule,
    DialogModule,
    GridTableModule,
    AreaTypeFilterModule,
    AuditActionFilterModule,
    ClientTypeFilterModule,
    CriticalityFilterModule,
    DecisionRuleFilterModule,
    EventTypeFilterModule,
    IoServerFilterModule,
    MaterialStatusFilterModule,
    MaterialTypeFilterModule,
    OperationalStateFilterModule,
    PerformanceIndicatorFilterModule,
    ReportLevelFilterModule,
    TimelineObjectTypeFilterModule,
    ValidationMethodFilterModule,
    ValidationProcedureFilterModule,
    ValidationResultFilterModule,
    ValidationStateFilterModule
  ],
  exports: [
    DataFilterComponent,
    DataListFilterSelectComponent,
    DataListFilterViewComponent,
    ErrorMessageModule,
    DialogModule,
    GridTableModule
  ]
})

export class DataFilterModule { }
