import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../../../../core/app.config';
import { LinearityChart } from './linearity-chart';
import { LinearitySession } from './linearity-session';
import { LinearityReading } from './linearity-reading';
import { CompService } from '../../stream/comp/comp.service';

@Injectable()
export class LinearitySessionService {
  constructor(private appConfig: AppConfig, private http: HttpClient, private compService: CompService) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getLinearitySessions(args: string): Observable<LinearitySession[]> { return this.http.get<LinearitySession[]>(this.apiHost + 'linearitysession/get' + args); }

  public getLinearitySessionsByInstrument(instrumentId: number, args: string): Observable<LinearitySession[]> { return this.http.get<LinearitySession[]>(this.apiHost + 'linearitysession/getbyinstrument/' + instrumentId + args); }

  public getLinearitySessionsByChart(chartId: number, args: string): Observable<LinearitySession[]> { return this.http.get<LinearitySession[]>(this.apiHost + 'linearitysession/getbychart/' + chartId + args); }

  public getLinearitySessionCount(args: string): Observable<number> { return this.http.get<number>(this.apiHost + 'linearitysession/count' + args); }

  public getLinearitySessionCountByInstrument(instrumentId: number, args: string): Observable<number> { return this.http.get<number>(this.apiHost + 'linearitysession/countbyinstrument/' + instrumentId + args); }

  public getLinearitySessionCountByChart(chartId: number, args: string): Observable<number> { return this.http.get<number>(this.apiHost + 'linearitysession/countbychart/' + chartId + args); }

  public getLinearitySession(id: number): Observable<LinearitySession> { return this.http.get<LinearitySession>(this.apiHost + 'linearitysession/get/' + id); }

  public postLinearitySession(linearitySession: LinearitySession): Observable<number> { return this.http.post<number>(this.apiHost + 'linearitysession/post', linearitySession); }

  public putLinearitySession(linearitySession: LinearitySession) { return this.http.put(this.apiHost + 'linearitysession/put', linearitySession); }

  public deleteLinearitySession(id: number) { return this.http.delete(this.apiHost + 'linearitysession/delete/' + id); }

  public newLinearitySession(linearityChart: LinearityChart): Observable<LinearitySession> {
    return new Observable<LinearitySession>(observer => {
      this.compService.getCompsByCompStream(linearityChart.componentStreamId, '?size=100').subscribe(components => {
        observer.next({
          id: null,
          dateTime: '',
          streamTag: '',
          linearityChartId: linearityChart.id,
          linearityReadings: components.map(comp => ({
            componentType: comp.componentType,
            componentTypeId: comp.componentTypeId,
            linearitySessionId: null,
            leanReadingValue: 0,
            calReadingValue: 0,
            richReadingValue: 0,
            leanAreaValue: 0,
            calAreaValue: 0,
            richAreaValue: 0
          }) as LinearityReading)
        });
        observer.complete();
      }, error => observer.error(error));
    });
  }
}
