import { ActiveFilter } from '../data/active-filter';

export class CustomLayoutSection {
  id: number;
  name: string;
  type: string;
  screen: string;
  sequenceNumber: number;
  collapsed: boolean;
  endpoint: string;
  informationLevelId: number;
  activeFilters: ActiveFilter[];
  chartType: string;
  chartId: number;
}
