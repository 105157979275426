<ngx-charts-chart style="display: none;" [view]="[width, height]"
                  [showLegend]="false"
                  [legendOptions]="legendOptions"
                  [activeEntries]="activeEntries"
                  [animations]="animations"
                  (legendLabelClick)="onClick($event)"
                  (legendLabelActivate)="onActivate($event)"
                  (legendLabelDeactivate)="onDeactivate($event)">
  <svg:defs>
    <svg:clipPath [attr.id]="clipPathId">
      <svg:rect [attr.width]="dims.width + 10"
                [attr.height]="dims.height + 10"
                [attr.transform]="'translate(-5, -5)'" />
    </svg:clipPath>
  </svg:defs>
  <svg:g [attr.transform]="transform" class="line-chart chart">
    <svg:g ngx-charts-x-axis
           *ngIf="xAxis"
           [xScale]="xScale"
           [dims]="dims"
           [showGridLines]="showGridLines"
           [showLabel]="showXAxisLabel"
           [labelText]="xAxisLabel"
           [tickFormatting]="xAxisTickFormatting"
           [ticks]="xAxisTicks"
           (dimensionsChanged)="updateXAxisHeight($event)">
    </svg:g>
    <svg:g aml-charts-y-axis
           *ngIf="yAxis"
           [yScale]="yScale"
           [dims]="dims"
           [showGridLines]="showGridLines"
           [showLabel]="showYAxisLabel"
           [labelText]="yAxisLabel"
           [tickFormatting]="yAxisTickFormatting"
           [ticks]="yAxisTicks"
           [referenceLines]="referenceLines"
           [showRefLines]="showRefLines"
           [showRefLabels]="showRefLabels"
           (dimensionsChanged)="updateYAxisWidth($event)">
    </svg:g>
    <svg:g [attr.clip-path]="clipPath">
      <svg:g *ngFor="let series of results; trackBy:trackBy" [@animationState]="'active'">
        <svg:g *ngIf="series.connected" aml-charts-line-series
               [xScale]="xScale"
               [yScale]="yScale"
               [colors]="colors"
               [data]="series"
               [activeEntries]="activeEntries"
               [scaleType]="scaleType"
               [curve]="curve"
               [rangeFillOpacity]="rangeFillOpacity"
               [hasRange]="hasRange"
               [animations]="animations" />
      </svg:g>
      <svg:g *ngFor="let series of results">
        <svg:g shape-series
               [xScale]="xScale"
               [yScale]="yScale"
               [data]="series"
               [scaleType]="scaleType"
               [tooltipDisabled]="tooltipDisabled"
               [tooltipTemplate]="tooltipTemplate"
               (select)="onClick($event, series)"
               (activate)="onActivate($event)"
               (deactivate)="onDeactivate($event)" />
      </svg:g>
    </svg:g>
  </svg:g>
  <svg:g ngx-charts-timeline data-html2canvas-ignore
         *ngIf="timeline && scaleType != 'ordinal'"
         [attr.transform]="timelineTransform"
         [results]="results"
         [view]="[timelineWidth, height]"
         [height]="timelineHeight"
         [scheme]="scheme"
         [customColors]="customColors"
         [scaleType]="scaleType"
         [legend]="legend"
         (onDomainChange)="updateDomain($event)">
    <svg:g *ngFor="let series of results; trackBy:trackBy">
      <svg:g *ngIf="series.connected" aml-charts-line-series
             [xScale]="timelineXScale"
             [yScale]="timelineYScale"
             [colors]="colors"
             [data]="series"
             [scaleType]="scaleType"
             [curve]="curve"
             [hasRange]="hasRange"
             [animations]="animations" />
    </svg:g>
  </svg:g>
</ngx-charts-chart>
<div *ngIf="legend" class="custom-legend ngx-charts" style="display: none;">
  <div *ngFor="let series of results">
    <ng-container *ngIf="series && series.series && series.series[0]; else blackShape"><svg width="14" height="14">
      <svg:g aml-charts-shape [cx]="7" [cy]="7" [r]="6" [shape]="series.shape" [stroke]="series.series[0].params.stroke" [fill]="series.series[0].params.color" (select)="selectSeries.emit(series.name)"></svg:g>
    </svg></ng-container>
    <ng-template #blackShape><svg width="14" height="14"><svg:g aml-charts-shape [cx]="7" [cy]="7" [r]="6" [shape]="series.shape" (select)="selectSeries.emit(series.name)"></svg:g></svg></ng-template>
    = {{ series.name | translate }}
  </div>
</div>
