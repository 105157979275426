<div *ngIf="!datasets">
  <error-message [error]="error"></error-message>
</div>
<div *ngIf="datasets" class="overview-wrapper">
  <div class="overview-header">{{ 'Report.SchedulerHistory' | translate }}</div>
  <grid [name]="'Report_Datasets'" [title]="title" [genericDataRetrieval]="false" [headers]="headers" [filterOptions]="filterOptions" [count]="count" [exportData]="datasets" [showHeader]="false" (filterEvent)="getDatasets($event)">
    <tr *ngFor="let dataset of datasets; let odd = odd" [class.odd]="odd">
      <td><a routerLink="{{ report.id }}/{{ dataset.id }}">{{ dataset.dataDateTime | date:dateTimeFormat }}</a></td>
      <td>{{ dataset.description }}</td>
      <td>
        <button class="button-blue" routerLink="{{ report.id }}/{{ dataset.id }}">{{ 'Report.View' | translate }}</button>
        <button *ngIf="deleteAccess" class="button-red" (click)="deleteDataset(dataset.id)">{{ 'Delete' | translate }}</button>
      </td>
    </tr>
  </grid>
  <error-message [error]="error"></error-message>
  <div class="control-buttons">
    <button class="button-gray" type="button" (click)="close()">{{ 'Close' | translate }}</button>
  </div>
</div>
<delete-dialog [visible]="deleteDialog" [error]="error" (confirmDelete)="confirmDelete($event)"></delete-dialog>
