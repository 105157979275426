import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FavoriteService } from './favorite.service';
import { FavoriteToggleComponent } from './favorite-toggle.component';
import { FavoritesComponent } from './favorites.component';
import { TooltipModule } from '../tooltip/tooltip.module';

@NgModule({
  declarations: [
    FavoriteToggleComponent,
    FavoritesComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    TooltipModule
  ],
  exports: [
    FavoriteToggleComponent,
    FavoritesComponent
  ]
})

export class FavoriteModule {
  static forRoot(): ModuleWithProviders<FavoriteModule> {
    return {
      ngModule: FavoriteModule,
      providers: [
        FavoriteService
      ]
    };
  }
}
