import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../../../core/app.config';
import { Generic } from '../../../shared/generic';
import { IoPoint } from './io-point';

@Injectable()
export class IoPointService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getIoPoints(args: string): Observable<IoPoint[]> { return this.http.get<IoPoint[]>(this.apiHost + 'iopoint/get' + args); }

  public getAllIoPointsByInformationLevel(informationLevelId: number): Observable<Generic[]> {
    return this.http.get<Generic[]>(this.apiHost + 'iopoint/getallbyinformationlevel/' + informationLevelId);
  }

  public getIoPointsByInformationLevel(informationLevelId: number, filterArguments: string): Observable<IoPoint[]> {
    return this.http.get<IoPoint[]>(this.apiHost + 'iopoint/getbyinformationlevel/' + informationLevelId + filterArguments);
  }

  public getIoPointsByIoClient(ioClientId: number, filterArguments: string): Observable<IoPoint[]> {
    return this.http.get<IoPoint[]>(this.apiHost + 'iopoint/getbyioclient/' + ioClientId + filterArguments);
  }

  public getIoPoint(id: number): Observable<IoPoint> { return this.http.get<IoPoint>(this.apiHost + 'iopoint/get/' + id); }

  public getIoPointCount(args: string): Observable<number> { return this.http.get<number>(this.apiHost + 'iopoint/count' + args); }

  public getIoPointCountByInformationLevel(informationLevelId: number, filterArguments: string): Observable<number> {
    return this.http.get<number>(this.apiHost + 'iopoint/countbyinformationlevel/' + informationLevelId + filterArguments);
  }

  public getIoPointCountByIoClient(ioClientId: number, filterArguments: string): Observable<number> {
    return this.http.get<number>(this.apiHost + 'iopoint/countbyioclient/' + ioClientId + filterArguments);
  }

  public postIoPoint(ioPoint: IoPoint) { return this.http.post(this.apiHost + 'iopoint/post', ioPoint); }

  public putIoPoint(ioPoint: IoPoint) { return this.http.put(this.apiHost + 'iopoint/put', ioPoint); }

  public deleteIoPoint(id: number) { return this.http.delete(this.apiHost + 'iopoint/delete/' + id); }

  public addOrUpdateIoPoints(ioPoints: IoPoint[]) { return this.http.post(this.apiHost + 'iopoint/addorupdaterange', ioPoints); }
}
