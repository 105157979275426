import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Report } from './report';
import { ReportService } from './report.service';
import { ReportDatasetData } from './report-dataset-data';
import { Header } from '../../shared/grid/header';
import { FilterOption } from '../../shared/data/filter-option';
import { AppConfig } from '../app.config';
import { ClaimService } from '../claim.service';

@Component({
  selector: 'report-history',
  templateUrl: './report-history.component.html',
  styleUrls: ['./report-history.component.css']
})

export class ReportHistoryComponent implements OnInit {
  constructor(
    private reportService: ReportService,
    private route: ActivatedRoute,
    private claimService: ClaimService,
    private appConfig: AppConfig
  ) { }
  @Input() public report: Report;
  @Output() public viewModeChanged = new EventEmitter<boolean>();
  private viewMode = false;
  public deleteDialog = false;
  private id = -1;
  private datasetId = -1;
  public datasets: ReportDatasetData[] = [];
  public title = '';
  public headers: Header[] = [{ header: 'DateTime', sort: true, width: '30%' }, { header: 'Description', sort: true, width: '40%' }, { header: 'Action', sort: false, width: '30%' }];
  public filterOptions: FilterOption[] = [{ name: 'DateTime', type: 'datetime' }, { name: 'Description', type: 'string' }];
  public count = 0;
  private args = '';
  public error: any;
  private dateTimeFormat: string;
  public deleteAccess: boolean;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.deleteAccess = this.claimService.checkClaimContains('aml.report', 'd');
    if (this.report == null) {
      this.id = +this.route.snapshot.paramMap.get('reportId');
      this.getDatasets('');
    } else {
      this.id = this.report.id;
      this.getDatasets('');
      this.changeViewMode(true);
    }
  }

  getDatasets(args: string) {
    this.error = null;
    this.args = args;
    this.reportService.getReportDatasetCount(this.id).subscribe(count => this.count = count);
    this.reportService.getReportDatasets(this.id, args).subscribe(datasets => this.datasets = datasets, error => this.error = error.error);
  }

  deleteDataset(id: number) {
    this.deleteDialog = true;
    this.datasetId = id;
  }

  confirmDelete(confirmed: boolean) {
    this.error = null;
    this.deleteDialog = false;
    if (confirmed) {
      this.reportService.deleteReportDataset(this.datasetId).subscribe(response => {
        this.getDatasets(this.args);
        this.datasetId = -1;
      }, error => this.error = error.error);
    }
  }

  close() {
    this.changeViewMode(false);
  }

  changeViewMode(viewMode: boolean) {
    this.viewMode = viewMode;
    this.viewModeChanged.emit(viewMode);
  }
}
