import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../../../../core/app.config';
import { StatisticalTest } from './statistical-test';

@Injectable()
export class HistoricalChartService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getStatisticalTests(controlChartSetId: number): Observable<StatisticalTest[]> {
    return this.http.get<StatisticalTest[]>(this.apiHost + 'historicalchart/get/' + controlChartSetId);
  }

  public putStatisticalTests(statisticalTests: StatisticalTest[], controlChartSetId: number) { return this.http.put(this.apiHost + 'historicalchart/put/' + controlChartSetId, statisticalTests); }

  public getSignificanceLevelTests(): Observable<string[]> { return this.http.get<string[]>(this.apiHost + 'historicalchart/significanceleveltests'); }

  public getStatisticalTestTypes(): Observable<string[]> { return this.http.get<string[]>(this.apiHost + 'historicalchart/getstatisticaltesttypes'); }

  public quickHistoricalCharts(instrumentId: number) { return this.http.post(this.apiHost + 'historicalchart/quickcharts/' + instrumentId, null); }
}
