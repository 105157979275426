import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SubscriptionLike as ISubscription, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { CustomLayoutSection } from './custom-layout-section';
import { ActiveFilter } from '../../shared/data/active-filter';
import { Endpoint } from '../../shared/data/endpoint';
import { ReportManagementService } from '../../core/report/report-management/report-management.service';

@Component({
  selector: 'custom-layout-table',
  templateUrl: './custom-layout-table.component.html'
})
export class CustomLayoutTableComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private reportManagementService: ReportManagementService
  ) { }

  @Input() screen: string;
  @Input() section: CustomLayoutSection;
  @Input() editMode = false;
  @Output() sectionChanged = new EventEmitter<void>();
  public endpoints: Endpoint[] = [];
  public activeFilters: ActiveFilter[] = [];
  public initialized = false;
  private areaId: number;
  private routeSubscription: ISubscription;

  ngOnInit() {
    this.activeFilters = this.section.activeFilters;
    this.getAreaId();
    this.getEndpoints();

    if (this.screen === 'area') {
      this.routeSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(async path => {
        this.initialized = false;
        await this.wait(100);
        this.getAreaId();
      });
    }
  }

  getAreaId() {
    if (this.screen === 'area') {
      this.areaId = +this.route.parent.snapshot.paramMap.get('id');
      this.activeFilters = this.activeFilters.filter(f => f.filterOption !== 'Area.Area');
      this.activeFilters.push({ id: -1, filterText: this.areaId.toString(), filterOption: 'Area.Area', filterDetails: { operator: '==' }, type: 'hidden', readonly: false });
    }
    this.initialized = true;
  }

  updateSection() {
    this.getAreaId();
    this.sectionChanged.emit();
  }

  filtersChanged(filters: ActiveFilter[], section: CustomLayoutSection) {
    section.activeFilters = (this.screen === 'area') ? filters.filter(f => f.filterOption !== 'Area.Area') : filters;
    this.sectionChanged.emit();
  }

  getEndpoints() {
    this.reportManagementService.getEndpoints().subscribe(endpoints => this.endpoints = endpoints);
  }

  wait(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
}
