<svg:circle *ngIf="shape === 'circle'" [attr.cx]="cx"
            [attr.cy]="cy"
            [attr.r]="r"
            [attr.fill]="fill"
            [attr.stroke]="stroke"
            [attr.opacity]="shapeOpacity"
            [attr.class]="classNames"
            [attr.pointer-events]="pointerEvents" />
<svg:polygon *ngIf="shape === 'triangle'"
            [attr.points]="(cx - r) + ',' + (cy + r) + ' ' + (cx) + ',' + (cy - r) + ' ' + (cx + r) + ',' + (cy + r)"
            [attr.fill]="fill"
            [attr.stroke]="stroke"
            [attr.opacity]="shapeOpacity"></svg:polygon>
<svg:rect *ngIf="shape === 'square'"
            [attr.width]="2 * r"
            [attr.height]="2 * r"
            [attr.x]="cx - r"
            [attr.y]="cy - r"
            [attr.fill]="fill"
            [attr.stroke]="stroke"
            [attr.opacity]="shapeOpacity"></svg:rect>
<svg:polygon *ngIf="shape === 'star'"
            [attr.points]="(cx) + ',' + (cy - r) + ' ' + (cx - 0.6 * r) + ',' + (cy + r) + ' ' + (cx + r) + ',' + (cy - 0.2 * r) + ' ' + (cx - r) + ',' + (cy - 0.2 * r) + ' ' + (cx + 0.6 * r) + ',' + (cy + r)"
            [attr.fill]="fill"
            [attr.opacity]="shapeOpacity"></svg:polygon>
<svg:polygon *ngIf="shape === 'diamond'"
            [attr.points]="(cx - 1.2 * r) + ',' + (cy) + ' ' + (cx) + ',' + (cy - 1.2 * r) + ' ' + (cx + 1.2 * r) + ',' + (cy) + ' ' + (cx) + ',' + (cy + 1.2 * r)"
            [attr.fill]="fill"
            [attr.stroke]="stroke"
            [attr.opacity]="shapeOpacity"></svg:polygon>
