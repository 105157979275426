import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { OwlDateTimeModule } from '@shtian/ng-pick-datetime';

import { GridService } from './grid.service';
import { GridComponent } from './grid.component';
import { CrudGridComponent } from './crud-grid.component';
import { GridConfigurationComponent } from './grid-configuration.component';
import { ErrorMessageModule } from '../error/error-message.module';
import { DialogModule } from '../dialog/dialog.module';
import { DeleteDialogModule } from '../delete-dialog/delete-dialog.module';
import { LoaderModule } from '../loader/loader.module';
import { DataFilterModule } from '../data/data-filter.module';
import { DataModule } from '../data/data.module';
import { GridTableModule } from './grid-table.module';
import { PerformanceIndicatorFilterModule } from '../../perf-indic/perf-indic-filter/perf-indic-filter.module';

@NgModule({
  declarations: [
    GridComponent,
    CrudGridComponent,
    GridConfigurationComponent,
  ],
  providers: [
    GridService
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    OwlDateTimeModule,
    ErrorMessageModule,
    DialogModule,
    DeleteDialogModule,
    LoaderModule,
    DataFilterModule,
    DataModule,
    GridTableModule,
    PerformanceIndicatorFilterModule
  ],
  exports: [
    GridComponent,
    CrudGridComponent,
    GridConfigurationComponent,
    ErrorMessageModule,
    DialogModule,
    DeleteDialogModule,
    DataFilterModule,
    GridTableModule
  ]
})

export class GridModule { }
