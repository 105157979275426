import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import { ClaimService } from '../../core/claim.service';
import { DataService } from '../data/data.service';
import { ErrorMessage } from '../error/error-message';
import { GridColumn } from '../grid/grid-column';
import { CustomInputProperty } from '../data-form/custom-input-property';

@Component({
  selector: 'form-dialog',
  templateUrl: './form-dialog.component.html'
})
export class FormDialogComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private claimService: ClaimService,
    private route: ActivatedRoute
  ) { }
  @Input() addTitle: string;
  @Input() editTitle: string;
  @Input() claims: string[];
  @Input() endpoint: string;
  @Input() item: any;
  @Input() customProperties: CustomInputProperty[];
  @Input() idProperty: string = 'id';
  @Input() informationLevelId: number;
  @Input() embedded = false;
  @Input() submit: Subject<void> = new Subject<void>();
  @Output() editModeChanged = new EventEmitter<boolean>();
  @Output() formValid = new EventEmitter<boolean>();
  @Output() submitResult = new EventEmitter<boolean>();
  public title = '';
  public properties: GridColumn[];
  public editMode = false;
  private id: number | string = 0;
  public updateAccess: boolean;
  public error: ErrorMessage;
  public submitReady: Subject<void> = new Subject<void>();

  getItem() {
    this.error = null;
    this.dataService.getItem(this.endpoint, this.id).subscribe(item => this.item = item, error => this.error = error.error);
  }

  getProperties() {
    this.dataService.getMetaData(this.endpoint).subscribe(metaData => {
      if (metaData !== undefined && metaData !== null && metaData.length !== undefined) {
        const names = metaData.map(item => item.name);
        this.properties = this.dataService.getInputProperties(names, this.customProperties, false, true);
      }
    }, error => this.error = error.error);
  }

  ngOnInit() {
    this.error = null;
    this.updateAccess = !(this.claims !== undefined && this.claims.map(c => this.claimService.checkClaimContains(c, 'u')).includes(false));
    if (this.item == null || this.item[this.idProperty] < 1 || this.item[this.idProperty] === '') {
      this.title = this.addTitle;
    } else {
      this.title = this.editTitle;
    }

    if (this.item == null) {
      this.id = +this.route.snapshot.paramMap.get('id');
      this.getItem();
    } else {
      this.id = this.item[this.idProperty];
      this.changeEditMode(true);
    }

    this.getProperties();
  }

  submitItem(item: any) {
    if (this.editMode) {
      this.item = item;
      this.error = null;

      if (this.item[this.idProperty] < 1 || this.id === '') { // POST
        this.dataService.postItem(this.endpoint, this.item).subscribe(response => {
          this.submitResult.emit(true);
          this.submitReady.next();
          this.changeEditMode(false);
        }, error => {
          this.error = error.error;
          this.submitResult.emit(false);
          this.submitReady.next();
        });
      } else { // PUT
        this.dataService.putItem(this.endpoint, this.item).subscribe(response => {
          this.submitResult.emit(true);
          this.submitReady.next();
          this.changeEditMode(false);
        }, error => {
          this.error = error.error;
          this.submitResult.emit(false);
          this.submitReady.next();
        });
      }
    } else {
      this.submitResult.emit(true);
    }
  }

  editItem() {
    this.getItem();
    this.changeEditMode(true);
  }

  cancel() {
    this.getItem();
    this.changeEditMode(false);
  }

  changeEditMode(editMode: boolean) {
    this.editMode = editMode;
    this.editModeChanged.emit(editMode);
  }

  setFormValid(valid: boolean) {
    this.formValid.emit(valid);
  }

  setSubmitResult(valid: boolean) {
    this.submitResult.emit(valid);
  }
}
