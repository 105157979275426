import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AmlComponent } from './aml.component';
import { CustomLayoutModule } from '../../shared/custom-layout/custom-layout.module';

@NgModule({
  declarations: [
    AmlComponent
  ],
  imports: [
    CommonModule,
    CustomLayoutModule
  ]
})

export class AmlModule { }
