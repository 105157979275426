<svg:g>
  <defs>
    <svg:g ngx-charts-svg-linear-gradient *ngIf="hasGradient"
           orientation="vertical"
           [name]="gradientId"
           [stops]="gradientStops" />
  </defs>
  <svg:g ngx-charts-line
         class="line-series"
         [data]="data"
         [path]="path"
         [stroke]="stroke"
         [animations]="animations"
         [class.active]="isActive(data)"
         [class.inactive]="isInactive(data)" />
  <svg:g ngx-charts-area
         *ngIf="hasRange"
         class="line-series-range"
         [data]="data"
         [path]="outerPath"
         [fill]="hasGradient ? gradientUrl : colors.getColor(data.name)"
         [class.active]="isActive(data)"
         [class.inactive]="isInactive(data)"
         [opacity]="rangeFillOpacity"
         [animations]="animations" />
</svg:g>
