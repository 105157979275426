import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../app.config';
import { User } from './user';
import { UserFull } from './user-full';

@Injectable()
export class UserService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getAll(): Observable<User[]> { return this.http.get<User[]>(this.apiHost + 'user/getall'); }

  public getAllWithMailAddress(): Observable<UserFull[]> { return this.http.get<UserFull[]>(this.apiHost + 'user/getallwithmailaddress'); }

  public getCurrent(): Observable<User> { return this.http.get<User>(this.apiHost + 'user/getcurrentuser'); }

  public getUsers(filterArguments: string): Observable<UserFull[]> { return this.http.get<UserFull[]>(this.apiHost + 'user/get' + filterArguments); }

  public getUserCount(filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'user/count' + filterArguments); }

  public getUser(id: string): Observable<UserFull> { return this.http.get<UserFull>(this.apiHost + 'user/get/' + id); }

  public postUser(user: UserFull) { return this.http.post(this.apiHost + 'user/post', user); }

  public putUser(user: UserFull) { return this.http.put(this.apiHost + 'user/put', user); }

  public deleteUser(id: string) { return this.http.delete(this.apiHost + 'user/delete/' + id); }

  public newUser(): UserFull { return { userId: null, userName: '', email: '', status: 'Enabled', password: '', roles: [] }; }
}
