import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../../core/app.config';
import { Generic } from '../../shared/generic';
import { ActiveFilter } from '../../shared/data/active-filter';
import { Instrument } from './instrument';

@Injectable()
export class InstrumentService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getAllInstruments(): Observable<Generic[]> { return this.http.get<Generic[]>(this.apiHost + 'measuringinstrument/getall'); }

  public getInstruments(filterArguments: string): Observable<Instrument[]> { return this.http.get<Instrument[]>(this.apiHost + 'measuringinstrument/get' + filterArguments); }

  public getInstrumentCount(filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'measuringinstrument/count' + filterArguments); }

  public postInstrument(instrument: Instrument): Observable<number> { return this.http.post<number>(this.apiHost + 'measuringinstrument/post', instrument); }

  public getInstrumentsByArea(id: number, filterArguments: string): Observable<Instrument[]> { return this.http.get<Instrument[]>(this.apiHost + 'measuringinstrument/getbyarea/' + id + filterArguments); }

  public getInstrumentCountByArea(id: number, filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'measuringinstrument/countbyarea/' + id + filterArguments); }

  public getInstrument(id: number): Observable<Instrument> { return this.http.get<Instrument>(this.apiHost + 'measuringinstrument/get/' + id); }

  public putInstrument(instrument: Instrument) { return this.http.put(this.apiHost + 'measuringinstrument/put', instrument); }

  public deleteInstrument(id: number) { return this.http.delete(this.apiHost + 'measuringinstrument/delete/' + id); }

  public addOrUpdateInstruments(instruments: Instrument[]) { return this.http.post(this.apiHost + 'measuringinstrument/addorupdaterange', instruments); }

  public newInstrument(parentId: number): Instrument {
    return {
      id: -1, tag: '', parentId: parentId, parentName: '', make: '', model: '', type: '', serialNumber: '', commissioned: '', decommissioned: '', ioServer: null,
      ioServerId: null, criticality: '', criticalityId: null, image: '', maintenanceConditionId: null, maintenanceOperationId: null,
      notRequiredConditionId: null, routineValidationDisabled: false, operationalStateControlByAml: true, referenceMaterialControlByAml: true,
      failedAlarmRules: [], failedFaultedRules: [], failedQualityRules: [], qualityRulesCount: 0, availabilityRate: null, reliabilityRate: null
    };
  }
}
