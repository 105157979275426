import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';

import { AppConfig } from '../app.config';
import { SearchItem } from './search-item';
import { SearchSource } from './search-source';

@Injectable()
export class SearchService {
  private apiHost: string;
  private sources: SearchSource[];
  constructor(
    private appConfig: AppConfig,
    private http: HttpClient
  ) {
    this.apiHost = this.appConfig.getConfig('apiHost');

    this.http.get<SearchSource[]>(this.apiHost + 'search/sources').subscribe(sources => this.sources = sources);
  }

  public concurrencyCheck: number;

  public search(searchInput: string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      const items: SearchItem[] = [];
      let counter = 0;
      if (this.sources !== null && this.sources.length > 0) {
        for (let i = 0; i < this.sources.length; i++) {
          this.http.get<SearchItem[]>(this.apiHost + this.sources[i].path + '?name=' + searchInput).subscribe(data => {
            if (data !== null && data.length > 0) {
              for (let j = 0; j < data.length; j++) {
                if (data[j] !== null && data[j].title !== null && data[j].path !== null) {
                  data[j].order = this.sources[i].order;
                  items.push(data[j]);
                }
                if ((j + 1) === data.length) {
                  counter++;
                  if (counter === this.sources.length) {
                    items.sort(function (a, b) { return a.order - b.order; });
                    const groupedResults = this.groupBy(items, 'type');
                    resolve(groupedResults);
                  }
                }
              }
            } else {
              counter++;
              if (counter === this.sources.length) {
                items.sort(function (a, b) { return a.order - b.order; });
                const groupedResults = this.groupBy(items, 'type');
                resolve(groupedResults);
              }
            }
          });
        }
      } else {
        this.http.get<SearchSource[]>(this.apiHost + 'search/sources').subscribe(sources => this.sources = sources);
      }
    });
    return promise;
  }

  private groupBy(xs, key): any {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
}
