<ng-container *ngIf="filter && filter.type && filter.type === 'reportlevel'">
  <select #reportLevelElement [(ngModel)]="filter.filterText" (change)="changed($event)" name="reportLevelFilter">
    <option [value]="null"></option>
    <option [value]="'ReportLevel.All'">{{ 'ReportLevel.All' | translate }}</option>
    <option [value]="'ReportLevel.InformationLevel'">{{ 'ReportLevel.InformationLevel' | translate }}</option>
    <option [value]="'ReportLevel.Area'">{{ 'ReportLevel.Area' | translate }}</option>
    <option [value]="'ReportLevel.Equipment'">{{ 'ReportLevel.Equipment' | translate }}</option>
    <option [value]="'ReportLevel.System'">{{ 'ReportLevel.System' | translate }}</option>
  </select>
</ng-container>
