import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { DecisionRuleFilterComponent } from './decision-rule-filter.component';
import { DecisionRuleService } from '../decision-rule.service';

@NgModule({
  declarations: [DecisionRuleFilterComponent],
  exports: [DecisionRuleFilterComponent],
  imports: [CommonModule, TranslateModule],
  providers: [DecisionRuleService]
})
export class DecisionRuleFilterModule { }
