import { Component, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ClaimService } from '../../core/claim.service';
import { NoteService } from './note.service';
import { Note } from './note';
import { ErrorMessage } from '../error/error-message';
import { AppConfig } from '../../core/app.config';
import { SubmitButtonComponent } from '../submit-button/submit-button.component';

@Component({
  selector: 'note',
  templateUrl: './note.component.html'
})

export class NoteComponent implements OnInit {
  constructor(
    private noteService: NoteService,
    private claimService: ClaimService,
    private appConfig: AppConfig
  ) { }
  @Input() public id: number;
  @Input() public type: string;
  @Output() public editModeChanged = new EventEmitter<boolean>();
  @ViewChild(SubmitButtonComponent) submitButton: SubmitButtonComponent;
  public note: Note;
  private editMode: boolean = false;
  public updateAccess: boolean;
  public error: ErrorMessage;
  private dateFormat: string;
  private dateTimeFormat: string;

  ngOnInit() {
    this.dateFormat = this.appConfig.getConfig('dateFormat');
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    switch (this.type) {
      case 'event':
        this.updateAccess = this.claimService.checkClaimContains('aml.event', 'u');
        break;
      case 'validationreadinggroup':
      case 'validationsession':
        this.updateAccess = this.claimService.checkClaimContains('aml.valnote', 'u');
        break;
    }
    this.getNote();
  }

  getNote() {
    this.error = null;
    this.noteService.getNote(this.id, this.type).subscribe(note => {
      if (!note) {
        this.note = new Note();
        this.note.id = 0;
        this.editMode = true;
      } else {
        this.note = note;
      }
    });
  }

  submitNote(form: NgForm) {
    if (this.submitButton !== undefined && this.submitButton.submit()) {
      if (form.valid) {
        this.error = null;
        if (this.note.id > 0) { // PUT
          this.noteService.putNote(this.note).subscribe(response => {
            this.submitButton.ready();
            this.editMode = false;
            if (!this.note.comment || this.note.comment === '') {
              this.editModeChanged.emit(false);
            }
            this.getNote();
          }, error => {
            this.error = error.error;
            this.submitButton.ready();
          });
        } else { // POST
          this.noteService.postNote(this.note, this.id, this.type).subscribe(response => {
            this.submitButton.ready();
            this.editMode = false;
            this.getNote();
          }, error => {
            this.error = error.error;
            this.submitButton.ready();
          });
        }
      } else {
        for (const i of Object.keys(form.controls)) {
          form.controls[i].markAsTouched();
        }
        this.submitButton.ready();
      }
    }
  }

  editNote() {
    this.getNote();
    this.changeEditMode(true);
  }

  cancel() {
    this.editMode = false;
    if (this.note.id < 1) {
      this.editModeChanged.emit(false);
    } else {
      this.getNote();
    }
  }

  close() {
    this.changeEditMode(false);
  }

  changeEditMode(editMode: boolean) {
    this.editMode = editMode;
    this.editModeChanged.emit(editMode);
  }
}
