import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { EventService } from '../event.service';
import { ActiveFilter } from '../../shared/data/active-filter';

@Component({
  selector: 'event-type-filter',
  templateUrl: './event-type-filter.component.html',
  styleUrls: ['../../shared/data/data-filter.component.css']
})
export class EventTypeFilterComponent implements OnInit, AfterContentChecked {
  constructor(
    private eventService: EventService
  ) { }
  @Input() filter: ActiveFilter;
  @Output() filterChanged = new EventEmitter<boolean>();
  public eventTypes: string[];
  @ViewChild('eventTypeElement') eventTypeElementRef: ElementRef;

  ngOnInit() {
    this.eventService.getEventSourceTypes().subscribe(eventTypes => this.eventTypes = eventTypes);
  }

  ngAfterContentChecked() {
    if (this.filter.filterText !== undefined && this.eventTypeElementRef !== undefined) {
      this.eventTypeElementRef.nativeElement.value = this.filter.filterText;
    }
  }

  changed(ev) {
    this.filter.filterText = ev.target.value;
    this.filterChanged.emit(true);
  }
}
