import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ReportManagementService } from './report-management.service';
import { ReportType } from '../report-type';
import { ClaimService } from '../../claim.service';
import { ErrorMessage } from '../../../shared/error/error-message';
import { Header } from '../../../shared/grid/header';
import { FilterOption } from '../../../shared/data/filter-option';
import { AppConfig } from '../../../core/app.config';
import { ReportEndpoint } from '../report-endpoint';

@Component({
  selector: 'report-type',
  templateUrl: './report-type.component.html',
  styleUrls: ['./report-type.component.css']
})

export class ReportTypeComponent implements OnInit {
  constructor(
    private reportManagementService: ReportManagementService,
    private route: ActivatedRoute,
    private claimService: ClaimService,
    private appConfig: AppConfig
  ) { }
  public title = 'ReportTypes';
  private id = -1;
  public headers: Header[] = [{ header: 'ReportType.Description', sort: true, width: '27%' }, { header: 'ReportType.Dataset', sort: true, width: '22%' },
    { header: 'ReportType.ReportLevel', sort: true, width: '15%' }, { header: 'Enabled', sort: true, width: '9%' }, { header: 'Action', sort: false, width: '27%' }];
  public filterOptions: FilterOption[] = [{ name: 'ReportType.Description', type: 'string' }, { name: 'ReportType.Dataset', type: 'string' }, { name: 'ReportType.ReportLevel', type: 'reportlevel' },
    { name: 'Enabled', type: 'boolean' }];
  public reportTypes: ReportType[] = [];
  public reportType: ReportType;
  public count = 0;
  public editDialog = false;
  public deleteDialog = false;
  public createAccess: boolean;
  public updateAccess: boolean;
  public deleteAccess: boolean;
  private args = '';
  public error: ErrorMessage;
  private dateTimeFormat: string;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.id = +this.route.parent.snapshot.paramMap.get('id');
    this.createAccess = this.claimService.checkClaimContains('aml.reportman', 'c');
    this.updateAccess = this.claimService.checkClaimContains('aml.reportman', 'u');
    this.deleteAccess = this.claimService.checkClaimContains('aml.reportman', 'd');

    this.getReportTypes(this.args);
  }

  getReportTypes(args: string) {
    this.error = null;
    this.args = args;
    this.reportManagementService.getReportTypeCount(args).subscribe(count => this.count = count);
    this.reportManagementService.getReportTypes(args).subscribe(reportTypes => this.reportTypes = reportTypes, error => this.error = error.error);
  }

  addReportType() {
    this.reportType = this.reportManagementService.newReportType();
    this.editDialog = true;
  }

  editReportType(reportType: ReportType) {
    this.editDialog = true;
    this.reportType = reportType;
  }

  copyReportType(reportType: ReportType) {
    this.reportManagementService.getFullReportType(reportType.id).subscribe(fullReportType => {
      this.reportType = {
        dataSelection: fullReportType.dataSelection,
        datasetId: fullReportType.datasetId,
        datasetName: fullReportType.datasetName,
        description: '',
        enabled: true,
        id: -1,
        pageOrientation: fullReportType.pageOrientation,
        periodSelection: fullReportType.periodSelection,
        reportLevel: fullReportType.reportLevel,
        reportEndpoints: (fullReportType.reportEndpoints !== null) ? fullReportType.reportEndpoints.map(re => new ReportEndpoint(re.name, re.endpoint, re.order, re.referencedEndpoints)) : null,
        reportParameters: fullReportType.reportParameters,
        reportSections: fullReportType.reportSections,
        updateDateTime: '',
        userId: '',
        username: ''
      };
      this.editDialog = true;
    });
  }

  deleteReportType(id: number) {
    this.deleteDialog = true;
    this.id = id;
  }

  toggleEnabled(id: number) {
    this.reportManagementService.toggleReportTypeEnabled(id).subscribe(response => {
      this.getReportTypes(this.args);
    });
  }

  confirmDelete(confirmed: boolean) {
    this.error = null;
    this.deleteDialog = false;
    if (confirmed) {
      this.reportManagementService.deleteReportType(this.id).subscribe(response => {
        this.getReportTypes(this.args);
        this.id = -1;
      }, error => this.error = error.error);
    }
  }

  dialogChanged(event: boolean) {
    this.editDialog = event;
    this.getReportTypes(this.args);
  }
}
