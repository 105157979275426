import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CustomLayoutSection } from './custom-layout-section';
import { CustomLayoutSectionType } from './custom-layout-section-type';
import { CustomLayoutService } from './custom-layout.service';
import { ActiveFilter } from '../../shared/data/active-filter';

@Component({
  selector: 'custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.css']
})
export class CustomLayoutComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private customLayoutService: CustomLayoutService
  ) { }

  @Input() screen: string; //possible values: home, area
  public editMode = false;
  public sectionTypes: CustomLayoutSectionType[];
  public sections: CustomLayoutSection[];
  public dragSection: CustomLayoutSection;
  public layoutClass: string;
  public dragging = false;

  ngOnInit() {
    this.layoutClass = this.customLayoutService.getLayout();
    this.sectionTypes = this.customLayoutService.getSectionTypes(this.screen);
    this.sections = this.customLayoutService.getSections(this.screen);

    this.customLayoutService.sectionsChanged.subscribe(() => this.sections = this.customLayoutService.getSections(this.screen));
  }

  setLayout(layoutClass: string) {
    this.layoutClass = layoutClass;
    this.customLayoutService.setLayout(layoutClass);
  }

  toggleCollapsed(section: CustomLayoutSection) {
    section.collapsed = !section.collapsed;
    this.updateSection(section);
  }

  updateSection(section: CustomLayoutSection) {
    if (section.id > 0) {
      this.customLayoutService.putSection(section);
    } else {
      this.customLayoutService.putSections(this.sections);
    }
  }

  deleteSection(section: CustomLayoutSection) {
    if (section.id > 0) {
      this.customLayoutService.deleteSection(section);
    } else {
      const i = this.sections.indexOf(section);
      this.sections.splice(i, 1);
      this.customLayoutService.putSections(this.sections);
    }
  }

  filtersChanged(filters: ActiveFilter[], section: CustomLayoutSection) {
    section.activeFilters = filters;
    this.updateSection(section);
  }

  onDragStart(section: CustomLayoutSection, name: string = null, type: string = null) {
    this.dragging = true;
    const translated = (name && name.length) ? this.translate.instant(name) : name;
    this.dragSection = section ?? this.customLayoutService.newSection(translated, type, this.screen, -1);

    if (this.dragSection.type === 'ioStatus' && this.dragSection.sequenceNumber === -1) {
      const filter = new ActiveFilter();
      filter.type = 'list';
      filter.filterOption = 'IoPoint.IoPoints';
      this.dragSection.activeFilters = [filter];
    }
  }

  onDragEnd() {
    this.dragging = false;
  }

  onDragEnter(div) {
    div.className = 'dragover-indicator dragover';
  }

  onDragLeave(div) {
    div.className = 'dragover-indicator';
  }

  onDummyDragEnter(div) {
    div.className = 'overview-wrapper dummy dragover';
  }

  onDummyDragLeave(div) {
    div.className = 'overview-wrapper dummy';
  }

  onDrop(event, section: CustomLayoutSection = null, div = null) {
    event.preventDefault();
    this.dragging = false;

    if (this.editMode) {
      const path = event.dataTransfer.getData('amlPath');
      if (path) {
        const pathArr = path.split('/');
        section.endpoint = (pathArr[1] === 'area') ? 'area' : 'measuringinstrument';
        section.informationLevelId = +pathArr[2];

        if (section.id > 0) {
          this.customLayoutService.putSection(section);
        } else {
          this.customLayoutService.putSections(this.sections);
        }
      } else if (section && this.dragSection) {
        if (this.dragSection.sequenceNumber === -1) {
          this.dragSection.sequenceNumber = 0;
          this.sections.push(this.dragSection);
        }

        const iOrigin = this.sections.indexOf(this.dragSection);
        const iTarget = this.sections.indexOf(section);

        if (iOrigin > iTarget) { // New index is lower: descend array, moving each element up one position.
          for (let i = iOrigin; i > iTarget; i--) {
            this.sections[i] = this.sections[i - 1];
          }
          this.sections[iTarget] = this.dragSection;
        }

        if (iOrigin < iTarget) { // New index is higher: ascend array, moving each element down one position.
          for (let i = iOrigin; i < iTarget; i++) {
            this.sections[i] = this.sections[i + 1];
          }
          this.sections[iTarget] = this.dragSection;
        }

        this.customLayoutService.putSections(this.sections);
        this.dragSection = null;
      } else if (this.dragSection && this.dragSection.sequenceNumber === -1) {
        if (div) {
          div.className = 'overview-wrapper dummy';
        }
        this.dragSection.sequenceNumber = 0;
        this.sections.push(this.dragSection);
        this.customLayoutService.putSections(this.sections);
        this.dragSection = null;
      }
    }
  }
}
