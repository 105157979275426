import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';

import { ActiveFilter } from './active-filter';
import { ErrorMessage } from '../error/error-message';
import { DataService } from './data.service';
import { ModelMetaData } from './model-meta-data';
import { FilterOption } from './filter-option';
import { GridColumn } from '../grid/grid-column';
import { GridDataArguments } from '../grid/grid-data-arguments';
import { GridService } from '../grid/grid.service';

@Component({
  selector: 'data-list-filter-select',
  templateUrl: './data-list-filter-select.component.html',
  styleUrls: [
    './data-list-filter-select.component.css',
    '../grid/grid.component.css'
  ]
})

export class DataListFilterSelectComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private gridService: GridService
  ) { }

  //Filter inputs
  @Input() parentId: number;
  @Input() informationLevelId: number;
  @Input() instrumentId: number;
  @Input() areaId: number;
  @Input() equipmentId: number;
  @Input() componentStreamId: number;
  @Input() chartId: number;
  @Input() controlChartSetId: number;
  @Input() fidelityChartId: number;
  @Input() ioClientId: number;
  @Input() maintenanceItemId: number;

  @Input() filter: ActiveFilter = null;
  @Input() selectedIds: number[] = [];
  @Output() public editModeChanged = new EventEmitter<boolean>();
  public editMode: boolean = false;
  public error: ErrorMessage;
  public data: any[] = [];
  public count: number;
  public loading = false;
  public metaData: ModelMetaData[];
  public filterOptions: FilterOption[];
  public filterList: ActiveFilter[] = [];
  public visibleColumns: GridColumn[] = [];
  public dataArguments: GridDataArguments = new GridDataArguments();
  public filterDialog = false;
  private endpoint: string = null;

  ngOnInit() {
    if (this.filter !== null && this.filter.filterOption !== undefined && this.filter.filterOption !== null) {
      switch (this.filter.filterOption) {
        case 'AreasOrInstruments':
          this.endpoint = 'informationlevel';
          break;
        case 'Areas':
          this.endpoint = 'area';
          break;
        case 'Instruments':
          this.endpoint = 'measuringinstrument';
          break;
        case 'IoPoint.IoPoints':
          this.endpoint = 'iopoint';
          break;
      }
    }

    this.addInputFilter(this.parentId, 'Area.Area');
    this.addInputFilter(this.informationLevelId, 'Area.Area');
    this.addInputFilter(this.instrumentId, 'Instrument.Id');
    this.addInputFilter(this.areaId, 'Area.Area');
    this.addInputFilter(this.equipmentId, 'Instrument.Id');
    this.addInputFilter(this.componentStreamId, 'ComponentStreamId');
    this.addInputFilter(this.chartId, 'ChartId');
    this.addInputFilter(this.controlChartSetId, 'ControlChartSetId');
    this.addInputFilter(this.fidelityChartId, 'FidelityChartId');
    this.addInputFilter(this.ioClientId, 'IoClient.Id');
    this.addInputFilter(this.maintenanceItemId, 'MaintenanceItemId');

    this.getData();
    this.changeEditMode(true);
  }

  getData() {
    if (this.endpoint !== null) {
      this.error = null;
      const args = this.getFilterGetArguments();
      this.loading = true;
      this.dataService.getMetaData(this.endpoint).subscribe(metaData => {
        this.metaData = metaData;
        this.filterOptions = metaData.filter(item => item.isFilter && item.filterType !== 'informationlevel'
          && item.filterType !== 'id_long' && item.filterType !== 'id_string').map(item => new FilterOption(item.name, item.filterType));
        this.getColumns();

        this.dataService.getItems(this.endpoint, args).subscribe(async data => {
          this.data = data;
          this.loading = false;
        }, error => this.error = error.error);
        this.dataService.getCount(this.endpoint, args).subscribe(options => this.count = options);
      }, error => this.error = error.error);
    }
  }

  addInputFilter(inputProperty: number, name: string) {
    if (inputProperty > 0) {
      const filter = new ActiveFilter();
      filter.id = (this.filterList.length > 0) ? this.filterList[this.filterList.length - 1].id + 1 : 0;
      filter.filterText = inputProperty.toString();
      filter.filterOption = name;
      filter.type = 'hidden';
      filter.filterDetails.operator = '==';
      this.filterList.push(filter);
    }
  }

  // Returns an url string containing the arguments for filtering on HTTP GET requests.
  getFilterGetArguments(): string {
    return '?index=' + this.dataArguments.pageIndex + '&size=' + this.dataArguments.pageSize + '&sort=' + this.dataArguments.sortColumn
      + ';' + this.dataArguments.sortOrder + '&filters=' + this.cleanString(JSON.stringify(this.filterList));
  }

  cleanString(url: string) {
    return url.replace(/%/gi, '%25')
      .replace(/&/gi, '%26')
      .replace(/#/gi, '%23');
  }

  getColumns() {
    this.visibleColumns = this.gridService.getVisibleColumns(this.metaData, null, null, false);
  }

  submit(form: NgForm) {
    if (form.valid) {
      this.filter.filterText = JSON.stringify(this.selectedIds);
      this.error = null;
      this.changeEditMode(false);
    } else {
      for (const i of Object.keys(form.controls)) {
        form.controls[i].markAsTouched();
      }
    }
  }

  cancel() {
    this.changeEditMode(false);
  }

  changeEditMode(editMode: boolean) {
    this.editMode = editMode;
    this.editModeChanged.emit(editMode);
  }

  refresh() {
    this.getData();
  }

  configureFilters() {
    this.filterDialog = true;
  }

  filterDialogChanged(event: boolean) {
    this.filterDialog = event;
  }
}
