import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@shtian/ng-pick-datetime';

import { ChartModule } from './chart/chart.module';
import { TimePeriodComponent } from './time-period/time-period.component';
import { ErrorMessageModule } from './error/error-message.module';
import { DialogModule } from './dialog/dialog.module';
import { DeleteDialogModule } from './delete-dialog/delete-dialog.module';
import { FormAlertModule } from './form-alert/form-alert.module';
import { GridModule } from './grid/grid.module';
import { ListInputModule } from './list-input/list-input.module';
import { LoaderModule } from './loader/loader.module';
import { SubmitButtonModule } from './submit-button/submit-button.module';
import { DataFormModule } from './data-form/data-form.module';
import { FormDialogModule } from './form-dialog/form-dialog.module';
import { FavoriteModule } from './favorite/favorite.module';
import { TooltipModule } from './tooltip/tooltip.module';

@NgModule({
  declarations: [
    TimePeriodComponent
  ],
  exports: [
    ChartModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    ErrorMessageModule,
    DialogModule,
    DeleteDialogModule,
    FormAlertModule,
    GridModule,
    ListInputModule,
    LoaderModule,
    SubmitButtonModule,
    DataFormModule,
    FormDialogModule,
    FavoriteModule,
    TooltipModule,
    TimePeriodComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ChartModule,
    TranslateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ErrorMessageModule,
    DialogModule,
    DeleteDialogModule,
    FormAlertModule,
    GridModule,
    ListInputModule,
    LoaderModule,
    SubmitButtonModule,
    DataFormModule,
    FormDialogModule,
    FavoriteModule,
    TooltipModule
  ]
})

export class SharedModule { }
