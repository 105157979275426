import { Component, OnInit, Input } from '@angular/core';

import { FavoriteService } from './favorite.service';
import { Favorite } from './favorite';

@Component({
  selector: 'favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.css']
})
export class FavoritesComponent implements OnInit {
  constructor(
    private favoriteService: FavoriteService
  ) { }

  @Input() editMode = false;
  public favorites: Favorite[];
  public configure = false;

  ngOnInit() {
    this.favorites = this.favoriteService.getFavorites();
    this.favoriteService.favoritesChanged.subscribe(favorites => this.favorites = favorites);
  }

  delete(favorite: Favorite) {
    this.favoriteService.deleteFavorite(favorite.informationLevelId);
  }

  onDragStart(event: DragEvent, path: string) {
    event.dataTransfer.setData('amlPath', path);
  }
}
