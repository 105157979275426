import { Component, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, forkJoin } from 'rxjs';
import { defaultIfEmpty } from 'rxjs/operators';

import { FidelityChart } from './fidelity-chart';
import { FidelityChartService } from './fidelity-chart.service';
import { FidelityGroup } from './fidelity-group';
import { FidelityGroupService } from './fidelity-group.service';
import { Stream } from '../../stream/stream';
import { StreamService } from '../../stream/stream.service';
import { ErrorMessage } from '../../../../shared/error/error-message';
import { SubmitButtonComponent } from '../../../../shared/submit-button/submit-button.component';

@Component({
  selector: 'fidelity-chart-overview',
  templateUrl: './fidelity-chart-overview.component.html',
  styleUrls: ['./fidelity-group-overview.component.css']
})
export class FidelityChartOverviewComponent implements OnInit {
  constructor(
    private fidelityChartService: FidelityChartService,
    private fidelityGroupService: FidelityGroupService,
    private streamService: StreamService
  ) { }
  @Input() public fidelityChartId: number;
  @Input() public instrumentId: number;
  @Output() public editModeChanged = new EventEmitter<boolean>();
  @ViewChild(SubmitButtonComponent) submitButton: SubmitButtonComponent;
  public fidelityChart: FidelityChart;
  private selectedFidelityGroup: FidelityGroup;
  private fidelityGroups: FidelityGroup[];
  private oldFidelityGroups: FidelityGroup[] = [];
  private streams: Stream[];
  private editMode = true;
  public groupDialog: boolean;
  public error: ErrorMessage;

  ngOnInit() {
    this.error = null;
    if (this.fidelityChartId > 0) {
      this.getFidelityChart();
    } else {
      this.fidelityChart = this.fidelityChartService.newFidelityChart();
      this.fidelityGroups = [];
    }
    this.streamService.getStreamsByInstrument(this.instrumentId, '?size=9999').subscribe(streams => this.streams = streams);
  }

  getFidelityChart() {
    this.fidelityChartService.getFidelityChart(this.fidelityChartId).subscribe(chart => {
      this.fidelityChart = chart;
      this.fidelityGroups = chart.fidelityGroups;
      this.oldFidelityGroups = Array.from(chart.fidelityGroups);
    }, error => this.error = error.error);
  }

  submitFidelityChart(form: NgForm) {
    if (this.submitButton !== undefined && this.submitButton.submit()) {
      if (form.valid) {
        this.error = null;
        if (this.fidelityChartId > 0) { // PUT
          for (let i = 0; i < this.fidelityGroups.length; i++) {
            this.fidelityGroups[i].fidelityChartId = this.fidelityChart.id;
          }
          this.fidelityChartService.putFidelityChart(this.fidelityChart).subscribe(response => {
            this.editModeChanged.emit(false);
            this.updateFidelityGroups().subscribe(innerResponse => {
              this.submitButton.ready();
              this.editModeChanged.emit(false);
            }, error => this.submitError(error));
          }, error => this.submitError(error));
        } else { // POST
          this.fidelityChartService.postFidelityChart(this.fidelityChart).subscribe(fidelityChartId => {
            this.editModeChanged.emit(false);
            for (let i = 0; i < this.fidelityGroups.length; i++) {
              this.fidelityGroups[i].fidelityChartId = fidelityChartId;
            }
            this.updateFidelityGroups().subscribe(innerResponse => {
              this.submitButton.ready();
              this.editModeChanged.emit(false);
            }, error => this.submitError(error));
          }, error => this.submitError(error));
        }
      } else {
        for (const i of Object.keys(form.controls)) {
          form.controls[i].markAsTouched();
        }
        this.submitButton.ready();
      }
    }
  }

  submitError(error: any) {
    this.error = error.error;
    this.submitButton.ready();
  }

  addFidelityGroup() {
    this.selectedFidelityGroup = this.fidelityGroupService.newFidelityGroup();
    this.fidelityGroups.push(this.selectedFidelityGroup);
    this.groupDialog = true;
  }

  editFidelityGroup(fidelityGroup: FidelityGroup) {
    this.selectedFidelityGroup = fidelityGroup;
    this.groupDialog = true;
  }

  deleteFidelityGroup(fidelityGroup: FidelityGroup) {
    this.fidelityGroups.splice(this.fidelityGroups.indexOf(fidelityGroup), 1);
  }

  private updateFidelityGroups(): Observable<void> {
    return new Observable<void>(observer => {
      const newGroups = this.fidelityGroups.filter(fg => this.oldFidelityGroups.indexOf(fg) < 0);
      const updatedGroups = this.fidelityGroups.filter(fg => this.oldFidelityGroups.indexOf(fg) >= 0);
      const removedGroups = this.oldFidelityGroups.filter(ofg => this.fidelityGroups.indexOf(ofg) < 0);
      forkJoin([
        forkJoin(newGroups.map(g => this.fidelityGroupService.postFidelityGroup(g))).pipe(defaultIfEmpty([])),
        forkJoin(updatedGroups.map(g => this.fidelityGroupService.putFidelityGroup(g))).pipe(defaultIfEmpty([])),
        forkJoin(removedGroups.map(g => this.fidelityGroupService.deleteFidelityGroup(g.id))).pipe(defaultIfEmpty([]))
      ]).subscribe(response => {
        observer.next();
        observer.complete();
      }, error => {
        this.error = error.error;
        observer.error(error);
      });
    });
  }

  cancel() {
    this.editModeChanged.emit(false);
  }

  dialogChanged(event: boolean) {
    this.groupDialog = event;
  }
}
