import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ReportManagementGuard } from './report-management-guard';
import { ReportManagementComponent } from './report-management.component';
import { ReportTypeComponent } from './report-type.component';
import { ReportTypeOverviewComponent } from './report-type-overview.component';
import { ReportUserDefinedComponent } from './report-user-defined.component';
import { ReportSettingsComponent } from './report-settings.component';

const reportRoutes: Routes = [
  { path: '', data: { helpId: 'Reports' }, component: ReportManagementComponent },
  { path: 'userdefined/:reportTypeId', data: { title: 'ReportType.Configuration', helpId: 'UserDefined' }, component: ReportTypeOverviewComponent, canLoad: [ReportManagementGuard] },
  { path: 'userdefined', data: { title: 'ReportType.UserDefined', helpId: 'UserDefined' }, component: ReportUserDefinedComponent, canLoad: [ReportManagementGuard] },
  { path: 'type/:reportTypeId', data: { title: 'ReportType.Configuration', helpId: 'ReportTypes' }, component: ReportTypeOverviewComponent, canLoad: [ReportManagementGuard] },
  { path: 'type', data: { title: 'ReportTypes', helpId: 'ReportTypes' }, component: ReportTypeComponent, canLoad: [ReportManagementGuard] },
  { path: 'settings', data: { title: 'Report.Settings', helpId: 'ReportSettings' }, component: ReportSettingsComponent, canLoad: [ReportManagementGuard] }
];

@NgModule({
  imports: [
    RouterModule.forChild(reportRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class ReportManagementRoutingModule { }
