<div class="table-wrapper" (scroll)="onScroll()">
  <div class="top-left-corner"></div>
  <div class="top-right-corner"></div>
  <table class="grid" cellspacing="0" (window:resize)="onResize($event)">
    <thead>
      <tr *ngIf="headers">
        <td *ngFor="let column of headers; let i = index" class="grid-table-header">
          <span class="grid-table-header-content" (click)="sort(column)">
            <ng-container *ngIf="translateHeader; else plainHeader">{{ column.header | translate }}</ng-container>
            <ng-template #plainHeader>{{ column.header }}</ng-template>
          </span>
          <span class="sort-arrows" *ngIf="dataArguments.sortColumn === column.header && showSortArrows">
            <i class="material-icons desc" id="previousPage" [ngClass]="{'active': dataArguments.sortOrder === 'asc'}">arrow_drop_up</i>
            <i class="material-icons asc" id="previousPage" [ngClass]="{'active': dataArguments.sortOrder === 'desc'}">arrow_drop_down</i>
          </span>
          <span class="resize" (mousedown)="columnResizeOnClick($event)" (drag)="doColumnResize($event, i)" (dragend)="doColumnResize($event, i)" draggable="true"></span> <!-- drag not working for Firefox, clientX always 0 -->
        </td>
      </tr>
      <tr *ngIf="!headers">
        <td *ngIf="showLeftScrollIndicator" class="left-scroll-indicator-column">
          <i class="material-icons" (mousedown)="scrollToLeft()">first_page</i>
        </td>
        <td *ngIf="showSelectionColumn" class="selection-column">{{ 'Select' | translate }}</td>
        <td *ngFor="let column of visibleColumns; let i = index" [ngClass]="column.header !== 'Action' ? 'grid-table-header' : showRightScrollIndicator ? 'grid-table-header grid-action-column show-right-scroll-indicator' : 'grid-table-header grid-action-column'">
          <span class="grid-table-header-content" (click)="sort(column)">
            <ng-container *ngIf="translateHeader; else plainHeader">
              <span *ngIf="column.header === 'Action' && showRightScrollIndicator" class="scroll-indicator"><i class="material-icons" (mousedown)="scrollToRight()">last_page</i></span>
              {{ column.fullHeader | translate }}
            </ng-container>
            <ng-template #plainHeader>{{ column.fullHeader }}</ng-template>
          </span>
          <span class="sort-arrows" *ngIf="dataArguments.sortColumn === column.header && showSortArrows">
            <i class="material-icons desc" id="previousPage" [ngClass]="{'active': dataArguments.sortOrder === 'asc'}">arrow_drop_up</i>
            <i class="material-icons asc" id="previousPage" [ngClass]="{'active': dataArguments.sortOrder === 'desc'}">arrow_drop_down</i>
          </span>
          <span *ngIf="column.header !== 'Action'" class="resize" (mousedown)="columnResizeOnClick($event)" (drag)="doColumnResize($event, i)" (dragend)="doColumnResize($event, i)" draggable="true"></span> <!-- drag not working for Firefox, clientX always 0 -->
        </td>
      </tr>
    </thead>
    <tbody>
      <ng-content></ng-content>
      <tr *ngFor="let rowData of data; let i = index; let odd = odd" [class.odd]="odd">
        <td *ngIf="showLeftScrollIndicator" class="left-scroll-indicator-column">...</td>
        <td *ngIf="showSelectionColumn" class="selection-column"><input type="checkbox" [checked]="selectedIds.includes(rowData.id)" (change)="toggleSelection(rowData)" /></td>
        <ng-container *ngFor="let column of visibleColumns; let j = index">
          <ng-container *ngIf="column.header !== 'Action'">
            <td *ngIf="!editMode" [title]="getColumnTitle(column, rowData)" [ngClass]="getColumnClass(column, rowData, j)">
              <ng-container *ngIf="!column.content">{{ getColumnContent(column, rowData) }}</ng-container>
              <ng-container *ngIf="column.content">
                <span *ngIf="column.type !== 'routerLink'" [innerHTML]="getColumnContent(column, rowData)"></span>
                <span *ngIf="column.type === 'routerLink'"><a [routerLink]="column.content(rowData)" routerLinkActive="active">{{ getColumnTitle(column, rowData) }}</a></span>
              </ng-container>
            </td>
            <td *ngIf="editMode">
              <data-form-item [item]="rowData" [property]="column" [informationLevelId]="informationLevelId" [selectionChanged]="selectionChanged" (selectionChangedEvent)="onSelectionChanged()"></data-form-item>
            </td>
          </ng-container>
          <td *ngIf="column.header === 'Action'" [ngClass]="showRightScrollIndicator ? 'grid-action-column show-right-scroll-indicator' : 'grid-action-column'">
            <span *ngIf="showRightScrollIndicator" class="scroll-indicator">...</span>
            <ng-container *ngFor="let action of rowActions">
              <button *ngIf="action.show && action.condition(rowData)" [disabled]="action.disabled(rowData)" [class]="'button-' + action.color" (click)="rowActionClick($event, action, rowData)">{{ action.name | translate }}</button>
            </ng-container>
          </td>
        </ng-container>
      </tr>
      <tr *ngIf="headers && !showFooter">
        <td class="grid-options-column" [colSpan]="headers.length"></td>
      </tr>
      <tr *ngIf="!headers && !showFooter">
        <td class="grid-options-column" [colSpan]="visibleColumns.length"></td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="showFooter" class="grid-options-wrapper">
    <div class="grid-options">
      <div class="left no-print">
        <span *ngIf="showAutoRefresh"><input type="checkbox" [(ngModel)]="autoRefresh" />{{ 'AutoRefresh' | translate }}</span>
        <i class="material-icons" id="refresh" (click)="refresh()" title="{{ 'Refresh' | translate }}">refresh</i>
        <span (click)="exportToCsv()">{{ 'CSV' | translate }}</span>
        <span (click)="exportToExcel()">{{ 'Excel' | translate }}</span>
        <span (click)="exportToRtf()">{{ 'Word' | translate }}</span>
        <!--<span (click)="exportToHtml()">HTML</span>-->
      </div>
      <div class="center">
        <i class="material-icons" id="firstPage" (click)="updatePage('first')" title="{{ 'FirstPage' | translate }}">first_page</i>
        <i class="material-icons" id="previousPage" (click)="updatePage('previous')" title="{{ 'PreviousPage' | translate }}">chevron_left</i>
        <span>
          {{ 'Grid.Page' | translate }}
          <input class="grid-indexer" type="text" (keyup.enter)="updatePageIndex($event)" (blur)="updatePageIndex($event)" maxlength="4" value="{{ dataArguments.pageIndex }}" />
          {{ 'Grid.of' | translate }} {{ dataArguments.pageCount }}
        </span>
        <i class="material-icons" id="nextPage" (click)="updatePage('next')" title="{{ 'NextPage' | translate }}">chevron_right</i>
        <i class="material-icons" id="lastPage" (click)="updatePage('last')" title="{{ 'LastPage' | translate }}">last_page</i>
        <input class="grid-indexer" type="text" (keyup.enter)="updatePageSize($event)" (blur)="updatePageSize($event)" maxlength="3" value="{{ dataArguments.pageSize }}" />
        {{ 'Grid.perpage' | translate }}
      </div>
      <div class="right">
        {{ count }} {{ 'Grid.total' | translate }}
      </div>
    </div>
  </div>
</div>
