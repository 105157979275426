import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { FidelitySession } from './fidelity-session';
import { FidelitySessionService } from './fidelity-session.service';
import { FidelityChart } from './fidelity-chart';
import { FidelityChartService } from './fidelity-chart.service';
import { DataPoint } from '../../../../shared/chart/data-point';
import { DataSerie } from '../../../../shared/chart/data-serie';

@Component({
  selector: 'fidelity-chart-view-chart',
  templateUrl: './fidelity-chart-view-chart.component.html'
})
export class FidelityChartViewChartComponent implements OnInit, OnChanges {
  constructor(
    private fidelitySessionService: FidelitySessionService,
    private fidelityChartService: FidelityChartService
  ) { }
  @Input() public fidelitySessionId: number;
  @Input() public fidelitySession: FidelitySession;
  @Input() public fidelityChart: FidelityChart;
  @Input() public view;
  @Output() public chartInitialized = new EventEmitter<boolean>();
  public chartData: DataSerie[];

  ngOnInit() {
    if (!this.fidelitySession || !this.fidelityChart) {
      this.fidelitySessionService.getFidelitySession(this.fidelitySessionId).subscribe(fidelitySession => {
        this.fidelitySession = fidelitySession;
        this.fidelityChartService.getFidelityChart(fidelitySession.fidelityChartId).subscribe(fidelityChart => {
          this.fidelityChart = fidelityChart;
          this.getFidelityChartData();
        });
      });
    } else {
      this.getFidelityChartData();
    }
  }

  ngOnChanges() { // NOTE: The implication here is this will NOT be triggered by fidelitySessionId changes, change implementation to save old id and check if it changed and reload session/chart first if so.
    if (this.fidelitySession && this.fidelityChart) {
      this.getFidelityChartData();
    }
  }

  getFidelityChartData() {
    if (this.fidelitySession && this.fidelityChart) {
      let index = 1;
      this.chartData = this.fidelityChart.fidelityGroups.map(fg => ({
        connected: true,
        name: (index++).toString(),
        series: this.fidelitySession.fidelityReadings.filter(fr => fg.fidelityComponents.some(fc => fc.componentTypeId === fr.componentTypeId))
          .map(fr => ({
            name: Math.log10(fr.componentType.molWeight), params: {
              description: fr.componentType.description, symbol: fr.componentType.symbol, unit: fr.componentType.unit, lrfValue: Math.log10(fr.readingValue), lmwValue: Math.log10(fr.componentType.molWeight)
            }, title: fr.componentType.symbol, value: Math.log10(fr.readingValue)
          }) as DataPoint),
        shape: 'circle'
      }) as DataSerie);
      this.chartInitialized.emit(true);
    }
  }
}
