import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../core/app.config';
import { Area } from './area';
import { Generic } from '../shared/generic';

@Injectable()
export class AreaService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getParentCandidates(id: number): Observable<Generic[]> { return this.http.get<Generic[]>(this.apiHost + 'area/getparentcandidates/' + id); }

  public getAreas(filterArguments: string): Observable<Area[]> { return this.http.get<Area[]>(this.apiHost + 'area/get' + filterArguments); }

  public getArea(id: number): Observable<Area> { return this.http.get<Area>(this.apiHost + 'area/get/' + id); }

  public getAreaCount(filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'area/count' + filterArguments); }

  public postArea(area: Area): Observable<number> { return this.http.post<number>(this.apiHost + 'area/post', area); }

  public putArea(area: Area) { return this.http.put(this.apiHost + 'area/put', area); }

  public deleteArea(id: number) { return this.http.delete(this.apiHost + 'area/delete/' + id); }

  public addOrUpdateAreas(areas: Area[]) { return this.http.post(this.apiHost + 'area/addorupdaterange', areas); }

  public getAreaTypes(): Observable<string[]> { return this.http.get<string[]>(this.apiHost + 'area/types'); }

  public putAreaImage(area: Area) { return this.http.put(this.apiHost + 'area/putAreaImage', area); }

  public newArea(): Area {
    return { id: -1, name: '', parent: '', parentId: null, type: '', ioServer: null, ioServerId: null, image: '', imageHeight: 650, imageWidth: 935 };
  }
}
