import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { IoServerFilterComponent } from './io-server-filter.component';
import { IoServerService } from '../io-server.service';

@NgModule({
  declarations: [IoServerFilterComponent],
  exports: [IoServerFilterComponent],
  imports: [CommonModule, TranslateModule],
  providers: [IoServerService]
})
export class IoServerFilterModule { }
