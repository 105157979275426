import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { DiagnosticDisplayModule } from '../diagnostic/diagnostic-display/diagnostic-display.module';
import { DiagnosticSelectionModule } from '../diagnostic/diagnostic-selection/diagnostic-selection.module';

import { AreaLayoutComponent } from './area-layout.component';
import { DiagDialogComponent } from './diag-dialog.component';
import { AreaLayoutService } from './area-layout.service';
import { AreaService } from '../area.service';
import { DiagnosticService } from '../diagnostic/diagnostic.service';
import { IoService } from '../io/io.service';
import { LevelService } from '../level.service';

@NgModule({
  declarations: [
    AreaLayoutComponent,
    DiagDialogComponent
  ],
  exports: [
    AreaLayoutComponent
  ],
  imports: [
    SharedModule,
    DiagnosticDisplayModule,
    DiagnosticSelectionModule
  ],
  providers: [
    AreaLayoutService,
    AreaService,
    DiagnosticService,
    IoService,
    LevelService
  ]
})
export class AreaLayoutModule { }
