import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { ActiveFilter } from '../../../shared/data/active-filter';

@Component({
  selector: 'audit-action-filter',
  templateUrl: './audit-action-filter.component.html',
  styleUrls: ['../../../shared/data/data-filter.component.css']
})
export class AuditActionFilterComponent implements AfterContentChecked {
  @Input() filter: ActiveFilter;
  @Output() filterChanged = new EventEmitter<boolean>();
  @ViewChild('auditActionElement') auditActionElementRef: ElementRef;

  ngAfterContentChecked() {
    if (this.filter.filterText !== undefined && this.auditActionElementRef !== undefined) {
      this.auditActionElementRef.nativeElement.value = this.filter.filterText;
    }
  }

  changed(ev) {
    this.filter.filterText = ev.target.value;
    this.filterChanged.emit(true);
  }
}
