import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ClaimService } from '../../core/claim.service';
import { PerformanceIndicator } from '../perf-indic';
import { PerformanceIndicatorService } from '../perf-indic.service';
import { ErrorMessage } from '../../shared/error/error-message';
import { Header } from '../../shared/grid/header';
import { PerformanceIndicatorSubResult } from '../perf-indic-sub-result';
import { PerformanceIndicatorChart } from '../perf-indic-chart';

@Component({
  selector: 'perf-indic-calculated',
  templateUrl: './perf-indic-calculated.component.html',
  styleUrls: ['./perf-indic-calculated.component.css']
})
export class PerformanceIndicatorCalculatedComponent implements OnInit, OnChanges, OnDestroy {
  constructor(
    private performanceIndicatorService: PerformanceIndicatorService,
    private claimService: ClaimService,
    private route: ActivatedRoute,
    private router: Router
  ) { }
  @Input() public informationLevelId = 0;
  @Input() public useSavedResults: boolean = false;
  @Input() public shownOnInstrumentOverview: boolean = false;
  @Input() public showWrapper = true;
  public title: string = 'PerformanceIndicator.PerformanceIndicators';
  public headers: Header[] = [{ header: 'Description', sort: false, width: '62%' }, { header: 'Value', sort: false, width: '18%' }, { header: 'UnitOfMeasurement', sort: false, width: '17%' }];
  public performanceIndicators: PerformanceIndicator[];
  private performanceIndicatorCharts: PerformanceIndicatorChart[];
  private activeChart: PerformanceIndicatorChart;
  private activeChartType: string;
  private activeChartSubResultsTitle: string;
  private activeChartSubResultsChartType: string;
  private activeChartSubResults: any[];
  private colors: any[] = [];
  public updateAccess: boolean;
  public showChartsTab: boolean = true;
  public showTableTab: boolean = false;
  public error: ErrorMessage;
  private routeSubscription: ISubscription;

  ngOnInit() {
    if (!this.informationLevelId || this.informationLevelId === 0) {
      this.informationLevelId = +this.route.parent.snapshot.paramMap.get('id');
    }

    this.getPerformanceIndicator();
    this.getPerformanceIndicatorCharts();
    this.updateAccess = this.claimService.checkClaimContains('aml.perfindic', 'u');

    if (!this.shownOnInstrumentOverview) {
      this.routeSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(path => {
        this.informationLevelId = +this.route.parent.snapshot.paramMap.get('id');
        this.getPerformanceIndicator();
        this.getPerformanceIndicatorCharts();
      });
    }
  }

  ngOnChanges() {
    if (this.shownOnInstrumentOverview) {
      this.getPerformanceIndicator();
      this.getPerformanceIndicatorCharts();
    }
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  getPerformanceIndicator() {
    this.error = null;

    if (this.useSavedResults) {
      this.performanceIndicatorService.getPerformanceIndicatorSavedResults(this.informationLevelId)
        .subscribe(pIndicators => this.performanceIndicators = pIndicators, error => this.error = error.error);
    } else {
      this.performanceIndicatorService.getPerformanceIndicatorsByInformationLevel(this.informationLevelId)
        .subscribe(pIndicators => this.performanceIndicators = pIndicators, error => this.error = error.error);
    }
  }

  getPerformanceIndicatorCharts() {
    this.error = null;
    this.activeChartSubResults = null;
    this.activeChartSubResultsTitle = null;
    if (this.activeChart !== undefined && this.activeChart !== null) {
      this.activeChart.dataPoints = [];
    }
    this.performanceIndicatorService.getPerformanceIndicatorChartsByInformationLevel(this.informationLevelId)
      .subscribe(pIndicatorCharts => {
        pIndicatorCharts.forEach(chart => {
          chart.dataPoints = [];
          chart.items.forEach(item => {
            if (item.formulaResult !== null && (item.formulaResult === 'NaN' || item.formulaResult === 'N.A.')) {
              item.formulaResult = '0';
            }
            if (item.formulaResult !== null && item.formulaResult !== 'NaN' && item.formulaResult !== 'N.A.' && item.formulaResult !== '∞') {
              item.formulaResult = item.formulaResult.replace(',', '.');
              chart.dataPoints.push({ name: item.name, value: item.formulaResult });
            }
          });
        });
        this.performanceIndicatorCharts = pIndicatorCharts;
        this.activeChart = pIndicatorCharts[0];
        if (this.activeChart !== undefined && this.activeChart !== null) {
          this.colors = this.activeChart.items.map(item => <any>{ name: item.name, value: item.color });
        }

        if ((this.activeChartType === undefined || this.activeChartType === null) && this.activeChart !== undefined) {
          this.activeChartType = this.activeChart.chartType;
        }
      }, error => this.error = error.error);
  }

  toggleEquipmentResults(performanceIndicator: PerformanceIndicator) {
    performanceIndicator.showSubResults = !performanceIndicator.showSubResults;

    if (performanceIndicator.showSubResults) {
      this.error = null;
      this.performanceIndicatorService.getPerformanceIndicatorSubResults(this.informationLevelId, performanceIndicator.id)
        .subscribe(results => performanceIndicator.subResults = results, error => this.error = error.error);
    }
  }

  toggleFocus(subResult: PerformanceIndicatorSubResult) {
    subResult.focus = !subResult.focus;
  }

  showCharts() {
    this.showChartsTab = true;
    this.showTableTab = false;
  }

  showTable() {
    this.showTableTab = true;
    this.showChartsTab = false;
  }

  openSubitemChart(event: any) {
    const performanceIndicatorName = (event.name !== undefined && event.name !== null) ? event.name : event;
    if (this.activeChart !== undefined && this.activeChart !== null) {
      //Get the performance indicator which was clicked on the chart by the user.
      const performanceIndicators = this.activeChart.items.filter(item => item.name === performanceIndicatorName);

      if (performanceIndicators.length === 1 && performanceIndicators[0].id !== null) {
        //Retrieve the sub results of the underlying information levels.
        this.error = null;
        if (this.activeChartSubResults !== null) {
          this.activeChartSubResults = [];
        }
        this.performanceIndicatorService.getPerformanceIndicatorSubResults(this.informationLevelId, performanceIndicators[0].id)
          .subscribe(results => {
            this.activeChartSubResultsTitle = performanceIndicators[0].name;
            this.activeChartSubResultsChartType = this.activeChartType;
            performanceIndicators[0].subResults = results;
            const subResults = [];

            results.forEach(item => {
              if (item.formulaResult !== null && (item.formulaResult === 'NaN' || item.formulaResult === 'N.A.')) {
                item.formulaResult = '0';
              }
              if (item.formulaResult !== null && item.formulaResult !== 'NaN' && item.formulaResult !== 'N.A.' && item.formulaResult !== '∞') {
                item.formulaResult = item.formulaResult.replace(',', '.');
                subResults.push({ name: item.name, value: item.formulaResult });
              }
            });

            this.activeChartSubResults = subResults;
          }, error => this.error = error.error);
      } else {
        this.activeChartSubResults = null;
        this.activeChartSubResultsTitle = null;
      }
    }
  }

  changeChartType(chartType: string) {
    if (chartType !== null) {
      this.activeChartType = chartType;
    }
  }

  navigateToInformationLevel(name: string) {
    this.performanceIndicatorService.searchInformationLevel(name)
      .subscribe(results => {
        const result = results.filter(item => item.title === name);

        if (result.length === 1 && result[0].path !== null && result[0].path !== '') {
          this.router.navigate([result[0].path]);
        }
      });
  }

  navigateToInformationLevel2(event: any) {
    this.navigateToInformationLevel(event.name);
  }

  setActiveChart() {
    this.activeChartSubResults = null;

    if (this.activeChart !== undefined && this.activeChart !== null) {
      this.activeChartType = this.activeChart.chartType;
      this.colors = this.activeChart.items.map(item => <any>{ name: item.name, value: item.color });
    }
  }
}
