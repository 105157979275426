import { Component, Input, OnInit } from '@angular/core';

import { HistoricalChartEventService } from './historical-chart-event.service';
import { ReferenceMaterialChange } from './reference-material-change';
import { Header } from '../../../../shared/grid/header';
import { AppConfig } from '../../../../core/app.config';

@Component({
  selector: 'reference-material-change',
  templateUrl: './reference-material-change.component.html'
})
export class ReferenceMaterialChangeComponent implements OnInit {
  constructor(
    private historicalChartEventService: HistoricalChartEventService,
    private appConfig: AppConfig
  ) { }
  @Input() public referenceMaterialId: number;
  public referenceMaterialChange: ReferenceMaterialChange;
  public title = 'Reference.MaterialChange';
  public headers: Header[] = [{ header: 'Description', sort: false, width: '30%' }, { header: 'Symbol', sort: false, width: '15%' }, { header: 'OldValue', sort: false, width: '20%' },
    { header: 'NewValue', sort: false, width: '20%' }, { header: 'UnitOfMeasurement', sort: false, width: '15%' }];
  private dateFormat: string;

  ngOnInit() {
    this.dateFormat = this.appConfig.getConfig('dateFormat');
    this.getReferenceMaterialChange();
  }

  getReferenceMaterialChange() {
    this.historicalChartEventService.getReferenceMaterialChange(this.referenceMaterialId).subscribe(rmc => this.referenceMaterialChange = rmc);
  }
}
