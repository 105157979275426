import { GridColumn } from './grid-column';

export class ListObjectMetaData {
  constructor(header: string, property: string, endpoint: string, requiredInput: boolean = true, type: string = 'listInput', filter: string = 'Name',
    idProperty: GridColumn = null, viewProperties: GridColumn[] = null) {
    this.header = header;
    this.property = property;
    this.endpoint = endpoint;
    this.requiredInput = requiredInput;
    this.type = type;
    this.filter = filter;
    this.idProperty = idProperty ?? new GridColumn(header, 'id', type, requiredInput);
    this.viewProperties = viewProperties ?? [new GridColumn(header, 'name')];
  }

  header: string;
  property: string;
  endpoint: string;
  requiredInput: boolean;
  type: string;
  filter: string;
  idProperty: GridColumn;
  viewProperties: GridColumn[];
}
