import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../../core/app.config';
import { ActiveFilter } from '../../shared/data/active-filter';

@Injectable()
export class FilterService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getFilters(args: string): Observable<ActiveFilter[]> { return this.http.get<ActiveFilter[]>(this.apiHost + 'filter/get' + args); }

  public countFilters(args: string): Observable<number> { return this.http.get<number>(this.apiHost + 'filter/count' + args); }

  public getFilter(id: number): Observable<ActiveFilter> { return this.http.get<ActiveFilter>(this.apiHost + 'filter/get/' + id); }

  public postFilter(filter: ActiveFilter) { return this.http.post(this.apiHost + 'filter/post', filter); }

  public putFilter(filter: ActiveFilter) { return this.http.put(this.apiHost + 'filter/put', filter); }

  public deleteFilter(id: number) { return this.http.delete(this.apiHost + 'filter/delete/' + id); }

  public newFilter(): ActiveFilter {
    return {
      id: -1, filterText: null, filterOption: null, filterDetails: {}, type: null, readonly: false
    };
  }
}
