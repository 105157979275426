<div *ngIf="!reportType">
  <error-message [error]="error"></error-message>
</div>

<div *ngIf="!datasetLoaded || !reportLoaded || (reportLoaded && !generatedPageImages && !reportNotAvailable && pageCount <= 100)" class="overview-wrapper loading-progress">
  <ng-container *ngIf="((!datasetLoaded && !reportLoaded) || (datasetLoaded && !reportLoaded)) && !generatedPageImages">{{ 'Report.LoadingReport' | translate }}</ng-container>
  <ng-container *ngIf="reportLoaded && !generatedPageImages">{{ 'Report.GeneratingExportPages' | translate }} ({{ generatedPageImagesCount }} {{ 'Report.Of' | translate }} <ng-container *ngIf="reportPages">{{ reportPages.length }}</ng-container> {{ 'Report.Ready' | translate }})</ng-container>
  <loader></loader>
</div>

<div *ngIf="selectedDatasetId && datasetLoaded && !reportNotAvailable" class="overview-wrapper historic-data-message">{{ 'Report.ViewingHistoricalDataOf' | translate }}: <span>{{ reportDataset.dataDateTime | date:dateTimeFormat }}</span></div>

<div class="report" *ngIf="reportType && datasetLoaded && !reportNotAvailable && pageCount <= 100">
  <div class="report-header">
    {{ report.description }}
    <div class="report-view-options">
      View: <span (click)="setViewSize('normal')" [ngClass]="{'active': viewSize === 'normal'}">{{ 'Normal' | translate }}</span> / <span (click)="setViewSize('compact')" [ngClass]="{'active': viewSize === 'compact'}">{{ 'Compact' | translate }}</span>
    </div>
  </div>
  <div class="report-page-spacer"></div>
  <ng-container *ngFor="let reportPage of reportPages">
    <div class="report-page">
      <div class="report-page-content">
        <ng-container *ngFor="let section of reportPage">
          <ng-container *ngTemplateOutlet="sectionTemplate; context: { tSection: section }"></ng-container>
        </ng-container>
      </div>
    </div>
    <div class="report-page-spacer"></div>
  </ng-container>
</div>
<div class="report-export-pages"></div>

<ng-template #sectionTemplate let-section="tSection">
  <div *ngIf="section.type === 'pageBreak'" class="report-section report-page-break">
    <br />
  </div>
  <div *ngIf="section.type === 'header'" class="page-header">
    <table>
      <tr>
        <td>
          <div class="report-title">{{ reportType.description | translate }}</div>
          <div class="report-sub-title">{{ section.content | translate }}</div>
        </td>
        <td><img class="report-logo" [src]="logo" /></td>
      </tr>
    </table>
  </div>
  <div *ngIf="section.title !== undefined && section.title !== null" class="report-section-title">
    {{ section.title }}
  </div>
  <div *ngIf="section.type === 'detailsTable'" class="report-section report-detailsTable">
    <table class="{{section.type}}_{{section.title}}">
      <tr *ngFor="let item of Array(section.headers.length).fill(0), let i = index">
        <th [ngStyle]="{ 'width': section.headers[i].width }">{{ section.headers[i].header }}</th>
        <td *ngIf="section.headers[i].header && section.content.length">: </td>
        <td *ngIf="!section.headers[i].header">&nbsp;</td>
        <td *ngIf="section.content[i] === undefined">&nbsp;</td>
        <td *ngIf="section.content[i] !== undefined && section.content[i].dataType !== 'DateTime' && section.content[i].dataType !== 'datetime' && section.content[i].dataType !== 'date'">{{ section.content[i].content }}</td>
        <td *ngIf="section.content[i] !== undefined && (section.content[i].dataType === 'DateTime' || section.content[i].dataType === 'datetime')">{{ section.content[i].content | date:dateTimeFormat }}</td>
        <td *ngIf="section.content[i] !== undefined && section.content[i].dataType === 'date'">{{ section.content[i].content | date:dateFormat }}</td>
      </tr>
    </table>
  </div>
  <div *ngIf="section.type === 'overviewTable'" class="report-section report-overviewTable">
    <table class="{{section.type}}_{{section.title}}" summary="section.title">
      <tr>
        <th *ngFor="let header of section.headers" [ngStyle]="{ 'width': header.width }">{{ header.header }}</th>
      </tr>
      <tr *ngFor="let row of section.content">
        <td *ngFor="let content of row">
          <ng-container *ngIf="content.dataType !== 'DateTime' && content.dataType !== 'datetime' && content.dataType !== 'date'">{{ content.content }}</ng-container>
          <ng-container *ngIf="content.dataType === 'DateTime' || content.dataType === 'datetime'">{{ content.content | date:dateTimeFormat }}</ng-container>
          <ng-container *ngIf="content.dataType === 'date'">{{ content.content | date:dateFormat }}</ng-container>
        </td>
      </tr>
    </table>
  </div>
  <div *ngIf="section.type === 'overviewTableVertical'" class="report-section report-overviewTable">
    <table class="{{section.type}}_{{section.title}}" summary="section.title">
      <tr *ngFor="let item of Array(section.headers.length).fill(0), let i = index">
        <th [ngStyle]="{ 'width': section.headers[i].width }">{{ section.headers[i].header }}</th>
        <td *ngFor="let content of section.content">
          <ng-container *ngIf="content[i].dataType !== 'DateTime' && content[i].dataType !== 'datetime' && content[i].dataType !== 'date'">{{ content[i].content }}</ng-container>
          <ng-container *ngIf="content[i].dataType === 'DateTime' || content[i].dataType === 'datetime'">{{ content[i].content | date:dateTimeFormat }}</ng-container>
          <ng-container *ngIf="content[i].dataType === 'date'">{{ content[i].content | date:dateFormat }}</ng-container>
        </td>
      </tr>
    </table>
  </div>
  <div *ngIf="section.type === 'accuracyChart'" #chartSectionTemplate class="report-section report-chart">
    <control-chart-report-accuracy-chart [id]="section.content[0].content" (chartInitialized)="initializeChart(section, chartSectionTemplate)"></control-chart-report-accuracy-chart>
  </div>
  <div *ngIf="section.type === 'precisionChart'" #chartSectionTemplate class="report-section report-chart">
    <control-chart-report-precision-chart [id]="section.content[0].content" (chartInitialized)="initializeChart(section, chartSectionTemplate)"></control-chart-report-precision-chart>
  </div>
  <div *ngIf="section.type === 'historicalChart'" #chartSectionTemplate class="report-section report-chart">
    <historical-chart-event-chart [controlChartSetId]="section.content[0].content" [initiatedByReport]="true" [start]="section.content[1].content" [end]="section.content[2].content" (chartInitialized)="initializeChart(section, chartSectionTemplate)"></historical-chart-event-chart>
  </div>
  <div *ngIf="section.type === 'fidelityChart'" #chartSectionTemplate class="report-section report-chart">
    <fidelity-chart-view-chart [fidelitySessionId]="section.content[0].content" [view]="[640,400]" (chartInitialized)="initializeChart(section, chartSectionTemplate)"></fidelity-chart-view-chart>
  </div>
  <div *ngIf="section.type === 'linearityChart'" #chartSectionTemplate class="report-section report-chart">
    <linearity-chart-view-chart [linearitySessionId]="section.content[0].content" [view]="[640,400]" (chartInitialized)="initializeChart(section, chartSectionTemplate)"></linearity-chart-view-chart>
  </div>
  <div *ngIf="section.type === 'text'" class="report-section report-text">
    {{ section.content[0].content }}
  </div>
  <div *ngIf="section.type === 'footer'" class="page-footer">
    <table>
      <tr>
        <td>{{ 'Report.DateTime' | translate }}: {{ reportDataset.dataDateTime | date:dateTimeFormat }}</td>
        <td>{{ 'Page' | translate }}: {{ section.content }} {{ 'of' | translate }} {{ pageCount }}</td>
      </tr>
    </table>
  </div>
</ng-template>

<div class="error" *ngIf="reportNotAvailable">{{ 'Report.ReportNotAvailable' | translate }}</div>
