import { Component } from '@angular/core';

@Component({
  selector: 'aml',
  templateUrl: './aml.component.html'
})
export class AmlComponent {
  constructor() { }

  public title = 'AML';
}
