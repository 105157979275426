import { Component, EventEmitter, OnInit, Input, Output, ViewChild, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { AppConfig } from '../../core/app.config';
import { DataService } from '../data/data.service';
import { ErrorMessage } from '../error/error-message';
import { GridColumn } from '../grid/grid-column';
import { SubmitButtonComponent } from '../submit-button/submit-button.component';

@Component({
  selector: 'data-form',
  templateUrl: './data-form.component.html'
})
export class DataFormComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private appConfig: AppConfig,
    private dataService: DataService
  ) { }
  @Input() item: any;
  @Input() properties: GridColumn[];
  @Input() editMode = true;
  @Input() updateAccess = true;
  @Input() error: ErrorMessage;
  @Input() submitReady: Observable<void>;
  @Input() informationLevelId: number;
  @Input() embedded = false;
  @Input() submit: Observable<void>;
  @Output() submitForm = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<void>();
  @Output() formValid = new EventEmitter<boolean>();
  @Output() submitResult = new EventEmitter<boolean>();
  @ViewChild(SubmitButtonComponent) submitButton: SubmitButtonComponent;
  @ViewChild('itemForm') itemFormElementRef: NgForm;
  public dateTimeFormat: string;
  private submitReadySubscription: Subscription;
  private submitSubscription: Subscription;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');

    if (this.submitReady !== undefined && this.submitButton !== undefined) {
      this.submitReadySubscription = this.submitReady.subscribe(() => this.submitButton.ready());
    }
  }

  ngAfterViewInit() {
    if (this.itemFormElementRef) {
      this.itemFormElementRef.statusChanges.subscribe(() => this.formValid.emit(this.itemFormElementRef.valid));
      if (this.submit !== undefined) {
        this.submitSubscription = this.submit.subscribe(() => this.submitItem(this.itemFormElementRef));
      }
    }
  }

  ngOnDestroy() {
    if (this.submitReadySubscription) {
      this.submitReadySubscription.unsubscribe();
    }
    if (this.submitSubscription) {
      this.submitSubscription.unsubscribe();
    }
  }

  getContent(property: GridColumn): string {
    if (property.content !== undefined && property.content !== null) {
      return this.dataService.transformToType(property.content(this.item), property.type, property.translate);
    } else {
      return this.dataService.getPropertyValue(this.item, property.property, property.type, property.translate);
    }
  }

  submitItem(form: NgForm) {
    if ((this.submitButton !== undefined && this.submitButton.submit()) || this.submitButton === undefined) {
      if (form.valid) {
        this.submitForm.emit(this.item);
      } else {
        for (const i of Object.keys(form.controls)) {
          form.controls[i].markAsTouched();
        }
        this.submitResult.emit(false);
        if (this.submitButton !== undefined) {
          this.submitButton.ready();
        }
      }
    }
  }

  cancelClicked() {
    this.cancel.emit();
  }
}
