import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { ClaimService } from '../../../../core/claim.service';
import { ActivatedRoute } from '@angular/router';

import { ControlChartSet } from '../control-chart-set';
import { ControlChartService } from './control-chart.service';
import { DataPoint } from '../../../../shared/chart/data-point';
import { DataSerie } from '../../../../shared/chart/data-serie';
import { Comp } from '../../stream/comp/comp';
import { CompService } from '../../stream/comp/comp.service';
import { ErrorMessage } from '../../../../shared/error/error-message';
import { AppConfig } from '../../../../core/app.config';

@Component({
  selector: 'control-chart-view',
  templateUrl: './control-chart-view.component.html',
  styleUrls: ['./control-chart-view.component.css']
})
export class ControlChartViewComponent implements OnInit, OnChanges {
  constructor(
    private controlChartService: ControlChartService,
    private compService: CompService,
    private claimService: ClaimService,
    private route: ActivatedRoute,
    private appConfig: AppConfig
  ) { }
  @Input() id;
  public controlChartSet: ControlChartSet;
  private component: Comp;
  private colors: any;
  public valDataDialog = false;
  @Input() dataChange: boolean;
  private onlyIncluded: boolean = true;
  public updateAccess: boolean;
  private accuracyChartPoints: DataSerie;
  private precisionChartPoints: DataSerie;
  private failedACRuleIds: number[];
  private failedPCRuleIds: number[];
  private accuracyControlLimits: DataPoint[];
  private validationSessionId = -1;
  private index: number;
  private unit: string;
  private updateChart: boolean;
  public error: ErrorMessage;
  private dateTimeFormat: string;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.updateAccess = this.claimService.checkClaimContains('aml.conchart', 'u');
    if (!this.id) {
      this.id = +this.route.snapshot.paramMap.get('id');
    }

    this.getControlChartSet();
  }

  ngOnChanges() {
    this.getControlChartSet();
  }

  getControlChartSet() {
    this.controlChartService.getControlChartSet(this.id, true, true).subscribe(ccs => {
      this.controlChartSet = ccs;
      this.accuracyControlLimits = this.calculateLimits(ccs.accuracyChart.lcl, ccs.accuracyChart.ucl);
      this.compService.getComp(ccs.componentId).subscribe(comp => {
        this.colors = [{ name: comp.tag, value: '#000000' }];
        this.component = comp;
        this.failedACRuleIds = [];
        this.index = 0;
        this.accuracyChartPoints = {
          name: comp.tag, connected: true, shape: 'circle', series: ccs.accuracyChart.validationResults.map(valResult => {
            valResult.failedChecks.map(check => this.failedACRuleIds.push(check));
            let color;
            let stroke;
            switch (valResult.worstResult) {
              case 'Bad':
                color = '#FF2222';
                break;
              case 'Alert':
                color = '#FFAA22';
                break;
              default:
                color = '#8000BD';
                stroke = '#000000';
                break;
            }
            this.unit = valResult.unit;
            return ({
              value: valResult.reading, name: this.index++, params: {
                color: color, stroke: stroke, value: valResult.reading, unit: valResult.unit, hasNote: valResult.hasNote, validationSessionId: valResult.validationSessionId,
                validationStart: valResult.validationStartDateTime, validationEnd: valResult.validationEndDateTime, validationProcedure: valResult.validationMethod
              }
            });
          })
        };
        this.failedPCRuleIds = [];
        this.index = 0;
        this.precisionChartPoints = {
          name: comp.tag, connected: true, shape: 'circle', series: ccs.precisionChart.validationResults.map(valResult => {
            valResult.failedChecks.map(check => this.failedPCRuleIds.push(check));
            let color;
            let stroke;
            switch (valResult.worstResult) {
              case 'Bad':
                color = '#FF2222';
                break;
              case 'Alert':
                color = '#FFAA22';
                break;
              default:
                color = '#8000BD';
                stroke = '#000000';
                break;
            }
            return ({
              value: valResult.reading, name: this.index++, params: {
                color: color, stroke: stroke, value: valResult.reading, unit: valResult.unit, validationSessionId: valResult.validationSessionId,
                validationStart: valResult.validationStartDateTime, validationEnd: valResult.validationEndDateTime, validationProcedure: valResult.validationMethod
              }
            });
          })
        };
        this.updateChart = !this.updateChart;
      });
    });
  }

  valDataDialogChanged(event: boolean) {
    this.valDataDialog = event;
    this.dataChange = !this.dataChange;
    this.getControlChartSet();
  }

  chartChange(event: boolean) {
    this.getControlChartSet();
  }

  viewDetails(event: any) {
    this.validationSessionId = event.params.validationSessionId;
    this.valDataDialog = true;
  }

  showIncludedOnly(event: boolean) {
    this.onlyIncluded = event;
  }

  private calculateLimits(lcl: number, ucl: number): DataPoint[] {
    if (lcl === 0) {
      const values = [ucl, ucl * 2 / 3];
      return [
        { name: 'UCL = ' + values[0].toFixed(2), value: values[0], params: { color: '#FF0000' } },
        { name: 'UWL = ' + values[1].toFixed(2), value: values[1], params: { color: '#FF8800' } },
        { name: '0.00', value: 0, params: { color: '#000000' } }
      ];
    } else {
      const values = [ucl, ucl * 5 / 6 + lcl / 6, ucl / 6 + lcl * 5 / 6, lcl];
      return [
        { name: 'UCL = ' + values[0].toFixed(2), value: values[0], params: { color: '#FF0000' } },
        { name: 'UWL = ' + values[1].toFixed(2), value: values[1], params: { color: '#FF8800' } },
        { name: '0.00', value: 0, params: { color: '#000000' } },
        { name: 'LWL = ' + values[2].toFixed(2), value: values[2], params: { color: '#FF8800' } },
        { name: 'LCL = ' + values[3].toFixed(2), value: values[3], params: { color: '#FF0000' } }
      ];
    }
  }
}
