import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/auth-guard';
import { Error401Component } from './core/errors/error401/error401.component';
import { AmlComponent } from './core/aml/aml.component';
import { AboutComponent } from './core/about/about.component';

const appRoutes: Routes = [
  {
    path: '', data: { title: 'AML' }, children: [
      { path: '', component: AmlComponent },
      { path: 'about', component: AboutComponent },
      { path: 'event', data: { title: 'Events' }, loadChildren: () => import('./event/event.module').then(m => m.EventModule), canLoad: [AuthGuard] },
      { path: 'pvt', data: { title: 'PVT.PVT' }, loadChildren: () => import('./pvt/pvt.module').then(m => m.PvtModule), canLoad: [AuthGuard] },
      { path: 'area', data: { title: 'Areas' }, loadChildren: () => import('./area/area.module').then(m => m.AreaModule), canLoad: [AuthGuard] },
      { path: 'instrument', data: { title: 'Instruments' }, loadChildren: () => import('./area/instrument/instrument.module').then(m => m.InstrumentModule), canLoad: [AuthGuard] },
      { path: 'administration', data: { title: 'Administration' }, loadChildren: () => import('./core/administration/administration.module').then(m => m.AdministrationModule), canLoad: [AuthGuard] },
      { path: 'activityoverview', data: { title: 'Activity.Overview' }, loadChildren: () => import('./change-man/change-man.module').then(m => m.ChangeManModule), canLoad: [AuthGuard] },
      { path: 'laboratory', data: { title: 'Laboratory' }, loadChildren: () => import('./laboratory/laboratory.module').then(m => m.LaboratoryModule), canLoad: [AuthGuard] },
      { path: 'maintenanceapproval', data: { title: 'Maintenance.Approval' }, loadChildren: () => import('./area/maintenance/maintenance-approval/maintenance-approval.module').then(m => m.MaintenanceApprovalModule), canLoad: [AuthGuard] },
      { path: 'historian', data: { title: 'Historian' }, loadChildren: () => import('./historian/historian.module').then(m => m.HistorianModule), canLoad: [AuthGuard] },
      { path: 'charting', data: { title: 'Charting' }, loadChildren: () => import('./charting/charting.module').then(m => m.ChartingModule), canLoad: [AuthGuard] },
      { path: 'import', data: { title: 'Imports' }, loadChildren: () => import('./import/import.module').then(m => m.ImportModule), canLoad: [AuthGuard] },
      { path: 'databrowser', data: { title: 'DataBrowser' }, loadChildren: () => import('./data/data-browser.module').then(m => m.DataBrowserModule), canLoad: [AuthGuard] },
      { path: 'devops', data: { title: 'Devops' }, loadChildren: () => import('./devops/devops.module').then(m => m.DevopsModule), canLoad: [AuthGuard] }
    ]
  },
  { path: '401', component: Error401Component },
  {
    path: '**', redirectTo: '' // TODO: Consider PageNotFound errors instead of directing to the home page?
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }
