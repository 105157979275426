export class Area {
  id: number;
  name: string;
  parent: string;
  parentId: number;
  type: string;
  ioServer: string;
  ioServerId: number;
  image: string;
  imageWidth: number;
  imageHeight: number;
}
