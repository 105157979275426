import { Component, Input } from '@angular/core';

@Component({
  selector: 'submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss'],
})
export class SubmitButtonComponent {
  constructor() { }

  @Input() name = 'Submit';
  @Input() disabled = false;
  public busy = false;

  submit(): boolean {
    if (this.busy) {
      return false;
    } else {
      this.busy = true;
      return true;
    }
  }

  ready(): void {
    this.busy = false;
  }
}
