<div *ngIf="controlChartSet" class="charts-block overview-wrapper">
  <chart *ngIf="controlChartSet.accuracyChart && accuracyChartPoints"
          (select)="viewDetails($event)"
          [view]="null"
          [title]="controlChartSet.accuracyChart.chartType | translate"
          [allowTypeChange]="false"
          [chartType]="'linechart'"
          [controlLimits]="accuracyControlLimits"
          [dataSeries]="[accuracyChartPoints]"
          [yAxis]=unit
          [customColors]="colors">
    <ng-template *ngIf="controlChartSet.accuracyChart && accuracyChartPoints" #tooltipTemplate let-model="model">
      <ng-container *ngIf="model">
        <table>
          <tr><td>{{ 'Validation.SessionId' | translate }}:</td><td>{{ model.validationSessionId }}</td></tr>
          <tr><td>{{ 'Validation.Start' | translate }}:</td><td>{{ model.validationStart | date:dateTimeFormat }}</td></tr>
          <tr><td>{{ 'Validation.End' | translate }}:</td><td>{{ model.validationEnd | date:dateTimeFormat }}</td></tr>
          <tr><td>{{ 'Validation.Procedure' | translate }}:</td><td>{{ model.validationProcedure | translate }}</td></tr>
          <tr><td></td><td></td></tr>
          <tr><td>{{ 'Value' | translate }}:</td><td>{{ model.value | number:'1.2-2' }} [{{ model.unit }}]</td></tr>
        </table>
      </ng-container>
    </ng-template>
  </chart>
  <table class="overview">
    <tr>
      <td colspan="2" class="column-padding-right">
        <table class="overview" *ngIf="controlChartSet.accuracyChart.statistics">
          <ng-template #statisticTemplate let-statistic="statistic">
            <ng-container *ngIf="statistic !== 'NaN'">{{ statistic | number:'1.4-4' }}</ng-container><ng-container *ngIf="statistic === 'NaN'">{{ 'NaN' | translate }}</ng-container>
          </ng-template>
          <tr><td></td><td>{{ 'Statistics' | translate }}</td></tr>
          <tr><td>{{ 'Count' | translate }}</td><td>{{ controlChartSet.accuracyChart.statistics.count }}</td></tr>
          <tr><td>{{ 'Mean' | translate }}</td><td><ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:controlChartSet.accuracyChart.statistics.mean}"></ng-container></td></tr>
          <tr><td>{{ 'Minimum' | translate }}</td><td><ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:controlChartSet.accuracyChart.statistics.minimum}"></ng-container></td></tr>
          <tr><td>{{ 'Maximum' | translate }}</td><td><ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:controlChartSet.accuracyChart.statistics.maximum}"></ng-container></td></tr>
          <tr><td>{{ 'Range' | translate }}</td><td><ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:controlChartSet.accuracyChart.statistics.range}"></ng-container></td></tr>
          <tr><td>{{ 'StandardDeviation' | translate }}</td><td><ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:controlChartSet.accuracyChart.statistics.standardDeviation}"></ng-container></td></tr>
        </table>
      </td>
      <td colspan="2">
        <table class="overview">
          <tr *ngFor="let decisionRule of controlChartSet.accuracyChart.decisionRules">
            <td>{{ decisionRule.name }}</td>
            <td *ngIf="failedACRuleIds" [ngClass]="(failedACRuleIds.indexOf(decisionRule.id) >= 0) ? 'Failed' : 'Passed'">{{ (failedACRuleIds.indexOf(decisionRule.id) >= 0) ? 'Failed' : 'Passed' | translate }}</td>
          </tr>
        </table>
      </td>
    </tr>
    <tr><td></td></tr>
  </table>
  <div class="chart-statistics">
  </div>
</div>
<div *ngIf="controlChartSet" class="charts-block overview-wrapper">
  <chart *ngIf="controlChartSet.precisionChart && precisionChartPoints"
         (select)="viewDetails($event)"
         [view]="null"
         [title]="controlChartSet.precisionChart.chartType | translate"
         [allowTypeChange]="false"
         [chartType]="'linechart'"
         [controlLimits]="[
            { 'name': 'UCL = ' + controlChartSet.precisionChart.ucl.toFixed(2), 'value': controlChartSet.precisionChart.ucl, 'params': { 'color': '#FF0000' } },
            { 'name': '0.00', 'value': 0, 'params': { 'color': '#000000' } }
          ]"
         [dataSeries]="[precisionChartPoints]"
         [yAxis]="unit"
         [customColors]="colors">
    <ng-template *ngIf="controlChartSet.precisionChart && precisionChartPoints" #tooltipTemplate let-model="model">
      <ng-container *ngIf="model">
        <table>
          <tr><td>{{ 'Validation.SessionId' | translate }}:</td><td>{{ model.validationSessionId }}</td></tr>
          <tr><td>{{ 'Validation.Start' | translate }}:</td><td>{{ model.validationStart | date:dateTimeFormat }}</td></tr>
          <tr><td>{{ 'Validation.End' | translate }}:</td><td>{{ model.validationEnd | date:dateTimeFormat }}</td></tr>
          <tr><td>{{ 'Validation.Procedure' | translate }}:</td><td>{{ model.validationProcedure | translate }}</td></tr>
          <tr><td></td><td></td></tr>
          <tr><td>{{ 'Value' | translate }}:</td><td>{{ model.value | number:'1.2-2' }} [{{ model.unit }}]</td></tr>
        </table>
      </ng-container>
    </ng-template>
  </chart>
  <table class="overview">
    <tr>
      <td colspan="2" class="column-padding-right">
        <table class="overview" *ngIf="controlChartSet.precisionChart.statistics">
          <ng-template #statisticTemplate let-statistic="statistic">
            <ng-container *ngIf="statistic !== 'NaN'">{{ statistic | number:'1.4-4' }}</ng-container><ng-container *ngIf="statistic === 'NaN'">{{ 'NaN' | translate }}</ng-container>
          </ng-template>
          <tr><td></td><td>{{ 'Statistics' | translate }}</td></tr>
          <tr><td>{{ 'Count' | translate }}</td><td>{{ controlChartSet.precisionChart.statistics.count }}</td></tr>
          <tr><td>{{ 'Mean' | translate }}</td><td><ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:controlChartSet.precisionChart.statistics.mean}"></ng-container></td></tr>
          <tr><td>{{ 'Minimum' | translate }}</td><td><ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:controlChartSet.precisionChart.statistics.minimum}"></ng-container></td></tr>
          <tr><td>{{ 'Maximum' | translate }}</td><td><ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:controlChartSet.precisionChart.statistics.maximum}"></ng-container></td></tr>
          <tr><td>{{ 'Range' | translate }}</td><td><ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:controlChartSet.precisionChart.statistics.range}"></ng-container></td></tr>
          <tr><td>{{ 'StandardDeviation' | translate }}</td><td><ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:controlChartSet.precisionChart.statistics.standardDeviation}"></ng-container></td></tr>
        </table>
      </td>
      <td colspan="2">
        <table class="overview">
          <tr *ngFor="let decisionRule of controlChartSet.precisionChart.decisionRules">
            <td>{{ decisionRule.name }}</td>
            <td *ngIf="failedPCRuleIds" [ngClass]="(failedPCRuleIds.indexOf(decisionRule.id) >= 0) ? 'Failed' : 'Passed'">{{ (failedPCRuleIds.indexOf(decisionRule.id) >= 0) ? 'Failed' : 'Passed' | translate }}</td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</div>
<div *ngIf="controlChartSet" class="charts-block overview-wrapper">
  <div class="header"><h1 style="margin-bottom:0; padding-bottom:0;">{{ 'Evaluation' | translate }}</h1></div>
  <table class="overview">
    <tr>
      <td>{{ 'OverallConclusion' | translate }}:</td>
      <td *ngIf="controlChartSet" [ngClass]="controlChartSet.lastResult">{{ controlChartSet.lastResult | translate }}</td>
    </tr>
  </table>
</div>
<validation-data [controlChartSetId]="id" [dataChange]="dataChange" (chartChange)="chartChange($event)"></validation-data>
<custom-dialog [visible]="valDataDialog" *ngIf="valDataDialog" (editModeChanged)="valDataDialogChanged($event)">
  <validation-data-overview [controlChartSetId]="id" [validationSessionId]="validationSessionId" (viewModeChanged)="valDataDialogChanged($event)"></validation-data-overview>
</custom-dialog>
