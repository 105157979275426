import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../core/app.config';
import { PerformanceIndicator } from './perf-indic';
import { PerformanceIndicatorReviewPeriod } from './perf-indic-review-period';
import { PerformanceIndicatorSubResult } from './perf-indic-sub-result';
import { PerformanceIndicatorChart } from './perf-indic-chart';
import { SearchItem } from '../core/search/search-item';

@Injectable()
export class PerformanceIndicatorService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getAllPerformanceIndicators(): Observable<PerformanceIndicator[]> { return this.http.get<PerformanceIndicator[]>(this.apiHost + 'performanceindicator/getall'); }

  public getPerformanceIndicators(filterArguments: string): Observable<PerformanceIndicator[]> {
    return this.http.get<PerformanceIndicator[]>(this.apiHost + 'performanceindicator/get' + filterArguments);
  }

  public getPerformanceIndicatorsByInformationLevel(id: number): Observable<PerformanceIndicator[]> {
    return this.http.get<PerformanceIndicator[]>(this.apiHost + 'performanceindicator/getbyinformationlevel/' + id);
  }

  public getPerformanceIndicatorChartsByInformationLevel(id: number): Observable<PerformanceIndicatorChart[]> {
    return this.http.get<PerformanceIndicatorChart[]>(this.apiHost + 'performanceindicator/getchartsbyinformationlevel/' + id);
  }

  public getPerformanceIndicatorCount(filterArguments: string): Observable<number> {
    return this.http.get<number>(this.apiHost + 'performanceindicator/count' + filterArguments);
  }

  public postPerformanceIndicator(performanceIndicator: PerformanceIndicator) {
    return this.http.post(this.apiHost + 'performanceindicator/post', performanceIndicator);
  }

  public putPerformanceIndicator(performanceIndicator: PerformanceIndicator) {
    return this.http.put(this.apiHost + 'performanceindicator/put', performanceIndicator);
  }

  public deletePerformanceIndicator(id: number) {
    return this.http.delete(this.apiHost + 'performanceindicator/delete/' + id);
  }

  public getPerformanceIndicator(id: number) {
    return this.http.get<PerformanceIndicator>(this.apiHost + 'performanceindicator/get/' + id);
  }

  public resequencePerformanceIndicators(performanceIndicators: PerformanceIndicator[]) {
    return this.http.put(this.apiHost + 'performanceindicator/resequence', performanceIndicators);
  }

  public getPerformanceIndicatorReviewPeriod(): Observable<PerformanceIndicatorReviewPeriod> {
    return this.http.get<PerformanceIndicatorReviewPeriod>(this.apiHost + 'performanceindicator/getperformanceindicatorreviewperiod');
  }

  public updatePerformanceIndicatorReviewPeriod(pirp: PerformanceIndicatorReviewPeriod) {
    return this.http.put(this.apiHost + 'performanceindicator/updateperformanceindicatorreviewperiod', pirp);
  }

  public getPerformanceIndicatorSubResults(informationLevelId: number, kpi: number): Observable<PerformanceIndicatorSubResult[]> {
    return this.http.get<PerformanceIndicatorSubResult[]>(this.apiHost + 'performanceindicator/getsubresults/' + informationLevelId + '?kpi=' + kpi);
  }

  public getPerformanceIndicatorSavedResults(informationLevelId: number): Observable<PerformanceIndicator[]> {
    return this.http.get<PerformanceIndicator[]>(this.apiHost + 'performanceindicator/getsavedresults/' + informationLevelId);
  }

  public newPerformanceIndicator(): PerformanceIndicator {
    return <PerformanceIndicator>{ formulaResult: '', id: 0, name: '', numberOfDecimals: 2, piFormula: '', sequenceNumber: 1, unit: '', visible: true, showSubResults: false, subResults: null, color: null };
  }

  public searchInformationLevel(name: string): Observable<SearchItem[]> {
    return this.http.get<SearchItem[]>(this.apiHost + 'informationlevel/search?name=' + name);
  }
}
