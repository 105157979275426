import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AppConfig } from '../../../../core/app.config';
import { ValidationProcedure } from './validation-procedure';
import { ValidationProcedureParam } from './validation-procedure-param';

@Injectable()
export class ValidationProcedureService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  private valsCache: Map<string, Observable<ValidationProcedure[]>> = new Map();

  public getValidationProcedureMethods(): Observable<string[]> { return this.http.get<string[]>(this.apiHost + 'streamvalidationprocedure/getvalidationproceduremethods'); }

  public getValidationProcedures(filterArguments: string, force: boolean = false): Observable<ValidationProcedure[]> {
    if (force || !this.valsCache.has('f' + filterArguments)) {
      this.valsCache.set('f' + filterArguments, this.http.get<ValidationProcedure[]>(this.apiHost + 'streamvalidationprocedure/get' + filterArguments).pipe(shareReplay()));
      setTimeout(() => this.valsCache.delete('f' + filterArguments), 10000);
    }
    return this.valsCache.get('f' + filterArguments);
  }

  public getValidationProceduresByInstrument(filterArguments: string, instrumentId: number): Observable<ValidationProcedure[]> {
    return this.http.get<ValidationProcedure[]>(this.apiHost + 'streamvalidationprocedure/getbyinstrument/' + instrumentId + filterArguments);
  }

  public getValidationProcedure(id: number): Observable<ValidationProcedure> { return this.http.get<ValidationProcedure>(this.apiHost + 'streamvalidationprocedure/get/' + id); }

  public getValidationProcedureCount(filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'streamvalidationprocedure/count' + filterArguments); }

  public getValidationProcedureCountByInstrument(filterArguments: string, instrumentId: number): Observable<number> {
    return this.http.get<number>(this.apiHost + 'streamvalidationprocedure/countbyinstrument/' + instrumentId + filterArguments);
  }

  public getValidationMethods(): Observable<string[]> { return this.http.get<string[]>(this.apiHost + 'streamvalidationprocedure/validationmethods'); }

  public getValidationMethodsByInstrument(instrumentId: number): Observable<string[]> {
    return this.http.get<string[]>(this.apiHost + 'streamvalidationprocedure/validationmethodsbyinstrument/' + instrumentId);
  }

  public getParamsByValidationProcedure(validationProcedure: string): Observable<ValidationProcedureParam[]> {
    return this.http.get<ValidationProcedureParam[]>(this.apiHost + 'streamvalidationprocedure/getparamsbyvalidationprocedure/' + validationProcedure);
  }

  public postValidationProcedure(validationProcedure: ValidationProcedure): Observable<number> { return this.http.post<number>(this.apiHost + 'streamvalidationprocedure/post', validationProcedure); }

  public putValidationProcedure(validationProcedure: ValidationProcedure) { return this.http.put(this.apiHost + 'streamvalidationprocedure/put', validationProcedure); }

  public deleteValidationProcedure(id: number) { return this.http.delete(this.apiHost + 'streamvalidationprocedure/delete/' + id); }

  public addOrUpdateValidationProcedures(validationProcedures: ValidationProcedure[]) { return this.http.post(this.apiHost + 'streamvalidationprocedure/addorupdaterange', validationProcedures); }

  public startAutomaticValidation(validationProcedureId: number) { return this.http.put(this.apiHost + 'validation/startautomaticvalidation', validationProcedureId); }

  public getAutoValidationProcedures(informationLevelId: number, force: boolean = false): Observable<ValidationProcedure[]> {
    if (force || !this.valsCache.has('a' + informationLevelId)) {
      this.valsCache.set('a' + informationLevelId, this.http.get<ValidationProcedure[]>(this.apiHost + 'streamvalidationprocedure/getautostreamvalprocsbyinstrument/' + informationLevelId).pipe(shareReplay()));
      setTimeout(() => this.valsCache.delete('a' + informationLevelId), 10000);
    }
    return this.valsCache.get('a' + informationLevelId);
  }

  public getManualValidationProcedures(informationLevelId: number, force: boolean = false): Observable<ValidationProcedure[]> {
    if (force || !this.valsCache.has('m' + informationLevelId)) {
      this.valsCache.set('m' + informationLevelId, this.http.get<ValidationProcedure[]>(this.apiHost + 'streamvalidationprocedure/getmanualstreamvalprocsbyinstrument/' + informationLevelId).pipe(shareReplay()));
      setTimeout(() => this.valsCache.delete('m' + informationLevelId), 10000);
    }
    return this.valsCache.get('m' + informationLevelId);
  }

  public newValidationProcedure(): ValidationProcedure {
    return {
      id: -1, procedure: '', referenceStreamId: null, streamTag: '', streamId: null, validationMethod: '', copyToAllStreams: false, overdueTimeHours: 0, maxTimeWithoutVal: 0,
      canStart: true, startAutomaticValidation: false, parameters: null, ioConditionId: null, ioOperationId: null, referenceStreamTag: ''
    };
  }
}
