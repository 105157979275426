import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from './app.config';

@Injectable()
export class ImageService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public imageToString(document: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('file', document, document.name);
    return this.http.post<string>(this.apiHost + 'image/imagetostring', formData);
  }
}
