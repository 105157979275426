import { Component, Input } from '@angular/core';
import { ErrorMessage } from './error-message';

@Component({
  selector: 'error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css']
})

export class ErrorMessageComponent {
  @Input() error: ErrorMessage;
  private expanded: boolean = false;

  toggleExpanded() {
    this.expanded = !this.expanded;
  }
}
