import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { DecisionRuleService } from '../decision-rule.service';
import { ActiveFilter } from '../../../../shared/data/active-filter';
import { Generic } from '../../../../shared/generic';

@Component({
  selector: 'decision-rule-filter',
  templateUrl: './decision-rule-filter.component.html',
  styleUrls: ['../../../../shared/data/data-filter.component.css']
})
export class DecisionRuleFilterComponent implements OnInit, AfterContentChecked {
  constructor(
    private decisionRuleService: DecisionRuleService
  ) { }
  @Input() filter: ActiveFilter;
  @Output() filterChanged = new EventEmitter<boolean>();
  public decisionRules: Generic[];
  @ViewChild('decisionRuleElement') decisionRuleElementRef: ElementRef;

  ngOnInit() {
    this.decisionRuleService.getAllDecisionRules().subscribe(decisionRules => this.decisionRules = decisionRules);
  }

  ngAfterContentChecked() {
    if (this.filter.filterText !== undefined && this.decisionRuleElementRef !== undefined) {
      this.decisionRuleElementRef.nativeElement.value = this.filter.filterText;
    }
  }

  changed(ev) {
    this.filter.filterText = ev.target.value;
    this.filterChanged.emit(true);
  }
}
