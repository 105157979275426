import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { IoServerService } from '../io-server.service';
import { ActiveFilter } from '../../shared/data/active-filter';
import { Generic } from '../../shared/generic';

@Component({
  selector: 'io-server-filter',
  templateUrl: './io-server-filter.component.html',
  styleUrls: ['../../shared/data/data-filter.component.css']
})
export class IoServerFilterComponent implements OnInit, AfterContentChecked {
  constructor(
    private ioServerService: IoServerService
  ) { }
  @Input() filter: ActiveFilter;
  @Output() filterChanged = new EventEmitter<boolean>();
  public ioServers: Generic[];
  @ViewChild('ioServerElement') ioServerElementRef: ElementRef;

  ngOnInit() {
    this.ioServerService.getIoServers('?count=9999').subscribe(ioServers => this.ioServers = ioServers.map(ioServer => ({ id: ioServer.id, name: ioServer.name })));
  }

  ngAfterContentChecked() {
    if (this.filter.filterText !== undefined && this.ioServerElementRef !== undefined) {
      this.ioServerElementRef.nativeElement.value = this.filter.filterText;
    }
  }

  changed(ev) {
    this.filter.filterText = ev.target.value;
    this.filterChanged.emit(true);
  }
}
