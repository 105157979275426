import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, OnChanges, TemplateRef, ContentChild } from '@angular/core';
import { Observable, Subscription, Subject } from 'rxjs';

import { GridColumn } from './grid-column';
import { GridAction } from './grid-action';
import { Header } from './header';
import { GridService } from './grid.service';
import { ErrorMessage } from '../error/error-message';
import { CustomGridColumn } from './custom-grid-column';
import { ModelMetaData } from '../data/model-meta-data';
import { FilterOption } from '../data/filter-option';
import { ActiveFilter } from '../data/active-filter';
import { GridDataArguments } from './grid-data-arguments';
import { DataService } from '../data/data.service';
import { CustomInputProperty } from '../data-form/custom-input-property';

@Component({
  selector: 'grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css']
})

export class GridComponent implements OnInit, OnDestroy, OnChanges {
  constructor(
    private gridService: GridService,
    private dataService: DataService
  ) { }

  //Filter inputs
  @Input() parentId: number;
  @Input() informationLevelId: number;
  @Input() instrumentId: number;
  @Input() areaId: number;
  @Input() equipmentId: number;
  @Input() componentStreamId: number;
  @Input() chartId: number;
  @Input() controlChartSetId: number;
  @Input() fidelityChartId: number;
  @Input() ioClientId: number;
  @Input() maintenanceItemId: number;

  @Input() name: string;
  @Input() headers: Header[];
  @Input() endpoint: string;
  @Input() rowActions: GridAction[] = [];
  @Input() customColumns: CustomGridColumn[];
  @Input() customProperties: CustomInputProperty[];
  @Input() metaData: ModelMetaData[];
  @Input() genericDataRetrieval = true;
  @Input() filterOptions: FilterOption[];
  @Input() title = 'Overview';
  @Input() dataChanged: Observable<void>;
  @Input() metaDataChanged: Subject<void> = new Subject<void>();
  @Input() filterChanged: Subject<void> = new Subject<void>();
  @Input() showAutoRefresh = true;
  @Input() showFilters = true;
  @Input() showHeader = true;
  @Input() showOnlyButtonsInHeader = false;
  @Input() showFooter = true;
  @Input() showSortArrows = true;
  @Input() showActionColumn = true;
  @Input() translateHeader = true;
  @Input() exportData: any;
  @Input() autoRefresh: boolean;
  @Input() autoRefreshTime = 5000;
  @Input() initialPageSize: number;
  @Input() sortColumn: string;
  @Input() activeFilters: ActiveFilter[] = [];
  @Input() editModeChanged: Subject<boolean> = new Subject<boolean>();
  @Output() filterChangedEvent = new EventEmitter<ActiveFilter[]>();
  @Output() filterEvent = new EventEmitter<string>();
  @Output() dataChangedEvent = new EventEmitter<any[]>();
  public data: any[];
  public columns: GridColumn[] = [];
  public filterList: ActiveFilter[] = [];
  public visibleColumns: GridColumn[] = [];
  public dataArguments: GridDataArguments = new GridDataArguments();
  public columnDialog = false;
  public filterDialog = false;
  public error: ErrorMessage;
  public loading = false;
  public editMode = false;
  private dataChangedSubscription: Subscription;
  private editModeChangedSubscription: Subscription;
  @ContentChild('overviewComponent') overviewComponentRef: TemplateRef<any>;

  /* Use get and set to react to the fact that initial count will be 0.
     Only when the count request gets a response will this component receive the actual count.
     Performing calculatePage on the set allows the grid to display the proper pageCount. */
  private internalCount: number;

  get count(): number {
    return this.internalCount;
  }

  @Input()
  set count(num: number) {
    this.internalCount = num;
    this.calculatePage();
  }

  ngOnInit() {
    if (this.dataChanged !== undefined) {
      this.dataChangedSubscription = this.dataChanged.subscribe(() => this.getData(true));
    }
    if (this.editModeChanged !== undefined) {
      this.editModeChangedSubscription = this.editModeChanged.subscribe((editMode) => {
        this.editMode = editMode;
      });
    }

    if (!(this.count > 0)) {
      this.count = 0; // Catches not providing the value or providing negative values.
    }
    this.dataArguments.pageIndex = 1;
    if (this.initialPageSize > 0) {
      this.dataArguments.pageSize = this.initialPageSize;
    } else {
      this.dataArguments.pageSize = 25;
    }
    this.calculatePage();
    this.dataArguments.sortColumn = (this.sortColumn !== undefined && this.sortColumn !== null && this.sortColumn.length > 0) ? this.sortColumn : '';
    this.dataArguments.sortOrder = 'asc';

    if (this.activeFilters !== null && this.activeFilters.length > 0) {
      this.activeFilters.forEach(filter => this.filterList.push(filter));
    }

    if (this.genericDataRetrieval) {
      this.addInputFilter(this.parentId, 'Area.Area');
      this.addInputFilter(this.informationLevelId, 'InformationLevelId');
      this.addInputFilter(this.instrumentId, 'Instrument.Id');
      this.addInputFilter(this.areaId, 'Area.Area');
      this.addInputFilter(this.equipmentId, 'Instrument.Id');
      this.addInputFilter(this.componentStreamId, 'ComponentStreamId');
      this.addInputFilter(this.chartId, 'ChartId');
      this.addInputFilter(this.controlChartSetId, 'ControlChartSetId');
      this.addInputFilter(this.fidelityChartId, 'FidelityChartId');
      this.addInputFilter(this.ioClientId, 'IoClient.Id');
      this.addInputFilter(this.maintenanceItemId, 'MaintenanceItemId');

      this.getData();
    }
  }

  ngOnDestroy() {
    if (this.dataChangedSubscription !== undefined) {
      this.dataChangedSubscription.unsubscribe();
    }
    if (this.editModeChangedSubscription !== undefined) {
      this.editModeChangedSubscription.unsubscribe();
    }
  }

  ngOnChanges() {
    if (!this.genericDataRetrieval) {
      this.getColumns();
    }
  }

  getData(force: boolean = false) {
    this.error = null;
    const args = this.getFilterGetArguments();
    this.loading = true;
    this.dataService.getMetaData(this.endpoint).subscribe(metaData => {
      this.metaData = metaData;
      this.filterOptions = this.metaData.filter(item => item.isFilter && item.filterType !== 'informationlevel'
        && item.filterType !== 'id_long' && item.filterType !== 'id_string').map(item => new FilterOption(item.name, item.filterType));
      this.getColumns();

      this.dataService.getItems(this.endpoint, args, force).subscribe(async data => {
        this.data = data;
        this.loading = false;
        this.metaDataChanged.next();
        this.dataChangedEvent.emit(this.data);
      }, error => this.error = error.error);
      this.dataService.getCount(this.endpoint, args, force).subscribe(options => this.count = options);
    }, error => this.error = error.error);
  }

  getColumns() {
    if (this.metaData !== undefined && this.metaData !== null && this.metaData.length !== undefined && this.metaData.length > 0) {
      const names = this.metaData.map(item => item.name);
      this.columns = this.gridService.getColumns(names, this.showActionColumn);

      if (this.editMode) {
        this.visibleColumns = this.dataService.getInputProperties(names, this.customProperties, true);
      } else {
        this.visibleColumns = this.gridService.getVisibleColumns(this.metaData, this.customColumns, this.name, this.showActionColumn);
      }
    }
  }

  calculatePage() {
    this.dataArguments.pageCount = Math.ceil(this.count / this.dataArguments.pageSize);
  }

  addInputFilter(inputProperty: number, name: string) {
    if (inputProperty > 0) {
      const filter = new ActiveFilter();
      filter.id = (this.filterList.length > 0) ? this.filterList[this.filterList.length - 1].id + 1 : 0;
      filter.filterText = inputProperty.toString();
      filter.filterOption = name;
      filter.type = 'hidden';
      filter.filterDetails.operator = '==';
      this.filterList.push(filter);
    }
  }

  // Returns an url string containing the arguments for filtering on HTTP GET requests.
  getFilterGetArguments(): string {
    const indexing = '?index=' + this.dataArguments.pageIndex + '&size=' + this.dataArguments.pageSize;
    const filters = this.dataArguments.sortColumn + ';' + this.dataArguments.sortOrder + '&filters=' + this.cleanString(JSON.stringify(this.filterList));
    return this.showFooter ? indexing + '&sort=' + filters : '?sort=' + filters;
  }

  refresh() {
    this.filterChanged.next();
    if (this.genericDataRetrieval) {
      this.getData(true);
    } else {
      this.filterEvent.emit(this.getFilterGetArguments());
    }
  }

  cleanString(url: string) {
    return url.replace(/%/gi, '%25')
      .replace(/&/gi, '%26')
      .replace(/#/gi, '%23');
  }

  configureColumns() {
    this.columnDialog = true;
  }

  columnDialogChanged(event: boolean) {
    const columnsString = JSON.stringify(this.columns);
    localStorage.setItem('grid:' + this.name, columnsString);
    this.visibleColumns = this.columns.filter(item => item.enabled);
    this.columnDialog = event;
    this.metaDataChanged.next();
  }

  configureFilters() {
    this.filterDialog = true;
  }

  filterDialogChanged(event: boolean) {
    this.filterDialog = event;
    this.metaDataChanged.next();
    this.filterChanged.next();
    this.filterChangedEvent.emit(this.filterList);
  }
}
