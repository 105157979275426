<svg:g [attr.class]="yAxisClassName" [attr.transform]="transform">
  <svg:g aml-charts-y-axis-ticks
         *ngIf="yScale"
         [trimTicks]="trimTicks"
         [maxTickLength]="maxTickLength"
         [tickFormatting]="tickFormatting"
         [tickArguments]="tickArguments"
         [tickValues]="ticks"
         [tickStroke]="tickStroke"
         [scale]="yScale"
         [orient]="yOrient"
         [showGridLines]="showGridLines"
         [gridLineWidth]="dims.width"
         [referenceLines]="referenceLines"
         [showRefLines]="showRefLines"
         [showRefLabels]="showRefLabels"
         [height]="dims.height"
         (dimensionsChanged)="emitTicksWidth($event)" />
  <svg:g ngx-charts-axis-label
         *ngIf="showLabel"
         [label]="labelText"
         [offset]="labelOffset"
         [orient]="yOrient"
         [height]="dims.height"
         [width]="dims.width"></svg:g>
</svg:g>
