// Generic object model used for dropdowns.
export class SelectionItem {
  constructor(id: any, name: string, value: any) {
    this.id = id;
    this.name = name;
    this.value = value;
  }

  id: any;
  name: string;
  value: any;
}
