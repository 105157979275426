import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { TimelineObjectTypeFilterComponent } from './timeline-object-type-filter.component';

@NgModule({
  declarations: [TimelineObjectTypeFilterComponent],
  exports: [TimelineObjectTypeFilterComponent],
  imports: [CommonModule, TranslateModule]
})
export class TimelineObjectTypeFilterModule { }
