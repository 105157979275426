<button *ngIf="editMode" class="button-blue" type="button" (click)="selectListItems()">{{ 'Filter.SelectItems' | translate }}</button>
<table *ngIf="ioPoints && ioPoints.length" class="grid simple-grid-table">
  <thead>
    <tr>
      <td>{{ 'IoPoint.IoPoint' | translate }}</td>
      <td>{{ 'Value' | translate }}</td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let ioPoint of ioPoints; let i = index; let odd = odd" [class.odd]="odd">
      <td [title]="ioPoint.dataSource">{{ ioPoint.name }}</td>
      <td>{{ ioPoint.value }}</td>
    </tr>
  </tbody>
</table>
<div *ngIf="!editMode && (!ioPoints || !ioPoints.length)">{{ 'aml.SectionNotConfigured' | translate }}</div>
<custom-dialog [visible]="listItemsDialog" *ngIf="listItemsDialog && activeFilters" (editModeChanged)="dialogChanged($event)">
  <data-list-filter-select [filter]="activeFilters[0]" [selectedIds]="selectedIds" (editModeChanged)="dialogChanged($event)"></data-list-filter-select>
</custom-dialog>
