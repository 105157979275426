import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { AppConfig } from '../app.config';
import { Report } from './report';
import { ReportType } from './report-type';
import { Generic } from '../../shared/generic';
import { ReportPeriodSelection } from './report-period-selection';
import { ReportDatasetData } from './report-dataset-data';
import { ModelMetaData } from '../../shared/data/model-meta-data';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class ReportService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  private reportsCache: Map<string, Observable<Report[]>> = new Map();

  public getReports(filterArguments: string): Observable<Report[]> { return this.http.get<Report[]>(this.apiHost + 'report/get' + filterArguments); }

  public getReportsByInformationLevel(filterArguments: string, informationLevelId: number, force: boolean = false): Observable<Report[]> {
    if (force || !this.reportsCache.has(filterArguments + informationLevelId)) {
      this.reportsCache.set(filterArguments + informationLevelId, this.http.get<Report[]>(this.apiHost + 'report/getbyinformationlevel/' + informationLevelId + filterArguments).pipe(shareReplay()));
      setTimeout(() => this.reportsCache.delete(filterArguments + informationLevelId), 10000);
    }
    return this.reportsCache.get(filterArguments + informationLevelId);
  }

  public getReportCountByInformationLevel(filterArguments: string, informationLevel: number): Observable<number> { return this.http.get<number>(this.apiHost + 'report/countbyinformationlevel/' + informationLevel + filterArguments); }

  public getReport(id: number): Observable<Report> { return this.http.get<Report>(this.apiHost + 'report/get/' + id); }

  public getReportCount(filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'report/count' + filterArguments); }

  public postReport(report: Report, informationLevel: number) { return this.http.post(this.apiHost + 'report/post/' + informationLevel, report); }

  public putReport(report: Report, informationLevel: number) { return this.http.put(this.apiHost + 'report/put/' + informationLevel, report); }

  public deleteReport(id: number) { return this.http.delete(this.apiHost + 'report/delete/' + id); }

  public newReport(): Report {
    return { id: -1, description: '', reportType: null, reportTypeDescription: '', updateDateTime: '', updatedByUser: '', filters: null, reportParameters: [], savedDatasets: [] };
  }

  public getAllReportTypes(reportLevel: string): Observable<ReportType[]> { return this.http.get<ReportType[]>(this.apiHost + 'reporttype/getall?reportLevel=' + reportLevel); }

  public getReportType(id: number): Observable<ReportType> { return this.http.get<ReportType>(this.apiHost + 'reporttype/get/' + id); }

  public getSelectionItems(source: string): Observable<Generic[]> { return this.http.get<Generic[]>(this.apiHost + source); }

  public getSelectionCount(source: string): Observable<number> { return this.http.get<number>(this.apiHost + source); }

  public getSelectionMetaData(source: string): Observable<ModelMetaData[]> { return this.http.get<ModelMetaData[]>(this.apiHost + source); }

  public getPeriodSelectionOptions(): Observable<ReportPeriodSelection> { return this.http.get<ReportPeriodSelection>(this.apiHost + 'reporttype/getperiodselectionoptions'); }

  public getReportLevel(informationLevel: number): Observable<string> { return this.http.get(this.apiHost + 'reporttype/getreportlevel?informationLevelId=' + informationLevel, { responseType: 'text' }); }

  public getReportDatasets(reportId: number, filterArguments: string): Observable<ReportDatasetData[]> { return this.http.get<ReportDatasetData[]>(this.apiHost + 'report/getreportdatasets/' + reportId + filterArguments); }

  public getReportDatasetCount(reportId: number): Observable<number> { return this.http.get<number>(this.apiHost + 'report/countreportdatasets/' + reportId); }

  public deleteReportDataset(id: number) { return this.http.delete(this.apiHost + 'report/deletedataset/' + id); }
}
