import { Component, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { LinearitySession } from './linearity-session';
import { LinearitySessionService } from './linearity-session.service';
import { Header } from '../../../../shared/grid/header';
import { ErrorMessage } from '../../../../shared/error/error-message';
import { AppConfig } from '../../../../core/app.config';
import { SubmitButtonComponent } from '../../../../shared/submit-button/submit-button.component';

@Component({
  selector: 'linearity-session-overview',
  templateUrl: './linearity-session-overview.component.html',
  styleUrls: ['./linearity-session-overview.component.css']
})
export class LinearitySessionOverviewComponent implements OnInit {
  constructor(
    private linearitySessionService: LinearitySessionService,
    private appConfig: AppConfig
  ) { }
  @Input() public linearitySession: LinearitySession;
  @Output() public editModeChanged = new EventEmitter<boolean>();
  @ViewChild(SubmitButtonComponent) submitButton: SubmitButtonComponent;
  public headers: Header[] = [{ header: 'Component.Type', sort: false, width: 'auto' },
    { header: 'Linearity.LeanReading', sort: false, width: 'auto' }, { header: 'Linearity.CalReading', sort: false, width: 'auto' }, { header: 'Linearity.RichReading', sort: false, width: 'auto' },
    { header: 'Linearity.LeanArea', sort: false, width: 'auto' }, { header: 'Linearity.CalArea', sort: false, width: 'auto' }, { header: 'Linearity.RichArea', sort: false, width: 'auto' },
  ];
  private activeTab = 0;
  private editMode: boolean;
  private dateTimeFormat: string;
  public error: ErrorMessage;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.editMode = !(this.linearitySession && this.linearitySession.id);
  }

  getLinearitySession() {
    this.error = null;
    this.linearitySessionService.getLinearitySession(this.linearitySession.id).subscribe(linearitySession => this.linearitySession = linearitySession, error => this.error = error.error);
  }

  submitLinearitySession(form: NgForm) {
    if (this.submitButton !== undefined && this.submitButton.submit()) {
      if (form.valid) {
        this.error = null;
        if (this.linearitySession.id > 0) { // PUT
          this.linearitySessionService.putLinearitySession(this.linearitySession).subscribe(response => {
            this.submitButton.ready();
            this.editModeChanged.emit(false);
          }, () => this.submitButton.ready());
        } else { // POST
          this.linearitySessionService.postLinearitySession(this.linearitySession).subscribe(response => {
            this.submitButton.ready();
            this.editModeChanged.emit(false);
          }, () => this.submitButton.ready());
        }
      } else {
        for (const i of Object.keys(form.controls)) {
          form.controls[i].markAsTouched();
        }
        this.submitButton.ready();
      }
    }
  }

  cancel() {
    this.getLinearitySession();
    this.editModeChanged.emit(false);
  }
}
