import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../../../../core/app.config';
import { LinearityChart } from './linearity-chart';

@Injectable()
export class LinearityChartService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getLinearityCharts(args: string): Observable<LinearityChart[]> { return this.http.get<LinearityChart[]>(this.apiHost + 'linearitychart/get' + args); }

  public getLinearityChartsByInstrument(instrumentId: number, args: string): Observable<LinearityChart[]> { return this.http.get<LinearityChart[]>(this.apiHost + 'linearitychart/getbyinstrument/' + instrumentId + args); }

  public getLinearityChartCount(args: string): Observable<number> { return this.http.get<number>(this.apiHost + 'linearitychart/count' + args); }

  public getLinearityChartCountByInstrument(instrumentId: number, args: string): Observable<number> { return this.http.get<number>(this.apiHost + 'linearitychart/countbyinstrument/' + instrumentId + args); }

  public getLinearityChart(id: number): Observable<LinearityChart> { return this.http.get<LinearityChart>(this.apiHost + 'linearitychart/get/' + id); }

  public postLinearityChart(linearityChart: LinearityChart): Observable<number> { return this.http.post<number>(this.apiHost + 'linearitychart/post', linearityChart); }

  public putLinearityChart(linearityChart: LinearityChart) { return this.http.put(this.apiHost + 'linearitychart/put', linearityChart); }

  public deleteLinearityChart(id: number) { return this.http.delete(this.apiHost + 'linearitychart/delete/' + id); }

  public newLinearityChart(): LinearityChart {
    return { componentStreamId: null, id: null, instrumentId: null };
  }
}
