import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { ActiveFilter } from '../../shared/data/active-filter';

@Component({
  selector: 'timeline-object-type-filter',
  templateUrl: './timeline-object-type-filter.component.html',
  styleUrls: ['../../shared/data/data-filter.component.css']
})
export class TimelineObjectTypeFilterComponent implements AfterContentChecked {
  @Input() filter: ActiveFilter;
  @Output() filterChanged = new EventEmitter<boolean>();
  @ViewChild('timelineObjectTypeElement') timelineObjectTypeElementRef: ElementRef;

  ngAfterContentChecked() {
    if (this.filter.filterText !== undefined && this.timelineObjectTypeElementRef !== undefined) {
      this.timelineObjectTypeElementRef.nativeElement.value = this.filter.filterText;
    }
  }

  changed(ev) {
    this.filter.filterText = ev.target.value;
    this.filterChanged.emit(true);
  }
}
