<div *ngIf="!report">
  <error-message [error]="error"></error-message>
</div>
<form *ngIf="report && editMode" #reportForm="ngForm" class="overview-wrapper">
  <div class="overview-header">{{ 'Report.ReportConfiguration' | translate }}</div>
  <div class="tab-headers">
    <div class="tab-header" (click)="showParametersTab()" [ngClass]="{'active':parametersTabActive}">{{ 'Parameters' | translate }}</div>
    <div class="tab-header" *ngIf="dataSelectionParameter" (click)="showDataSelectionTab()" [ngClass]="{'active':dataSelectionTabActive}">{{ 'DataSelection' | translate }}</div>
  </div>
  <div class="tab-wrapper">
    <!-- Start of Parameters tab -->
    <div *ngIf="parametersTabActive" class="parameters-tab">
      <div class="overview-wrapper">
        <div class="overview-header">{{ 'Report.ReportConfiguration' | translate }}</div>
        <table class="overview">
          <tr>
            <td>{{ 'Report.ReportType' | translate }}:</td>
            <td *ngIf="report.id === -1">
              <loader *ngIf="!reportTypes.length" type="formItem"></loader>
              <select *ngIf="reportTypes.length" [(ngModel)]="report.reportType" name="reportType" required (ngModelChange)="setReportType($event)" #reportType1="ngModel">
                <option *ngFor="let t of reportTypes" [ngValue]="t.id">{{ t.description | translate }}</option>
              </select>
              <form-alert [field]="reportType1" name="Report.ReportType"></form-alert>
            </td>
            <td *ngIf="report.id !== -1 && reportType">{{ reportType.description | translate }}</td>
            <td *ngIf="report.id !== -1 && !reportType"><loader type="formItem"></loader></td>
          </tr>
          <tr>
            <td>{{ 'Report.Description' | translate }}:</td>
            <td *ngIf="!reportTypes.length"><input type="text" name="description" disabled /></td>
            <td *ngIf="reportTypes.length"><input type="text" name="description" required [(ngModel)]="report.description" #description="ngModel" /><form-alert [field]="description" name="Report.Description"></form-alert></td>
          </tr>
        </table>
      </div>
      <div *ngIf="periodSelectionParameter && periodSelection" class="overview-wrapper">
        <div class="overview-header">{{ 'Report.ReviewPeriod' | translate }}</div>
        <table class="overview">
          <tr>
            <td>{{ periodSelectionParameter.description | translate }}</td>
            <td>
              <select [(ngModel)]="periodSelection.periodSelection" name="periodSelection">
                <option *ngFor="let item of periodSelection.periodOptions" [ngValue]="item">{{ item | translate }}</option>
              </select>
            </td>
          </tr>
          <tr *ngIf="periodSelection.periodSelection === 'Report.CustomDateTime'">
            <td>{{ 'Report.PeriodStart' | translate }}</td>
            <td>
              <input [owlDateTime]="dtPeriodStart" [owlDateTimeTrigger]="dtPeriodStart" [(ngModel)]="periodSelection.startValue" name="periodSelectionStartDateTime" />
              <owl-date-time #dtPeriodStart></owl-date-time>
            </td>
          </tr>
          <tr *ngIf="periodSelection.periodSelection === 'Report.CustomDateTime'">
            <td>{{ 'Report.PeriodEnd' | translate }}</td>
            <td>
              <input [owlDateTime]="dtPeriodEnd" [owlDateTimeTrigger]="dtPeriodEnd" [(ngModel)]="periodSelection.endValue" name="periodSelectionEndDateTime" />
              <owl-date-time #dtPeriodEnd></owl-date-time>
            </td>
          </tr>
          <tr *ngIf="periodSelection.periodSelection === 'Report.CustomPeriod'">
            <td>{{ 'Report.PeriodStart' | translate }}</td>
            <td>
              <input type="text" [(ngModel)]="periodSelection.startValue" name="periodSelectionStartValue" />
              <select [(ngModel)]="periodSelection.startPeriodType" name="periodSelectionStartPeriodType">
                <option *ngFor="let item of periodSelection.periodTypes" [ngValue]="item">{{ item | translate }}</option>
              </select>
              <select [(ngModel)]="periodSelection.startInPast" name="periodSelectionStartInPast">
                <option value="1">{{ 'In the Past' | translate }}</option>
                <option value="0">{{ 'In the Future' | translate }}</option>
              </select>
            </td>
          </tr>
          <tr *ngIf="periodSelection.periodSelection === 'Report.CustomPeriod'">
            <td>{{ 'Report.PeriodEnd' | translate }}</td>
            <td>
              <input type="text" [(ngModel)]="periodSelection.endValue" name="periodSelectionEndValue" />
              <select [(ngModel)]="periodSelection.endPeriodType" name="periodSelectionEndPeriodType">
                <option *ngFor="let item of periodSelection.periodTypes" [ngValue]="item">{{ item | translate }}</option>
              </select>
              <select [(ngModel)]="periodSelection.endInPast" name="periodSelectionEndInPast">
                <option value="1">{{ 'Report.InThePast' | translate }}</option>
                <option value="0">{{ 'Report.InTheFuture' | translate }}</option>
              </select>
            </td>
          </tr>
        </table>
      </div>
      <div *ngIf="reportParameters && reportParameters.length" class="overview-wrapper">
        <div class="overview-header">{{ 'Report.ViewOptions' | translate }}</div>
        <table class="overview">
          <tr *ngFor="let parameter of reportParameters">
            <td>{{ parameter.description | translate }}</td>
            <td>
              <div *ngIf="parameter.type === 'selectionDropDown'">
                <select *ngIf="parameter.selectionItems.length > 0 && parameter.selectionItems[0].id !== undefined && parameter.selectionItems[0].id !== null" [(ngModel)]="parameter.values" [name]="parameter.description">
                  <option *ngFor="let item of parameter.selectionItems" [ngValue]="item.id">{{ item.name | translate }}</option>
                </select>
                <select *ngIf="parameter.selectionItems.length > 0 && !parameter.selectionItems[0].id" [(ngModel)]="parameter.values" [name]="parameter.description">
                  <option *ngFor="let item of parameter.selectionItems" [ngValue]="item">{{ item | translate }}</option>
                </select>
              </div>
              <div *ngIf="parameter.type === 'booleanYesNo'">
                <select [(ngModel)]="parameter.values" [name]="parameter.description">
                  <option value="1">{{ 'Report.Yes' | translate }}</option>
                  <option value="0">{{ 'Report.No' | translate }}</option>
                </select>
              </div>
              <div *ngIf="parameter.type === 'selectionCheckboxes' && parameter.selectionItems.length > 0">
                <div *ngFor="let item of parameter.selectionItems">
                  <div *ngIf="item.id">
                    <input type="checkbox" [name]="parameter.description" (click)="toggleParameterSelectionItem(parameter.values, item.id)" [checked]="parameter.values.includes(item.id)" />
                    <label for="parameter.description">{{ item.name | translate }}</label>
                  </div>
                  <div *ngIf="!item.id">
                    <input type="checkbox" [name]="parameter.description" (click)="toggleParameterSelectionItem(parameter.values, item)" [checked]="parameter.values.includes(item)" />
                    <label for="parameter.description">{{ item | translate }}</label>
                  </div>
                </div>
              </div>
              <div *ngIf="parameter.type === 'datetime'">
                <input [owlDateTime]="dtDatetime" [owlDateTimeTrigger]="dtDatetime" [(ngModel)]="parameter.values" [name]="parameter.description" />
                <owl-date-time #dtDatetime></owl-date-time>
              </div>
              <div *ngIf="parameter.type === 'numericEquation'">
                <select [(ngModel)]="parameter.values[0]" [name]="parameter.description + 'eq'">
                  <option value=">">></option>
                  <option value="=">=</option>
                  <option value="<"><</option>
                </select>
                <input [(ngModel)]="parameter.values[1]" [name]="parameter.description" type="number" />
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div *ngIf="filterOptions && filterOptions.length" class="overview-wrapper">
        <div class="overview-header">{{ 'Filters' | translate }}</div>
        <data-filter [filterOptions]="filterOptions" [filterList]="filterList" [index]="index" [informationLevelId]="informationLevelId" [editMode]="true"></data-filter>
      </div>
    </div>
    <!-- End of Parameters tab -->
    <!-- Start of Data Selection tab -->
    <div *ngIf="dataSelectionTabActive" class="data-selection-tab">
      <div class="grid-wrapper">
        <button class="button-orange toggle-all" type="button" (click)="toggleAllDataSelectionItems()">{{ 'Report.All' | translate }}</button>
        <grid [name]="'Data_Selection'" [title]="dataSelectionParameter.description" [activeFilters]="dataSelectionActiveFilters" [filterOptions]="dataSelectionFilterOptions" [headers]="dataSelectionHeaders"
              [initialPageSize]="9999" [count]="dataSelectionCount" [showAutoRefresh]="false" [genericDataRetrieval]="false" [exportData]="dataSelectionItems" (filterEvent)="getDataSelectionItems($event)">
          <tr *ngFor="let item of dataSelectionItems; let i = index; let odd = odd" [class.odd]="odd" (click)="toggleDataSelectionItem(item.id)">
            <td style="width:15px;padding-left:3px;padding-right:3px;"><input type="checkbox" [checked]="dataSelectionParameter.values.includes(item.id)" style="width:15px;" /></td>
            <td *ngIf="rawDataSelectionHeaders.includes('Path')">{{ item.path }}</td>
            <td *ngIf="rawDataSelectionHeaders.includes('Name')">{{ item.name }}</td>
            <td *ngIf="rawDataSelectionHeaders.includes('Tag')">{{ item.tag }}</td>
            <td *ngIf="rawDataSelectionHeaders.includes('Type') || rawDataSelectionHeaders.includes('Area.Type')">{{ item.type }}</td>
            <td *ngIf="rawDataSelectionHeaders.includes('Make')">{{ item.make }}</td>
            <td *ngIf="rawDataSelectionHeaders.includes('Model')">{{ item.model }}</td>
            <td *ngIf="rawDataSelectionHeaders.includes('Criticality.Criticality')">{{ item.criticality }}</td>
            <td *ngIf="rawDataSelectionHeaders.includes('ComponentType.Description')">{{ item.componentType.description }}</td>
            <td *ngIf="rawDataSelectionHeaders.includes('ComponentType.Symbol')">{{ item.componentType.symbol }}</td>
            <td *ngIf="rawDataSelectionHeaders.includes('ComponentType.UnitOfMeasurement')">{{ item.componentType.unit }}</td>
            <td *ngIf="rawDataSelectionHeaders.includes('Stream.Description')">{{ item.description }}</td>
            <td *ngIf="rawDataSelectionHeaders.includes('DateTime')" [title]="item.dateTime | date:dateTimeFormat">{{ item.dateTime | date:dateTimeFormat }}</td>
            <td *ngIf="rawDataSelectionHeaders.includes('StartDateTime')" [title]="item.startDateTime | date:dateTimeFormat">{{ item.startDateTime | date:dateTimeFormat }}</td>
            <td *ngIf="rawDataSelectionHeaders.includes('EndDateTime')" [title]="item.endDateTime | date:dateTimeFormat">{{ item.endDateTime | date:dateTimeFormat }}</td>
            <td *ngIf="rawDataSelectionHeaders.includes('Validation.Method')" [title]="item.validationMethod | translate">{{ item.validationMethod | translate }}</td>
            <td *ngIf="rawDataSelectionHeaders.includes('Stream.Tag')" [title]="item.streamTag">{{ item.streamTag }}</td>
            <td *ngIf="rawDataSelectionHeaders.includes('Reference.StreamTag')" [title]="item.referenceStreamTag">{{ item.referenceStreamTag }}</td>
          </tr>
        </grid>
      </div>
    </div>
    <!-- End of Data Selection tab -->
  </div>
  <error-message [error]="error"></error-message>
  <div class="control-buttons">
    <button class="button-gray" type="reset" (click)="cancel()">{{ 'Cancel' | translate }}</button>
    <submit-button name="Save" [disabled]="reportForm.invalid" (click)="submitReport(reportForm)"></submit-button>
  </div>
</form>
<div *ngIf="!editMode" class="report-wrapper">
  <style>
    .control-buttons {
      margin-top: 5px;
    }
  </style>
  <error-message *ngIf="report" [error]="error"></error-message>
  <div class="overview-wrapper control-buttons" *ngIf="reportLoaded">
    <span>{{ 'Report.ExportOptions' | translate }}</span>
    <button *ngIf="pageCount <= 100" class="button-pdf" (click)="getPdfFile()">{{ 'Report.Pdf' | translate }}</button>
    <button *ngIf="reportType && (reportType.datasetName || reportType.reportEndpoints)" class="button-excel" (click)="getExcelFile()">{{ 'Report.Excel' | translate }}</button>
    <button *ngIf="pageCount <= 100" class="button-word" (click)="getWordFile()">{{ 'Report.Word' | translate }}</button>
    <button *ngIf="reportType && (reportType.datasetName || reportType.reportEndpoints)" class="button-csv" (click)="getCsvFile()">{{ 'Report.Csv' | translate }}</button>
    <div *ngIf="pageCount > 100" class="too-much-pages">{{ 'Report.PdfWordExportDisabledTooMuchPages' | translate }}</div>
  </div>
  <report-view *ngIf="report" [report]="report" (loaded)="onReportLoaded($event)" (dataDateTime)="setDataDateTime($event)" #reportElement></report-view>
</div>
<custom-dialog [visible]="exporting" *ngIf="exporting" (editModeChanged)="exporting = false">
  <div class="overview-wrapper">
    <div class="overview-header">{{ 'Report.Export' | translate }}</div>
    {{ 'Report.Exporting' | translate }}
    <loader></loader>
  </div>
</custom-dialog>
