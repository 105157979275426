import { Component, EventEmitter, OnInit, OnChanges, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Generic } from '../../shared/generic';
import { LevelService } from '../level.service';

@Component({
  selector: 'diag-dialog',
  templateUrl: './diag-dialog.component.html'
})
export class DiagDialogComponent implements OnInit, OnChanges {
  constructor(
    private levelService: LevelService
  ) { }
  private lastDiagnosticId: number;
  @Input() diagnosticId: number;
  @Output() diagnosticIdChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() areaId: number;
  @Input() levelId: number;
  @Output() editModeChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  private lastAreaId: number;
  public levels: Generic[];
  public selectedLevelId: number;
  public selectedDiagnosticId: number;

  ngOnInit() {
    this.lastAreaId = this.areaId;
    this.lastDiagnosticId = this.diagnosticId;
    if (this.levelId) {
      this.selectedLevelId = this.levelId;
      this.selectedDiagnosticId = this.diagnosticId;
    }
    this.getInstruments();
  }

  ngOnChanges() {
    if (this.areaId !== this.lastAreaId) { // Only react to areaId changes.
      this.lastAreaId = this.areaId;
      this.lastDiagnosticId = this.diagnosticId;
      this.getInstruments();
    }
  }

  getInstruments() {
    this.levelService.getLevelAndChildren(this.areaId).subscribe(levels => this.levels = levels);
  }

  submitDiag(form: NgForm) {
    this.editModeChanged.emit(false);
  }

  cancel() {
    this.diagnosticId = this.lastDiagnosticId;
    this.editModeChanged.emit(false);
  }
}
