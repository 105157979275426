<error-message [error]="error"></error-message>
<div *ngIf="updateAccess" class="control-buttons"><button class="button-blue" (click)="evaluateControlChartSet()">{{ 'SaveAndReevaluate' | translate }}</button></div>
<div class="grid-wrapper">
  <grid [name]="'Validations'" [title]="'Chart.Events.ValidationData'" [genericDataRetrieval]="false" [headers]="headers" [filterOptions]="filterOptions" [count]="count" [exportData]="validationData" (filterEvent)="getValidationData($event)">
    <tr *ngFor="let dataPoint of validationData; let i = index; let odd = odd" [class.odd]="odd">
      <td [title]="dataPoint.validationSessionId">{{ dataPoint.validationSessionId }}</td>
      <td [title]="dataPoint.startDateTime | date:dateTimeFormat">{{ dataPoint.startDateTime | date:dateTimeFormat }}</td>
      <td [title]="dataPoint.endDateTime | date:dateTimeFormat">{{ dataPoint.endDateTime | date:dateTimeFormat }}</td>
      <td [title]="dataPoint.initiatedBy">{{ dataPoint.initiatedBy }}</td>
      <td>
        <table *ngIf="dataPoint.validationReadings" class="grid-column-table">
          <thead>
            <tr>
              <td>{{ 'Reading' | translate }}</td>
              <td>{{ 'Reference.Reference' | translate }}</td>
              <td>{{ 'Deviation' | translate }}</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let valReading of dataPoint.validationReadings; let i = index">
              <td [title]="valReading.reading | number:'1.2-2'">{{ valReading.reading | number:'1.2-2' }}</td>
              <td [title]="valReading.reference | number:'1.2-2'">{{ valReading.reference | number:'1.2-2' }}</td>
              <td *ngIf="valReading.deviation !== 'NaN'" [title]="valReading.deviation | number:'1.2-2'">{{ valReading.deviation | number:'1.2-2' }}</td>
              <td *ngIf="valReading.deviation === 'NaN'">&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </td>
      <td>
        <ng-container *ngIf="dataPoint.statistics">
          <ng-template #statisticTemplate let-statistic="statistic">
            <ng-container *ngIf="statistic !== 'NaN'">{{ statistic | number:'1.2-2' }}</ng-container><ng-container *ngIf="statistic === 'NaN'">{{ 'NaN' | translate }}</ng-container><br />
          </ng-template>
          {{ 'Count' | translate }} = {{ dataPoint.statistics.count }}<br />
          {{ 'Mean' | translate }} = <ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:dataPoint.statistics.mean}"></ng-container>
          {{ 'Minimum' | translate }} = <ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:dataPoint.statistics.minimum}"></ng-container>
          {{ 'Maximum' | translate }} = <ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:dataPoint.statistics.maximum}"></ng-container>
          {{ 'Range' | translate }} = <ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:dataPoint.statistics.range}"></ng-container>
          {{ 'StandardDeviation' | translate }} = <ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:dataPoint.statistics.standardDeviation}"></ng-container>
        </ng-container>
      </td>
      <td [title]="dataPoint.plottedValue">{{ dataPoint.plottedValue }}</td>
      <td><input type="checkbox" name="exclude{{i}}" [checked]="dataPoint.excluded" (input)="dataPoint.excluded = !dataPoint.excluded" [disabled]="!updateAccess" /></td>
      <td><input type="checkbox" name="reset{{i}}" [checked]="dataPoint.resetSpc" (input)="dataPoint.resetSpc = !dataPoint.resetSpc" [disabled]="!updateAccess" /></td>
      <td><button class="button-blue" (click)="viewDetails(dataPoint.validationSessionId)">{{ 'Details' | translate }}</button></td>
    </tr>
  </grid>
</div>
<custom-dialog [visible]="valDataDialog" *ngIf="valDataDialog" (editModeChanged)="valDataDialogChanged($event)">
  <validation-data-overview [controlChartSetId]="controlChartSetId" [validationSessionId]="validationSessionId" (viewModeChanged)="valDataDialogChanged($event)"></validation-data-overview>
</custom-dialog>
