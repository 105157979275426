import { Component, EventEmitter, Input, OnInit, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ActiveFilter } from './active-filter';
import { FilterOption } from './filter-option';

@Component({
  selector: 'data-filter',
  templateUrl: './data-filter.component.html',
  styleUrls: ['./data-filter.component.css']
})

export class DataFilterComponent implements OnInit, OnChanges {
  constructor(
    private router: Router
  ) {
    this.debouncer.pipe(debounceTime(1000)).subscribe((value) => this.filterChangedEvent.emit(value));
  }

  //Filter inputs
  @Input() parentId: number;
  @Input() informationLevelId: number;
  @Input() instrumentId: number;
  @Input() areaId: number;
  @Input() equipmentId: number;
  @Input() componentStreamId: number;
  @Input() chartId: number;
  @Input() controlChartSetId: number;
  @Input() fidelityChartId: number;
  @Input() ioClientId: number;
  @Input() maintenanceItemId: number;

  @Input() viewAsDialog = false;
  @Input() filterOptions: FilterOption[];
  @Input() filterList: ActiveFilter[] = [];
  @Input() editMode: boolean;
  @Input() showFilters = true;
  @Input() filterChanged: Subject<void> = new Subject<void>();
  @Output() filterChangedEvent = new EventEmitter<boolean>();
  @Output() editModeChanged = new EventEmitter<boolean>();
  public listItemsDialog = false;
  public activeFilter: ActiveFilter;
  public selectedIds: number[] = [];
  public count: number;
  public instrumentLevel: boolean;
  private filterListBackup: ActiveFilter[] = [];
  private debouncer: Subject<boolean> = new Subject<boolean>();

  ngOnInit() {
    this.instrumentLevel = this.router.url.startsWith('/instrument/');
    this.filterListBackup = JSON.parse(JSON.stringify(this.filterList)); //Creates a deep copy.
  }

  ngOnChanges() {
    this.count = this.filterList.filter(f => f.type !== 'hidden').length;
  }

  addFilter() {
    const filter = new ActiveFilter();
    filter.id = (this.filterList.length > 0) ? this.filterList[this.filterList.length - 1].id + 1 : 0;
    this.filterList.push(filter);
    this.ngOnChanges();
  }

  getFilterById(id: number): ActiveFilter {
    const filtered = this.filterList.filter(filter => filter.id === id);
    return filtered.length > 0 ? filtered[0] : null;
  }

  public refresh() {
    this.debouncer.next(true);
  }

  removeFilter(id: number) {
    for (let i = 0; i < this.filterList.length; i++) {
      if (this.filterList[i].id === id) {
        this.filterList.splice(i, 1);
      }
    }
    this.refresh();
  }

  setFilterOption(filterOptionName: string, id: number) {
    const filterOption = this.filterOptions.filter(fo => fo.name === filterOptionName)[0];
    const oldFilter = this.getFilterById(id);
    const index = this.filterList.indexOf(oldFilter);
    const filter = new ActiveFilter();
    filter.id = oldFilter.id;
    filter.filterText = (filterOption.type === 'string') ? oldFilter.filterText : '';
    filter.filterOption = filterOption.name;
    filter.type = filterOption.type;
    filter.filterDetails = {};
    this.filterList[index] = filter;
    this.refresh();
  }

  setFilterText(event: any, id: number) {
    this.getFilterById(id).filterText = event.target.value;
    this.refresh();
  }

  setSelectOption(event: any, id: number) {
    this.getFilterById(id).filterText = event;
    this.refresh();
  }

  selectListItems(filter: ActiveFilter) {
    this.activeFilter = filter;
    this.selectedIds = (this.activeFilter.filterText !== null && this.activeFilter.filterText.length > 0) ? JSON.parse(this.activeFilter.filterText) : [];
    this.listItemsDialog = true;
  }

  submit() {
    this.filterListBackup = JSON.parse(JSON.stringify(this.filterList));
    this.filterChangedEvent.emit(true);
    this.editModeChanged.emit(false);
  }

  cancel() {
    this.filterList.splice(0, this.filterList.length);
    this.filterListBackup.forEach(f => this.filterList.push(f));
    this.filterChangedEvent.emit(true);
    this.editModeChanged.emit(false);
  }

  dialogChanged(event: boolean) {
    this.filterListBackup = JSON.parse(JSON.stringify(this.filterList));
    this.listItemsDialog = event;
    this.filterChangedEvent.emit(true);
    this.filterChanged.next();
  }
}
