import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { ActiveFilter } from '../../../../shared/data/active-filter';

@Component({
  selector: 'validation-state-filter',
  templateUrl: './validation-state-filter.component.html',
  styleUrls: ['../../../../shared/data/data-filter.component.css']
})
export class ValidationStateFilterComponent implements AfterContentChecked {
  @Input() filter: ActiveFilter;
  @Output() filterChanged = new EventEmitter<boolean>();
  @ViewChild('validationStateElement') validationStateElementRef: ElementRef;

  ngAfterContentChecked() {
    if (this.filter.filterText !== undefined && this.validationStateElementRef !== undefined) {
      this.validationStateElementRef.nativeElement.value = this.filter.filterText;
    }
  }

  changed(ev) {
    this.filter.filterText = ev.target.value;
    this.filterChanged.emit(true);
  }
}
