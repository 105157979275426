import { Injectable } from '@angular/core';

import { ModelMetaData } from '../data/model-meta-data';
import { GridColumn } from './grid-column';
import { CustomGridColumn } from './custom-grid-column';
import { ListObjectMetaData } from './list-object-meta-data';

@Injectable()
export class GridService {
  constructor() { }

  //Types: listInput, selectOptions, ioPoint
  private listObjects: ListObjectMetaData[] = [
    new ListObjectMetaData('AreaOrInstrument', 'informationLevelId', 'informationlevel'),
    new ListObjectMetaData('Area.Name', 'parentId', 'area', false),
    new ListObjectMetaData('Area.Containing', 'parentId', 'area', false),
    new ListObjectMetaData('Area.Type', 'type', 'area/types', true, 'selectOptions', null,
      new GridColumn('Area.Type', null, 'selectOptions', true, true), [new GridColumn('Area.Type', null)]),
    new ListObjectMetaData('Instrument.Tag', 'measuringInstrumentId', 'measuringinstrument', true, 'listInput', 'Tag', null, [new GridColumn('Instrument.Tag', 'tag')]),
    new ListObjectMetaData('Component.Type', 'componentTypeId', 'componenttype', true, 'listInput', 'Description', null, [
      new GridColumn('ComponentType.Description', 'description'),
      new GridColumn('ComponentType.Symbol', 'symbol'),
      new GridColumn('ComponentType.UnitOfMeasurement', 'unit')
    ]),
    new ListObjectMetaData('Stream.Tag', 'streamId', 'componentstream', true, 'listInput', 'Tag', null, [
      new GridColumn('Stream.Tag', 'tag'),
      new GridColumn('Instrument.Tag', 'instrumentTag')
    ]),
    new ListObjectMetaData('Reference.StreamTag', 'referenceStreamId', 'referencestream', false, 'listInput', 'Tag', null, [
      new GridColumn('Reference.StreamTag', 'tag'),
      new GridColumn('Instrument.Tag', 'instrumentTag')
    ]),
    new ListObjectMetaData('Criticality.Criticality', 'criticalityId', 'criticality', false),
    new ListObjectMetaData('IoServer.IoServer', 'ioServerId', 'ioserver', false),
    new ListObjectMetaData('IoPoint.IoPoint', 'ioId', null, false, 'ioPoint', null, new GridColumn('IoPoint.IoPoint', 'ioId', 'ioPoint', false), [new GridColumn('IoPoint.IoPoint', 'ioName')]),
    new ListObjectMetaData('IoPoint.IoPointNumeric', 'ioId', null, false, 'ioPointNumeric', null, new GridColumn('IoPoint.IoPointNumeric', 'ioId', 'ioPointNumeric', false), [new GridColumn('IoPoint.IoPointNumeric', 'ioName')]),
    new ListObjectMetaData('IoPoint.IoPointBoolean', 'ioId', null, false, 'ioPointBoolean', null, new GridColumn('IoPoint.IoPointBoolean', 'ioId', 'ioPointBoolean', false), [new GridColumn('IoPoint.IoPointBoolean', 'ioName')]),
    new ListObjectMetaData('IoPoint.IoPointText', 'ioId', null, false, 'ioPointText', null, new GridColumn('IoPoint.IoPointText', 'ioId', 'ioPointText', false), [new GridColumn('IoPoint.IoPointText', 'ioName')]),
    new ListObjectMetaData('IoClient.IoClient', 'ioClientId', 'ioclient', true, 'listInput', 'Name', null, [
      new GridColumn('IoClient.IoClient', 'name'),
      new GridColumn('AreaOrInstrument', 'informationLevel')
    ]),
    new ListObjectMetaData('IoClient.IoServerName', 'ioServerId', 'ioserver'),
    new ListObjectMetaData('IoClient.ClientType', 'clientType', 'ioclient/getclienttypes', true, 'selectOptions', null,
      new GridColumn('IoClient.ClientType', null, 'selectOptions', true, true), [new GridColumn('IoClient.ClientType', null)]),
    new ListObjectMetaData('IoClient.HistorianType', 'historianType', 'ioclient/gethistoriantypes', true, 'selectOptions', null,
      new GridColumn('IoClient.HistorianType', null, 'selectOptions', true, true), [new GridColumn('IoClient.HistorianType', null)]),
    new ListObjectMetaData('Validation.Procedure', 'procedure', 'streamvalidationprocedure/getvalidationproceduremethods', true, 'selectOptions', null,
      new GridColumn('Validation.Procedure', null, 'selectOptions', true, true), [new GridColumn('Validation.Procedure', null)]),
    new ListObjectMetaData('MaintenanceActivity.MaintenanceActivity', 'maintenanceActivityId', 'maintenanceactivity'),
    new ListObjectMetaData('ResponsibleUser', 'responsibleUserId', 'user/getusernames'),
    new ListObjectMetaData('Priority', 'priority', 'maintenanceitem/getpriorities', true, 'selectOptions', null,
      new GridColumn('Priority', null, 'selectOptions', true, true), [new GridColumn('Priority', null)]),
    new ListObjectMetaData('ControlChart.ControlChart', 'chartId', 'controlchart', false, 'listInput', 'Description', null, [
      new GridColumn('Description', 'description'),
      new GridColumn('Component.Tag', 'componentTag')
    ]),
  ];

  //Possible types: string (default), numeric, boolean, datetime, date, stringArray, ioPoint
  private columns: GridColumn[] = [
    new GridColumn('Tag', 'tag'),
    //user
    new GridColumn('User.Name', 'userName'),
    new GridColumn('Email', 'email'),
    new GridColumn('Role.Roles', 'roles', 'stringArray'),
    //area
    new GridColumn('Area.Containing', 'parent'),
    new GridColumn('Area.Type', 'type', 'string', true, true),
    new GridColumn('Name', 'name'),
    new GridColumn('DateTime', 'eventDateTime', 'datetime'),
    new GridColumn('EventSourceType', 'eventSourceType', 'string', true, true),
    new GridColumn('Description', 'description', 'string', true, true),
    new GridColumn('AckDateTime', 'ackDateTime', 'datetime'),
    new GridColumn('AckedBy', 'ackedBy'),
    new GridColumn('Counter', 'counter', 'numeric'),
    //new GridColumn('AreaOrInstrument', 'informationLevel'),
    //audit trial
    new GridColumn('Object.Path', 'objectPath'),
    new GridColumn('Object.Object', 'objectIdentifier'),
    new GridColumn('Object.Type', 'objectTypeName', 'string', true, true),
    new GridColumn('Operation', 'operation'),
    new GridColumn('ChangedDateTime', 'dateTime', 'datetime'),
    new GridColumn('ChangedBy', 'changedBy'),
    //comp type
    new GridColumn('Symbol', 'symbol'),
    new GridColumn('UnitOfMeasurement', 'unit'),
    new GridColumn('UnitOfMeasurementNR', 'unit', 'string', false),
    new GridColumn('MolWeight', 'molWeight', 'numeric', false),
    //component
    new GridColumn('ComponentType.Description', 'componentType.description'),
    new GridColumn('ComponentType.Symbol', 'componentType.symbol'),
    new GridColumn('ComponentType.UnitOfMeasurement', 'componentType.unit'),
    new GridColumn('CalibratedRangeLowerValue', 'calibrationRangeLowerValue', 'numeric'),
    new GridColumn('CalibratedRangeUpperValue', 'calibrationRangeUpperValue', 'numeric'),
    ///config setting
    new GridColumn('ConfigSettings.Name', 'name'),
    //setting
    new GridColumn('Setting.Setting', 'setting', 'string', true, true),
    //new GridColumn('Value', 'value', 'string', true, true), // TODO: FIX, duplicate column name definition NOT allowed!
    //decision rule
    new GridColumn('DisplayName', 'displayName'),
    new GridColumn('DecisionRule.Name', 'decisionRuleName', 'string', true, true),
    new GridColumn('SeverityLevel', 'severityLevel', 'string', true, true),
    new GridColumn('DecisionRule.ConformSize', 'conformSize', 'numeric'),
    new GridColumn('DecisionRule.SetSize', 'setSize', 'numeric'),
    //io server
    new GridColumn('Url', 'url'),
    new GridColumn('Port', 'port', 'numeric'),
    new GridColumn('Status', 'status', 'string', true, true, false, false),
    //io
    new GridColumn('IO', 'io'),
    new GridColumn('Formula', 'formula'),
    new GridColumn('IoFormula.IoFormula', 'ioFormula'),
    new GridColumn('Value', 'value'),
    new GridColumn('IoPoint.IoPoint', 'ioName', 'string', false),
    new GridColumn('IoPoint.IoPointNumeric', 'ioName', 'string', false),
    new GridColumn('IoPoint.IoPointBoolean', 'ioName', 'string', false),
    new GridColumn('IoPoint.IoPointText', 'ioName', 'string', false),
    new GridColumn('WriteValue', 'writeValue'),
    new GridColumn('IoCondition.Condition', 'ioCondition'),
    new GridColumn('IoServer.IoServer', 'ioServer'),
    new GridColumn('DataSource', 'dataSource'),
    new GridColumn('DataType', 'dataType', 'ioPointDataType', true, true),
    //io client
    new GridColumn('IoClient.IoServerName', 'ioServer'),
    new GridColumn('IoClient.IoClient', 'ioClient'),
    new GridColumn('IoClient.ClientType', 'clientType'),
    new GridColumn('IoClient.HistorianType', 'historianType'),
    //perf indic
    new GridColumn('Visible', 'visible', 'boolean', true, true),
    //supplier
    new GridColumn('Supplier.Id', 'supplierId'),
    //instrument
    new GridColumn('Area.Name', 'parentName'),
    new GridColumn('Type', 'type', 'string', false),
    new GridColumn('Make', 'make', 'string', false),
    new GridColumn('Model', 'model', 'string', false),
    new GridColumn('SerialNo', 'serialNumber', 'string', false),
    new GridColumn('Criticality.Criticality', 'criticality'),
    new GridColumn('HasAlarms', 'failedAlarmRules', 'string'),
    new GridColumn('RoutineValidation', 'routineValidationDisabled', 'string'),
    //stream
    new GridColumn('Stream.Description', 'description', 'string', false),
    //reference material
    new GridColumn('Instrument.Tag', 'instrumentTag'),
    new GridColumn('Reference.StreamTag', 'referenceStreamTag'),
    new GridColumn('Reference.MaterialType', 'materialType'),
    new GridColumn('MaterialId', 'materialId'),
    new GridColumn('CertificateDate', 'certificateDate', 'date'),
    new GridColumn('ExpiryDate', 'expiryDate', 'date'),
    //new GridColumn('Status', 'status', 'string', true, true, true), // TODO: FIX, duplicate column name definition NOT allowed!
    new GridColumn('InstallDateTime', 'installDateTime', 'date'),
    new GridColumn('InstalledBy', 'installedBy'),
    //operational state
    new GridColumn('State', 'state', 'string', true, true, true),
    new GridColumn('InitiatedBy', 'initiatedBy'),
    new GridColumn('StartDateTime', 'startDateTime', 'datetime'),
    //document
    new GridColumn('File.Name', 'fileName'),
    new GridColumn('File.Size', 'fileSize', 'string', true, true),
    new GridColumn('Document.DateTime', 'uploadDate', 'datetime'),
    new GridColumn('Document.AddedBy', 'uploadUser'),
    //validation
    new GridColumn('EndDateTime', 'endDateTime', 'datetime'),
    new GridColumn('AbortedBy', 'abortedBy'),
    new GridColumn('Validation.Check', 'validationCheck', 'string', true, true, true),
    new GridColumn('Validation.Method', 'validationMethod', 'string', true, true, false, false),
    new GridColumn('Validation.Procedure', 'procedure', 'string', true, true),
    new GridColumn('Validation.State', 'validationState', 'string', true, true, true),
    new GridColumn('Stream.Stream', 'stream'),
    new GridColumn('Stream.Tag', 'streamTag'),
    new GridColumn('SPCResult', 'validationResult', 'string', true, true, true),
    //control chart
    new GridColumn('LastEvaluation', 'lastEvaluation', 'datetime'),
    new GridColumn('LastViewed', 'lastViewed', 'datetime'),
    new GridColumn('LastResult', 'lastResult', 'string', true, true, true),
    //maintenance
    new GridColumn('MaintenanceActivity.MaintenanceActivity', 'maintenanceActivity'),
    new GridColumn('CreatedDateTime', 'createdDateTime', 'dateTime'),
    new GridColumn('CompletedDateTime', 'completedDateTime', 'dateTime'),
    new GridColumn('DueDateTime', 'dueDateTime', 'datetime', false),
    new GridColumn('EstimatedHours', 'estimatedHours', 'numeric', false),
    new GridColumn('Planned.StartDateTime', 'plannedStartDateTime', 'datetime', false),
    new GridColumn('Planned.EndDateTime', 'plannedEndDateTime', 'datetime', false),
    new GridColumn('ResponsibleUser', 'responsibleUser'),
    new GridColumn('Priority', 'priority', 'string', true, true),
    new GridColumn('Maintenance.ApproveDateTime', 'approveDateTime', 'datetime', false, false, false, false),
    new GridColumn('Maintenance.ApprovedByUser', 'approvedByUser', 'string', false, false, false, false),
    new GridColumn('Maintenance.Approve2DateTime', 'approve2DateTime', 'datetime', false, false, false, false),
    new GridColumn('Maintenance.Approved2ByUser', 'approved2ByUser', 'string', false, false, false, false),
    new GridColumn('Maintenance.ApprovalRequired', 'maintenanceApprovalRequired', 'boolean', false, false, false, false),
    new GridColumn('Downtime', 'downtime', 'numeric', false, false, false, false),
    //scheduler
    new GridColumn('Scheduler.Area', 'areaName', 'string', false, false),
    new GridColumn('Scheduler.Name', 'name', 'string', true, false),
    new GridColumn('Scheduler.ScheduleType', 'schedulingType', 'string', true, true),
    new GridColumn('Scheduler.Recurrence', 'recurrencyType', 'string', true, true),
    new GridColumn('Scheduler.Interval', 'interval', 'numeric'),
    new GridColumn('Scheduler.Description', 'description', 'string', false, false),
    new GridColumn('Scheduler.TaskType', 'taskType', 'string', true, true),
    new GridColumn('Scheduler.NextExecution', 'nextFireTime', 'datetime', false),
    new GridColumn('Scheduler.LastExecution', 'prevFireTime', 'datetime', false),
    new GridColumn('Scheduler.Status', 'triggerState', 'string', false, true),
    new GridColumn('Scheduler.TaskSettings.DueAfterDays', 'dueAfterDays', 'numeric')
  ];

  public getListObjects(names: string[]): ListObjectMetaData[] {
    return names.map(name => this.listObjects.filter(obj => obj.header === name)[0]).filter(column => column !== undefined);
  }

  public getListObject(name: string): ListObjectMetaData {
    const obj = this.listObjects.filter(item => item.header === name);
    return (obj.length === 1) ? obj[0]: null;
  }

  public getListObjectByEndpoint(endpoint: string): ListObjectMetaData {
    const obj = this.listObjects.filter(item => item.endpoint === endpoint);
    return (obj.length > 0) ? obj[0] : null;
  }

  public getColumns(names: string[], addActionColumn = true): GridColumn[] {
    const columns = names.map(name => this.columns.filter(column => column.header === name)[0]).filter(column => column !== undefined);

    if (addActionColumn) {
      columns.push({
        header: 'Action', fullHeader: 'Action', property: null, type: null, requiredInput: false, translate: false, sort: false, enabled: true, valueAsCssClass: false,
        editable: false, title: null, content: null, selectedItem: null, listInputEndpoint: null, selectOptions: null
      });
    }

    return columns;
  }

  public getVisibleColumns(metaData: ModelMetaData[], customColumns: CustomGridColumn[], name: string, addActionColumn = true): GridColumn[] {
    if (metaData !== undefined && metaData !== null && metaData.length !== undefined && metaData.length > 0) {
      const names = metaData.map(item => item.name);
      const columns = this.getColumns(names, false);
      const sortOptions = metaData.filter(item => item.isSortable).map(item => item.name);
      columns.forEach(column => column.sort = sortOptions.includes(column.header));

      if (customColumns !== undefined && customColumns !== null && customColumns.length !== undefined && customColumns.length > 0) {
        customColumns.forEach(customColumn => {
          if (!columns.map(column => column.header).includes(customColumn.header)) {
            columns.push({
              header: customColumn.header, fullHeader: customColumn.header, property: null, type: customColumn.type, requiredInput: false, translate: customColumn.translate, sort: false,
              enabled: true, valueAsCssClass: false, editable: false, title: null, content: null, selectedItem: null, listInputEndpoint: null, selectOptions: null
            });
          }
          columns.filter(column => customColumn.header === column.header).forEach(item => {
            item.type = customColumn.type;
            item.translate = customColumn.translate;
            item.enabled = customColumn.enabled;
            item.title = customColumn.title;
            item.content = customColumn.content;
          });
        });
      }

      if (addActionColumn) {
        columns.push({
          header: 'Action', fullHeader: 'Action', property: null, type: null, requiredInput: false, translate: false, sort: false, enabled: true, valueAsCssClass: false,
          editable: false, title: null, content: null, selectedItem: null, listInputEndpoint: null, selectOptions: null
        });
      }

      if (name !== undefined && name !== null) {
        const savedColumnsString = localStorage.getItem('grid:' + name);
        if (savedColumnsString !== null && savedColumnsString.length > 0) {
          const savedColumns = JSON.parse(savedColumnsString);
          columns.filter(item => item.enabled).forEach(item => {
            savedColumns.forEach(savedItem => {
              if (item.header === savedItem.header) {
                item.enabled = savedItem.enabled;
              }
            });
          });
        }
      }

      return columns.filter(item => item.enabled);
    }
    return [];
  }
}
