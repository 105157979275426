import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { ActiveFilter } from '../../../../shared/data/active-filter';

@Component({
  selector: 'validation-result-filter',
  templateUrl: './validation-result-filter.component.html',
  styleUrls: ['../../../../shared/data/data-filter.component.css']
})
export class ValidationResultFilterComponent implements AfterContentChecked {
  @Input() filter: ActiveFilter;
  @Output() filterChanged = new EventEmitter<boolean>();
  @ViewChild('validationResultElement') validationResultElementRef: ElementRef;

  ngAfterContentChecked() {
    if (this.filter.filterText !== undefined && this.validationResultElementRef !== undefined) {
      this.validationResultElementRef.nativeElement.value = this.filter.filterText;
    }
  }

  changed(ev) {
    this.filter.filterText = ev.target.value;
    this.filterChanged.emit(true);
  }
}
