import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AppConfig } from '../../core/app.config';
import { ModelMetaData } from './model-meta-data';
import { CustomInputProperty } from '../data-form/custom-input-property';
import { GridColumn } from '../grid/grid-column';
import { GridService } from '../grid/grid.service';
import { ListObjectMetaData } from '../grid/list-object-meta-data';
import { SelectionItem } from '../selection-item';
import { Endpoint } from './endpoint';

@Injectable()
export class DataService {
  constructor(
    private datePipe: DatePipe,
    private translate: TranslateService,
    private appConfig: AppConfig,
    private http: HttpClient,
    private gridService: GridService
  ) {
    this.apiHost = this.appConfig.getConfig('apiHost');
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.dateFormat = this.appConfig.getConfig('dateFormat');
  }
  private apiHost: string;
  public dateTimeFormat: string;
  public dateFormat: string;

  private metaDataCache: Map<string, Observable<ModelMetaData[]>> = new Map();
  private getDataCache: Map<string, Observable<any[]>> = new Map();
  private countDataCache: Map<string, Observable<number>> = new Map();

  public getMetaData(endpoint: string, force: boolean = false): Observable<ModelMetaData[]> {
    if (force || !this.metaDataCache.has(endpoint)) {
      this.metaDataCache.set(endpoint, this.http.get<ModelMetaData[]>(this.apiHost + endpoint + '/getmetadata').pipe(shareReplay()));
      setTimeout(() => this.metaDataCache.delete(endpoint), 60000);
    }
    return this.metaDataCache.get(endpoint);
  }

  public getItems(endpoint: string, filterArguments: string, force: boolean = false): Observable<any[]> {
    const path = (endpoint.includes('/')) ? '' : '/get';
    if (force || !this.getDataCache.has(endpoint + path + ':' + filterArguments)) {
      this.getDataCache.set(endpoint + path + ':' + filterArguments, this.http.get<any[]>(this.apiHost + endpoint + path + filterArguments).pipe(shareReplay()));
      setTimeout(() => this.getDataCache.delete(endpoint + path + ':' + filterArguments), 4850);
    }
    return this.getDataCache.get(endpoint + path + ':' + filterArguments);
  }

  public getItem(endpoint: string, id: number | string): Observable<any> {
    return this.http.get<any>(this.apiHost + endpoint + '/get/' + id);
  }

  public getCount(endpoint: string, filterArguments: string, force: boolean = false): Observable<number> {
    if (force || !this.countDataCache.has(endpoint + ':' + filterArguments)) {
      this.countDataCache.set(endpoint + ':' + filterArguments, this.http.get<number>(this.apiHost + endpoint + '/count' + filterArguments).pipe(shareReplay()));
      setTimeout(() => this.countDataCache.delete(endpoint + ':' + filterArguments), 4850);
    }
    return this.countDataCache.get(endpoint + ':' + filterArguments);
  }

  public postItem(endpoint: string, item: any) {
    return this.http.post(this.apiHost + endpoint + '/post', item);
  }

  public putItem(endpoint: string, item: any) {
    return this.http.put(this.apiHost + endpoint + '/put', item);
  }

  public deleteItem(endpoint: string, id: number | string) {
    return this.http.delete(this.apiHost + endpoint + '/delete/' + id);
  }

  public addOrUpdateItems(endpoint: string, items: any[]) { return this.http.post(this.apiHost + endpoint + '/addorupdaterange', items); }

  public getEndpoints(): Observable<Endpoint[]> { return this.http.get<any[]>(this.apiHost + 'endpoint/get'); }

  public getInputProperties(names: string[], customProperties: CustomInputProperty[], addActionColumn = false, showDisabledColumns = false): GridColumn[] {
    const objects: ListObjectMetaData[] = JSON.parse(JSON.stringify(this.gridService.getListObjects(names)));
    const allColumns: GridColumn[] = JSON.parse(JSON.stringify(this.gridService.getColumns(names, false)));
    let columns: GridColumn[] = [];
    const viewProperties: GridColumn[] = [];

    if (objects.length && objects.length > 0) {
      objects.forEach(obj => obj.viewProperties.forEach(vp => viewProperties.push(vp)));

      allColumns.forEach(ac => {
        if (viewProperties.filter(vp => vp.header === ac.header).length === 0) {
          columns.push(ac);
        } else {
          objects.forEach(obj => {
            if (obj.header === ac.header || obj.viewProperties[0].header === ac.header) {
              if (obj.viewProperties.length > 1) {
                const propertyHeaders = obj.viewProperties.map(property => this.translate.instant(property.header));
                obj.idProperty.fullHeader = this.translate.instant(obj.idProperty.header) + ' (' + propertyHeaders.join(', ') + ')';
              }
              obj.idProperty.property = obj.property;
              columns.push(obj.idProperty);
            }
          });
        }
      });
    } else {
      columns = allColumns;
    }

    if (customProperties && customProperties.length && customProperties.length > 0) {
      customProperties.forEach(customProperty => {
        if (!columns.map(column => column.header).includes(customProperty.header)) {
          columns.push({
            header: customProperty.header, fullHeader: customProperty.header, property: customProperty.property, type: customProperty.type, requiredInput: customProperty.requiredInput,
            translate: false, sort: false, enabled: true, valueAsCssClass: false, editable: true, title: null, content: null, selectedItem: null,
            listInputEndpoint: null, selectOptions: null
          });
        }
        columns.filter(column => customProperty.header === column.header).forEach(item => {
          item.property = customProperty.property;
          item.type = customProperty.type;
          item.requiredInput = customProperty.requiredInput;
          item.selectedItem = customProperty.selectedItem;
          item.listInputEndpoint = customProperty.listInputEndpoint;
          item.selectOptions = customProperty.selectOptions;
        });
      });
    }

    if (addActionColumn) {
      columns.push({
        header: 'Action', fullHeader: 'Action', property: null, type: null, requiredInput: false, translate: false, sort: false, enabled: true, valueAsCssClass: false,
        editable: false, title: null, content: null, selectedItem: null, listInputEndpoint: null, selectOptions: null
      });
    }

    return columns.filter(item => (showDisabledColumns || item.enabled) && (item.editable || item.header === 'Action'));
  }

  public getPropertyValue(obj, path, type, translate): string {
    if (type !== 'multiple') {
      if (obj !== null && path !== null) {
        path = path.split('.');
        let current = obj;
        while (path.length) {
          if (typeof current !== 'object' || current === null) { return ''; }
          current = current[path.shift()];
        }

        if (current !== null && current !== '') {
          return this.transformToType(current, type, translate);
        }
      }
    } else {
      const items: string[] = path.split('|');
      let result = '';
      items.forEach(item => result += this.getPropertyValue(obj, item, 'string', translate));
      return result;
    }
    return '';
  }

  public transformToType(value, type, translate) {
    type = (type !== null && type.length > 0) ? type.toLowerCase() : type;
    switch (type) {
      case 'numeric':
      case 'sum':
      case 'int32':
      case 'int64':
      case 'double':
        return value;
      case 'boolean':
        return (value) ? 'Yes' : 'No';
      case 'datetime':
        return this.datePipe.transform(value, this.dateTimeFormat);
      case 'date':
        return this.datePipe.transform(value, this.dateFormat);
      case 'stringArray':
        return value.join(', ');
      case 'string':
      default:
        const translated = (translate && value !== undefined && value.length) ? this.translate.instant(value) : value;
        value = (translated !== null) ? translated : '';
        return value;
    }
  }
}
