import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { CriticalityFilterComponent } from './criticality-filter.component';
import { CriticalityService } from '../criticality.service';

@NgModule({
  declarations: [CriticalityFilterComponent],
  exports: [CriticalityFilterComponent],
  imports: [CommonModule, TranslateModule],
  providers: [CriticalityService]
})
export class CriticalityFilterModule { }
