import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AppConfig } from '../../core/app.config';
import { Diagnostic } from './diagnostic';

@Injectable()
export class DiagnosticService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  private diagnosticsCache: Map<string, Observable<Diagnostic[]>> = new Map();

  public getDiagnostics(args: string): Observable<Diagnostic[]> { return this.http.get<Diagnostic[]>(this.apiHost + 'diagnostic/get' + args); }

  public getDiagnosticsByInformationLevel(informationLevelId: number, params: string, force: boolean = false): Observable<Diagnostic[]> {
    if (force || !this.diagnosticsCache.has(informationLevelId + params)) {
      this.diagnosticsCache.set(informationLevelId + params, this.http.get<Diagnostic[]>(this.apiHost + 'diagnostic/getbyinformationlevel/' + informationLevelId + params).pipe(shareReplay()));
      setTimeout(() => this.diagnosticsCache.delete(informationLevelId + params), 10000);
    }
    return this.diagnosticsCache.get(informationLevelId + params);
  }

  public getDiagnosticsByIds(ids: number[]): Observable<Diagnostic[]> { return this.http.get<Diagnostic[]>(this.apiHost + 'diagnostic/getbyids?ids=' + ids.join('&ids=')); }

  public countDiagnostics(args: string): Observable<number> { return this.http.get<number>(this.apiHost + 'diagnostic/count' + args); }

  public countDiagnosticsByInformationLevel(informationLevelId: number, params: string): Observable<number> {
    return this.http.get<number>(this.apiHost + 'diagnostic/countbyinformationlevel/' + informationLevelId + params);
  }

  public getDiagnostic(id: number): Observable<Diagnostic> { return this.http.get<Diagnostic>(this.apiHost + 'diagnostic/get/' + id); }

  public postDiagnostic(diagnostic: Diagnostic) { return this.http.post(this.apiHost + 'diagnostic/post', diagnostic); }

  public putDiagnostic(diagnostic: Diagnostic) { return this.http.put(this.apiHost + 'diagnostic/put', diagnostic); }

  public deleteDiagnostic(id: number) { return this.http.delete(this.apiHost + 'diagnostic/delete/' + id); }

  public addOrUpdateDiagnostics(diagnostics: Diagnostic[]) { return this.http.post(this.apiHost + 'diagnostic/addorupdaterange', diagnostics); }

  public resequenceDiagnostics(diagnostics: Diagnostic[]) { return this.http.put(this.apiHost + 'diagnostic/resequence', diagnostics); }

  public newDiagnostic(informationLevelId: number): Diagnostic {
    return {
      io: null, ioId: null, ioName: '', description: '', informationLevel: '',
      displayParameters: { trueInput: 'Ok', falseInput: 'Faulted', trueColor: '#000000', falseColor: '#FF0000', textColor: '#000000', numberOfDecimals: 2 },
      id: -1, informationLevelId: informationLevelId, sequenceNumber: 1, unit: '', visible: true, processValue: '', alarmAt: 'none', faultedAt: 'none', eventAt: 'none', qualityBadAt: 'none'
    };
  }
}
