import { NgModule } from '@angular/core';
import { OwlDateTimeModule } from '@shtian/ng-pick-datetime';

import { SharedModule } from '../../../../shared/shared.module';

import { GaussianChartComponent } from './gaussian-chart.component';
import { GaussianChartService } from './gaussian-chart.service';

@NgModule({
  declarations: [
    GaussianChartComponent
  ],
  exports: [
    GaussianChartComponent
  ],
  imports: [
    SharedModule,
    OwlDateTimeModule
  ],
  providers: [
    GaussianChartService
  ]
})
export class GaussianChartModule { }
