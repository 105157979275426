import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ValidationProcedureService } from '../validation-procedure.service';
import { ActiveFilter } from '../../../../../shared/data/active-filter';

@Component({
  selector: 'validation-method-filter',
  templateUrl: './validation-method-filter.component.html',
  styleUrls: ['../../../../../shared/data/data-filter.component.css']
})
export class ValidationMethodFilterComponent implements OnInit, AfterContentChecked {
  constructor(
    private validationProcedureService: ValidationProcedureService
  ) { }
  @Input() filter: ActiveFilter;
  @Output() filterChanged = new EventEmitter<boolean>();
  public validationMethods: string[];
  @ViewChild('validationMethodElement') validationMethodElementRef: ElementRef;

  ngOnInit() {
    this.validationProcedureService.getValidationMethods().subscribe(validationMethods => this.validationMethods = validationMethods);
  }

  ngAfterContentChecked() {
    if (this.filter.filterText !== undefined && this.validationMethodElementRef !== undefined) {
      this.validationMethodElementRef.nativeElement.value = this.filter.filterText;
    }
  }

  changed(ev) {
    this.filter.filterText = ev.target.value;
    this.filterChanged.emit(true);
  }
}
