import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { PerformanceIndicatorCalculatedComponent } from './perf-indic-calculated.component';
import { PerformanceIndicatorService } from '../perf-indic.service';

@NgModule({
  declarations: [
    PerformanceIndicatorCalculatedComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    PerformanceIndicatorCalculatedComponent
  ],
  providers: [
    PerformanceIndicatorService
  ]
})
export class PerformanceIndicatorCalculatedModule { }
