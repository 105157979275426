<div class="grid-wrapper">
  <grid #grid [name]="name" [title]="title" [endpoint]="endpoint" [rowActions]="rowActions" [customColumns]="customColumns" [dataChanged]="dataChanged" [parentId]="parentId"
    [informationLevelId]="informationLevelId" [instrumentId]="instrumentId" [areaId]="areaId" [equipmentId]="equipmentId" [componentStreamId]="componentStreamId"
    [chartId]="chartId" [controlChartSetId]="controlChartSetId" [fidelityChartId]="fidelityChartId" [ioClientId]="ioClientId" [maintenanceItemId]="maintenanceItemId"
    [showFilters]="showFilters" [showHeader]="showHeader" [showFooter]="showFooter" [activeFilters]="activeFilters" (dataChangedEvent)="gridDataChanged($event)"></grid>
  <error-message [error]="error"></error-message>
  <div *ngIf="addAction && createAccess" class="control-buttons">
    <ng-container *ngIf="additionalActions">
      <ng-container *ngFor="let action of additionalActions">
        <button *ngIf="action.show && action.condition(rowData)" [class]="'button-' + action.color" (click)="action.callback()">{{ action.name | translate }}</button>
      </ng-container>
    </ng-container>
    <button *ngIf="showAdd" class="button-blue" type="button" (click)="addItem()">{{ addAction.name | translate }}</button>
  </div>
</div>
<div *ngIf="overviewComponentRef" [ngClass]="nestingDialog ? 'nesting-dialog' : ''">
  <custom-dialog [visible]="editDialog" *ngIf="editDialog" (editModeChanged)="dialogChanged($event)">
    <ng-container *ngTemplateOutlet="overviewComponentRef; context:{item:item, editModeChanged:dialogChanged.bind(this),
      parentId:parentId, informationLevelId:informationLevelId, instrumentId:instrumentId, areaId:areaId, equipmentId:equipmentId,
      componentStreamId:componentStreamId, chartId:chartId, controlChartSetId:controlChartSetId, fidelityChartId:fidelityChartId,
      ioClientId:ioClientId, maintenanceItemId:maintenanceItemId}"></ng-container>
  </custom-dialog>
</div>
<delete-dialog [visible]="deleteDialog" [error]="error" (confirmDelete)="confirmDelete($event)"></delete-dialog>
