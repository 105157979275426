import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ErrorMessage } from '../error/error-message';

@Component({
  selector: 'delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})

export class DeleteDialogComponent {
  @Input() visible: boolean;
  @Input() error: ErrorMessage;
  @Output() confirmDelete = new EventEmitter<boolean>();

  confirmDialog(confirmed: boolean): void {
    this.confirmDelete.emit(confirmed);
  }
}
