
import {map, filter} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { RouterModule, Routes, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';

import { AppConfig } from '../app.config';

@Injectable()
export class HelpService {
  private pageLink: Observable<string>;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    if (router.events !== undefined) {
      this.pageLink = router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => this.getPageLink(activatedRoute.root)));
    }
  }

  public getHelpPageLink(): Observable<string> {
    return this.pageLink;
  }

  public getPageLink(route: ActivatedRoute): string {
    const helpId: string = this.getRouteHelpId(route);
    let pageLink: string = 'assets/help/Index.html';

    if (helpId !== null) {
      pageLink += '?' + helpId + '.html';
    }

    return pageLink;
  }

  public getRouteHelpId(route: ActivatedRoute): string {
    if (route.routeConfig && route.routeConfig.data && route.routeConfig.data.helpId) {
      return route.routeConfig.data.helpId;
    }

    if (route.firstChild) {
      return this.getRouteHelpId(route.firstChild);
    }

    return null;
  }
}
