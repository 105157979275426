<div class="header">
  <h1>{{ title | translate }}</h1>
</div>
<div class="grid-wrapper">
  <grid [name]="'Report_Types'" [genericDataRetrieval]="false" [headers]="headers" [filterOptions]="filterOptions" [count]="count" [exportData]="reportTypes" (filterEvent)="getReportTypes($event)">
    <tr *ngFor="let reportType of reportTypes; let i = index; let odd = odd" [class.odd]="odd">
      <td><a routerLink="{{ reportType.id }}">{{ reportType.description | translate }}</a></td>
      <td>{{ reportType.datasetName | translate }}</td>
      <td>{{ reportType.reportLevel | translate }}</td>
      <td>{{ reportType.enabled ? 'Yes' : 'No' | translate }}</td>
      <ng-container *ngIf="reportManagementReadAccess">
        <td>{{ reportType.username }}</td>
        <td>{{ reportType.updateDateTime | date:dateTimeFormat }}</td>
      </ng-container>
      <td>
        <button class="button-blue" routerLink="{{ reportType.id }}">{{ 'Report.View' | translate }}</button>
        <button *ngIf="(reportType.userId === userId && updateAccess) || reportManagementUpdateAccess" class="button-blue" (click)="editReportType(reportType)">{{ 'Edit' | translate }}</button>
        <button *ngIf="((reportType.userId === userId && updateAccess) || reportManagementUpdateAccess) && !reportType.enabled" class="button-orange" (click)="toggleEnabled(reportType.id)">{{ 'Enable' | translate }}</button>
        <button *ngIf="((reportType.userId === userId && updateAccess) || reportManagementUpdateAccess) && reportType.enabled" class="button-orange" (click)="toggleEnabled(reportType.id)">{{ 'Disable' | translate }}</button>
        <button *ngIf="(reportType.userId === userId && deleteAccess) || reportManagementDeleteAccess" class="button-red" (click)="deleteReportType(reportType.id)">{{ 'Delete' | translate }}</button>
      </td>
    </tr>
  </grid>
  <error-message [error]="error"></error-message>
  <div *ngIf="createAccess" class="control-buttons">
    <button class="button-blue" (click)="addReportType()">{{ 'Report.Add' | translate }}</button>
  </div>
</div>
<delete-dialog [visible]="deleteDialog" [error]="error" (confirmDelete)="confirmDelete($event)"></delete-dialog>
<custom-dialog [visible]="editDialog" *ngIf="editDialog" (editModeChanged)="dialogChanged($event)">
  <report-type-overview [reportType]="reportType" [userDefined]="true" [shownInDialog]="true" (editModeChanged)="dialogChanged($event)"></report-type-overview>
</custom-dialog>
