import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AppConfig } from '../../../../core/app.config';
import { Comp } from './comp';

@Injectable()
export class CompService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  private compCache: Map<number, Observable<Comp>> = new Map();

  public getComps(filterArguments: string): Observable<Comp[]> { return this.http.get<Comp[]>(this.apiHost + 'component/get' + filterArguments); }

  public getCompsByCompStream(id: number, filterArguments: string): Observable<Comp[]> { return this.http.get<Comp[]>(this.apiHost + 'component/getbycomponentstream/' + id + filterArguments); }

  public getComp(id: number, force: boolean = false): Observable<Comp> {
    if (force || !this.compCache.has(id)) {
      this.compCache.set(id, this.http.get<Comp>(this.apiHost + 'component/get/' + id).pipe(shareReplay()));
      setTimeout(() => this.compCache.delete(id), 15000);
    }
    return this.compCache.get(id);
  }

  public getCompCount(filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'component/count' + filterArguments); }

  public getCompCountByCompStream(id: number, filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'component/countbycomponentstream/' + id + filterArguments); }

  public getCompsByInstrument(instrumentId: number): Observable<Comp[]> { return this.http.get<Comp[]>(this.apiHost + 'component/getbyinstrument/' + instrumentId); }

  public postComp(comp: Comp): Observable<number> { return this.http.post<number>(this.apiHost + 'component/post', comp); }

  public putComp(comp: Comp) { return this.http.put(this.apiHost + 'component/put', comp); }

  public deleteComp(id: number) { return this.http.delete(this.apiHost + 'component/delete/' + id); }

  public addOrUpdateComps(comps: Comp[]) { return this.http.post(this.apiHost + 'component/addorupdaterange', comps); }

  public getCompCbrFactor(id: number): Observable<number> { return this.http.get<number>(this.apiHost + 'component/cbrfactor/' + id); }

  public getComponentDataSourceMappingOfStream(streamid: number): Observable<Map<string, string>> {
    return this.http.get<Map<string, string>>(this.apiHost + 'component/getcomponentdatasourcemappingofstream/' + streamid);
  }

  public newComp(id: number): Comp {
    return {
      id: -1, calibrationRangeLowerValue: 0, calibrationRangeUpperValue: 0, componentTypeId: null, componentType: null,
      measuringInstrumentTag: '', measuringInstrumentId: null, tag: '', streamId: id, ioName: '', ioId: null, processValue: null, streamTag: ''
    };
  }
}
