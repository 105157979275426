import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { PerformanceIndicatorService } from '../perf-indic.service';
import { PerformanceIndicator } from '../perf-indic';
import { ActiveFilter } from '../../shared/data/active-filter';

@Component({
  selector: 'perf-indic-filter',
  templateUrl: './perf-indic-filter.component.html',
  styleUrls: ['../../shared/data/data-filter.component.css']
})
export class PerformanceIndicatorFilterComponent implements OnInit, OnDestroy, AfterContentChecked {
  constructor(
    private performanceIndicatorService: PerformanceIndicatorService
  ) { }
  @Input() filter: ActiveFilter;
  @Input() editMode = true;
  @Input() filterInputChanged: Observable<void>;
  @Output() filterChanged = new EventEmitter<boolean>();
  public performanceIndicators: PerformanceIndicator[];
  private filterInputChangedSubscription: Subscription;
  @ViewChild('performanceIndicatorElement') performanceIndicatorElementRef: ElementRef;
  @ViewChild('operatorElement') operatorElementRef: ElementRef;
  @ViewChild('filterTextElement') filterTextElementRef: ElementRef;

  ngOnInit() {
    if (this.filterChanged !== undefined) {
      this.filterInputChangedSubscription = this.filterChanged.subscribe(() => this.getData());
    }

    this.getData();
  }

  ngOnDestroy() {
    if (this.filterInputChangedSubscription !== undefined) {
      this.filterInputChangedSubscription.unsubscribe();
    }
  }

  ngAfterContentChecked() {
    if (this.filter.filterDetails.performanceIndicatorId !== undefined && this.performanceIndicatorElementRef !== undefined) {
      this.performanceIndicatorElementRef.nativeElement.value = this.filter.filterDetails.performanceIndicatorId;
    }
    if (this.filter.filterDetails.operator !== undefined && this.operatorElementRef !== undefined) {
      this.operatorElementRef.nativeElement.value = this.filter.filterDetails.operator;
    }
    if (this.filter.filterText !== undefined && this.filterTextElementRef !== undefined) {
      this.filterTextElementRef.nativeElement.value = this.filter.filterText;
    }
  }

  getData() {
    this.performanceIndicatorService.getAllPerformanceIndicators().subscribe(perfIndics => {
      this.performanceIndicators = perfIndics;

      if (perfIndics !== null && this.filter !== undefined && this.filter !== null && this.filter.filterDetails !== null && this.filter.filterDetails.performanceIndicatorId !== undefined) {
        const id = parseInt(this.filter.filterDetails.performanceIndicatorId);
        this.filter.filterDetails.name = perfIndics.filter(p => p.id === id)[0].name;
      }
    });
  }

  changedPI(ev) {
    this.filter.filterDetails.performanceIndicatorId = ev.target.value;
    this.filterChanged.emit(true);
  }

  changedOperator(ev) {
    this.filter.filterDetails.operator = ev.target.value;
    this.filterChanged.emit(true);
  }

  changedFilterText(ev) {
    this.filter.filterText = ev.target.value;
    this.filterChanged.emit(true);
  }
}
