import { ReportSectionHeader } from './report-section-header';
import { ReportSectionContent } from './report-section-content';

export class ReportSection {
  constructor(type?: string, content?: any, title?: string, enabled: boolean = true, loaded: boolean = true) {
    this.type = type;
    this.title = title;
    this.enabled = enabled;
    this.content = content;
    this.loaded = loaded;
    this.headers = null;
    this.dataTable = null;
    this.sectionChildren = null;
    this.id = undefined;
    this.order = undefined;
    this.dataPath = undefined;
  }

  id: number;
  type: string;
  enabled: boolean;
  title: string;
  headers: ReportSectionHeader[];
  content: any[];
  dataTable: string;
  sectionChildren: ReportSection[];
  loaded: boolean;
  order: number;
  dataPath: string;
}
