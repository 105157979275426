import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AppConfig } from '../core/app.config';
import { Generic } from '../shared/generic';

@Injectable()
export class LevelService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  private levelCache: Map<number, Observable<Generic>> = new Map();
  private levelsCache: Map<number, Observable<Generic[]>> = new Map();

  public getAllLevels(): Observable<Generic[]> { return this.http.get<Generic[]>(this.apiHost + 'informationlevel/getall'); }

  public getLevel(id: number, force: boolean = false): Observable<Generic> {
    if (force || !this.levelCache.has(id)) {
      this.levelCache.set(id, this.http.get<Generic>(this.apiHost + 'informationlevel/getitem/' + id).pipe(shareReplay()));
      setTimeout(() => this.levelCache.delete(id), 30000);
    }
    return this.levelCache.get(id);
  }

  public getLevelAndChildren(id: number, force: boolean = false): Observable<Generic[]> {
    if (force || !this.levelsCache.has(id)) {
      this.levelsCache.set(id, this.http.get<Generic[]>(this.apiHost + 'informationlevel/getitemandchildren?informationlevelid=' + id).pipe(shareReplay()));
      setTimeout(() => this.levelsCache.delete(id), 30000);
    }
    return this.levelsCache.get(id);
  }

  public getLevelType(id: number): Observable<string> { return this.http.get<string>(this.apiHost + 'informationlevel/getleveltype/' + id, { responseType: 'text' as 'json' }); }
}
