import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ClaimService } from '../../../../../core/claim.service';

import { ValidationData } from './validation-data';
import { VrgInclusion } from '../vrg-inclusion';
import { ControlChartService } from '../control-chart.service';
import { ValidationDataService } from './validation-data.service';
import { ErrorMessage } from '../../../../../shared/error/error-message';
import { Header } from '../../../../../shared/grid/header';
import { FilterOption } from '../../../../../shared/data/filter-option';
import { AppConfig } from '../../../../../core/app.config';

@Component({
  selector: 'validation-data',
  templateUrl: './validation-data.component.html',
  styleUrls: ['./validation-data.component.css']
})
export class ValidationDataComponent implements OnInit, OnChanges {
  constructor(
    private controlChartService: ControlChartService,
    private validationDataService: ValidationDataService,
    private claimService: ClaimService,
    private appConfig: AppConfig
  ) { }
  public headers: Header[] = [{ header: 'Id', sort: true, width: '3%' }, { header: 'StartDateTime', sort: true, width: '11%' }, { header: 'EndDateTime', sort: true, width: '11%' },
    { header: 'InitiatedBy', sort: true, width: '8%' }, { header: 'Data', sort: false, width: '19%' }, { header: 'Statistics', sort: false, width: '17%' },
    { header: 'PlottedVariable', sort: false, width: '11%' }, { header: 'Excluded', sort: true, width: '7%' }, { header: 'Reset', sort: true, width: '5%' },
    { header: 'Action', sort: false, width: '7%' }];
  public filterOptions: FilterOption[] = [{ name: 'Id', type: 'numeric' }, { name: 'StartDateTime', type: 'datetime' }, { name: 'EndDateTime', type: 'datetime' },
    { name: 'InitiatedBy', type: 'string' }, { name: 'Excluded', type: 'boolean' }, { name: 'Reset', type: 'boolean' }];
  public count = 0;
  @Input() public controlChartSetId: number;
  @Input() public dataChange: boolean;
  @Output() public chartChange = new EventEmitter<boolean>();
  public validationData: ValidationData[];
  public valDataDialog: boolean;
  private validationSessionId: number;
  private args = '';
  public error: ErrorMessage;
  public updateAccess: boolean;
  private dateTimeFormat: string;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.updateAccess = this.claimService.checkClaimContains('aml.valsession', 'u');
    this.getValidationData(this.args);
  }

  ngOnChanges() {
    this.getValidationData(this.args);
  }

  getValidationData(args: string) {
    this.error = null;
    this.args = args;
    this.validationDataService.getValidationDataByControlChartSet(args, this.controlChartSetId).subscribe(data => this.validationData = data, error => this.error = error.error);
    this.validationDataService.countValidationDataByControlChartSet(args, this.controlChartSetId).subscribe(count => this.count = count);
  }

  evaluateControlChartSet() {
    this.error = null;
    this.controlChartService.saveAndReevaluateControlChartSet(this.controlChartSetId, this.validationData.map(vd =>
      <VrgInclusion>{ id: vd.validationReadingGroupId, exclude: vd.excluded, reset: vd.resetSpc })).subscribe(response => this.chartChange.emit(true), error => this.error = error.error);
  }

  viewDetails(id: number) {
    this.validationSessionId = id;
    this.valDataDialog = true;
  }

  valDataDialogChanged(event: boolean) {
    this.valDataDialog = event;
    this.getValidationData(this.args);
    this.chartChange.emit(true);
  }
}
