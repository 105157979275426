<div class="header">
  <h1>{{ title | translate }}</h1>
</div>
<ng-container *ngIf="referenceMaterialChange">
  <table class="overview">
    <tr>
      <td>{{ 'InstalledBy' | translate }}:</td>
      <td>{{ referenceMaterialChange.installedBy }}</td>
    </tr>
    <tr>
      <td>{{ 'InstallDate' | translate }}:</td>
      <td>{{ referenceMaterialChange.installDate | date:dateFormat }}</td>
    </tr>
    <tr>
      <td>{{ 'CertificateDate' | translate }}:</td>
      <td>{{ referenceMaterialChange.certificateDate | date:dateFormat }}</td>
    </tr>
    <tr>
      <td>{{ 'ExpiryDate' | translate }}:</td>
      <td>{{ referenceMaterialChange.expiryDate | date:dateFormat }}</td>
    </tr>
    <tr>
      <td>{{ 'NotificationDate' | translate }}</td>
      <td>{{ referenceMaterialChange.notificationDate | date:dateFormat }}</td>
    </tr>
  </table>
  <grid *ngIf="referenceMaterialChange.newReferenceMaterialCompValues" [name]="'Reference_Material_Components'" [genericDataRetrieval]="false" [headers]="headers" [exportData]="referenceMaterialChange.newReferenceMaterialCompValues" (filterEvent)="true"
        [showFilters]="false" [showHeader]="false" [showFooter]="false" [showSortArrows]="false" [title]="'Component.Values'">
    <tr *ngFor="let refMatCompValue of referenceMaterialChange.newReferenceMaterialCompValues; let i = index; let odd = odd" [class.odd]="odd">
      <td [title]="refMatCompValue.description">{{ refMatCompValue.description }}</td>
      <td [title]="refMatCompValue.symbol">{{ refMatCompValue.symbol }}</td>
      <td [title]="referenceMaterialChange.oldReferenceMaterialCompValues.length > 0 ? referenceMaterialChange.oldReferenceMaterialCompValues[i].value : ''">
        {{ referenceMaterialChange.oldReferenceMaterialCompValues.length > 0 ? referenceMaterialChange.oldReferenceMaterialCompValues[i].value : '' }}
      </td>
      <td [title]="refMatCompValue.value">{{ refMatCompValue.value }}</td>
      <td [title]="refMatCompValue.unit">{{ refMatCompValue.unit }}</td>
    </tr>
  </grid>
</ng-container>
