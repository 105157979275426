import { Component, OnInit, OnChanges, Output, Input, ContentChild, TemplateRef, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import html2canvas from 'html2canvas';

import { DataPoint } from './data-point';
import { DataSerie } from './data-serie';

@Component({
  selector: 'chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})

export class ChartComponent implements OnInit, OnChanges {
  @Input() public title = 'Chart Title';
  @Input() public allowTypeChange = true;
  @Input() public chartType = '';
  @Input() public legend: boolean;
  @Input() public xAxis: string = '';
  @Input() public yAxis: string = '';
  @Input() public customColors: any[] = null;
  @Input() public scheme: any = { domain: ['#A20F28', '#656669', '#25439A', '#11A749', '#662961', '#5A5B39', '#1DA673'] };
  @Output() public select: EventEmitter<any> = new EventEmitter();
  @Output() public selectSeries: EventEmitter<string> = new EventEmitter<string>();
  @Output() public selectChartType: EventEmitter<string> = new EventEmitter();
  @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;
  private points: boolean = false;
  private series: boolean = false;
  private hasControlLimits: boolean = false;
  private yMin: number = null;
  private yMax: number = null;

  @Input() public view;
  @Input() public dataPoints: DataPoint[];
  @Input() public barDataSeries: DataSerie[];
  @Input() public dataSeries: DataSerie[];
  @Input() public controlLimits: DataPoint[];

  ngOnInit() {
    this.refreshChart();
  }

  ngOnChanges() {
    this.refreshChart();
  }

  refreshChart() {
    this.points = this.dataPoints != null;
    this.series = this.dataSeries != null;
    this.hasControlLimits = this.controlLimits != null;
    this.yMin = null;
    this.yMax = null;
    if (this.points) {
      this.updateLimits(this.dataPoints);
    }
    if (this.series) {
      this.dataSeries.map(ds => this.updateLimits(ds.series));
    }
    if (this.hasControlLimits) {
      this.updateLimits(this.controlLimits);
    }
  }

  updateLimits(points: DataPoint[]) {
    points.map(cl => {
      if (this.yMax == null || cl.value > this.yMax) {
        this.yMax = cl.value;
      }
      if (this.yMin == null || cl.value < this.yMin) {
        this.yMin = cl.value;
      }
    });
    if (!this.yMax && !this.yMin) {
      this.yMin = 0;
      this.yMax = 1;
    }
    if (this.yMax <= this.yMin) {
      this.yMax = this.yMin + 1;
    }
  }

  xAxisFormatting(val: any): string {
    const datePipe = new DatePipe('en');
    if (val instanceof Date) {
      return datePipe.transform(<Date>val, 'MM-dd HH:mm:ss');
    } else {
      return val;
    }
  }

  exportPicture(chart: TemplateRef<any>) {
    html2canvas(chart).then(function (canvas) {
      const link = document.createElement('a');
      link.href = canvas.toDataURL();
      link.download = 'chart_export.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }
}
