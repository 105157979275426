import { Component, Input, OnInit } from '@angular/core';
import { ClaimService } from '../../../../core/claim.service';
import { ActivatedRoute, Router } from '@angular/router';

import { FidelityChart } from './fidelity-chart';
import { FidelityChartService } from './fidelity-chart.service';
import { FidelitySession } from './fidelity-session';
import { FidelitySessionService } from './fidelity-session.service';
import { DataPoint } from '../../../../shared/chart/data-point';
import { DataSerie } from '../../../../shared/chart/data-serie';
import { ErrorMessage } from '../../../../shared/error/error-message';
import { AppConfig } from '../../../../core/app.config';

@Component({
  selector: 'fidelity-chart-view',
  templateUrl: './fidelity-chart-view.component.html'
})
export class FidelityChartViewComponent implements OnInit {
  constructor(
    private fidelityChartService: FidelityChartService,
    private fidelitySessionService: FidelitySessionService,
    private claimService: ClaimService,
    private route: ActivatedRoute,
    private router: Router,
    private appConfig: AppConfig
  ) { }
  @Input() id: number;
  private fidelityChart: FidelityChart;
  public selectedFidelitySession: FidelitySession;
  private fidelitySessions: FidelitySession[];
  private chartData: DataSerie[];
  public dataChange: boolean;
  public createAccess: boolean;
  public updateAccess: boolean;
  public editDialog = false;
  private sessionDialogSession: FidelitySession;
  public sessionDialog = false;
  private args = '';
  private dateTimeFormat: string;
  public error: ErrorMessage;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.createAccess = this.claimService.checkClaimContains('aml.valsession', 'c');
    this.updateAccess = this.claimService.checkClaimContains('aml.valsession', 'u');

    if (!this.id) {
      this.id = +this.route.snapshot.paramMap.get('id');
    }

    this.selectFirstSession();
  }

  selectFirstSession() {
    this.fidelitySessionService.getFidelitySessionsByChart(this.id, '').subscribe(fidelitySessions => {
      this.fidelitySessions = fidelitySessions;
      if (fidelitySessions.length > 0) {
        this.selectedFidelitySession = fidelitySessions[0];
      }
      this.getFidelityChart();
    });
  }

  getFidelityChart() {
    this.fidelityChartService.getFidelityChart(this.id).subscribe(fidelityChart => {
      this.fidelityChart = fidelityChart;
      this.getFidelityChartData();
    }, error => this.error = error.error);
  }

  getFidelityChartData() {
    if (this.selectedFidelitySession) {
      let index = 1;
      this.chartData = this.fidelityChart.fidelityGroups.map(fg => ({
        connected: true,
        name: (index++).toString(),
        series: this.selectedFidelitySession.fidelityReadings.filter(fr => fg.fidelityComponents.some(fc => fc.componentTypeId === fr.componentTypeId))
          .map(fr => ({ name: Math.log10(fr.componentType.molWeight), params: fr.componentType, title: fr.componentType.symbol, value: Math.log10(fr.readingValue) }) as DataPoint),
        shape: 'circle'
      }) as DataSerie);
    }
  }

  addFidelitySession() {
    this.error = null;
    this.fidelitySessionService.newFidelitySession(this.fidelityChart).subscribe(fidelitySession => {
      this.sessionDialogSession = fidelitySession;
      this.sessionDialog = true;
    }, error => this.error = error.error);
  }

  backToList() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  editFidelityChart() {
    this.editDialog = true;
  }

  dialogChanged(event: boolean) {
    this.editDialog = event;
    this.getFidelityChart();
  }

  sessionDialogChanged(event: boolean) {
    this.sessionDialog = event;
    if (this.selectedFidelitySession) {
      this.getFidelityChart();
    } else {
      this.selectFirstSession();
    }
    this.dataChange = !this.dataChange; // Invoke ngOnChanges
  }

  setSelectedFidelitySession(fidelitySession: FidelitySession) {
    this.selectedFidelitySession = fidelitySession;
    this.getFidelityChartData();
  }
}
