import { Component, OnInit } from '@angular/core';

import { ImageService } from '../../image.service';
import { ReportManagementService } from './report-management.service';

@Component({
  selector: 'report-settings',
  templateUrl: './report-settings.component.html',
  styleUrls: ['./report-settings.component.css']
})

export class ReportSettingsComponent implements OnInit {
  constructor(
    private imageService: ImageService,
    private reportManagementService: ReportManagementService
  ) { }
  public title: string = 'Report.Settings';
  public reportLogo: string;
  public error: any;

  ngOnInit() {
    this.getSettings();
  }

  getSettings() {
    this.reportManagementService.getReportLogo().subscribe(logo => this.reportLogo = logo, error => this.error = error.error);
  }

  handleFileInput(files: FileList) {
    this.error = null;
    this.imageService.imageToString(files.item(0)).subscribe(image => {
      this.reportManagementService.putReportLogo(image).subscribe(response => this.getSettings(), error => this.error = error.error);
    }, error => this.error = error.error);
  }
}
