<div *ngIf="datasetProperties && editMode" id="legend">
  <div id="legend-title">{{ 'ReportType.Legend' | translate }}</div>
  <div id="legend-section"><tooltip text="ReportEditorHelp.Section" position="left"></tooltip>{{ 'ReportType.Section' | translate }}</div>
  <div id="legend-list"><tooltip text="ReportEditorHelp.DatasetList" position="left"></tooltip>{{ 'ReportType.DatasetList' | translate }}</div>
  <div id="legend-property"><tooltip text="ReportEditorHelp.DatasetProperty" position="left"></tooltip>{{ 'ReportType.DatasetProperty' | translate }}</div>
  <div></div>
  <div></div>
</div>
<div *ngIf="editMode" id="datasetProperties" class="overview-wrapper">
  <div class="overview-header">{{ 'ReportType.DatasetProperties' | translate }}</div>
  <div class="overview-content">
    <ng-container *ngTemplateOutlet="propertiesTemplate; context: { tProperties: datasetProperties }"></ng-container>
    <ng-template #propertiesTemplate let-properties="tProperties">
      <ul *ngIf="properties">
        <ng-container *ngFor="let property of properties">
          <li *ngIf="reportType.reportEndpoints" [ngClass]="{'list':property.items}" (dragstart)="onDragPropertyStart($event, property)" (dragend)="onDragPropertyEnd($event)" draggable="true" title="{{ property.title | translate }} ({{ property.type }})">{{ property.title | translate }}</li>
          <li *ngIf="!reportType.reportEndpoints" [ngClass]="{'list':property.items}" (dragstart)="onDragPropertyStart($event, property)" (dragend)="onDragPropertyEnd($event)" draggable="true" title="{{ property.title }} ({{ property.type }})">{{ property.title }}</li>
          <ng-container *ngTemplateOutlet="propertiesTemplate; context: { tProperties: property.items }"></ng-container>
        </ng-container>
      </ul>
    </ng-template>
  </div>
</div>
<div *ngIf="editMode" id="report-tab-above-main">
  <div id="report-tab-main-top" class="overview-wrapper">
    <div class="overview-header">{{ 'ReportType.Sections' | translate }}</div>
    <div class="overview-content">
      <!-- ReportSection types: pageBreak, header, detailsTable, overviewTable, overviewTableVertical, accuracyChart, precisionChart, historicalChart, text, container, footer -->
      <span class="list" (dragstart)="onDragSectionStart($event, 'container')" (dragend)="onDragSectionEnd($event)" draggable="true">
        <span class="list-section"></span><i class="material-icons">folder_open</i>{{ 'ReportType.Container' | translate }}<tooltip text="ReportEditorHelp.Container"></tooltip>
      </span>
      <span class="details-table" (dragstart)="onDragSectionStart($event, 'detailsTable')" (dragend)="onDragSectionEnd($event)" draggable="true">
        <i class="material-icons">view_list</i>{{ 'ReportType.DetailsTable' | translate }}<tooltip text="ReportEditorHelp.DetailsTable"></tooltip>
      </span>
      <span class="overview-table" (dragstart)="onDragSectionStart($event, 'overviewTable')" (dragend)="onDragSectionEnd($event)" draggable="true">
        <span class="list-section"></span><i class="material-icons">view_list</i>{{ 'ReportType.OverviewTable' | translate }}<tooltip text="ReportEditorHelp.OverviewTable"></tooltip>
      </span>
      <span class="overview-table-vertical" (dragstart)="onDragSectionStart($event, 'overviewTableVertical')" (dragend)="onDragSectionEnd($event)" draggable="true">
        <span class="list-section"></span><i class="material-icons">view_list</i>{{ 'ReportType.OverviewTableVertical' | translate }}<tooltip text="ReportEditorHelp.OverviewTableVertical"></tooltip>
      </span>
      <span class="text" (dragstart)="onDragSectionStart($event, 'text')" (dragend)="onDragSectionEnd($event)" draggable="true">
        <i class="material-icons">short_text</i>{{ 'ReportType.Text' | translate }}<tooltip text="ReportEditorHelp.Text"></tooltip>
      </span>
      <span class="page-break" (dragstart)="onDragSectionStart($event, 'pageBreak')" (dragend)="onDragSectionEnd($event)" draggable="true">
        <i class="material-icons">last_page</i>{{ 'ReportType.PageBreak' | translate }}<tooltip text="ReportEditorHelp.PageBreak"></tooltip>
      </span>
    </div>
  </div>
</div>
<div id="report-tab-main" class="" [ngClass]="{'not-in-dialog':!shownInDialog,'not-in-editmode':!editMode}">
  <div class="overview-wrapper" *ngIf="reportType">
    <div class="overview-header">{{ 'ReportType.Editor' | translate }}</div>
    <div class="overview-content">
      <ng-container *ngTemplateOutlet="sectionsTemplate; context: { tSections: reportType.reportSections, tDatapath: 'root' }"></ng-container>
      <ng-template #sectionsTemplate let-sections="tSections" let-datapath="tDatapath">
        <div *ngIf="sections !== null && sections.length">
          <ng-container *ngFor="let section of sections; let sectionIndex = index">
            <div *ngIf="editMode" #editorSectionDropZone class="editor-section-drop-zone" (dragenter)="dragEnterSectionZone($event, editorSectionDropZone, section)" (dragleave)="dragLeaveSectionZone($event, editorSectionDropZone, section)" (drop)="dropSection($event, sections, datapath, editorSectionDropZone, sectionIndex)" [ngClass]="{'highlight':section.highlightEditorSectionDropZone}"></div>

            <!-- Start of container section -->
            <div *ngIf="section.type === 'container'" #containerSection class="section container" [ngClass]="{'disabled':!section.enabled}">
              <div class="section-header">
                <span class="section-type">{{ 'ReportType.Container' | translate }}</span>
                <ng-container *ngIf="editMode">
                  <i class="material-icons" (click)="deleteSection(section)">delete</i>
                  <i class="material-icons" title="{{ 'Enable' | translate }}" *ngIf="!section.enabled" (click)="toggleSectionEnabled(section)">visibility</i>
                  <i class="material-icons" title="{{ 'Disable' | translate }}" *ngIf="section.enabled" (click)="toggleSectionEnabled(section)">visibility_off</i>
                  <i class="material-icons" title="{{ 'Reorder' | translate }}" (dragstart)="onDragEditorSectionStart($event, sectionIndex, sections, containerSection)" (dragend)="onDragEditorSectionEnd($event)" draggable="true">reorder</i>
                </ng-container>
              </div>
              <div *ngIf="!section.enabled" class="section-disabled">{{ 'ReportType.DisabledSection' | translate }}</div>
              <div *ngIf="editMode" class="section-title" #editorSectionHeaderZone (dragenter)="dragEnterEditorSection($event, editorSectionHeaderZone, section)" (dragleave)="dragLeaveEditorSection($event, editorSectionHeaderZone, section)" (drop)="dropEditorSection($event, sections, editorSectionHeaderZone, sectionIndex)">
                <input type="text" name="section{{ sectionIndex }}title{{ i }}_{{ section.order }}" [(ngModel)]="section.title" placeholder="{{ 'Title' | translate }}" />
              </div>
              <div *ngIf="!editMode" class="section-title"><b>{{ section.title }}</b></div>
              <div *ngIf="!section.dataTable && editMode" #detailsListDropZone class="drop-zone list-zone" (drop)="dropDatasetList($event, section, detailsListDropZone)" (dragenter)="dragEnterListZone($event, section, detailsListDropZone)" (dragleave)="dragLeaveListZone($event, section, detailsListDropZone)" [ngClass]="{'highlight':section.highlightListDropZone}">
                {{ 'ReportType.DragDatasetListToHere' | translate }}
              </div>
              <ng-container *ngIf="section.dataTable">
                <div class="section-dataset-list">{{ 'ReportType.DatasetList' | translate }}: <span>{{ section.dataTable }}</span></div>
                <ng-container *ngTemplateOutlet="sectionsTemplate; context: { tSections: section.sectionChildren, tDatapath: section.dataPath }"></ng-container>
              </ng-container>
            </div>
            <!-- End of container section -->

            <!-- Start of details table section -->
            <div *ngIf="section.type === 'detailsTable'" #detailsTableSection class="section" [ngClass]="{'disabled':!section.enabled}">
              <div class="section-header">
                <span class="section-type">{{ 'ReportType.DetailsTable' | translate }}</span>
                <ng-container *ngIf="editMode">
                  <i class="material-icons" (click)="deleteSection(section)">delete</i>
                  <i class="material-icons" title="{{ 'Enable' | translate }}" *ngIf="!section.enabled" (click)="toggleSectionEnabled(section)">visibility</i>
                  <i class="material-icons" title="{{ 'Disable' | translate }}" *ngIf="section.enabled" (click)="toggleSectionEnabled(section)">visibility_off</i>
                  <i class="material-icons" title="{{ 'Reorder' | translate }}" (dragstart)="onDragEditorSectionStart($event, sectionIndex, sections, detailsTableSection)" (dragend)="onDragEditorSectionEnd($event, detailsTableSection)" draggable="true">reorder</i>
                </ng-container>
              </div>
              <div *ngIf="!section.enabled" class="section-disabled">{{ 'ReportType.DisabledSection' | translate }}</div>
              <div *ngIf="editMode" class="section-title" #editorSectionHeaderZone (dragenter)="dragEnterEditorSection($event, editorSectionHeaderZone, section)" (dragleave)="dragLeaveEditorSection($event, editorSectionHeaderZone, section)" (drop)="dropEditorSection($event, sections, editorSectionHeaderZone, sectionIndex)">
                <input type="text" name="section{{ sectionIndex }}title{{ i }}_{{ section.order }}" [(ngModel)]="section.title" placeholder="{{ 'Title' | translate }}" />
              </div>
              <div *ngIf="!editMode" class="section-title"><b>{{ section.title }}</b></div>
              <table #detailsTable>
                <tr *ngFor="let contentRow of section.content; let i = index" #detailsRow (drop)="dropColumn($event, i, section)" (dragenter)="dragEnterColumn($event, detailsRow)" (dragleave)="dragLeaveColumn($event, detailsRow)">
                  <td><i *ngIf="editMode" (dragstart)="onDragColumnStart($event, i, section)" (dragend)="onDragColumnEnd($event, detailsTable)" draggable="true" class="material-icons" title="{{ 'Reorder' | translate }}">reorder</i></td>
                  <td *ngIf="editMode"><input type="text" name="section{{ sectionIndex }}header{{ i }}{{ contentRow.content }}" [(ngModel)]="section.headers[i].header" /></td>
                  <td *ngIf="!editMode">{{ section.headers[i].header }}</td>
                  <td>&nbsp;&nbsp;:&nbsp;&nbsp;&lt;{{ contentRow.content }}&gt;</td>
                  <td><i *ngIf="editMode" class="material-icons" title="{{ 'Delete' | translate }}" (click)="deleteProperty(i, section)">delete_forever</i></td>
                </tr>
                <tr *ngIf="editMode">
                  <td colspan="4" class="new-row">
                    <div #detailsPropertyDropZone (drop)="dropProperty($event, section, detailsPropertyDropZone)" (dragenter)="dragEnterPropertyZone($event, section, detailsPropertyDropZone)" (dragleave)="dragLeavePropertyZone($event, section, detailsPropertyDropZone)" class="property-zone" [ngClass]="{'highlight':section.highlightPropertyDropZone}">
                      {{ 'ReportType.DragPropertyToHere' | translate }}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <!-- End of details table section -->

            <!-- Start of horizontal overview table section -->
            <div *ngIf="section.type === 'overviewTable'" #overviewTableSection class="section overview-table" [ngClass]="{'disabled':!section.enabled}">
              <div class="section-header">
                <span class="section-type">{{ 'ReportType.OverviewTable' | translate }}</span>
                <ng-container *ngIf="editMode">
                  <i class="material-icons" (click)="deleteSection(section)">delete</i>
                  <i class="material-icons" title="{{ 'Enable' | translate }}" *ngIf="!section.enabled" (click)="toggleSectionEnabled(section)">visibility</i>
                  <i class="material-icons" title="{{ 'Disable' | translate }}" *ngIf="section.enabled" (click)="toggleSectionEnabled(section)">visibility_off</i>
                  <i class="material-icons" title="{{ 'Reorder' | translate }}" (dragstart)="onDragEditorSectionStart($event, sectionIndex, sections, overviewTableSection)" (dragend)="onDragEditorSectionEnd($event, overviewTableSection)" draggable="true">reorder</i>
                </ng-container>
              </div>
              <div *ngIf="!section.enabled" class="section-disabled">{{ 'ReportType.DisabledSection' | translate }}</div>
              <div *ngIf="editMode" class="section-title" #editorSectionHeaderZone (dragenter)="dragEnterEditorSection($event, editorSectionHeaderZone, section)" (dragleave)="dragLeaveEditorSection($event, editorSectionHeaderZone, section)" (drop)="dropEditorSection($event, sections, editorSectionHeaderZone, sectionIndex)">
                <input type="text" name="section{{ sectionIndex }}title{{ i }}_{{ section.order }}" [(ngModel)]="section.title" placeholder="{{ 'Title' | translate }}" />
              </div>
              <div *ngIf="!editMode" class="section-title"><b>{{ section.title }}</b></div>
              <div *ngIf="!section.dataTable" #overviewListDropZone class="drop-zone list-zone" (drop)="dropDatasetList($event, section, overviewListDropZone)" (dragenter)="dragEnterListZone($event, section, overviewListDropZone)" (dragleave)="dragLeaveListZone($event, section, overviewListDropZone)" [ngClass]="{'highlight':section.highlightListDropZone}">
                {{ 'ReportType.DragDatasetListToHere' | translate }}
              </div>
              <ng-container *ngIf="section.dataTable">
                <div class="section-dataset-list">{{ 'ReportType.DatasetList' | translate }}: <span>{{ section.dataTable }}</span></div>
                <table #overviewTable>
                  <thead>
                    <tr>
                      <td *ngFor="let contentColumn of section.content; let i = index" #overviewTableRow (drop)="dropColumn($event, i, section)" (dragenter)="dragEnterColumn($event, overviewTableRow)" (dragleave)="dragLeaveColumn($event, overviewTableRow)">
                        <i *ngIf="editMode" (dragstart)="onDragColumnStart($event, i, section)" (dragend)="onDragColumnEnd($event, overviewTable)" draggable="true" class="material-icons" title="{{ 'Reorder' | translate }}">reorder</i>
                        <i *ngIf="editMode" class="material-icons" title="{{ 'Delete' | translate }}" (click)="deleteProperty(i, section)">delete_forever</i>
                      </td>
                    </tr>
                    <tr>
                      <td *ngFor="let header of section.headers; let i = index">
                        <input *ngIf="editMode" type="text" name="section{{ sectionIndex }}header{{ i }}{{ section.order }}" [(ngModel)]="header.header" />
                        <ng-container *ngIf="!editMode">{{ header.header }}</ng-container>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td *ngFor="let contentColumn of section.content; let i = index">&lt;{{ contentColumn.content }}&gt;</td>
                    </tr>
                    <tr *ngIf="editMode">
                      <td [colSpan]="section.content.length" class="new-row">
                        <div #overviewPropertyDropZone (drop)="dropProperty($event, section, overviewPropertyDropZone)" (dragenter)="dragEnterPropertyZone($event, section, overviewPropertyDropZone)" (dragleave)="dragLeavePropertyZone($event, section, overviewPropertyDropZone)" class="property-zone" [ngClass]="{'highlight':section.highlightPropertyDropZone}">
                          {{ 'ReportType.DragPropertyToHere' | translate }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </div>
            <!-- End of horizontal overview table section -->

            <!-- Start of vertical overview table section -->
            <div *ngIf="section.type === 'overviewTableVertical'" #overviewTableVerticalSection class="section overview-table-vertical" [ngClass]="{'disabled':!section.enabled}">
              <div class="section-header">
                <span class="section-type">{{ 'ReportType.OverviewTableVertical' | translate }}</span>
                <ng-container *ngIf="editMode">
                  <i class="material-icons" (click)="deleteSection(section)">delete</i>
                  <i class="material-icons" title="{{ 'Enable' | translate }}" *ngIf="!section.enabled" (click)="toggleSectionEnabled(section)">visibility</i>
                  <i class="material-icons" title="{{ 'Disable' | translate }}" *ngIf="section.enabled" (click)="toggleSectionEnabled(section)">visibility_off</i>
                  <i class="material-icons" title="{{ 'Reorder' | translate }}" (dragstart)="onDragEditorSectionStart($event, sectionIndex, sections, overviewTableVerticalSection)" (dragend)="onDragEditorSectionEnd($event, overviewTableVerticalSection)" draggable="true">reorder</i>
                </ng-container>
              </div>
              <div *ngIf="!section.enabled" class="section-disabled">{{ 'ReportType.DisabledSection' | translate }}</div>
              <div *ngIf="editMode" class="section-title" #editorSectionHeaderZone (dragenter)="dragEnterEditorSection($event, editorSectionHeaderZone, section)" (dragleave)="dragLeaveEditorSection($event, editorSectionHeaderZone, section)" (drop)="dropEditorSection($event, sections, editorSectionHeaderZone, sectionIndex)">
                <input type="text" name="section{{ sectionIndex }}title{{ i }}_{{ section.order }}" [(ngModel)]="section.title" placeholder="{{ 'Title' | translate }}" />
              </div>
              <div *ngIf="!editMode" class="section-title"><b>{{ section.title }}</b></div>
              <div *ngIf="!section.dataTable" #overviewListDropZone class="drop-zone list-zone" (drop)="dropDatasetList($event, section, overviewListDropZone)" (dragenter)="dragEnterListZone($event, section, overviewListDropZone)" (dragleave)="dragLeaveListZone($event, section, overviewListDropZone)" [ngClass]="{'highlight':section.highlightListDropZone}">
                {{ 'ReportType.DragDatasetListToHere' | translate }}
              </div>
              <ng-container *ngIf="section.dataTable">
                <div class="section-dataset-list">{{ 'ReportType.DatasetList' | translate }}: <span>{{ section.dataTable }}</span></div>
                <table #overviewTableVertical>
                  <tr *ngFor="let contentRow of section.content; let i = index" #overviewTableVerticalRow (drop)="dropColumn($event, i, section)" (dragenter)="dragEnterColumn($event, overviewTableVerticalRow)" (dragleave)="dragLeaveColumn($event, overviewTableVerticalRow)">
                    <td><i *ngIf="editMode" (dragstart)="onDragColumnStart($event, i, section)" (dragend)="onDragColumnEnd($event, overviewTableVertical)" draggable="true" class="material-icons" title="{{ 'Reorder' | translate }}">reorder</i></td>
                    <td *ngIf="editMode"><input type="text" name="section{{ sectionIndex }}header{{ i }}{{ contentRow.content }}" [(ngModel)]="section.headers[i].header" /></td>
                    <td *ngIf="!editMode">{{ section.headers[i].header }}</td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&lt;{{ contentRow.content }}&gt;</td>
                    <td><i *ngIf="editMode" class="material-icons" title="{{ 'Delete' | translate }}" (click)="deleteProperty(i, section)">delete_forever</i></td>
                  </tr>
                  <tr *ngIf="editMode">
                    <td colspan="4" class="new-row">
                      <div #overviewPropertyDropZone (drop)="dropProperty($event, section, overviewPropertyDropZone)" (dragenter)="dragEnterPropertyZone($event, section, overviewPropertyDropZone)" (dragleave)="dragLeavePropertyZone($event, section, overviewPropertyDropZone)" class="property-zone" [ngClass]="{'highlight':section.highlightPropertyDropZone}">
                        {{ 'ReportType.DragPropertyToHere' | translate }}
                      </div>
                    </td>
                  </tr>
                </table>
              </ng-container>
            </div>
            <!-- End of vertical overview table section -->

            <!-- Start of text section -->
            <div *ngIf="section.type === 'text'" #textSection class="section text" [ngClass]="{'disabled':!section.enabled}">
              <div class="section-header">
                <span class="section-type">{{ 'ReportType.Text' | translate }}</span>
                <ng-container *ngIf="editMode">
                  <i class="material-icons" (click)="deleteSection(section)">delete</i>
                  <i class="material-icons" title="{{ 'Enable' | translate }}" *ngIf="!section.enabled" (click)="toggleSectionEnabled(section)">visibility</i>
                  <i class="material-icons" title="{{ 'Disable' | translate }}" *ngIf="section.enabled" (click)="toggleSectionEnabled(section)">visibility_off</i>
                  <i class="material-icons" title="{{ 'Reorder' | translate }}" (dragstart)="onDragEditorSectionStart($event, sectionIndex, sections, textSection)" (dragend)="onDragEditorSectionEnd($event, textSection)" draggable="true">reorder</i>
                </ng-container>
              </div>
              <div *ngIf="!section.enabled" class="section-disabled">{{ 'ReportType.DisabledSection' | translate }}</div>
              <div *ngIf="editMode" class="section-title" #editorSectionHeaderZone (dragenter)="dragEnterEditorSection($event, editorSectionHeaderZone, section)" (dragleave)="dragLeaveEditorSection($event, editorSectionHeaderZone, section)" (drop)="dropEditorSection($event, sections, editorSectionHeaderZone, sectionIndex)">
                <input type="text" name="section{{ sectionIndex }}title{{ i }}_{{ section.order }}" [(ngModel)]="section.title" placeholder="{{ 'Title' | translate }}" />
              </div>
              <div *ngIf="!editMode" class="section-title"><b>{{ section.title }}</b></div>
              <div *ngIf="editMode"><textarea type="text" name="section{{ sectionIndex }}content{{ i }}_{{ section.order }}" [(ngModel)]="section.content[0].content" placeholder="{{ 'Text' | translate }}"></textarea></div>
              <div *ngIf="!editMode">section.content[0].content</div>
            </div>
            <!-- End of text section -->

            <!-- Start of page break section -->
            <div *ngIf="section.type === 'pageBreak'" #pageBreakSection class="section" [ngClass]="{'disabled':!section.enabled}">
              <div class="section-header">
                <span class="section-type">{{ 'ReportType.PageBreak' | translate }}</span>
                <ng-container *ngIf="editMode">
                  <i class="material-icons" (click)="deleteSection(section)">delete</i>
                  <i class="material-icons" title="{{ 'Enable' | translate }}" *ngIf="!section.enabled" (click)="toggleSectionEnabled(section)">visibility</i>
                  <i class="material-icons" title="{{ 'Disable' | translate }}" *ngIf="section.enabled" (click)="toggleSectionEnabled(section)">visibility_off</i>
                  <i class="material-icons" title="{{ 'Reorder' | translate }}" (dragstart)="onDragEditorSectionStart($event, sectionIndex, sections, pageBreakSection)" (dragend)="onDragEditorSectionEnd($event, pageBreakSection)" draggable="true">reorder</i>
                </ng-container>
              </div>
              <div *ngIf="!section.enabled" class="section-disabled">{{ 'ReportType.DisabledSection' | translate }}</div>
              <div><b>{{ 'ReportType.PageBreak' | translate }}</b></div>
            </div>
            <!-- End of page break section -->

          </ng-container>
        </div>
        <div *ngIf="editMode" #sectionDropZone class="drop-zone section-zone" (drop)="dropSection($event, sections, datapath, sectionDropZone, null)" (dragenter)="dragEnterSectionZone($event, sectionDropZone, section)" (dragleave)="dragLeaveSectionZone($event, sectionDropZone, section)">
          {{ 'ReportType.DragSectionToHere' | translate }}
        </div>
      </ng-template>
    </div>
  </div>
</div>
<delete-dialog [visible]="deleteSectionDialog" [error]="error" (confirmDelete)="confirmDeleteSection($event)"></delete-dialog>
