<div *ngIf="loggedIn" id="app-component" [ngClass]="sidebarVisibilityClassForSmallScreens" (dragover)="allowDrop($event)">
  <header>
    <a routerLink=""><img src="/assets/img/Hint_Logo_White.png" alt="Hint logo" id="logo" /></a>
    {{ title | translate }}
    <button *ngIf="eventAccess && false" id="events-button" class="no-print" routerLink="/event">{{ 'Events' | translate }}</button>
    <i *ngIf="eventAccess && false" id="events-icon" class="material-icons no-print" routerLink="/event">notifications</i>
  </header>
  <nav>
    <div id="sidebar-toggle" class="no-big-screen no-print">
      <i id="sidebar-toggle-button" class="material-icons" (click)="toggleSidebar()">menu</i>
    </div>
    <breadcrumb></breadcrumb>
    <div id="tools" class="no-print">
      <span class="no-small-screen">
        <language-select></language-select>
        <help></help>
        <a class="no-small-screen" routerLink="/about">{{ 'aml.About' | translate }}</a>
        <a (click)="print()" class="no-small-screen">{{ 'Print' | translate }}</a>
        <a (click)="logout()">{{ 'Logout' | translate }}</a>
      </span>
      <span class="no-big-screen">
        <i class="material-icons" routerLink="/help">help_outline</i>
        <i class="material-icons" routerLink="/about">info_outline</i>
        <i class="material-icons" (click)="logout()">account_circle</i>
      </span>
      <search></search>
    </div>
  </nav>
  <sidebar (toggle)="toggleSidebar()">
    <router-outlet></router-outlet>
  </sidebar>
  <footer>
    &copy;2019-2021 Hint Holding B.V. {{ 'AllRightsReserved' | translate }}.
  </footer>
</div>
