import { NgModule } from '@angular/core';

import { SharedModule } from '../../../../shared/shared.module';

import { FidelityChartViewChartComponent } from './fidelity-chart-view-chart.component';
import { FidelityChartService } from './fidelity-chart.service';
import { FidelitySessionService } from './fidelity-session.service';

@NgModule({
  declarations: [
    FidelityChartViewChartComponent
  ],
  exports: [
    FidelityChartViewChartComponent
  ],
  imports: [
    SharedModule
  ],
  providers: [
    FidelityChartService,
    FidelitySessionService
  ]
})
export class FidelityChartModule { }
