import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { AreaOverviewComponent } from './area-overview.component';
import { AreaService } from '../area.service';
import { IoServerService } from '../../io-server/io-server.service';

@NgModule({
  declarations: [
    AreaOverviewComponent
  ],
  exports: [
    AreaOverviewComponent
  ],
  imports: [
    SharedModule
  ],
  providers: [
    AreaService,
    IoServerService
  ]
})
export class AreaOverviewModule { }
