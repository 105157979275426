import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, SubscriptionLike as ISubscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import html2canvas from 'html2canvas';

import { ReportViewService } from './report-view.service';
import { ReportViewPageService } from './report-view-page.service';
import { Report } from './report';
import { ReportType } from './report-type';
import { ReportSection } from './report-section';
import { ReportDatasetData } from './report-dataset-data';
import { ErrorMessage } from '../../shared/error/error-message';
import { AppConfig } from '../../core/app.config';

@Component({
  selector: 'report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view-page.css', './report-view.component.css']
})

export class ReportViewComponent implements OnInit, OnDestroy {
  constructor(
    private reportViewService: ReportViewService,
    private reportViewPageService: ReportViewPageService,
    private route: ActivatedRoute,
    private router: Router,
    private appConfig: AppConfig,
    private elRef: ElementRef
  ) { }
  @Input() public report: Report = null;
  @Output() public loaded = new EventEmitter<number>();
  @Output() public dataDateTime = new EventEmitter<string>();
  public reportType: ReportType = null;
  public reportPages: ReportSection[][] = null; //Array containing multiple report sections, every page has its own ReportSection[].
  public pageWidth: number;
  public pageHeight: number;
  public reportDataset: ReportDatasetData = null;
  public selectedDatasetId: number = null;
  public title = 'Report';
  public subTitle = 'AML';
  private id = -1;
  public pageNumber: number = 0;
  public pageCount: number = null;
  public datasetLoaded: boolean = false;
  public reportLoaded: boolean = false;
  public generatedPageImages: boolean = false;
  public generatedPageImagesCount: number = 0;
  public reportNotAvailable: boolean = false;
  private routeSubscription: ISubscription;
  public error: ErrorMessage;
  private dateTimeFormat: string;
  private dateFormat: string;
  private logo: string;
  public viewSize: string = 'normal';
  Array = Array;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.dateFormat = this.appConfig.getConfig('dateFormat');
    this.error = null;
    this.loaded.emit(-1);
    this.id = +this.route.snapshot.paramMap.get('reportId');
    let datasetId = +this.route.snapshot.paramMap.get('datasetId');
    this.selectedDatasetId = (datasetId !== null && datasetId > 0) ? datasetId : null;
    this.getReport();
    this.reportViewService.getReportLogo().subscribe(logo => this.logo = logo);

    this.routeSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(path => {
      datasetId = +this.route.snapshot.paramMap.get('datasetId');
      this.selectedDatasetId = (datasetId !== null && datasetId > 0) ? datasetId : null;
      this.getReport();
    });
  }

  ngOnDestroy() {
    if (this.routeSubscription) { this.routeSubscription.unsubscribe(); }
  }

  getReport() {
    this.error = null;
    this.reportNotAvailable = false;
    this.reportType = null;
    this.pageCount = null;
    this.pageNumber = 0;
    this.generatedPageImages = false;
    this.generatedPageImagesCount = 0;

    if (this.id > 0) {
      this.reportViewService.getReport(this.id).subscribe(report => {
        this.report = report;

        if (report !== null) {
          this.reportViewService.getReportType(this.report.reportType).subscribe(type => {
            this.reportType = type;
            this.getPageOrientation();

            if (this.reportType.reportEndpoints !== undefined && this.reportType.reportEndpoints !== null && this.reportType.reportEndpoints.length > 0) {
              this.reportViewService.getReportData(this.report.id).subscribe(dataset => {
                this.getReportPages(dataset, type.id);
              }, error => {
                this.datasetLoaded = true;
                this.reportLoaded = true;
                this.reportNotAvailable = true;
                this.error = error.error;
              });
            } else {
              this.reportViewService.getReportDataset(this.report.id, this.selectedDatasetId).subscribe(dataset => {
                this.getReportPages(dataset, type.id);
              }, error => {
                this.datasetLoaded = true;
                this.reportLoaded = true;
                this.reportNotAvailable = true;
                this.error = error.error;
              });
            }
          }, error => {
            this.datasetLoaded = true;
            this.reportLoaded = true;
            this.error = error.error;
          });
        }
      }, error => this.reportNotAvailable = true);
    }
  }

  getReportPages(dataset: ReportDatasetData, reportTypeId: number) {
    this.reportDataset = dataset;
    this.dataDateTime.emit(dataset.dataDateTime);
    const sections = this.reportViewService.setReportSectionsData(this.reportType.reportSections, dataset);

    if (sections !== null && sections.length > 0) {
      this.reportPages = this.reportViewPageService.getReportPages(sections, this.reportType.pageOrientation);
      this.pageCount = (this.reportPages.length !== null) ? this.reportPages.length : null;
    }

    this.datasetLoaded = true;

    if (sections === null) {
      this.reportLoaded = true;
      this.reportNotAvailable = true;
      return;
    }

    this.waitForReportLoading().then(() => {
      this.reportLoaded = true;
      this.setPageOrientation(true);

      this.waitForGeneratedPageImages().then(() => {
        this.loaded.emit(this.pageCount);
      });
    });
  }

  async initializeChart(section: ReportSection, reportElement) {
    if (!section.loaded && reportElement !== null) {
      await this.wait(1000);
      section.loaded = true;
    }
  }

  async waitForReportLoading() {
    for (let i = 0; i < 300; i++) {
      await this.wait(100);
      if (this.reportPages === null || this.reportPages.length === undefined || this.reportPages.length === 0) {
        break;
      }

      let loaded = true;
      for (let j = 0; j < this.reportPages.length; j++) {
        if (this.reportPages[j] !== null && this.reportPages[j].length > 0 && this.reportPages[j].some(section => !section.loaded)) {
          loaded = false;
        }
      }

      if (loaded) {
        break;
      }
    }
  }

  async waitForGeneratedPageImages() {
    for (let i = 0; i < 1200; i++) {
      await this.wait(100);
      if ((this.reportLoaded && (this.reportPages === null || this.reportPages.length === 0 || this.reportPages.length > 100))
        || (this.reportPages !== null && this.reportPages.length !== undefined && this.reportPages.length === this.generatedPageImagesCount)) {
        await this.wait(250);
        this.generatedPageImages = true;
        break;
      }
      if (i === 1200) {
        this.generatedPageImages = true;
      }
    }
  }

  wait(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  getPageOrientation() {
    if (this.reportType !== undefined && this.reportType !== null) {
      this.pageWidth = this.reportViewPageService.getPageWidth(this.reportType.pageOrientation);
      this.pageHeight = this.reportViewPageService.getPageHeight(this.reportType.pageOrientation);
    }
  }

  async setPageOrientation(generatePageImages: boolean) {
    if (this.pageWidth !== undefined && this.pageWidth !== null && this.pageHeight !== undefined && this.pageHeight !== null) {
      const reportElements = this.elRef.nativeElement.getElementsByClassName('report-page');
      const reportExportPages = this.elRef.nativeElement.getElementsByClassName('report-export-pages');

      if (reportElements !== null && reportElements.length > 0) {
        for (let i = 0; i < reportElements.length; i++) {
          const page = <HTMLElement>reportElements[i];
          page.style.setProperty('width', this.pageWidth + 'px');
          page.style.setProperty('height', this.pageHeight + 'px');
        }

        await this.wait(500);
        if (generatePageImages && reportElements.length <= 100) {
          //The page images won't be generated if there are more than 100 pages.
          for (let i = 0; i < reportElements.length; i++) {
            await this.wait(100);
            const page = <HTMLElement>reportElements[i];
            await this.generatePageImages(page, reportExportPages[0]).subscribe(response => this.generatedPageImagesCount++);
          }
        }
      }
    }
  }

  setViewSize(size: string) {
    this.viewSize = size;
    const reportElements = this.elRef.nativeElement.getElementsByClassName('report-page');
    const contentElements = this.elRef.nativeElement.getElementsByClassName('report-page-content');

    if (reportElements !== null && reportElements.length > 0) {
      for (let i = 0; i < reportElements.length; i++) {
        const page = <HTMLElement>reportElements[i];
        const content = <HTMLElement>contentElements[i];
        if (size === 'compact') {
          page.style.setProperty('height', 'initial');
          content.style.setProperty('margin', '15px', 'important');
          page.className = 'report-page compact';
        }
        if (size === 'normal') {
          this.setPageOrientation(false);
          content.style.setProperty('margin', 'initial');
          page.className = 'report-page';
        }
      }
    }
  }

  generatePageImages(reportPage, reportExportPages): Observable<void> {
    return new Observable(observer => {
      if (reportPage !== null && reportExportPages !== null) {
        html2canvas(reportPage, <any>{ windowWidth: this.pageWidth, windowHeight: this.pageHeight, logging: false }).then(function (canvas) {
          const dataUrl = canvas.toDataURL();
          const img = new Image();
          img.src = dataUrl;
          reportExportPages.appendChild(img);
          observer.next();
          observer.complete();
        }, error => observer.error());
      }
    });
  }
}
