import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../../core/app.config';
import { CustomLayoutSection } from './custom-layout-section';
import { CustomLayoutSectionType } from './custom-layout-section-type';
import { ClaimService } from '../../core/claim.service';

@Injectable({
  providedIn: 'root'
})
export class CustomLayoutService {
  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private appConfig: AppConfig,
    private claimService: ClaimService
  ) {
    this.apiHost = this.appConfig.getConfig('apiHost');
    this.initialize();

    const areaAccess = this.claimService.checkClaim('scope', 'area') && (this.claimService.checkClaimContains('aml.area', 'r'));
    const valsessionAccess = this.claimService.checkClaim('scope', 'valsession') && (this.claimService.checkClaimContains('aml.valsession', 'r'));
    this.sectionTypes = [];
    if (areaAccess) {
      this.sectionTypes.push(
        { name: 'Area.Details', type: 'areaOverview', home: false, area: true },
        { name: 'Favorite.Favorites', type: 'favorites', home: true, area: false },
        { name: 'PerformanceIndicator.PerformanceIndicators', type: 'perfIndic', home: true, area: true },
        { name: 'Area.Status', type: 'areaLayout', home: true, area: true },
        { name: 'aml.ioStatus', type: 'ioStatus', home: true, area: false },
        { name: 'BadActors', type: 'badActors', home: true, area: true }
      );
    }
    this.sectionTypes.push({ name: 'Overview', type: 'table', home: true, area: true });
    if (valsessionAccess) {
      this.sectionTypes.push({ name: 'Chart.Chart', type: 'chart', home: true, area: false });
    }
  }
  private apiHost: string;
  private initialized = false;
  private sectionTypes: CustomLayoutSectionType[];
  private sections: CustomLayoutSection[];
  private defaultSections: CustomLayoutSection[] = [
    { id: -1, name: this.translate.instant('Favorites'), type: 'favorites', screen: 'home', sequenceNumber: 0, collapsed: false, endpoint: null, informationLevelId: null, activeFilters: [], chartType: null, chartId: null },
    { id: -1, name: this.translate.instant('Area.Details'), type: 'areaOverview', screen: 'area', sequenceNumber: 0, collapsed: false, endpoint: null, informationLevelId: null, activeFilters: [], chartType: null, chartId: null },
    { id: -1, name: this.translate.instant('Area.Status'), type: 'areaLayout', screen: 'area', sequenceNumber: 0, collapsed: false, endpoint: null, informationLevelId: null, activeFilters: [], chartType: null, chartId: null },
    { id: -1, name: this.translate.instant('PerformanceIndicator.PerformanceIndicators'), type: 'perfIndic', screen: 'area', sequenceNumber: 0, collapsed: false, endpoint: null, informationLevelId: null, activeFilters: [], chartType: null, chartId: null },
    { id: -1, name: this.translate.instant('BadActors'), type: 'badActors', screen: 'area', sequenceNumber: 0, collapsed: false, endpoint: null, informationLevelId: null, activeFilters: [], chartType: null, chartId: null }
  ];
  public sectionsChanged = new EventEmitter<void>();

  public initialize() {
    this.http.get<CustomLayoutSection[]>(this.apiHost + 'customlayoutsection/get').subscribe(sections => {
      if ((sections && sections.length > 0) || this.initialized) {
        this.sections = sections;
      } else {
        this.sections = this.defaultSections;
        this.sections.forEach(section => section.name = this.translate.instant(section.name));
      }
      this.initialized = true;
      this.sectionsChanged.emit();
    });
  }

  public getSectionTypes(screen: string): CustomLayoutSectionType[] {
    switch (screen) {
      case 'home':
        return this.sectionTypes.filter(type => type.home);
      case 'area':
        return this.sectionTypes.filter(type => type.area);
      default:
        return [];
    }
  }

  public getSections(screen: string): CustomLayoutSection[] {
    return (this.sections) ? this.sections.filter(section => section.screen === screen) : null;
  }

  public postSection(section: CustomLayoutSection) {
    this.http.post(this.apiHost + 'customlayoutsection/post', section).subscribe(() => this.initialize());
  }

  public putSection(section: CustomLayoutSection) {
    this.http.put(this.apiHost + 'customlayoutsection/put', section).subscribe(() => this.sectionsChanged.emit());
  }

  public putSections(sections: CustomLayoutSection[]) {
    this.sections = this.sections.filter(section => section.screen !== sections[0].screen);
    sections.forEach(section => this.sections.push(section));
    this.http.post(this.apiHost + 'customlayoutsection/addorupdaterange', this.sections).subscribe(() => this.initialize());
  }

  public deleteSection(section: CustomLayoutSection) {
    this.http.delete(this.apiHost + 'customlayoutsection/delete/' + section.id).subscribe(() => this.initialize());
  }

  public newSection(name: string, type: string, screen: string, sequenceNumber = 0): CustomLayoutSection {
    return { id: -1, name, type, screen, sequenceNumber, collapsed: false, endpoint: null, informationLevelId: null, activeFilters: [], chartType: null, chartId: null };
  }

  public setLayout(layoutClass: string) {
    localStorage.setItem('aml:layout', layoutClass);
  }

  public getLayout() {
    return localStorage.getItem('aml:layout');
  }
}
