import { NgModule } from '@angular/core';

import { SharedModule } from '../shared.module';

import { NoteComponent } from './note.component';
import { NoteService } from './note.service';

@NgModule({
  declarations: [
    NoteComponent
  ],
  exports: [
    NoteComponent
  ],
  imports: [
    SharedModule
  ],
  providers: [
    NoteService
  ]
})
export class NoteModule { }
