import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AppConfig } from '../../../../../core/app.config';
import { ValidationData } from './validation-data';

@Injectable()
export class ValidationDataService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  private valDataCache: Map<string, Observable<ValidationData[]>> = new Map();
  private valCountCache: Map<string, Observable<number>> = new Map();
  private valPointCache: Map<string, Observable<ValidationData>> = new Map();

  public getValidationDataByControlChartSet(filterArguments: string, controlChartSetId: number, force: boolean = false): Observable<ValidationData[]> {
    if (force || !this.valDataCache.has(filterArguments + controlChartSetId)) {
      this.valDataCache.set(filterArguments + controlChartSetId, this.http.get<ValidationData[]>(this.apiHost + 'validationdata/getbycontrolchartset/' + controlChartSetId + filterArguments).pipe(shareReplay()));
      setTimeout(() => this.valDataCache.delete(filterArguments + controlChartSetId), 15000);
    }
    return this.valDataCache.get(filterArguments + controlChartSetId);
  }

  public countValidationDataByControlChartSet(filterArguments: string, controlChartSetId: number, force: boolean = false): Observable<number> {
    if (force || !this.valCountCache.has(filterArguments + controlChartSetId)) {
      this.valCountCache.set(filterArguments + controlChartSetId, this.http.get<number>(this.apiHost + 'validationdata/countbycontrolchartset/' + controlChartSetId + filterArguments).pipe(shareReplay()));
      setTimeout(() => this.valCountCache.delete(filterArguments + controlChartSetId), 15000);
    }
    return this.valCountCache.get(filterArguments + controlChartSetId);
  }

  public getValidationDataPoint(controlChartSetId: number, validationSessionId: number, force: boolean = false): Observable<ValidationData> {
    if (force || !this.valPointCache.has(controlChartSetId + ';' + validationSessionId)) {
      this.valPointCache.set(controlChartSetId + ';' + validationSessionId, this.http.get<ValidationData>(this.apiHost + 'validationdata/get/' + controlChartSetId + '?validationsessionid=' + validationSessionId).pipe(shareReplay()));
      setTimeout(() => this.valPointCache.delete(controlChartSetId + ';' + validationSessionId), 15000);
    }
    return this.valPointCache.get(controlChartSetId + ';' + validationSessionId);
  }
}
