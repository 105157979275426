import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../../../../core/app.config';
import { FidelityChart } from './fidelity-chart';
import { FidelitySession } from './fidelity-session';
import { FidelityReading } from './fidelity-reading';
import { CompService } from '../../stream/comp/comp.service';

@Injectable()
export class FidelitySessionService {
  constructor(
    private appConfig: AppConfig,
    private http: HttpClient,
    private compService: CompService
  ) {
    this.apiHost = this.appConfig.getConfig('apiHost');
  }
  private apiHost: string;

  public getFidelitySessions(args: string): Observable<FidelitySession[]> { return this.http.get<FidelitySession[]>(this.apiHost + 'fidelitysession/get' + args); }

  public getFidelitySessionsByInstrument(instrumentId: number, args: string): Observable<FidelitySession[]> { return this.http.get<FidelitySession[]>(this.apiHost + 'fidelitysession/getbyinstrument/' + instrumentId + args); }

  public getFidelitySessionsByChart(chartId: number, args: string): Observable<FidelitySession[]> { return this.http.get<FidelitySession[]>(this.apiHost + 'fidelitysession/getbychart/' + chartId + args); }

  public getFidelitySessionCount(args: string): Observable<number> { return this.http.get<number>(this.apiHost + 'fidelitysession/count' + args); }

  public getFidelitySessionCountByInstrument(instrumentId: number, args: string): Observable<number> { return this.http.get<number>(this.apiHost + 'fidelitysession/countbyinstrument/' + instrumentId + args); }

  public getFidelitySessionCountByChart(chartId: number, args: string): Observable<number> { return this.http.get<number>(this.apiHost + 'fidelitysession/countbychart/' + chartId + args); }

  public getFidelitySession(id: number): Observable<FidelitySession> { return this.http.get<FidelitySession>(this.apiHost + 'fidelitysession/get/' + id); }

  public postFidelitySession(fidelitySession: FidelitySession): Observable<number> { return this.http.post<number>(this.apiHost + 'fidelitysession/post', fidelitySession); }

  public putFidelitySession(fidelitySession: FidelitySession) { return this.http.put(this.apiHost + 'fidelitysession/put', fidelitySession); }

  public deleteFidelitySession(id: number) { return this.http.delete(this.apiHost + 'fidelitysession/delete/' + id); }

  public newFidelitySession(fidelityChart: FidelityChart): Observable<FidelitySession> {
    return new Observable<FidelitySession>(observer => {
      this.compService.getCompsByCompStream(fidelityChart.componentStreamId, '?size=100').subscribe(components => {
        observer.next({
          id: null,
          dateTime: '',
          streamTag: '',
          fidelityChartId: fidelityChart.id,
          fidelityReadings: components.map(comp => ({
            id: null,
            componentType: comp.componentType,
            componentTypeId: comp.componentTypeId,
            fidelitySessionId: null,
            readingValue: 0
          }) as FidelityReading)
        });
        observer.complete();
      }, error => observer.error(error));
    });
  }
}
