import { ReportEndpoint } from './report-endpoint';
import { ReportSection } from './report-section';
import { ReportParameter } from './report-parameter';

export class ReportType {
  id: number;
  description: string;
  datasetName: string;
  datasetId: number;
  reportLevel: string;
  pageOrientation: string;
  enabled: boolean;
  username: string;
  userId: string;
  updateDateTime: string;
  reportEndpoints: ReportEndpoint[];
  reportSections: ReportSection[];
  dataSelection: ReportParameter;
  periodSelection: ReportParameter;
  reportParameters: ReportParameter[];
}
