import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AppConfig } from '../../core/app.config';
import { Io } from './io';
import { IoReading } from './io-reading';

@Injectable()
export class IoService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  private iosCache: Map<string, Observable<Io[]>> = new Map();

  public getAllIosByInformationLevel(informationLevelId: number, dataType: string, force: boolean = false): Observable<Io[]> {
    if (force || !this.iosCache.has(informationLevelId + dataType)) {
      this.iosCache.set(informationLevelId + dataType, this.http.get<Io[]>(this.apiHost + 'iogeneric/getallbyinformationlevel/' + informationLevelId + '?dataType=' + dataType).pipe(shareReplay()));
      setTimeout(() => this.iosCache.delete(informationLevelId + dataType), 10000);
    }
    return this.iosCache.get(informationLevelId + dataType);
  }

  public getIosByInformationLevel(informationLevelId: number, args: string): Observable<Io[]> {
    return this.http.get<Io[]>(this.apiHost + 'iogeneric/getbyinformationlevel/' + informationLevelId + args);
  }

  public getIoCountByInformationLevel(informationLevelId: number, args: string): Observable<number> {
    return this.http.get<number>(this.apiHost + 'iogeneric/countbyinformationlevel/' + informationLevelId + args);
  }

  public getIo(id: number): Observable<Io> { return this.http.get<Io>(this.apiHost + 'iogeneric/get/' + id); }

  public getIoReadings(idList: number[]): Observable<IoReading[]> {
    return this.http.get<IoReading[]>(this.apiHost + 'iogeneric/readvalues?ioIds=' + idList.join('&ioIds='));
  }
}
