<div class="header">
  <h1>{{ title | translate }}</h1>
</div>
<ng-container *ngIf="controlLimitsChanged">
  <table class="overview">
    <tr>
      <td>{{ 'ChangedBy' | translate }}</td>
      <td>{{ controlLimitsChanged.changedBy }}</td>
    </tr>
    <tr>
      <td>{{ 'Chart.Type' | translate }}</td>
      <td>{{ controlLimitsChanged.chartType }}</td>
    </tr>
    <tr>
      <td>{{ 'DateTime' | translate }}</td>
      <td>{{ controlLimitsChanged.dateTime | date:dateTimeFormat }}</td>
    </tr>
  </table>
  <table class="limits-table">
    <thead>
      <tr>
        <td></td>
        <td>{{ 'OldValue' | translate }}</td>
        <td>{{ 'NewValue' | translate }}</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ 'LCL' | translate }}</td>
        <td>{{ controlLimitsChanged.oldLcl }}</td>
        <td>{{ controlLimitsChanged.newLcl }}</td>
      </tr>
      <tr>
        <td>{{ 'UCL' | translate }}</td>
        <td>{{ controlLimitsChanged.oldUcl }}</td>
        <td>{{ controlLimitsChanged.newUcl }}</td>
      </tr>
    </tbody>
  </table>
</ng-container>
