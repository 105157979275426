import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { EventTypeFilterComponent } from './event-type-filter.component';
import { EventService } from '../event.service';

@NgModule({
  declarations: [EventTypeFilterComponent],
  exports: [EventTypeFilterComponent],
  imports: [CommonModule, TranslateModule],
  providers: [EventService]
})
export class EventTypeFilterModule { }
