<div *ngIf="field && field.invalid && (field.dirty || field.touched)" class="form-alert">
  <i class="material-icons">error</i>
  <div *ngIf="field.errors.required">
    <!-- Example output: Name is required. -->
    {{ name | translate }} {{ 'Error.Form.IsRequired' | translate }}.
  </div>
  <div *ngIf="field.errors.minlength">
    <!-- Example output: Name must be at least 4 characters long. -->
    {{ name | translate }} {{ 'Error.Form.MinLength' | translate }} {{field.errors.minlength.requiredLength}} {{ 'Error.Form.CharactersLong' | translate }}.
  </div>
  <div *ngIf="field.errors.maxlength">
    <!-- Example output: Name must be maximum 4 characters long. -->
    {{ name | translate }} {{ 'Error.Form.MaxLength' | translate }} {{field.errors.maxlength.requiredLength}} {{ 'Error.Form.CharactersLong' | translate }}.
  </div>
  <div *ngIf="field.errors.invalidListInputValue">
    <!-- Select an item from the list. -->
    {{ 'Error.Form.InvalidListInputValue' | translate }}
  </div>
</div>
