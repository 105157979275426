export class GridDataArguments {
  constructor() {
    this.pageCount = 0;
    this.pageIndex = 1;
    this.pageSize = 25;
    this.sortColumn = '';
    this.sortOrder = 'asc';
  }

  pageCount: number;
  pageIndex: number;
  pageSize: number;
  sortColumn: string;
  sortOrder: string;
}
