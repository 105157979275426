import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CustomLayoutComponent } from './custom-layout.component';
import { CustomLayoutChartComponent } from './custom-layout-chart.component';
import { CustomLayoutTableComponent } from './custom-layout-table.component';
import { CustomLayoutService } from './custom-layout.service';
import { SharedModule } from '../shared.module';
import { DataFilterModule } from '../data/data-filter.module';
import { AreaLayoutModule } from '../../area/area-layout/area-layout.module';
import { AreaOverviewModule } from '../../area/area-overview/area-overview.module';
import { BadActorsModule } from '../../area/bad-actors/bad-actors.module';
import { PerformanceIndicatorCalculatedModule } from '../../perf-indic/perf-indic-calculated/perf-indic-calculated.module';
import { ReportManagementModule } from '../../core/report/report-management/report-management.module';
import { IoSelectionModule } from '../../area/io/io-selection/io-selection.module';
import { IoStatusModule } from '../../area/io/io-status/io-status.module';
import { ControlChartModule } from '../../area/instrument/chart/control-chart/control-chart.module';
import { FidelityModule } from '../../area/instrument/chart/fidelity/fidelity.module';
import { GaussianChartModule } from '../../area/instrument/chart/gaussian-chart/gaussian-chart.module';
import { HistoricalChartEventModule } from '../../area/instrument/chart/historical-chart/historical-chart-event.module';
import { LinearityModule } from '../../area/instrument/chart/linearity/linearity.module';

@NgModule({
  declarations: [
    CustomLayoutComponent,
    CustomLayoutChartComponent,
    CustomLayoutTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    SharedModule,
    DataFilterModule,
    AreaLayoutModule,
    AreaOverviewModule,
    BadActorsModule,
    PerformanceIndicatorCalculatedModule,
    ReportManagementModule,
    IoSelectionModule,
    IoStatusModule,
    ControlChartModule,
    FidelityModule,
    GaussianChartModule,
    HistoricalChartEventModule,
    LinearityModule
  ],
  exports: [
    CustomLayoutComponent
  ]
})

export class CustomLayoutModule {
  static forRoot(): ModuleWithProviders<CustomLayoutModule> {
    return {
      ngModule: CustomLayoutModule,
      providers: [
        CustomLayoutService
      ]
    };
  }
}
