<div class="header">
  <h1>{{ title }}</h1>
</div>
<div *ngIf="!validationDataPoint">
  <error-message [error]="error"></error-message>
  <div *ngIf="!embedded" class="control-buttons"><button class="button-gray" type="reset" (click)="cancel()">{{ 'Cancel' | translate }}</button></div>
</div>
<form *ngIf="validationDataPoint">
  <table class="overview">
    <tr><td>{{ 'Validation.SessionId' | translate }}:</td><td>{{ validationDataPoint.validationSessionId }}</td></tr>
    <tr><td>{{ 'Validation.StartDateTime' | translate }}:</td><td>{{ validationDataPoint.startDateTime | date:dateTimeFormat }}</td></tr>
    <tr><td>{{ 'Validation.EndDateTime' | translate }}:</td><td>{{ validationDataPoint.endDateTime | date:dateTimeFormat }}</td></tr>
    <tr><td>{{ 'Validation.InitiatedBy' | translate }}:</td><td>{{ validationDataPoint.initiatedBy }}</td></tr>
    <tr>
      <td>{{ 'Excluded' | translate }}:</td>
      <td>
        <input type="checkbox" name="excluded" [checked]="validationDataPoint.excluded" [disabled]="initiatedByReport || !updateAccess"
               (input)="validationDataPoint.excluded = !validationDataPoint.excluded" />
      </td>
    </tr>
    <tr>
      <td>{{ 'Reset' | translate }}:</td>
      <td>
        <input type="checkbox" name="resetSpc" [checked]="validationDataPoint.resetSpc" [disabled]="embedded || !updateAccess"
               (input)="validationDataPoint.resetSpc = !validationDataPoint.resetSpc" />
      </td>
    </tr>
    <tr><td>{{ 'PlottedVariable' | translate }}:</td><td>{{ validationDataPoint.plottedValue }}</td></tr>
    <ng-container *ngIf="validationDataPoint.statistics">
      <tr><td>{{ 'NumberOfReadings' | translate }}</td><td>{{ validationDataPoint.statistics.count }}</td></tr>
      <tr><td>{{ 'Mean' | translate }}</td><td>{{ validationDataPoint.statistics.mean | number:'1.2-2' }}</td></tr>
      <tr><td>{{ 'Minimum' | translate }}</td><td>{{ validationDataPoint.statistics.minimum | number:'1.2-2' }}</td></tr>
      <tr><td>{{ 'Maximum' | translate }}</td><td>{{ validationDataPoint.statistics.maximum | number:'1.2-2' }}</td></tr>
      <tr><td>{{ 'Range' | translate }}</td><td>{{ validationDataPoint.statistics.range | number:'1.2-2' }}</td></tr>
      <tr>
        <td>{{ 'StandardDeviation' | translate }}</td>
        <td>
          <ng-container *ngIf="validationDataPoint.statistics.count > 1 && validationDataPoint.statistics.standardDeviation !== 'NaN'">{{ validationDataPoint.statistics.standardDeviation | number:'1.2-2' }}</ng-container>
          <ng-container *ngIf="validationDataPoint.statistics.count <= 1 || validationDataPoint.statistics.standardDeviation === 'NaN'">{{ 'NaN' | translate }}</ng-container>
        </td>
      </tr>
    </ng-container>
  </table>
  <table *ngIf="validationDataPoint.validationReadings" class="grid simple-grid-table" style="width: 50vw !important;">
    <thead>
      <tr>
        <td>{{ 'Reading' | translate }}</td>
        <td>{{ 'Reference.Reference' | translate }}</td>
        <td>{{ 'Deviation' | translate }}</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let valReading of validationDataPoint.validationReadings; let i = index">
        <td>{{ valReading.reading | number:'1.2-2' }}</td>
        <td>{{ valReading.reference | number:'1.2-2' }}</td>
        <td *ngIf="valReading.deviation !== 'NaN'">{{ valReading.deviation | number:'1.2-2' }}</td>
        <td *ngIf="valReading.deviation === 'NaN'">&nbsp;</td>
      </tr>
    </tbody>
  </table>
  <error-message [error]="error"></error-message>
  <div *ngIf="!initiatedByReport" [ngClass]="{'control-buttons': !embedded, 'control-buttons-left next-to-control-buttons': embedded}">
    <button *ngIf="createAccess && !validationDataPoint.hasNote" class="button-blue margin-right" (click)="viewNote()">{{ 'Note.Add' | translate }}</button>
    <button *ngIf="validationDataPoint.hasNote" class="button-green margin-right" (click)="viewNote()">{{ 'Note.View' | translate }}</button>
    <button *ngIf="!embedded" class="button-gray" type="reset" (click)="cancel()">{{ 'Cancel' | translate }}</button>
    <button *ngIf="updateAccess" class="button-blue" type="submit" (click)="reevaluateControlChart()">{{ 'SaveAndReevaluate' | translate }}</button>
  </div>
  <div *ngIf="initiatedByReport && !embedded" class="control-buttons">
    <button class="button-gray" type="reset" (click)="cancel()">{{ 'Close' | translate }}</button>
  </div>
</form>
<custom-dialog [visible]="noteDialog" *ngIf="noteDialog && validationDataPoint" (editModeChanged)="noteDialogChanged($event)">
  <note [id]="validationDataPoint.validationReadingGroupId" [type]="'validationreadinggroup'" (editModeChanged)="noteDialogChanged($event)"></note>
</custom-dialog>
