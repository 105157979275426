import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { DataService } from './data.service';

@NgModule({
  declarations: [],
  providers: [
    DataService
  ],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  exports: []
})

export class DataModule { }
