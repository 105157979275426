import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { NgForm } from '@angular/forms';

import { AppConfig } from '../../app.config';
import { ReportType } from '../report-type';
import { ReportDatasetProperty } from './report-dataset-property';
import { ReportSectionExtended } from './report-section-extended';
import { ReportSectionHeader } from '../report-section-header';
import { ReportSectionContent } from '../report-section-content';
import { ReportViewPageService } from '../report-view-page.service';
import { ReportViewService } from '../report-view.service';
import { ReportSection } from '../report-section';

@Component({
  selector: 'report-type-layout-editor',
  templateUrl: './report-type-layout-editor.component.html',
  styleUrls: ['../report-view-page.css', './report-type-layout-editor.component.css']
})

export class ReportTypeLayoutEditorComponent implements OnInit, OnChanges {
  constructor(
    private appConfig: AppConfig,
    private reportViewPageService: ReportViewPageService,
    private reportViewService: ReportViewService
  ) { }
  @Input() public reportType: ReportType;
  @Input() public shownInDialog: boolean = false;
  @Input() public editMode: boolean = false;
  @Input() public datasetProperties: ReportDatasetProperty[] = [];
  public reportPages: any[] = null; //Array containing multiple report sections, every page has its own ReportSection[].
  public pageCount: number = null;
  public selectedSection = null;
  public selectedSectionColumnWidth: string;
  public pageWidth: number;
  public pageHeight: number;
  public dateTime;
  public numberOfContentRows: number = 1;
  private dateTimeFormat: string;
  private logo: string;
  private mouseX: number = 0;
  private startWidth: number;
  private startWidthRightColumn: number;
  Array = Array;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.reportViewService.getReportLogo().subscribe(logo => this.logo = logo);
    this.dateTime = Date.now();

    if (this.reportType !== undefined && this.reportType !== null) {
      this.resetPreview(this.reportType.pageOrientation);
    }
  }

  ngOnChanges() {
    this.resetPreview(this.reportType.pageOrientation);
  }

  resetPreview(pageOrientation: string) {
    if (this.reportType !== undefined && this.reportType !== null && this.reportType.reportSections !== null && this.reportType.reportSections.length > 0) {
      this.getPageOrientation();
      const sections = this.getSectionsWithContent();
      this.reportPages = this.reportViewPageService.getReportPages(sections, pageOrientation);
      this.pageCount = (this.reportPages.length !== null) ? this.reportPages.length : null;
      this.linkOriginalSectionsToPageSections();
    } else {
      this.reportPages = null;
      this.pageCount = null;
    }
  }

  getPageOrientation() {
    if (this.reportType !== undefined && this.reportType !== null) {
      this.pageWidth = this.reportViewPageService.getPageWidth(this.reportType.pageOrientation);
      this.pageHeight = this.reportViewPageService.getPageHeight(this.reportType.pageOrientation);
    }
  }

  getSectionsWithContent(reportSections: ReportSection[] = null): ReportSection[] {
    let sections: ReportSection[];

    if (reportSections === null) {
      //Cloning (deep copy) the report sections is necessary because the original sections must not be changed inside the reportViewPageService.getReportPages method.
      sections = JSON.parse(JSON.stringify(this.reportType.reportSections));
    } else {
      sections = reportSections;
    }

    for (let i = 0; i < sections.length; i++) {
      if (sections[i].type === 'overviewTable' || sections[i].type === 'overviewTableVertical') {
        const newContent = [];
        for (let j = 0; j < this.numberOfContentRows; j++) {
          newContent.push(sections[i].content);
        }
        sections[i].content = newContent;
      }

      if (sections[i].sectionChildren !== undefined && sections[i].sectionChildren !== null && sections[i].sectionChildren.length > 0) {
        sections[i].sectionChildren = this.getSectionsWithContent(sections[i].sectionChildren);
      }
    }

    return sections;
  }

  linkOriginalSectionsToPageSections() {
    if (this.reportPages !== null && this.reportPages.length > 0 && this.reportType !== undefined && this.reportType != null && this.reportType.reportSections !== null) {
      for (let i = 0; i < this.reportPages.length; i++) {
        for (let j = 0; j < this.reportPages[i].length; j++) {
          if (this.reportPages[i][j].type !== 'header' && this.reportPages[i][j].type !== 'footer') {
            this.reportPages[i][j].originalSection = this.findOriginalSection(this.reportPages[i][j]);
          }
        }
      }
    }
  }

  findOriginalSection(pageSection: ReportSection, sections: ReportSection[] = null): ReportSection {
    if (sections === null) {
      sections = this.reportType.reportSections;
    }

    for (let i = 0; i < sections.length; i++) {
      if (sections[i].id === pageSection.id && sections[i].type === pageSection.type && sections[i].order === pageSection.order && sections[i].dataPath === pageSection.dataPath) {
        if (sections[i].type === 'overviewTable' && sections[i].headers !== null && sections[i].headers.length > 0 && sections[i].headers[0].width === null) {
          const width = Math.round(((this.pageWidth - 72) / sections[i].headers.length) - 9);
          for (let j = 0; j < sections[i].headers.length; j++) {
            sections[i].headers[j].width = width + 'px';
          }
        }

        return sections[i];
      }

      if (sections[i].type === 'container' && sections[i].sectionChildren !== null && sections[i].sectionChildren.length > 0) {
        const section = this.findOriginalSection(pageSection, sections[i].sectionChildren);

        if (section !== null) {
          return section;
        }
      }
    }

    return null;
  }

  sectionClick(section) {
    if (this.editMode) {
      if (this.selectedSection !== null) {
        this.selectedSection.highlight = false;
        this.selectedSection.changed = false;
      }

      this.selectedSection = section;
      this.selectedSection.highlight = false;
      this.selectedSection.changed = false;

      const width = this.reportViewPageService.getHeaderColumnWidth(section.originalSection, 0, this.pageWidth);
      this.selectedSectionColumnWidth = (width !== null) ? width + 'px' : null;
      this.selectedSection = section;
      section.highlight = true;
    }
  }

  setColumnWidth(width, section: ReportSection, index: number) {
    if (section.type === 'overviewTable') {
      section.headers[index].width = width;
    } else {
      for (let i = 0; i < section.headers.length; i++) {
        section.headers[i].width = width;
      }
    }

    if (this.selectedSection !== null) {
      this.selectedSection.changed = true;
    }
  }

  columnResizeOnClick(event: MouseEvent, section, index: number) {
    this.sectionClick(section);
    this.mouseX = event.clientX;

    this.startWidth = this.reportViewPageService.getHeaderColumnWidth(section.originalSection, index, this.pageWidth, 50);

    if (section.type === 'overviewTable') {
      this.startWidthRightColumn = this.reportViewPageService.getHeaderColumnWidth(section.originalSection, index + 1, this.pageWidth);
    }
  }

  doColumnResize(event: MouseEvent, section, index: number) {
    if (event.clientX > 0) {
      const pixelsMoved = event.clientX - this.mouseX;
      let newWidth = this.startWidth + pixelsMoved;
      const tooSmall = (newWidth < 50);

      if (tooSmall) {
        newWidth = 50;
      }

      if (section.type === 'overviewTable') {
        const newWidthRightColumn = this.startWidthRightColumn - pixelsMoved;

        if (!tooSmall && newWidthRightColumn >= 50) {
          section.originalSection.headers[index].width = newWidth + 'px';
          section.originalSection.headers[index + 1].width = newWidthRightColumn + 'px';
          this.selectedSectionColumnWidth = newWidth + 'px';
        }
      } else {
        for (let i = 0; i < section.originalSection.headers.length; i++) {
          section.originalSection.headers[i].width = newWidth + 'px';
          this.selectedSectionColumnWidth = newWidth + 'px';
        }
      }

      this.selectedSection.changed = true;
    }
  }

  deselectSection() {
    if (this.selectedSection !== null) {
      this.selectedSection.highlight = false;
      this.selectedSection.changed = false;
    }

    this.selectedSection = null;
  }

  resetColumnWidth(section) {
    if (section.originalSection.headers !== null && section.originalSection.headers.length > 0) {
      let width = null;

      if (section.type === 'overviewTable') {
        const calcwidth = Math.round(((this.pageWidth - 72) / section.originalSection.headers.length) - 9);
        width = calcwidth + 'px';
      }

      for (let i = 0; i < section.originalSection.headers.length; i++) {
        section.originalSection.headers[i].width = width;
      }
    }

    this.sectionClick(section);
    this.selectedSection.changed = true;
  }

  applyAndResetPreview(pageOrientation: string) {
    this.deselectSection();
    this.resetPreview(pageOrientation);
  }
}
