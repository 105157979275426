<loader *ngIf="!area && !error"></loader>
<div *ngIf="area" class="dropregion" [ngStyle]="{ 'background-image': area.image ? 'url(' + area.image + ')' : null, 'width': area.imageWidth + 'px', 'height': area.imageHeight + 'px' }"
     (drop)="drop($event)" (dragover)="dragover($event)">
  <div *ngFor="let layoutItem of layoutItems" class="draggable"
       [ngClass]="getOperationalStateClass(layoutItem.state)"
       [ngStyle]="{ 'left': layoutItem.left + 'px', 'top': layoutItem.top + 'px', 'width': layoutItem.width + 'px', 'height': layoutItem.height + 'px', 'z-index': layoutItem.z }"
       [draggable]="editMode && !resize" (mousedown)="onClick($event, layoutItem)" (dragend)="onReleaseItem(layoutItem)" (drag)="drag($event, layoutItem)" (dragstart)="dragStart($event)">
    <ng-container *ngIf="editMode">
      <div class="draggable-updown">
        <div class="draggable-up draggable-action" (click)="changeZ(layoutItem, true)">+</div>
        <div class="draggable-down draggable-action" (click)="changeZ(layoutItem, false)">-</div>
      </div>
      <div class="draggable-deleteedit">
        <div class="draggable-delete draggable-action" (click)="deleteItem(layoutItem)">x</div>
      </div>
      <div class="ui-resizable-handle ui-resizable-e" draggable="true" (mousedown)="onClick($event)" (dragend)="onResizeRelease(layoutItem)" (drag)="resizeDrag($event, layoutItem, true, false)" (dragstart)="dragStart($event)"></div>
      <div class="ui-resizable-handle ui-resizable-s" draggable="true" (mousedown)="onClick($event)" (dragend)="onResizeRelease(layoutItem)" (drag)="resizeDrag($event, layoutItem, false, true)" (dragstart)="dragStart($event)"></div>
      <div class="ui-resizable-handle ui-resizable-se" draggable="true" (mousedown)="onClick($event)" (dragend)="onResizeRelease(layoutItem)" (drag)="resizeDrag($event, layoutItem, true, true)" (dragstart)="dragStart($event)"></div>
    </ng-container>
    <div class="title">{{ layoutItem.title }}</div>
    <div class="state">{{ layoutItem.state | translate }}</div>
    <div *ngIf="layoutItem.hasAlarms" class="alarms">{{ 'Alarm' | translate }}</div>
  </div>
  <div *ngFor="let layoutDiag of layoutDiags" class="draggable diagnostic" [draggable]="editMode && !resize"
       [ngStyle]="{ 'left': layoutDiag.left + 'px', 'top': layoutDiag.top + 'px', 'z-index': layoutDiag.z }"
       (mousedown)="onClick($event)" (dragend)="onReleaseDiag(layoutDiag)" (drag)="drag($event, layoutDiag)" (dragstart)="dragStart($event)">
    <ng-container *ngIf="editMode">
      <div class="draggable-updown">
        <div class="draggable-up draggable-action" (click)="changeZ(layoutDiag, true)">+</div>
        <div class="draggable-down draggable-action" (click)="changeZ(layoutDiag, false)">-</div>
      </div>
      <div class="draggable-deleteedit">
        <div class="draggable-delete draggable-action" (click)="deleteDiag(layoutDiag)">x</div>
        <div class="draggable-edit draggable-action" (click)="selectDiag(layoutDiag)"><i class="material-icons">create</i></div>
      </div>
    </ng-container>
    <ng-container *ngIf="layoutDiag.targetDiagnostic"><diagnostic-display [diagnostic]="layoutDiag.targetDiagnostic"></diagnostic-display> {{ layoutDiag.targetDiagnostic.unit }}</ng-container>
  </div>
  <ng-container *ngIf="editMode">
    <div class="ui-resizable-handle ui-resizable-e" draggable="true" (mousedown)="onClick($event)" (drag)="resizeViewDrag($event, true, false)" (dragstart)="dragStart($event)"></div>
    <div class="ui-resizable-handle ui-resizable-s" draggable="true" (mousedown)="onClick($event)" (drag)="resizeViewDrag($event, false, true)" (dragstart)="dragStart($event)"></div>
    <div class="ui-resizable-handle ui-resizable-se" draggable="true" (mousedown)="onClick($event)" (drag)="resizeViewDrag($event, true, true)" (dragstart)="dragStart($event)"></div>
  </ng-container>
</div>
<error-message [error]="error"></error-message>
<div *ngIf="area && updateAccess" class="control-buttons">
  <ng-container *ngIf="editMode; else edit">
    <button class="button-blue" (click)="quickLayout()">{{ 'QuickLayout' | translate }}</button>
    <button class="button-blue" (click)="addDiag()">{{ 'Diagnostic.Add' | translate }}</button>
    <button *ngIf="area.image" class="button-red" (click)="area.image = ''">{{ 'RemoveImage' | translate }}</button>
    <label class="button-orange label-button margin-right"><input type="file" id="file" name="image" class="invisible" (change)="handleFileInput($event.target.files)" onclick="this.value=null;" />{{ 'UploadImage' | translate }}</label>
    <button class="button-gray" (click)="cancel()">{{ 'Cancel' | translate }}</button>
    <submit-button name="Save" (click)="saveChanges()"></submit-button>
  </ng-container>
  <ng-template #edit><button class="button-blue" (click)="changeEditMode(true)">{{ 'Edit' | translate }}</button></ng-template>
</div>
<custom-dialog *ngIf="diagnosticDialog && selectedDiag && area" [visible]="diagnosticDialog" (editModeChanged)="dialogChanged(null)">
  <diag-dialog [(diagnosticId)]="selectedDiag.targetDiagnosticId" [areaId]="area.id" [levelId]="selectedDiag.targetDiagnostic?.informationLevelId" (editModeChanged)="dialogChanged($event)"></diag-dialog>
</custom-dialog>
