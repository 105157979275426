import { Component, Input, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})

export class LoaderComponent implements OnInit {
  @Input() type: string;
  public loaderClasses: string;

  ngOnInit() {
    switch (this.type) {
      case 'formItem':
        this.loaderClasses = 'loader-wrapper formItem';
        break;
      default:
        this.loaderClasses = 'loader-wrapper';
        break;
    }
  }
}
