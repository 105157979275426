import { NgModule } from '@angular/core';
import { OwlDateTimeModule } from '@shtian/ng-pick-datetime';

import { FidelityChartModule } from './fidelity-chart.module';
import { SharedModule } from '../../../../shared/shared.module';

import { FidelityChartViewComponent } from './fidelity-chart-view.component';
import { FidelitySessionComponent } from './fidelity-session.component';
import { FidelityChartOverviewComponent } from './fidelity-chart-overview.component';
import { FidelityGroupOverviewComponent } from './fidelity-group-overview.component';
import { FidelitySessionOverviewComponent } from './fidelity-session-overview.component';
import { FidelityChartService } from './fidelity-chart.service';
import { FidelityGroupService } from './fidelity-group.service';
import { FidelitySessionService } from './fidelity-session.service';
import { StreamService } from '../../stream/stream.service';
import { CompTypeService } from '../../comp-type/comp-type.service';

@NgModule({
  declarations: [
    FidelityChartViewComponent,
    FidelitySessionComponent,
    FidelityChartOverviewComponent,
    FidelityGroupOverviewComponent,
    FidelitySessionOverviewComponent
  ],
  exports: [
    FidelityChartViewComponent,
    FidelityChartOverviewComponent
  ],
  imports: [
    FidelityChartModule,
    SharedModule,
    OwlDateTimeModule
  ],
  providers: [
    FidelityChartService,
    FidelityGroupService,
    FidelitySessionService,
    StreamService,
    CompTypeService
  ]
})
export class FidelityModule { }
