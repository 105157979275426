import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { OwlDateTimeModule } from '@shtian/ng-pick-datetime';

import { DataFormComponent } from './data-form.component';
import { DataFormItemComponent } from './data-form-item.component';
import { ErrorMessageModule } from '../error/error-message.module';
import { FormAlertModule } from '../form-alert/form-alert.module';
import { SubmitButtonModule } from '../submit-button/submit-button.module';
import { IoSelectionModule } from '../../area/io/io-selection/io-selection.module';
import { ListInputModule } from '../list-input/list-input.module';

@NgModule({
  declarations: [
    DataFormComponent,
    DataFormItemComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    OwlDateTimeModule,
    ErrorMessageModule,
    FormAlertModule,
    SubmitButtonModule,
    IoSelectionModule,
    ListInputModule
  ],
  exports: [
    DataFormComponent,
    DataFormItemComponent
  ]
})

export class DataFormModule { }
