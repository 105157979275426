<div class="overview-wrapper">
  <div class="overview-header">
    {{ 'Linearity.Chart' | translate }}
  </div>
  <div class="control-buttons">
    <button *ngIf="createAccess" class="button-blue" (click)="addLinearitySession()">{{ 'Linearity.AddSession' | translate }}</button>
    <button *ngIf="updateAccess" class="button-blue" (click)="editLinearityChart()">{{ 'Settings' | translate }}</button>
    <button class="button-blue" (click)="backToList()">{{ 'Overview' | translate }}</button>
  </div>
</div>
<div class="overview-wrapper">
  <linearity-chart-view-chart *ngIf="selectedLinearitySession" [linearitySessionId]="selectedLinearitySession.id" [linearitySession]="selectedLinearitySession" [linearityChart]="linearityChart"></linearity-chart-view-chart>
</div>
<linearity-session [linearityChartId]="id" [dataChange]="dataChange" (selectedLinearitySessionChanged)="setSelectedLinearitySession($event)"></linearity-session>
<custom-dialog [visible]="editDialog" *ngIf="editDialog" (editModeChanged)="dialogChanged($event)">
  <div class="header">
    <h1>{{ 'Chart.Settings' | translate }}</h1>
  </div>
  <linearity-chart-overview [linearityChartId]="linearityChart.id" [instrumentId]="linearityChart.instrumentId" (editModeChanged)="dialogChanged($event)"></linearity-chart-overview>
</custom-dialog>
<custom-dialog [visible]="sessionDialog" *ngIf="sessionDialog" (editModeChanged)="sessionDialogChanged($event)">
  <linearity-session-overview [linearitySession]="sessionDialogSession" (editModeChanged)="sessionDialogChanged($event)"></linearity-session-overview>
</custom-dialog>
