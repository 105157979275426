import { NgModule } from '@angular/core';
import { OwlDateTimeModule } from '@shtian/ng-pick-datetime';

import { SharedModule } from '../../../../shared/shared.module';
import { ValidationDataModule } from '../control-chart/validation-data/validation-data.module';

import { HistoricalChartEventComponent } from './historical-chart-event.component';
import { HistoricalChartEventOverviewComponent } from './historical-chart-event-overview.component';
import { HistoricalChartEventChartComponent } from './historical-chart-event-chart.component';
import { HistoricalChartEventService } from './historical-chart-event.service';
import { HistoricalChartService } from './historical-chart.service';
import { ReferenceMaterialChangeComponent } from './reference-material-change.component';
import { ControlLimitsChangedComponent } from './control-limits-changed.component';

@NgModule({
  declarations: [
    HistoricalChartEventComponent,
    HistoricalChartEventOverviewComponent,
    HistoricalChartEventChartComponent,
    ReferenceMaterialChangeComponent,
    ControlLimitsChangedComponent
  ],
  exports: [
    HistoricalChartEventComponent,
    HistoricalChartEventOverviewComponent,
    HistoricalChartEventChartComponent
  ],
  imports: [
    SharedModule,
    ValidationDataModule,
    OwlDateTimeModule
  ],
  providers: [
    HistoricalChartEventService,
    HistoricalChartService
  ]
})

export class HistoricalChartEventModule { }
