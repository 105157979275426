import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IoStatusComponent } from './io-status.component';
import { IoPointService } from '../io-point/io-point.service';
import { IoService } from '../io.service';
import { SharedModule } from '../../../shared/shared.module';
import { DataFilterModule } from '../../../shared/data/data-filter.module';

@NgModule({
  declarations: [
    IoStatusComponent
  ],
  providers: [
    IoPointService,
    IoService
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    DataFilterModule,
  ],
  exports: [
    IoStatusComponent
  ]
})

export class IoStatusModule { }
