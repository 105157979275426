import { HttpClient } from '@angular/common/http';
import { Router, RoutesRecognized } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { ClaimService } from '../claim.service';

import { AppConfig } from '../app.config';
import { TreeItem } from './tree-item';
import { Breadcrumb } from '../breadcrumb/breadcrumb';

@Injectable()
export class SidebarService {
  private apiHost: string;
  private id = 0;
  private activePath: string[] = [];
  private treeItems: TreeItem[] = [];
  constructor(
    private appConfig: AppConfig,
    private http: HttpClient,
    private router: Router,
    private claimService: ClaimService
  ) {
    this.checkClaims();

    if (this.router.events !== undefined) {
      const sub = this.router.events.subscribe(val => {
        if (val instanceof RoutesRecognized) {
          this.activePath = val.url.split('/');
          if (this.treeItems.length === 0) {
            this.getTreeItems();
          } else {
            this.expandActivePathItems();
          }
        }
      });
    }
  }
  private areaAccess: boolean;
  private audittrailAccess: boolean;
  private authmanAccess: boolean;
  private conchartAccess: boolean;
  private consettingsAccess: boolean;
  private documentAccess: boolean;
  private eventAccess: boolean;
  private hischartAccess: boolean;
  private inventoryAccess: boolean;
  private maiactAccess: boolean;
  private maiapprovalAccess: boolean;
  private maiapproval2Access: boolean;
  private operstateAccess: boolean;
  private perfindicAccess: boolean;
  private pvtAccess: boolean;
  private refmatsAccess: boolean;
  private reportAccess: boolean;
  private reportManagementAccess: boolean;
  private schedulerAccess: boolean;
  private usermanAccess: boolean;
  private valnoteAccess: boolean;
  private valprocedureAccess: boolean;
  private valsessionAccess: boolean;

  public getTreeItems(): Observable<void> {
    return new Observable(observer => {
      if (this.areaAccess) {
        this.http.get<TreeItem[]>(this.apiHost + 'navigation/tree')
          .toPromise()
          .then(data => {
            const areaTree: TreeItem[] = [];
            if (data !== null && data.length > 0) {
              for (let i = 0; i < data.length; i++) {
                areaTree.push(data[i]);
              }
            }

            this.buildAmlTree(areaTree);
            observer.next();
            observer.complete();
          })
          .catch(error => {
            this.buildAmlTree([]);
            observer.next();
            observer.complete();
          });
      } else {
        this.buildAmlTree([]);
        observer.next();
        observer.complete();
      }
    });
  }

  public getTree(): Observable<TreeItem[]> {
    return new Observable(observer => {
      if (!this.treeItems || this.treeItems.length === 0) {
        this.getTreeItems().subscribe(response => {
          observer.next(this.treeItems);
          observer.complete();
        });
      } else {
        observer.next(this.treeItems);
        observer.complete();
      }
    });
  }

  public expandActivePathItems() {
    if (this.activePath !== null && this.activePath.length > 0 && this.treeItems !== null && this.treeItems.length > 0) {
      let treeItems = this.treeItems[0].children;

      for (let i = 1; i <= this.activePath.length; i++) {
        const path = this.activePath.slice(1, i).join('/');

        for (let j = 0; j < treeItems.length; j++) {
          if (treeItems[j].path === path) {
            treeItems[j].expand = true;

            if (treeItems[j].children !== null && treeItems[j].children.length > 0) {
              treeItems = treeItems[j].children;
            } else {
              treeItems = [];
            }
            break;
          }
        }
      }
    }
  }

  public expandCurrentBreadcrumbItems(breadcrumbs: Breadcrumb[]) {
    if (breadcrumbs !== null && breadcrumbs.length > 1 && this.treeItems !== null && this.treeItems.length > 0) {
      let treeItems = this.treeItems[0].children;

      for (let i = 1; i < breadcrumbs.length - 1; i++) {
        let breadcrumbPath = breadcrumbs[i].path.replace('//', '');
        if (breadcrumbPath.charAt(breadcrumbPath.length - 1) === '/') {
          breadcrumbPath = breadcrumbPath.slice(0, breadcrumbPath.length - 1);
        }

        for (let j = 0; j < treeItems.length; j++) {
          const treeItemPath = treeItems[j].path.replace('//', '');
          if (treeItemPath === breadcrumbPath) {
            treeItems[j].expand = true;

            if (treeItems[j].children !== null && treeItems[j].children.length > 0) {
              treeItems = treeItems[j].children;
            } else {
              treeItems = [];
            }
            break;
          }
        }
      }
    }
  }

  private buildAmlTree(areaTree: TreeItem[]) {
    const amlTree = new Array<TreeItem>();

    if (this.eventAccess) {
      amlTree.push(
        { title: 'Events', path: 'event', expand: false, children: [] }
      );
    }

    /*if (this.valsessionAccess) {
      amlTree.push(
        { title: 'Charting', path: 'charting', expand: false, children: [] }
      );
    }*/

    if (this.pvtAccess) {
      amlTree.push(
        {
          title: 'PVT.PVT', path: 'pvt', expand: false, children: [
            { title: 'PVT.Component.Components', path: 'pvt/component', expand: false, children: [] }
          ]
        }
      );
    }
    /* End of POCs. */

    if (this.audittrailAccess) {
      amlTree.push(
        {
          title: 'Activity.Overview', path: 'activityoverview', expand: false, children: [
            { title: 'AuditTrail', path: 'activityoverview/audittrail', expand: false, children: [] }/*,
            { title: 'User.Sessions', path: 'activityoverview/usersessions', expand: false, children: [] }*/
          ]
        }
      );
    }

    const securityTree = new Array<TreeItem>();

    securityTree.push(
      { title: 'ChangePassword', path: 'administration/security/changepassword', expand: false, children: [] }
    );

    if (this.usermanAccess) {
      securityTree.push(
        { title: 'User.Management', path: 'administration/security/usermanagement', expand: false, children: [] }
      );
    }

    if (this.authmanAccess) {
      securityTree.push(
        { title: 'Role.Management', path: 'administration/security/rolemanagement', expand: false, children: [] },
        { title: 'AuthorizationManagement', path: 'administration/security/authorizationmanagement', expand: false, children: [] }
      );

      if (this.claimService.checkClaimContains('aml.authman', 'u')) {
        securityTree.push(
          { title: 'SessionRights', path: 'administration/security/sessionrights', expand: false, children: [] }
        );
      }
    }

    const configDataTree = new Array<TreeItem>();

    configDataTree.push(
      { title: 'Component.Types', path: 'administration/configsettings/componenttype', expand: false, children: [] },
      { title: 'Reference.MaterialTypes', path: 'administration/configsettings/refmattype', expand: false, children: [] },
      { title: 'Criticalities', path: 'administration/configsettings/criticality', expand: false, children: [] },
      { title: 'IoServer.IoServers', path: 'administration/configsettings/ioserver', expand: false, children: [] },
      { title: 'Document.Extensions', path: 'administration/configsettings/documentextension', expand: false, children: [] },
      { title: 'Document.Domains', path: 'administration/configsettings/domain', expand: false, children: [] },
      { title: 'GlobalSettings', path: 'administration/configsettings/globalsetting', expand: false, children: [] },
      { title: 'DecisionRule.DecisionRules', path: 'administration/configsettings/decisionrule', expand: false, children: [] },
      { title: 'IoFormula.IoFormulas', path: 'administration/configsettings/ioformulas', expand: false, children: [] },
      { title: 'BadActors', path: 'administration/configsettings/badactors', expand: false, children: [] },
      { title: 'Timeline.Timeline', path: 'administration/configsettings/timeline', expand: false, children: [] }
    );

    if (this.maiactAccess) {
      const maintenanceTree = new Array<TreeItem>();

      maintenanceTree.push(
        { title: 'MaintenanceActivities', path: 'administration/configsettings/maintenance/maintenanceactivity', expand: false, children: [] },
        { title: 'Maintenance.Types', path: 'administration/configsettings/maintenance/type', expand: false, children: [] },
        { title: 'Maintenance.TypesOfFailure', path: 'administration/configsettings/maintenance/typeoffailure', expand: false, children: [] },
        { title: 'Maintenance.CausesOfFailure', path: 'administration/configsettings/maintenance/causeoffailure', expand: false, children: [] },
        { title: 'Maintenance.Parts', path: 'administration/configsettings/maintenance/part', expand: false, children: [] },
        { title: 'Maintenance.Statuses', path: 'administration/configsettings/maintenance/status', expand: false, children: [] },
        { title: 'Maintenance.Actions', path: 'administration/configsettings/maintenance/action', expand: false, children: [] }
      );

      configDataTree.push(
        { title: 'Maintenance.Maintenance', path: 'administration/configsettings/maintenance', expand: false, children: maintenanceTree }
      );
    }

    if (this.inventoryAccess) {
      configDataTree.push(
        { title: 'Supplier.Suppliers', path: 'administration/configsettings/suppliers', expand: false, children: [] }
      );
    }

    if (this.perfindicAccess) {
      configDataTree.push({
        title: 'PerformanceIndicator.PerformanceIndicators', path: 'administration/configsettings/perfindic', expand: false, children: [
          { title: 'PerformanceIndicator.PerformanceIndicators', path: 'administration/configsettings/perfindic/perfindic', expand: false, children: [] },
          { title: 'PerformanceIndicator.ReviewPeriod', path: 'administration/configsettings/perfindic/reviewperiod', expand: false, children: [] },
          { title: 'PerformanceIndicator.Charts', path: 'administration/configsettings/perfindic/chart', expand: false, children: [] }
        ]
      });
    }

    const administrationTree = new Array<TreeItem>();

    administrationTree.push(
      { title: 'Security', path: 'administration/security', expand: false, children: securityTree }
    );

    if (this.consettingsAccess) {
      administrationTree.push(
        { title: 'ConfigurationSettings', path: 'administration/configsettings', expand: false, children: configDataTree }
      );
    }

    if (this.reportManagementAccess || this.reportAccess) {
      const reportTree: TreeItem[] = [];

      if (this.reportManagementAccess) {
        reportTree.push(
          { title: 'ReportTypes', path: 'administration/report/type', expand: false, children: [] },
          { title: 'Report.Settings', path: 'administration/report/settings', expand: false, children: [] }
        );
      }

      if (this.reportAccess) {
        reportTree.push(
          { title: 'ReportType.UserDefined', path: 'administration/report/userdefined', expand: false, children: [] }
        );
      }

      administrationTree.push(
        { title: 'Reports', path: 'administration/report', expand: false, children: reportTree }
      );

      if (this.areaAccess) {
        administrationTree.push(
          { title: 'MassConfig.MassConfig', path: 'administration/massconfig', expand: false, children: [] }
        );
      }
    }

    amlTree.push(
      { title: 'Administration', path: 'administration', expand: false, children: administrationTree }
    );

    const laboratoryTree = new Array<TreeItem>();

    if (this.refmatsAccess) {
      laboratoryTree.push(
        { title: 'Reference.Materials', path: 'laboratory/refmat', expand: false, children: [] }
      );
    }

    if (this.valsessionAccess) {
      laboratoryTree.push(
        { title: 'LineSample.LineSamples', path: 'laboratory/linesample', expand: false, children: [] }
      );
    }

    if (this.refmatsAccess || this.valsessionAccess) {
      amlTree.push(
        { title: 'Laboratory', path: 'laboratory', expand: false, children: laboratoryTree }
      );
    }

    if (this.maiapprovalAccess || this.maiapproval2Access) {
      amlTree.push(
        { title: 'Maintenance.Approval', path: 'maintenanceapproval', expand: false, children: [] }
      );
    }

    if (this.areaAccess) {
      amlTree.push(
        { title: 'Historian', path: 'historian', expand: false, children: [] }
      );

      amlTree.push(
        { title: 'Areas', path: 'area', expand: areaTree && areaTree.length > 0, children: areaTree }
      );
    }

    this.treeItems.splice(0);
    this.treeItems.push(
      { title: 'AML', path: '', expand: true, children: amlTree }
    );

    this.expandActivePathItems();
  }

  public checkClaims() {
    this.apiHost = this.appConfig.getConfig('apiHost');
    this.areaAccess = this.claimService.checkClaim('scope', 'area') && (this.claimService.checkClaimContains('aml.area', 'r'));
    this.audittrailAccess = this.claimService.checkClaim('scope', 'audittrail') && (this.claimService.checkClaimContains('aml.audittrail', 'r'));
    this.authmanAccess = this.claimService.checkClaim('scope', 'authman') && (this.claimService.checkClaimContains('aml.authman', 'r'));
    this.conchartAccess = this.claimService.checkClaim('scope', 'conchart') && (this.claimService.checkClaimContains('aml.conchart', 'r'));
    this.consettingsAccess = this.claimService.checkClaim('scope', 'consettings') && (this.claimService.checkClaimContains('aml.consettings', 'r'));
    this.documentAccess = this.claimService.checkClaim('scope', 'document') && (this.claimService.checkClaimContains('aml.document', 'r'));
    this.eventAccess = this.claimService.checkClaim('scope', 'event') && (this.claimService.checkClaimContains('aml.event', 'r'));
    this.hischartAccess = this.claimService.checkClaim('scope', 'hischart') && (this.claimService.checkClaimContains('aml.hischart', 'r'));
    this.inventoryAccess = this.claimService.checkClaim('scope', 'inventory') && (this.claimService.checkClaimContains('aml.inventory', 'r'));
    this.maiactAccess = this.claimService.checkClaim('scope', 'maiact') && (this.claimService.checkClaimContains('aml.maiact', 'r'));
    this.maiapprovalAccess = this.claimService.checkClaim('scope', 'maiapproval') && (this.claimService.checkClaimContains('aml.maiapproval', 'r'));
    this.maiapproval2Access = this.claimService.checkClaim('scope', 'maiapproval2') && (this.claimService.checkClaimContains('aml.maiapproval2', 'r'));
    this.operstateAccess = this.claimService.checkClaim('scope', 'operstate') && (this.claimService.checkClaimContains('aml.operstate', 'r'));
    this.perfindicAccess = this.claimService.checkClaim('scope', 'perfindic') && (this.claimService.checkClaimContains('aml.perfindic', 'r'));
    this.pvtAccess = this.claimService.checkClaim('scope', 'pvt') && (this.claimService.checkClaimContains('aml.pvt', 'r'));
    this.refmatsAccess = this.claimService.checkClaim('scope', 'refmats') && (this.claimService.checkClaimContains('aml.refmats', 'r'));
    this.reportAccess = this.claimService.checkClaim('scope', 'report') && (this.claimService.checkClaimContains('aml.report', 'r'));
    this.reportManagementAccess = this.claimService.checkClaim('scope', 'reportman') && (this.claimService.checkClaimContains('aml.reportman', 'r'));
    this.schedulerAccess = this.claimService.checkClaim('scope', 'scheduler') && (this.claimService.checkClaimContains('aml.scheduler', 'r'));
    this.usermanAccess = this.claimService.checkClaim('scope', 'userman') && (this.claimService.checkClaimContains('aml.userman', 'r'));
    this.valnoteAccess = this.claimService.checkClaim('scope', 'valnote') && (this.claimService.checkClaimContains('aml.valnote', 'r'));
    this.valprocedureAccess = this.claimService.checkClaim('scope', 'valprocedure') && (this.claimService.checkClaimContains('aml.valprocedure', 'r'));
    this.valsessionAccess = this.claimService.checkClaim('scope', 'valsession') && (this.claimService.checkClaimContains('aml.valsession', 'r'));
  }
}
