import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AppConfig } from '../../core/app.config';
import { AreaLayoutItem } from './area-layout-item';
import { AreaLayoutDiag } from './area-layout-diag';

@Injectable()
export class AreaLayoutService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  private areaLayoutCache: Map<number, Observable<AreaLayoutItem[]>> = new Map();
  private areaLayoutDiagCache: Map<number, Observable<AreaLayoutDiag[]>> = new Map();

  public getAreaLayout(id: number, force: boolean = false): Observable<AreaLayoutItem[]> {
    if (force || !this.areaLayoutCache.has(id)) {
      this.areaLayoutCache.set(id, this.http.get<AreaLayoutItem[]>(this.apiHost + 'arealayout/getarealayout/' + id).pipe(shareReplay()));
      setTimeout(() => this.areaLayoutCache.delete(id), 20000);
    }
    return this.areaLayoutCache.get(id);
  }

  public getAreaLayoutDiags(id: number, force: boolean = false): Observable<AreaLayoutDiag[]> {
    if (force || !this.areaLayoutDiagCache.has(id)) {
      this.areaLayoutDiagCache.set(id, this.http.get<AreaLayoutDiag[]>(this.apiHost + 'arealayout/getarealayoutdiags/' + id).pipe(shareReplay()));
      setTimeout(() => this.areaLayoutDiagCache.delete(id), 20000);
    }
    return this.areaLayoutDiagCache.get(id);
  }

  public quickAreaLayout(id: number, width: number, height: number): Observable<AreaLayoutItem[]> {
    return this.http.get<AreaLayoutItem[]>(this.apiHost + 'arealayout/quickarealayout/' + id + '?width=' + width + '&height=' + height);
  }

  public newAreaLayoutItem(id: number): Observable<AreaLayoutItem> { return this.http.get<AreaLayoutItem>(this.apiHost + 'arealayout/newarealayoutitem/' + id); }

  public saveOrUpdateAreaLayout(layout: AreaLayoutItem[], informationLevelId: number) {
    return this.http.put(this.apiHost + 'arealayout/saveorupdatearealayout/' + informationLevelId, layout);
  }

  public saveOrUpdateAreaLayoutDiags(layoutDiags: AreaLayoutDiag[], informationLevelId: number) {
    return this.http.put(this.apiHost + 'arealayout/saveorupdatearealayoutdiags/' + informationLevelId, layoutDiags);
  }
}
