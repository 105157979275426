import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { GridColumn } from '../grid/grid-column';
import { DataService } from '../data/data.service';
import { GridService } from '../grid/grid.service';
import { SelectionItem } from '../selection-item';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'data-form-item',
  templateUrl: './data-form-item.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class DataFormItemComponent implements OnInit, OnChanges, OnDestroy {
  constructor(
    private dataService: DataService,
    private gridService: GridService
  ) { }

  @Input() item: any;
  @Input() property: GridColumn;
  @Input() informationLevelId: number;
  @Input() selectionChanged: Observable<void>;
  @Output() selectionChangedEvent = new EventEmitter<void>();
  public i: number;
  private selectionChangedSubscription: Subscription;

  ngOnInit() {
    this.i = Math.floor(Math.random() * 999999);

    if (this.selectionChanged !== undefined) {
      this.selectionChangedSubscription = this.selectionChanged.subscribe(() => this.ngOnChanges());
    }
    if (this.property.type === 'selectOptions' && !this.property.selectOptions) {
      this.getSelectOptions();
    }
  }

  ngOnChanges() {
    if ((this.property.type === 'ioPoint' || this.property.type === 'ioPointNumeric' || this.property.type === 'ioPointBoolean' || this.property.type === 'ioPointText')
      && this.item && this.property) {
      if (this.item.informationLevelId) {
        this.informationLevelId = this.item.informationLevelId;
      } else if (this.item.measuringInstrumentId) {
        this.informationLevelId = this.item.measuringInstrumentId;
      } else if (this.item.instrumentId) {
        this.informationLevelId = this.item.instrumentId;
      }
    }
  }

  ngOnDestroy() {
    if (this.selectionChangedSubscription !== undefined) {
      this.selectionChangedSubscription.unsubscribe();
    }
  }

  onSelectionChanged() {
    this.selectionChangedEvent.emit();
  }

  getSelectOptions() {
    let args = ('?index=1&size=999&sort=;asc');
    if (this.property.listInputEndpoint) {
      this.dataService.getItems(this.property.listInputEndpoint, args).subscribe(data => {
        this.property.selectOptions = data;
      });
    } else {
      const obj = this.gridService.getListObject(this.property.header);
      this.dataService.getItems(obj.endpoint, args).subscribe(data => {
        this.property.selectOptions = data.map(item => {
          if (obj.idProperty.property !== undefined && obj.idProperty.property !== null) {
            const name = obj.viewProperties.map(property => item[property.property]).join(', ');
            return new SelectionItem(null, name, item[obj.idProperty.property]);
          } else {
            return new SelectionItem(null, item, item);
          }
        });
      });
    }
  }
}
