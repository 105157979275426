import { Component, Input, AfterContentChecked } from '@angular/core';

import { FavoriteService } from './favorite.service';
import { Favorite } from './favorite';

@Component({
  selector: 'favorite-toggle',
  templateUrl: './favorite-toggle.component.html',
  styleUrls: ['./favorite-toggle.component.css']
})

export class FavoriteToggleComponent implements AfterContentChecked {
  constructor(
    private favoriteService: FavoriteService
  ) { }

  @Input() informationLevelId: number;
  public isFavorite: boolean;

  ngAfterContentChecked() {
    this.isFavorite = this.favoriteService.isFavorite(this.informationLevelId);
  }

  async add() {
    this.favoriteService.postFavorite(this.informationLevelId);
    this.isFavorite = true;
  }

  async delete() {
    this.favoriteService.deleteFavorite(this.informationLevelId);
    this.isFavorite = false;
  }
}
