import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { LinearitySession } from './linearity-session';
import { LinearitySessionService } from './linearity-session.service';
import { LinearityChart } from './linearity-chart';
import { LinearityChartService } from './linearity-chart.service';
import { DataPoint } from '../../../../shared/chart/data-point';
import { DataSerie } from '../../../../shared/chart/data-serie';

@Component({
  selector: 'linearity-chart-view-chart',
  templateUrl: './linearity-chart-view-chart.component.html'
})
export class LinearityChartViewChartComponent implements OnInit, OnChanges {
  constructor(
    private linearitySessionService: LinearitySessionService,
    private linearityChartService: LinearityChartService
  ) { }
  @Input() public linearitySessionId: number;
  @Input() public linearitySession: LinearitySession;
  @Input() public linearityChart: LinearityChart;
  @Input() public view;
  @Output() public chartInitialized = new EventEmitter<boolean>();
  public chartData: DataSerie[];

  ngOnInit() {
    if (!this.linearitySession || !this.linearityChart) {
      this.linearitySessionService.getLinearitySession(this.linearitySessionId).subscribe(linearitySession => {
        this.linearitySession = linearitySession;
        this.linearityChartService.getLinearityChart(linearitySession.linearityChartId).subscribe(linearityChart => {
          this.linearityChart = linearityChart;
          this.getLinearityChartData();
        });
      });
    } else {
      this.getLinearityChartData();
    }
  }

  ngOnChanges() { // NOTE: The implication here is this will NOT be triggered by linearitySessionId changes, change implementation to save old id and check if it changed and reload session/chart first if so.
    if (this.linearitySession && this.linearityChart) {
      this.getLinearityChartData();
    }
  }

  getLinearityChartData() {
    if (this.linearitySession && this.linearityChart) {
      this.chartData = this.linearitySession.linearityReadings.map(lr => ({
        connected: true,
        name: lr.componentType.description,
        series: [
          ({
            name: 'Lean', params: {
              description: lr.componentType.description, symbol: lr.componentType.symbol, unit: lr.componentType.unit, areaValue: lr.leanAreaValue / lr.leanReadingValue * lr.calReadingValue
            }, value: lr.leanAreaValue / lr.leanReadingValue * lr.calReadingValue
          }) as DataPoint,
          ({
            name: 'Cal', params: {
              description: lr.componentType.description, symbol: lr.componentType.symbol, unit: lr.componentType.unit, areaValue: lr.calAreaValue
            }, value: lr.calAreaValue }) as DataPoint,
          ({
            name: 'Rich', params: {
              description: lr.componentType.description, symbol: lr.componentType.symbol, unit: lr.componentType.unit, areaValue: lr.richAreaValue / lr.richReadingValue * lr.calReadingValue
            }, value: lr.richAreaValue / lr.richReadingValue * lr.calReadingValue }) as DataPoint
        ],
        shape: 'circle'
      }) as DataSerie);
      this.chartInitialized.emit(true);
    }
  }
}
