<div class="header">
  <h1>{{ title | translate }}</h1>
</div>
<div class="grid-wrapper">
  <grid #grid [name]="'Report_Types'" [genericDataRetrieval]="false" [headers]="headers" [filterOptions]="filterOptions" [count]="count" [exportData]="reportTypes" (filterEvent)="getReportTypes($event)">
    <tr *ngFor="let reportType of reportTypes; let i = index; let odd = odd" [class.odd]="odd">
      <td><a routerLink="{{ reportType.id }}">{{ reportType.description | translate }}</a></td>
      <td *ngIf="reportType.reportEndpoints && reportType.reportEndpoints.length">
        <ng-container *ngFor="let endpoint of reportType.reportEndpoints" class="endpoint">
          <ng-container *ngTemplateOutlet="endpointNameTemplate; context: { tReportEndpoint: endpoint }"></ng-container>
        </ng-container>
        <ng-template #endpointNameTemplate let-reportEndpoint="tReportEndpoint">
          <span class="endpoint">{{ reportEndpoint.name | translate }}</span>
          <ng-container *ngIf="reportEndpoint.referencedEndpoints">
            <ng-container *ngFor="let referencedEndpoint of reportEndpoint.referencedEndpoints">
              <ng-container *ngTemplateOutlet="endpointNameTemplate; context: { tReportEndpoint: referencedEndpoint }"></ng-container>
            </ng-container>
          </ng-container>
        </ng-template>
      </td>
      <td *ngIf="!reportType.reportEndpoints || !reportType.reportEndpoints.length">{{ reportType.datasetName | translate }}</td>
      <td>{{ reportType.reportLevel | translate }}</td>
      <td *ngIf="reportType.enabled">{{ 'Yes' | translate }}</td>
      <td *ngIf="!reportType.enabled">{{ 'No' | translate }}</td>
      <td>
        <button class="button-blue" routerLink="{{ reportType.id }}">{{ 'Report.View' | translate }}</button>
        <button *ngIf="updateAccess" class="button-blue" (click)="editReportType(reportType)">{{ 'Edit' | translate }}</button>
        <button *ngIf="updateAccess" class="button-blue" (click)="copyReportType(reportType)">{{ 'Copy' | translate }}</button>
        <button *ngIf="updateAccess && !reportType.enabled" class="button-orange" (click)="toggleEnabled(reportType.id)">{{ 'Enable' | translate }}</button>
        <button *ngIf="updateAccess && reportType.enabled" class="button-orange" (click)="toggleEnabled(reportType.id)">{{ 'Disable' | translate }}</button>
        <button *ngIf="deleteAccess" class="button-red" (click)="deleteReportType(reportType.id)">{{ 'Delete' | translate }}</button>
      </td>
    </tr>
  </grid>
  <error-message [error]="error"></error-message>
  <div *ngIf="createAccess" class="control-buttons">
    <button class="button-blue" (click)="addReportType()">{{ 'Report.Add' | translate }}</button>
  </div>
</div>
<delete-dialog [visible]="deleteDialog" [error]="error" (confirmDelete)="confirmDelete($event)"></delete-dialog>
<custom-dialog [visible]="editDialog" *ngIf="editDialog" (editModeChanged)="dialogChanged($event)">
  <report-type-overview [reportType]="reportType" [userDefined]="false" [shownInDialog]="true" (editModeChanged)="dialogChanged($event)"></report-type-overview>
</custom-dialog>
