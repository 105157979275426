<div class="header">
  <h1>{{ 'Fidelity.Session' | translate }}</h1>
</div>
<form *ngIf="fidelitySession" (ngSubmit)="submitFidelitySession(fidelitySessionForm)" #fidelitySessionForm="ngForm">
  <table class="overview">
    <tr>
      <td>{{ 'DateTime' | translate }}:</td>
      <td *ngIf="editMode">
        <input [owlDateTime]="dtDateTime" [owlDateTimeTrigger]="dtDateTime" name="dateTime" [(ngModel)]="fidelitySession.dateTime" required #dateTime="ngModel" />
        <form-alert [field]="dateTime" name="DateTime"></form-alert>
        <owl-date-time #dtDateTime></owl-date-time>
      </td>
      <td *ngIf="!editMode">{{ fidelitySession.dateTime | date:dateTimeFormat }}</td>
    </tr>
  </table>
  <grid [name]="'Fidelity_Readings'" [title]="''" [genericDataRetrieval]="false" [headers]="headers" [exportData]="fidelitySession.fidelityReadings" [showFilters]="false" [showHeader]="false" [showFooter]="false" (filterEvent)="true" [showSortArrows]="false">
    <tr *ngFor="let fidelityReading of fidelitySession.fidelityReadings; let odd = odd; index as i" [class.odd]="odd">
      <td [title]="fidelityReading.componentType.description">{{ fidelityReading.componentType.description }}</td>
      <td [title]="fidelityReading.componentType.symbol">{{ fidelityReading.componentType.symbol }}</td>
      <td *ngIf="editMode"><input type="number" name="fidelityReading{{i}}" [(ngModel)]="fidelityReading.readingValue" required #fReading="ngModel" /><form-alert [field]="fReading" name="Value"></form-alert></td>
      <td *ngIf="!editMode">{{ fidelityReading.readingValue }}</td>
      <td [title]="fidelityReading.componentType.unit">{{ fidelityReading.componentType.unit }}</td>
    </tr>
  </grid>
  <error-message [error]="error"></error-message>
  <div *ngIf="editMode" class="control-buttons">
    <button class="button-gray" type="reset" (click)="cancel()">{{ 'Cancel' | translate }}</button>
    <submit-button name="Save" [disabled]="fidelitySessionForm.invalid"></submit-button>
  </div>
  <div *ngIf="!editMode" class="control-buttons-left">
    <button class="button-gray" type="reset" (click)="cancel()">{{ 'Close' | translate }}</button>
  </div>
</form>
