import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../../../../core/app.config';
import { FidelityChart } from './fidelity-chart';

@Injectable()
export class FidelityChartService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getFidelityCharts(args: string): Observable<FidelityChart[]> { return this.http.get<FidelityChart[]>(this.apiHost + 'fidelitychart/get' + args); }

  public getFidelityChartsByInstrument(instrumentId: number, args: string): Observable<FidelityChart[]> { return this.http.get<FidelityChart[]>(this.apiHost + 'fidelitychart/getbyinstrument/' + instrumentId + args); }

  public getFidelityChartCount(args: string): Observable<number> { return this.http.get<number>(this.apiHost + 'fidelitychart/count' + args); }

  public getFidelityChartCountByInstrument(instrumentId: number, args: string): Observable<number> { return this.http.get<number>(this.apiHost + 'fidelitychart/countbyinstrument/' + instrumentId + args); }

  public getFidelityChart(id: number): Observable<FidelityChart> { return this.http.get<FidelityChart>(this.apiHost + 'fidelitychart/get/' + id); }

  public postFidelityChart(fidelityChart: FidelityChart): Observable<number> { return this.http.post<number>(this.apiHost + 'fidelitychart/post', fidelityChart); }

  public putFidelityChart(fidelityChart: FidelityChart) { return this.http.put(this.apiHost + 'fidelitychart/put', fidelityChart); }

  public deleteFidelityChart(id: number) { return this.http.delete(this.apiHost + 'fidelitychart/delete/' + id); }

  public newFidelityChart(): FidelityChart {
    return { componentStreamId: null, instrumentId: null, fidelityGroups: [], id: null, logNumber: 10 };
  }
}
