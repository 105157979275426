<svg:g #ticksel>
  <svg:g *ngFor="let tick of ticks" class="tick" [attr.transform]="transform(tick)">
    <title>{{ tickFormat(tick) }}</title>
    <svg:text stroke-width="0.01"
              [attr.dy]="dy"
              [attr.x]="x1"
              [attr.y]="y1"
              [attr.text-anchor]="textAnchor"
              [style.font-size]="'12px'">
      {{ tickTrim(tickFormat(tick)) }}
    </svg:text>
  </svg:g>
</svg:g>
<svg:path *ngIf="referenceLineLength > 1 && refMax && refMin && showRefLines"
          class="reference-area"
          [attr.d]="referenceAreaPath"
          [attr.transform]="gridLineTransform()" />
<svg:g *ngFor="let tick of ticks" [attr.transform]="transform(tick)">
  <svg:g *ngIf="showGridLines" [attr.transform]="gridLineTransform()">
    <svg:line *ngIf="orient === 'left'"
              class="gridline-path gridline-path-horizontal"
              x1="0"
              [attr.x2]="gridLineWidth" />
    <svg:line *ngIf="orient === 'right'"
              class="gridline-path gridline-path-horizontal"
              x1="0"
              [attr.x2]="-gridLineWidth" />
  </svg:g>
</svg:g>
<svg:g *ngFor="let refLine of referenceLines">
  <svg:g *ngIf="showRefLines" [attr.transform]="transform(refLine.value)">
    <svg:line class="gridline-path-horizontal"
              x1="0"
              [attr.stroke]="refLine.params.color"
              [attr.x2]="gridLineWidth"
              [attr.transform]="gridLineTransform()" />
    <svg:g *ngIf="showRefLabels">
      <title>{{ tickTrim(tickFormat(refLine.value)) }}</title>
      <svg:text class="refline-label"
                [attr.dy]="dy"
                [attr.y]="-6"
                [attr.x]="gridLineWidth"
                [attr.text-anchor]="textAnchor">
        {{ refLine.name }}
      </svg:text>
    </svg:g>
  </svg:g>
</svg:g>
