<div *ngIf="controlChartSet" class="charts-block overview-wrapper" style="display: none;">
  <chart *ngIf="controlChartSet.precisionChart && precisionChartPoints"
          (select)="viewDetails($event)"
          [view]="[700,320]"
          [title]="controlChartSet.precisionChart.chartType | translate"
          [allowTypeChange]="false"
          [chartType]="'linechart'"
          [controlLimits]="[
            { 'name': 'UCL = ' + controlChartSet.precisionChart.ucl.toFixed(2), 'value': controlChartSet.precisionChart.ucl, 'params': { 'color': '#FF0000' } },
            { 'name': '0.00', 'value': 0, 'params': { 'color': '#000000' } }
          ]"
          [dataSeries]="[precisionChartPoints]"
          [yAxis]="unit"
          [customColors]="colors">
    <ng-template *ngIf="controlChartSet.precisionChart && precisionChartPoints" #tooltipTemplate let-model="model">
      <ng-container *ngIf="model">
        <table>
          <tr><td>{{ 'Validation.SessionId' | translate }}:</td><td>{{ model.validationSessionId }}</td></tr>
          <tr><td>{{ 'Validation.Start' | translate }}:</td><td>{{ model.validationStart | date:dateTimeFormat }}</td></tr>
          <tr><td>{{ 'Validation.End' | translate }}:</td><td>{{ model.validationEnd | date:dateTimeFormat }}</td></tr>
          <tr><td>{{ 'Validation.Procedure' | translate }}:</td><td>{{ model.validationProcedure | translate }}</td></tr>
          <tr><td></td><td></td></tr>
          <tr><td>{{ 'Value' | translate }}:</td><td>{{ model.value | number:'1.2-2' }} [{{ model.unit }}]</td></tr>
        </table>
      </ng-container>
    </ng-template>
  </chart>
</div>
<custom-dialog [visible]="valDataDialog" *ngIf="valDataDialog" (editModeChanged)="valDataDialogChanged($event)">
  <validation-data-overview [controlChartSetId]="id" [validationSessionId]="validationSessionId" [initiatedByReport]="true" [embedded]="true" (viewModeChanged)="valDataDialogChanged($event)"></validation-data-overview>
</custom-dialog>
