import { Component, EventEmitter, OnInit, OnDestroy, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ClaimService } from '../../core/claim.service';
import { Area } from '../area';
import { AreaService } from '../area.service';
import { Generic } from '../../shared/generic';
import { SidebarService } from '../../core/sidebar/sidebar.service';
import { ErrorMessage } from '../../shared/error/error-message';
import { SubmitButtonComponent } from '../../shared/submit-button/submit-button.component';

@Component({
  selector: 'area-overview',
  templateUrl: './area-overview.component.html'
})

export class AreaOverviewComponent implements OnInit, OnDestroy {
  constructor(
    private areaService: AreaService,
    private sidebarService: SidebarService,
    private route: ActivatedRoute,
    private router: Router,
    private claimService: ClaimService
  ) { }
  public title = 'Area.Area';
  @Input() public area: Area;
  @Input() public embedded = false;
  @Output() public editModeChanged = new EventEmitter<boolean>();
  @ViewChild(SubmitButtonComponent) submitButton: SubmitButtonComponent;
  public editMode = false;
  public id = -1;
  private areas: Generic[] = [];
  private areaTypes: string[] = [];
  public updateAccess: boolean;
  private routeSubscription: ISubscription;
  public error: ErrorMessage;

  ngOnInit() {
    this.error = null;
    this.updateAccess = this.claimService.checkClaimContains('aml.area', 'u');
    if (this.area == null) {
      this.id = +this.route.parent.snapshot.paramMap.get('id');
      this.getArea();
    } else {
      this.id = this.area.id;
      this.changeEditMode(true);
    }
    this.areaService.getParentCandidates(this.id).subscribe(areas => this.areas = areas);
    this.areaService.getAreaTypes().subscribe(types => this.areaTypes = types);
    this.routeSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(path => {
      this.id = +this.route.parent.snapshot.paramMap.get('id');
      this.editMode = false;
      this.getArea();
      this.areaService.getParentCandidates(this.id).subscribe(areas => this.areas = areas);
    });
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  getArea() {
    this.error = null;
    this.areaService.getArea(this.id).subscribe(area => {
      this.area = area;
    }, error => this.error = error.error);
  }

  submitArea(form: NgForm) {
    if (this.submitButton !== undefined && this.submitButton.submit()) {
      if (form.valid) {
        this.error = null;
        if (this.area.id < 1) { // POST
          this.areaService.postArea(this.area).subscribe(response => {
            this.id = response;
            this.area.id = response;
            this.submitButton.ready();
            this.changeEditMode(false);
            this.sidebarService.getTreeItems().subscribe();
            this.getArea();
          }, error => {
            this.error = error.error;
            this.submitButton.ready();
          });
        } else { // PUT
          this.areaService.putArea(this.area).subscribe(response => {
            this.submitButton.ready();
            this.changeEditMode(false);
            this.sidebarService.getTreeItems().subscribe();
            this.getArea();
          }, error => {
            this.error = error.error;
            this.submitButton.ready();
          });
        }
      } else {
        for (const i of Object.keys(form.controls)) {
          form.controls[i].markAsTouched();
        }
        this.submitButton.ready();
      }
    }
  }

  editArea() {
    this.changeEditMode(true);
  }

  cancel() {
    this.getArea();
    this.changeEditMode(false);
  }

  changeEditMode(editMode: boolean) {
    this.editMode = editMode;
    this.editModeChanged.emit(editMode);
  }
}
