<table *ngIf="editMode" class="overview">
  <tr>
    <td>{{ 'Instrument.Instrument' | translate }}: </td>
    <td>
      <input type="hidden" name="instrumentId" [(ngModel)]="section.informationLevelId" #instrument="ngModel" />
      <list-input [name]="'Area'" [model]="instrument" [endpoint]="'measuringinstrument'" [selectedId]="section.informationLevelId" (selectionChanged)="updateSection(section)"></list-input>
    </td>
  </tr>
  <tr>
    <td>{{ 'Chart.Type' | translate }}: </td>
    <td>
      <select [(ngModel)]="section.chartType" name="chartType" (change)="updateSection(section)">
        <option></option>
        <option value="ControlChart.ControlChart">{{ 'ControlChart.ControlChart' | translate }}</option>
        <option value="HistoricalChart.HistoricalChart">{{ 'HistoricalChart.HistoricalChart' | translate }}</option>
        <option value="GaussianChart.GaussianChart">{{ 'GaussianChart.GaussianChart' | translate }}</option>
        <option value="Fidelity.Chart">{{ 'Fidelity.Chart' | translate }}</option>
        <option value="Linearity.Chart">{{ 'Linearity.Chart' | translate }}</option>
      </select>
    </td>
  </tr>
  <tr *ngIf="section.informationLevelId && (section.chartType === 'ControlChart.ControlChart' || section.chartType === 'HistoricalChart.HistoricalChart' || section.chartType === 'GaussianChart.GaussianChart')">
    <td>{{ 'Component.Component' | translate }}: </td>
    <td>
      <input type="hidden" name="controlChartId" [(ngModel)]="section.chartId" #controlChart="ngModel" />
      <list-input [name]="'ControlChart'" [model]="controlChart" [endpoint]="'controlchart'" [selectedId]="section.chartId" [activeFilters]="chartFilters" (selectionChanged)="updateSection(section)"></list-input>
    </td>
  </tr>
  <tr *ngIf="section.informationLevelId && (section.chartType === 'Fidelity.Chart' || section.chartType === 'Linearity.Chart')">
    <td>{{ 'Stream.Stream' | translate }}: </td>
    <td>
      <select [(ngModel)]="section.chartId" name="chartId" (change)="updateSection(section)">
        <option></option>
        <option *ngFor="let chart of instrumentCharts" [value]="chart.id">{{ chart.stream }}</option>
      </select>
    </td>
  </tr>
</table>
<div class="overview-content" [ngSwitch]="section.chartType">
  <ng-container *ngSwitchCase="'ControlChart.ControlChart'">
    <control-chart-view *ngIf="section.chartId" [id]="section.chartId"></control-chart-view>
  </ng-container>
  <ng-container *ngSwitchCase="'HistoricalChart.HistoricalChart'">
    <historical-chart-event-chart *ngIf="section.chartId" [controlChartSetId]="section.chartId"></historical-chart-event-chart>
  </ng-container>
  <ng-container *ngSwitchCase="'GaussianChart.GaussianChart'">
    <gaussian-chart *ngIf="section.chartId" [controlChartSetId]="section.chartId"></gaussian-chart>
  </ng-container>
  <ng-container *ngSwitchCase="'Fidelity.Chart'">
    <fidelity-chart-view *ngIf="section.chartId" [id]="section.chartId"></fidelity-chart-view>
  </ng-container>
  <ng-container *ngSwitchCase="'Linearity.Chart'">
    <linearity-chart-view *ngIf="section.chartId" [id]="section.chartId"></linearity-chart-view>
  </ng-container>
</div>
<div *ngIf="!editMode && (!section.chartType || !section.chartId)">{{ 'aml.SectionNotConfigured' | translate }}</div>
