import { Component, Input, Output, OnInit, OnChanges, EventEmitter, ViewChild } from '@angular/core';

import { HistoricalChartEvent } from './historical-chart-event';
import { HistoricalChartEventService } from './historical-chart-event.service';
import { VrgInclusion } from '../control-chart/vrg-inclusion';
import { ClaimService } from '../../../../core/claim.service';
import { ErrorMessage } from '../../../../shared/error/error-message';
import { Header } from '../../../../shared/grid/header';
import { FilterOption } from '../../../../shared/data/filter-option';
import { AppConfig } from '../../../../core/app.config';
import { SubmitButtonComponent } from '../../../../shared/submit-button/submit-button.component';

@Component({
  selector: 'historical-chart-event',
  templateUrl: './historical-chart-event.component.html'
})
export class HistoricalChartEventComponent implements OnInit, OnChanges {
  constructor(
    private historicalChartEventService: HistoricalChartEventService,
    private claimService: ClaimService,
    private appConfig: AppConfig
  ) { }
  @Input() public controlChartSetId: number;
  @Input() public dataChange: boolean; // Used for triggering ngOnChanges.
  @Output() public exclusionChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public showIncludedOnly: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(SubmitButtonComponent) submitButton: SubmitButtonComponent;
  public headers: Header[] = [{ header: 'Type', sort: true, width: '13%' }, { header: 'StartDateTime', sort: true, width: '10%' }, { header: 'EndDateTime', sort: true, width: '10%' },
    { header: 'ResponsibleUser', sort: true, width: '8%' }, { header: 'Description', sort: false, width: '15%' }, { header: 'Statistics', sort: false, width: '15%' },
    { header: 'PlottedVariable', sort: true, width: '10%' }, { header: 'Excluded', sort: true, width: '6%' }, { header: 'Outlier', sort: false, width: '5%' },
    { header: 'Action', sort: false, width: '7%' }];
  public filterOptions: FilterOption[] = [{ name: 'Type', type: 'name' }, { name: 'StartDateTime', type: 'string' }, { name: 'EndDateTime', type: 'string' },
    { name: 'ResponsibleUser', type: 'string' }, { name: 'PlottedVariable', type: 'numeric' }, { name: 'Excluded', type: 'boolean' }];
  public count = 0;
  public historicalChartEvents: HistoricalChartEvent[];
  public historicChartEvent: HistoricalChartEvent;
  public passed: boolean;
  public onlyIncluded = true;
  public detailDialog: boolean;
  public updateAccess: boolean;
  private args = '';
  public error: ErrorMessage;
  private dateTimeFormat: string;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.updateAccess = this.claimService.checkClaimContains('aml.valsession', 'u');
    this.getHistoricalChartEvents(this.args);
  }

  ngOnChanges() {
    this.getHistoricalChartEvents(this.args);
  }

  getHistoricalChartEvents(args: string) {
    this.args = args;
    this.historicalChartEventService.getHistoricalChartEventsByInstrument(this.controlChartSetId, args, this.onlyIncluded).subscribe(hces => this.historicalChartEvents = hces);
    this.historicalChartEventService.getHistoricalChartEventCount(this.controlChartSetId, args, this.onlyIncluded).subscribe(hvec => this.count = hvec);
  }

  viewHistoricalChartEventDetails(historicalChartEvent: HistoricalChartEvent) {
    this.historicChartEvent = historicalChartEvent;
    this.detailDialog = true;
  }

  evaluateCharts() {
    if (this.submitButton !== undefined && this.submitButton.submit()) {
      this.error = null;
      this.historicalChartEventService.updateValidationReadingGroupExclusion(this.controlChartSetId,
        this.historicalChartEvents.filter(hce => hce.eventType === 'Chart.Events.ValidationData').map(historicalChartEvent => <VrgInclusion>{ exclude: historicalChartEvent.excluded, id: historicalChartEvent.id, reset: false })).subscribe(response => {
          this.getHistoricalChartEvents(this.args);
          this.submitButton.ready();
          this.exclusionChange.emit(true);
        }, error => {
          this.error = error.error;
          this.submitButton.ready();
        });
    }
  }

  dialogChanged(event: boolean) {
    this.detailDialog = event;
  }

  changeInclusionView() {
    this.onlyIncluded = !this.onlyIncluded;
    this.showIncludedOnly.emit(this.onlyIncluded);
    this.getHistoricalChartEvents(this.args);
  }
}
