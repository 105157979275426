import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { ActiveFilter } from './active-filter';
import { DataService } from './data.service';

@Component({
  selector: 'data-list-filter-view',
  templateUrl: './data-list-filter-view.component.html',
  styleUrls: ['./data-list-filter-view.component.css']
})

export class DataListFilterViewComponent implements OnInit, OnDestroy {
  constructor(
    private dataService: DataService
  ) { }

  @Input() filter: ActiveFilter = null;
  @Input() filterChanged: Observable<void>;
  @Input() editMode: boolean = false;
  @Output() filterChangedEvent = new EventEmitter();
  public data: any[] = [];
  public names: string;
  private endpoint: string = null;
  private filterChangedSubscription: Subscription;

  ngOnInit() {
    if (this.filterChanged !== undefined) {
      this.filterChangedSubscription = this.filterChanged.subscribe(() => this.getData());
    }

    if (this.filter !== null && this.filter.filterOption !== undefined && this.filter.filterOption !== null) {
      switch (this.filter.filterOption) {
        case 'AreasOrInstruments':
          this.endpoint = 'informationlevel';
          break;
        case 'Areas':
          this.endpoint = 'area';
          break;
        case 'Instruments':
          this.endpoint = 'measuringinstrument';
          break;
        case 'IoPoint.IoPoints':
          this.endpoint = 'iopoint';
          break;
      }

      this.getData();
    }
  }

  ngOnDestroy() {
    if (this.filterChangedSubscription !== undefined) {
      this.filterChangedSubscription.unsubscribe();
    }
  }

  getData() {
    if (this.endpoint !== null && this.filter !== null && this.filter.filterText !== null && this.filter.filterText.length > 2) {
      const args = '?filters=' + this.cleanString(JSON.stringify([this.filter]));
      this.dataService.getItems(this.endpoint, args).subscribe(async data => {
        this.data = data;

        if (data !== null && data.length > 0) {
          if (data[0].name !== undefined) {
            this.names = data.map(item => item.name).join(', ');
          } else if (data[0].tag !== undefined) {
            this.names = data.map(item => item.tag).join(', ');
          }
        }
      });
    } else {
      this.data = null;
      this.names = null;
    }
  }

  cleanString(url: string) {
    return url.replace(/%/gi, '%25')
      .replace(/&/gi, '%26')
      .replace(/#/gi, '%23');
  }

  delete(id: number) {
    const ids: number[] = JSON.parse(this.filter.filterText);
    ids.splice(ids.indexOf(id), 1);
    this.filter.filterText = JSON.stringify(ids);
    this.getData();
    this.filterChangedEvent.next();
  }
}
