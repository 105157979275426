<form *ngIf="linearityChart" (ngSubmit)="submitLinearityChart(linearityChartForm)" #linearityChartForm="ngForm">
  <table class="overview">
    <tr>
      <td>{{ 'Stream.Stream' | translate }}:</td>
      <td>
        <select [(ngModel)]="linearityChart.componentStreamId" name="componentStreamId" required>
          <option [ngValue]="null"></option>
          <option *ngFor="let stream of streams" [ngValue]="stream.id">{{ stream.tag }}</option>
        </select>
      </td>
    </tr>
  </table>
  <ng-container *ngIf="editMode">
    <div class="control-buttons">
      <button class="button-gray" type="reset" (click)="cancel()">{{ 'Cancel' | translate }}</button>
      <submit-button name="Save" [disabled]="linearityChartForm.invalid"></submit-button>
    </div>
  </ng-container>
</form>
