import { Component, Input } from '@angular/core';

import { Diagnostic } from '../diagnostic';

@Component({
  selector: 'diagnostic-display',
  templateUrl: './diagnostic-display.component.html'
})

export class DiagnosticDisplayComponent {
  constructor() { }
  @Input() public diagnostic: Diagnostic;

  diagnosticStyle(diagnostic: Diagnostic): any {
    const style: any = {};
    if (diagnostic.displayParameters) {
      if (diagnostic.io != null && diagnostic.io.dataType === 'boolean') {
        if (diagnostic.displayParameters.trueColor && diagnostic.processValue && parseInt(diagnostic.processValue, 10) === 1) {
          style.color = diagnostic.displayParameters.trueColor;
        } else {
          style.color = diagnostic.displayParameters.falseColor;
        }
      } else {
        if (diagnostic.displayParameters.textColor) {
          style.color = diagnostic.displayParameters.textColor;
        }
      }
    }
    return style;
  }

  numberPipeArguments(diagnostic: Diagnostic): string {
    if (diagnostic.displayParameters && diagnostic.displayParameters.numberOfDecimals > 0) {
      return '1.' + diagnostic.displayParameters.numberOfDecimals + '-' + diagnostic.displayParameters.numberOfDecimals;
    } else {
      return '1.0-0';
    }
  }

  booleanDiagnosticOutput(diagnostic: Diagnostic): string {
    if (!diagnostic.displayParameters) {
      return 'N.A.';
    }
    return (diagnostic.processValue !== 'N.A.') ? (diagnostic.processValue && parseInt(diagnostic.processValue, 10) === 1 ?
      diagnostic.displayParameters.trueInput : diagnostic.displayParameters.falseInput) : diagnostic.processValue;
  }
}
