import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppConfig } from '../../app.config';
import { ReportType } from '../report-type';
import { ReportDataset } from './report-dataset';
import { ReportDatasetProperty } from './report-dataset-property';
import { GridService } from '../../../shared/grid/grid.service';
import { ReportEndpoint } from '../report-endpoint';
import { Endpoint } from '../../../shared/data/endpoint';
import { ModelMetaData } from '../../../shared/data/model-meta-data';

@Injectable()
export class ReportManagementService {
  constructor(
    private http: HttpClient,
    private appConfig: AppConfig,
    private gridService: GridService
  ) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getReportTypes(filterArguments: string): Observable<ReportType[]> { return this.http.get<ReportType[]>(this.apiHost + 'reporttype/get' + filterArguments); }

  public getReportType(id: number): Observable<ReportType> { return this.http.get<ReportType>(this.apiHost + 'reporttype/get/' + id); }

  public getFullReportType(id: number): Observable<ReportType> { return this.http.get<ReportType>(this.apiHost + 'reporttype/getfull/' + id); }

  public getReportTypeCount(filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'reporttype/count' + filterArguments); }

  public postReportType(reportType: ReportType) { return this.http.post(this.apiHost + 'reporttype/post', reportType); }

  public putReportType(reportType: ReportType) { return this.http.put(this.apiHost + 'reporttype/put', reportType); }

  public deleteReportType(id: number) { return this.http.delete(this.apiHost + 'reporttype/delete/' + id); }

  public newReportType(): ReportType {
    return {
      id: -1, description: '', datasetName: '', datasetId: null, reportLevel: 'ReportLevel.All', pageOrientation: 'landscape', enabled: true,
      username: '', userId: '', updateDateTime: '', reportEndpoints: null, reportSections: [], dataSelection: null, periodSelection: null, reportParameters: []
    };
  }

  public getReportDatasets(): Observable<ReportDataset[]> { return this.http.get<ReportDataset[]>(this.apiHost + 'reportdataset/getall'); }

  public getReportDataset(id: number): Observable<ReportDataset> { return this.http.get<ReportDataset>(this.apiHost + 'reportdataset/get/' + id); }

  public getReportLevels(): Observable<string[]> { return this.http.get<string[]>(this.apiHost + 'reporttype/getreportlevels'); }

  public getReportDatasetProperties(datasetId: number): Observable<ReportDatasetProperty[]> { return this.http.get<ReportDatasetProperty[]>(this.apiHost + 'reportdataset/getdatasetproperties/' + datasetId); }

  public toggleReportTypeEnabled(id: number) { return this.http.put(this.apiHost + 'reporttype/toggleenabled', id); }

  public getReportLogo(): Observable<string> { return this.http.get(this.apiHost + 'reporttype/getlogo', { responseType: 'text' }); }

  public putReportLogo(image: string) { return this.http.put(this.apiHost + 'reporttype/putlogo', { id: 0, name: image }); }

  //User Defined Reports
  public getUserDefinedReports(filterArguments: string): Observable<ReportType[]> { return this.http.get<ReportType[]>(this.apiHost + 'reportuserdefined/get' + filterArguments); }

  public getUserDefinedReportCount(filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'reportuserdefined/count' + filterArguments); }

  public getAllUserDefinedReports(filterArguments: string): Observable<ReportType[]> { return this.http.get<ReportType[]>(this.apiHost + 'reportuserdefined/getall' + filterArguments); }

  public getAllUserDefinedReportCount(filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'reportuserdefined/countall' + filterArguments); }

  public postUserDefinedReport(reportType: ReportType) { return this.http.post(this.apiHost + 'reportuserdefined/post', reportType); }

  public putUserDefinedReport(reportType: ReportType) { return this.http.put(this.apiHost + 'reportuserdefined/put', reportType); }

  public deleteUserDefinedReport(id: number) { return this.http.delete(this.apiHost + 'reportuserdefined/delete/' + id); }

  public toggleUserDefinedReportEnabled(id: number) { return this.http.put(this.apiHost + 'reportuserdefined/toggleenabled', id); }

  public getEndpoints(): Observable<Endpoint[]> { return this.http.get<any[]>(this.apiHost + 'endpoint/get'); }

  public getEndpointMetaData(endpoint: string): Observable<ModelMetaData[]> { return this.http.get<ModelMetaData[]>(this.apiHost + endpoint + '/getmetadata'); }

  public getReportMetaData(endpoints: ReportEndpoint[]): Observable<ModelMetaData[]> {
    return this.http.get<ModelMetaData[]>(this.apiHost + 'reportdata/getmetadata?endpoints=' + JSON.stringify(endpoints));
  }

  public getReportMetaDataProperties(metaData: ModelMetaData[], dataPath = 'root'): ReportDatasetProperty[] {
    const properties: ReportDatasetProperty[] = [];
    dataPath = dataPath.charAt(0).toLowerCase() + dataPath.slice(1);
    metaData.filter(m => m.filterType !== 'list').forEach(property => {
      if (property.properties !== null && property.properties.length > 0) {
        const items = this.getReportMetaDataProperties(property.properties, dataPath + '/' + property.name.charAt(0).toLowerCase() + property.name.slice(1));
        properties.push({ name: property.name.charAt(0).toLowerCase() + property.name.slice(1), title: property.name.replace(/([A-Z])/g, ' $1'), type: 'List', dataPath, translatable: false, items });
      } else {
        const columns = this.gridService.getColumns([property.name], false);
        if (columns.length > 0) {
          properties.push(new ReportDatasetProperty(columns[0], dataPath));
        }
      }
    });
    return properties;
  }
}
