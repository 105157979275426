<div class="header">
  <h1>{{ title }}</h1>
</div>
<select *ngIf="allowTypeChange" [(ngModel)]="chartType" (change)="selectChartType.emit(chartType)">
  <option [ngValue]=""></option>
  <option *ngIf="points" [ngValue]="'horbarchart'">{{ 'Chart.HorizontalBar' | translate }}</option>
  <option *ngIf="points" [ngValue]="'verbarchart'">{{ 'Chart.VerticalBar' | translate }}</option>
  <option *ngIf="points" [ngValue]="'piechart'">{{ 'Chart.Pie' | translate }}</option>
  <!--<option *ngIf="points" [ngValue]="'advpiechart'">{{ 'Chart.AdvancedPie' | translate }}</option>
  <option *ngIf="points" [ngValue]="'piegridchart'">{{ 'Chart.PieGrid' | translate }}</option>-->
  <option *ngIf="points" [ngValue]="'treemapchart'">{{ 'Chart.TreeMap' | translate }}</option>
  <option *ngIf="points" [ngValue]="'numbercardchart'">{{ 'Chart.NumberCard' | translate }}</option>
  <option *ngIf="points" [ngValue]="'gaugechart'">{{ 'Chart.Gauge' | translate }}</option>
  <option *ngIf="points" [ngValue]="'donutchart'">{{ 'Chart.Donut' | translate }}</option>
  <option *ngIf="series" [ngValue]="'clinechart'">{{ 'Chart.Line' | translate }}</option>
  <option *ngIf="series" [ngValue]="'polarchart'">{{ 'Chart.Polar' | translate }}</option>
  <!--<option *ngIf="series && dataSeries.length > 1" [ngValue]="'grohorbarchart'">{{ 'Chart.GroupedHorizontalBar' | translate }}</option>
  <option *ngIf="series && dataSeries.length > 1" [ngValue]="'groverbarchart'">{{ 'Chart.GroupedVerticalBar' | translate }}</option>
  <option *ngIf="series && dataSeries.length > 1" [ngValue]="'stahorbarchart'">{{ 'Chart.StackedHorizontalBar' | translate }}</option>
  <option *ngIf="series && dataSeries.length > 1" [ngValue]="'staverbarchart'">{{ 'Chart.StackedVerticalBar' | translate }}</option>
  <option *ngIf="series && dataSeries.length > 1" [ngValue]="'norhorbarchart'">{{ 'Chart.NormalizedHorizontalBar' | translate }}</option>
  <option *ngIf="series && dataSeries.length > 1" [ngValue]="'norverbarchart'">{{ 'Chart.NormalizedVerticalBar' | translate }}</option>-->
  <option *ngIf="series" [ngValue]="'areachart'">{{ 'Chart.Area' | translate }}</option>
  <option *ngIf="series && dataSeries.length > 1" [ngValue]="'stackedareachart'">{{ 'Chart.StackedArea' | translate }}</option>
  <option *ngIf="series && dataSeries.length > 1" [ngValue]="'norareachart'">{{ 'Chart.NormalizedArea' | translate }}</option>
  <!--<option *ngIf="series && dataSeries.length > 1" [ngValue]="'heatmapchart'">{{ 'Chart.HeatMap' | translate }}</option>
  <option *ngIf="bubble" [ngValue]="'bubblechart'">{{ 'Chart.Bubble' | translate }}</option>-->
</select>
<div #chart class="ngx-charts-container">
  <ngx-charts-bar-horizontal *ngIf="chartType === 'horbarchart'" [view]="view" [results]="dataPoints" [customColors]="customColors" [scheme]="scheme" [legend]="legend" [xAxis]="true" (select)="select.emit($event)"></ngx-charts-bar-horizontal>
  <ngx-charts-bar-vertical *ngIf="chartType === 'verbarchart'" [view]="view" [results]="dataPoints" [customColors]="customColors" [scheme]="scheme" [legend]="legend" [xAxis]="xAxis !== ''" [showXAxisLabel]="true" [xAxisLabel]="xAxis" [yAxis]="true" (select)="select.emit($event)"></ngx-charts-bar-vertical>
  <ngx-charts-pie-chart *ngIf="chartType === 'piechart'" [view]="view" [results]="dataPoints" [customColors]="customColors" [scheme]="scheme" [legend]="legend" (select)="select.emit($event)"></ngx-charts-pie-chart>
  <!--<ngx-charts-advanced-pie-chart *ngIf="chartType === 'advpiechart'" [view]="view" [results]="dataPoints" [customColors]="customColors" [scheme]="scheme"></ngx-charts-advanced-pie-chart>
  <ngx-charts-pie-grid *ngIf="chartType === 'piegridchart'" [view]="view" [results]="dataPoints" [customColors]="customColors" [scheme]="scheme"></ngx-charts-pie-grid>-->
  <ngx-charts-tree-map *ngIf="chartType === 'treemapchart'" [view]="view" [results]="dataPoints" [customColors]="customColors" [scheme]="scheme" (select)="select.emit($event)"></ngx-charts-tree-map>
  <ngx-charts-number-card *ngIf="chartType === 'numbercardchart'" [view]="view" [results]="dataPoints" [customColors]="customColors" [scheme]="scheme" (select)="select.emit($event)"></ngx-charts-number-card>
  <ngx-charts-gauge *ngIf="chartType === 'gaugechart'" [view]="view" [results]="dataPoints" [customColors]="customColors" [scheme]="scheme" [legend]="legend" (select)="select.emit($event)"></ngx-charts-gauge>
  <ngx-charts-pie-chart *ngIf="chartType === 'donutchart'" [view]="view" [results]="dataPoints" [customColors]="customColors" [scheme]="scheme" [doughnut]="true" [legend]="legend" [labels]="true" [trimLabels]="false" (select)="select.emit($event)"></ngx-charts-pie-chart>
  <aml-charts-line-chart *ngIf="chartType === 'linechart'" (select)="select.emit($event)" (selectSeries)="selectSeries.emit($event)" [showGridLines]="true" [xAxis]="xAxis !== ''" [showXAxisLabel]="true" [xAxisLabel]="xAxis" [yAxis]="yAxis !== ''" [showYAxisLabel]="true" [yAxisLabel]="yAxis" [xAxisTickFormatting]="xAxisFormatting" [view]="view" [results]="dataSeries" [customColors]="customColors" [scheme]="scheme" [referenceLines]="controlLimits" [showRefLines]="hasControlLimits" [timeline]="true" [tooltipTemplate]="tooltipTemplate" [yScaleMin]="yMin" [yScaleMax]="yMax" [legend]="legend"></aml-charts-line-chart>
  <aml-charts-timeline-chart *ngIf="chartType === 'timelinechart'" (select)="select.emit($event)" (selectSeries)="selectSeries.emit($event)" [showGridLines]="true" [xAxis]="xAxis !== ''" [showXAxisLabel]="true" [xAxisLabel]="xAxis" [yAxis]="yAxis !== ''" [showYAxisLabel]="true" [yAxisLabel]="yAxis" [yAxisTickFormatting]="yAxisFormatting" [view]="view" [barResults]="barDataSeries" [results]="dataSeries" [customColors]="customColors" [scheme]="scheme" [tooltipTemplate]="tooltipTemplate" [yScaleMin]="yMin" [yScaleMax]="yMax" [noBarWhenZero]="true"></aml-charts-timeline-chart>
  <ngx-charts-line-chart *ngIf="chartType === 'clinechart'" [view]="view" [results]="dataSeries" [customColors]="customColors" [scheme]="scheme" [timeline]="true" [yAxis]="true" [showYAxisLabel]="yAxis" [yAxisLabel]="yAxis" [xAxis]="true" [showXAxisLabel]="xAxis" [xAxisLabel]="xAxis" [xAxisTickFormatting]="xAxisFormatting" [yScaleMin]="yMin" [yScaleMax]="yMax"></ngx-charts-line-chart>
  <ngx-charts-polar-chart *ngIf="chartType === 'polarchart'" [view]="view" [results]="dataSeries" [customColors]="customColors" [scheme]="scheme"></ngx-charts-polar-chart>
  <!--<ngx-charts-bar-horizontal-2d *ngIf="chartType === 'grohorbarchart'" [view]="view" [results]="dataSeries" [customColors]="customColors" [scheme]="scheme"></ngx-charts-bar-horizontal-2d>
  <ngx-charts-bar-vertical-2d *ngIf="chartType === 'groverbarchart'" [view]="view" [results]="dataSeries" [customColors]="customColors" [scheme]="scheme"></ngx-charts-bar-vertical-2d>
  <ngx-charts-bar-horizontal-stacked *ngIf="chartType === 'stahorbarchart'" [view]="view" [results]="dataSeries" [customColors]="customColors" [scheme]="scheme"></ngx-charts-bar-horizontal-stacked>
  <ngx-charts-bar-vertical-stacked *ngIf="chartType === 'staverbarchart'" [view]="view" [results]="dataSeries" [customColors]="customColors" [scheme]="scheme"></ngx-charts-bar-vertical-stacked>
  <ngx-charts-bar-horizontal-normalized *ngIf="chartType === 'norhorbarchart'" [view]="view" [results]="dataSeries" [customColors]="customColors" [scheme]="scheme"></ngx-charts-bar-horizontal-normalized>
  <ngx-charts-bar-vertical-normalized *ngIf="chartType === 'norverbarchart'" [view]="view" [results]="dataSeries" [customColors]="customColors" [scheme]="scheme"></ngx-charts-bar-vertical-normalized>-->
  <ngx-charts-area-chart *ngIf="chartType === 'areachart'" [view]="view" [results]="dataSeries" [customColors]="customColors" [scheme]="scheme" [timeline]="true" [yAxis]="true" [xAxis]="true"></ngx-charts-area-chart>
  <ngx-charts-area-chart-stacked *ngIf="chartType === 'stackedareachart'" [view]="view" [results]="dataSeries" [customColors]="customColors" [scheme]="scheme" [timeline]="true" [yAxis]="true" [xAxis]="true"></ngx-charts-area-chart-stacked>
  <ngx-charts-area-chart-normalized *ngIf="chartType === 'norareachart'" [view]="view" [results]="dataSeries" [customColors]="customColors" [scheme]="scheme"></ngx-charts-area-chart-normalized>
  <!--<ngx-charts-heat-map *ngIf="chartType === 'heatmapchart'" [view]="view" [results]="dataSeries" [customColors]="customColors" [scheme]="scheme"></ngx-charts-heat-map>
  <ngx-charts-bubble-chart *ngIf="chartType === 'bubblechart'" [view]="view" [results]="dataBubbles"></ngx-charts>-->
</div>
<div class="export-button" (click)="exportPicture(chart)">{{ 'Export' | translate }}</div>
