import { GridColumn } from '../../../shared/grid/grid-column';

export class ReportDatasetProperty {
  constructor(column: GridColumn, dataPath = 'root') {
    this.name = column.property;
    this.title = column.header;
    this.type = column.type;
    this.dataPath = dataPath;
    this.translatable = column.translate;
  }

  name: string;
  title: string;
  type: string;
  dataPath: string;
  translatable: boolean;
  items: ReportDatasetProperty[];
}
