import { Component } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AppConfig } from './core/app.config';
import { ClaimService } from './core/claim.service';
import { GlobalsService } from './core/globals.service';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  constructor(
    private appConfig: AppConfig,
    private claimService: ClaimService,
    private oauthService: OAuthService,
    private globals: GlobalsService
  ) {
    this.configureWithNewConfigApi();
  }

  private eventAccess = false;
  public loggedIn = false;

  title = 'AML';
  sidebarVisibilityClassForSmallScreens = 'hidden-sidebar';

  private configureWithNewConfigApi() {
    this.oauthService.configure({
      issuer: this.appConfig.getConfig('issuer'),
      redirectUri: this.appConfig.getConfig('redirectUri'),
      postLogoutRedirectUri: this.appConfig.getConfig('postLogoutRedirectUri'),
      clientId: this.appConfig.getConfig('clientId'),
      scope: this.appConfig.getConfig('scope'),
      requireHttps: this.appConfig.getConfig('requireHttps'),
      silentRefreshRedirectUri: this.appConfig.getConfig('silentRefreshRedirectUri')
    });
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.login();
    this.oauthService.setupAutomaticSilentRefresh();
    const url = localStorage.getItem('url');
    if (url && url.length > 0) {
      const interval = setInterval(() => {
        if (this.oauthService.hasValidAccessToken()) {
          localStorage.setItem('url', '');

          if (!this.appConfig.inTestMode()) {
            window.location.href = url;
          }

          clearInterval(interval);
        }
      }, 300);
    }
  }

  public logout() {
    this.loggedIn = false;
    this.oauthService.logOut();
  }

  toggleSidebar() {
    this.sidebarVisibilityClassForSmallScreens = (this.sidebarVisibilityClassForSmallScreens === 'hidden-sidebar') ? 'active-sidebar' : 'hidden-sidebar';
  }

  allowDrop(ev: MouseEvent) {
    ev.preventDefault();
    this.globals.clientX = ev.clientX;
    this.globals.clientY = ev.clientY;
  }

  print() {
    window.print();
  }

  login() {
    this.oauthService.loadDiscoveryDocumentAndLogin()
      .then(() => {
        this.eventAccess = this.claimService.checkClaimContains('aml.event', 'r');
        this.loggedIn = true;
      }, () => {
        this.loggedIn = false;
        window.location.href = '';
      });
  }
}
