import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import * as jsPDF from 'jspdf';
import { asBlob } from 'html-docx-js-typescript';
import { Observable } from 'rxjs';

import { ReportType } from './report-type';
import { ReportSection } from './report-section';
import { ReportDatasetData } from './report-dataset-data';
import { ReportViewService } from './report-view.service';
import { DataService } from '../../shared/data/data.service';

@Injectable()
export class ReportExportService {
  constructor(
    private reportViewService: ReportViewService,
    private dataService: DataService
  ) {}

  exportToPdf(name: string, pageOrientation: string, pageImages: HTMLCollection, datetime: string, testing: boolean = false): Observable<boolean> {
    return new Observable(observer => {
      if (pageImages !== undefined && pageImages !== null && pageImages.length > 0 && pageOrientation !== null && (pageOrientation === 'landscape' || pageOrientation === 'portrait')) {
        const doc = new jsPDF({ orientation: pageOrientation, unit: 'pt', format: 'a4' });

        for (let i = 0; i < pageImages.length; i++) {
          if (i > 0) {
            doc.addPage();
            doc.page++;
          }

          const image = <HTMLImageElement>pageImages[i];

          if (pageOrientation === 'landscape') {
            doc.addImage(image.src, 'PNG', 15, 0, image.width * 3 / 4, image.height * 3 / 4, null, 'FAST', 0);
          } else {
            doc.addImage(image.src, 'PNG', 0, 15, image.width * 3 / 4, image.height * 3 / 4, null, 'FAST', 0);
          }
        }

        if (testing === false) {
          doc.save(name + '_export_' + datetime + '.pdf');
        }
        observer.next(true);
        observer.complete();
      } else {
        observer.error();
      }
    });
  }

  exportToWord(name: string, pageOrientation: string, htmlElement: Element, datetime: string, testing: boolean = false): Observable<boolean> {
    return new Observable(observer => {
      if (htmlElement !== null && pageOrientation !== null && (pageOrientation === 'landscape' || pageOrientation === 'portrait')) {
        const htmlContent: string = '<!doctype html><html lang="en"><head></head><body>' + htmlElement.innerHTML + '</body></html>';
        let converted;

        if (pageOrientation === 'landscape') {
          converted = asBlob(htmlContent, { orientation: 'landscape', margins: { top: 250, bottom: 0, left: 0, right: 0 } });
        } else {
          converted = asBlob(htmlContent, { orientation: 'portrait', margins: { top: 0, bottom: 0, left: 300, right: 0 } });
        }

        if (testing === false) {
          converted.then(data => saveAs(data, name + '_export_' + datetime + '.docx'));
        }
        observer.next(true);
        observer.complete();
      }
      observer.error();
    });
  }

  exportToExcel(name: string, reportId: number, reportType: ReportType, datetime: string): Observable<boolean> {
    return new Observable(observer => {
      this.getReportDataArray(reportId, reportType).subscribe(data => {
        if (data !== null) {
          const file: any = XLSX.writeFile(this.getXlsxWorkBook(data), name + '_export_' + datetime + '.xlsx', { bookType: 'xlsx', type: 'file' });
          observer.next(true);
          observer.complete();
        } else {
          observer.error();
        }
      }, error => {
        observer.error();
      });
    });
  }

  exportToCsv(name: string, reportId: number, reportType: ReportType, datetime: string): Observable<boolean> {
    return new Observable(observer => {
      this.getReportDataArray(reportId, reportType).subscribe(data => {
        if (data !== null) {
          const file: any = XLSX.writeFile(this.getXlsxWorkBook(data), name + '_export_' + datetime + '.csv', { bookType: 'csv', type: 'file' });
          observer.next(true);
          observer.complete();
        } else {
          observer.error();
        }
      }, error => {
        observer.error();
      });
    });
  }

  getXlsxWorkBook(jsonObject): XLSX.WorkBook {
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(jsonObject);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    return workbook;
  }

  getReportDataArray(reportId: number, reportType: ReportType): Observable<any[]> {
    return new Observable(observer => {
      if (reportType.reportEndpoints !== undefined && reportType.reportEndpoints !== null && reportType.reportEndpoints.length > 0) {
        this.reportViewService.getReportData(reportId).subscribe(dataset => {
          observer.next(this.linkDataToSections(reportType, dataset));
          observer.complete();
        }, error => {
          observer.error(error);
        });
      } else {
        this.reportViewService.getReportDataset(reportId, null).subscribe(dataset => {
          observer.next(this.linkDataToSections(reportType, dataset));
          observer.complete();
        }, error => {
          observer.error(error);
        });
      }
    });
  }

  linkDataToSections(reportType: ReportType, dataset: ReportDatasetData): any[] {
    if (dataset !== undefined && dataset !== null && dataset.data !== undefined && dataset.data !== null && dataset.data.length > 0 && reportType.reportSections !== undefined && reportType.reportSections !== null && reportType.reportSections.length > 0) {
      const rows: any[] = [];
      const headers = this.getHeaderRow(reportType.reportSections);
      rows.push(headers);
      const dataRows = this.getDataRows(reportType.reportSections, dataset.data);
      rows.push.apply(rows, dataRows);

      return rows;
    } else {
      return null;
    }
  }

  getHeaderRow(reportSections: ReportSection[]): any[] {
    const headers: any[] = [];

    if (reportSections !== null && reportSections.length > 0) {
      for (let i = 0; i < reportSections.length; i++) {
        if (reportSections[i].type !== 'container' && reportSections[i].headers !== undefined && reportSections[i].headers !== null && reportSections[i].headers.length > 0) {
          for (let j = 0; j < reportSections[i].headers.length; j++) {
            if (reportSections[i].headers[j] !== undefined && reportSections[i].headers[j] !== null && reportSections[i].headers[j].header !== null) {
              headers.push(reportSections[i].headers[j].header);
            } else {
              headers.push('');
            }
          }
        } else if (reportSections[i].type === 'container') {
          if (reportSections[i].sectionChildren !== undefined && reportSections[i].sectionChildren !== null && reportSections[i].sectionChildren.length > 0) {
            const headers1 = this.getHeaderRow(reportSections[i].sectionChildren);
            headers.push.apply(headers, headers1);
          }
        }
      }
    }

    return headers;
  }

  getDataRows(reportSections: ReportSection[], data: any[]): any[] {
    const rows: any[] = [];
    let dataTable: string = null;

    if (reportSections !== null && reportSections.length > 0 && data !== undefined && data !== null && data.length > 0) {
      for (let i = 0; i < reportSections.length; i++) {
        if (reportSections[i].type !== undefined && reportSections[i].type !== 'container' && reportSections[i].dataTable !== undefined && reportSections[i].dataTable !== null && reportSections[i].dataTable !== '') {
          dataTable = reportSections[i].dataTable;
        }
      }

      for (let i = 0; i < data.length; i++) {
        if (dataTable !== null) {
          if (data[i][dataTable] !== undefined && data[i][dataTable].length > 0) {
            for (let d = 0; d < data[i][dataTable].length; d++) {
              const row: string[] = [];

              for (let j = 0; j < reportSections.length; j++) {
                if (reportSections[j].content !== undefined && reportSections[j].content !== null && reportSections[j].content.length > 0) {
                  if (reportSections[j].content.length !== undefined) {
                    for (let k = 0; k < reportSections[j].content.length; k++) {
                      if (reportSections[j].dataTable !== undefined && reportSections[j].dataTable !== null && reportSections[j].dataTable !== '') {
                        if (data[i][reportSections[j].dataTable][d] !== undefined && data[i][reportSections[j].dataTable][d][reportSections[j].content[k].content] !== undefined
                          && data[i][reportSections[j].dataTable][d][reportSections[j].content[k].content] !== null
                          && data[i][reportSections[j].dataTable][d][reportSections[j].content[k].content] !== '') {
                          const content = this.dataService.transformToType(data[i][reportSections[j].dataTable][d][reportSections[j].content[k].content], reportSections[j].content[k].dataType, reportSections[j].content[k].translatable);
                          row.push(content);
                        } else {
                          row.push('');
                        }
                      } else if (data[i][reportSections[j].content[k].content] !== undefined && data[i][reportSections[j].content[k].content] !== null && data[i][reportSections[j].content[k].content] !== '') {
                        const content = this.dataService.transformToType(data[i][reportSections[j].content[k].content], reportSections[j].content[k].dataType, reportSections[j].content[k].translatable);
                        row.push(content);
                      } else {
                        row.push('');
                      }
                    }
                  } else {
                    row.push('');
                  }
                } else {
                  row.push('');
                }
              }

              rows.push(row);
            }
          } else {
            const row: string[] = [];

            for (let j = 0; j < reportSections.length; j++) {
              if (reportSections[j].content !== undefined && reportSections[j].content !== null && reportSections[j].content.length !== undefined && reportSections[j].content.length > 0) {
                for (let k = 0; k < reportSections[j].content.length; k++) {
                  if (data[i][reportSections[j].content[k].content] !== undefined && data[i][reportSections[j].content[k].content] !== null && data[i][reportSections[j].content[k].content] !== '') {
                    const content = this.dataService.transformToType(data[i][reportSections[j].content[k].content], reportSections[j].content[k].dataType, reportSections[j].content[k].translatable);
                    row.push(content);
                  } else {
                    row.push('');
                  }
                }
              }
            }

            rows.push(row);
          }
        } else {
          const row: string[] = [];
          let pushed: boolean = false;

          for (let j = 0; j < reportSections.length; j++) {
            if (reportSections[j].type !== 'container' && reportSections[j].content !== undefined && reportSections[j].content !== null) {
              if (reportSections[j].content.length !== undefined) {
                for (let k = 0; k < reportSections[j].content.length; k++) {
                  if (data[i][reportSections[j].content[k].content] !== undefined && data[i][reportSections[j].content[k].content] !== null && data[i][reportSections[j].content[k].content] !== '') {
                    const content = this.dataService.transformToType(data[i][reportSections[j].content[k].content], reportSections[j].content[k].dataType, reportSections[j].content[k].translatable);
                    row.push(content);
                  } else {
                    row.push('');
                  }
                }
              } else {
                row.push('');
              }
            } else if (reportSections[j].type === 'container') {
              if (reportSections[j].sectionChildren !== undefined && reportSections[j].sectionChildren !== null && reportSections[j].sectionChildren.length > 0
                && reportSections[j].dataTable !== undefined && reportSections[j].dataTable !== null && reportSections[j].dataTable !== ''
                && data[i][reportSections[j].dataTable] !== undefined && data[i][reportSections[j].dataTable] !== null && data[i][reportSections[j].dataTable] !== '') {
                const childRows = this.getDataRows(reportSections[j].sectionChildren, data[i][reportSections[j].dataTable]);

                if (childRows !== null && childRows.length > 0) {
                  for (let c = 0; c < childRows.length; c++) {
                    if (row.length > 0) {
                      for (let r = row.length - 1; r >= 0; r--) {
                        childRows[c].unshift(row[r]);
                      }
                    }

                    rows.push(childRows[c]);
                    pushed = true;
                  }
                }
              }
            } else {
              row.push('');
            }
          }

          if (!pushed) {
            rows.push(row);
          }
        }
      }
    }

    return rows;
  }
}
