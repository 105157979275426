import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../../core/app.config';
import { Note } from './note';

@Injectable()
export class NoteService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getNote(id: number, type: string): Observable<Note> { return this.http.get<Note>(this.apiHost + 'note/get/' + id + '?type=' + type); }

  public postNote(note: Note, id: number, type: string) { return this.http.post(this.apiHost + 'note/post?id=' + id + '&type=' + type, note); }

  public putNote(note: Note) { return this.http.put(this.apiHost + 'note/put', note); }
}
