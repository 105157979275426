import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { ErrorMessage } from '../../shared/error/error-message';
import { ActiveFilter } from '../../shared/data/active-filter';
import { Header } from '../../shared/grid/header';
import { Instrument } from '../instrument/instrument';
import { FilterService } from './filter.service';
import { InstrumentService } from '../instrument/instrument.service';
import { PerformanceIndicatorService } from '../../perf-indic/perf-indic.service';

@Component({
  selector: 'bad-actors',
  templateUrl: './bad-actors.component.html'
})

export class BadActorsComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private filterService: FilterService,
    private instrumentService: InstrumentService,
    private performanceIndicatorService: PerformanceIndicatorService,
    private router: Router
  ) { }
  @Input() public areaId = -1;
  @Input() public shownOnAreaOverview: boolean = false;
  public endpoint = 'measuringinstrument';
  public headers: Header[] = [{ header: 'Area.Name', sort: true, width: 'auto' }, { header: 'Tag', sort: true, width: 'auto' },
    { header: 'Criticality.Criticality', sort: true, width: 'auto' }, { header: 'PerformanceIndicator.AvailabilityRate', sort: true, width: 'auto' },
    { header: 'PerformanceIndicator.ReliabilityRate', sort: true, width: 'auto' }];
  public title = 'BadActors';
  public instruments: Instrument[];
  public count = 0;
  public error: ErrorMessage;
  private filterList: ActiveFilter[] = [];
  private sort: string = 'sort=Criticality.Criticality;asc';

  ngOnInit() {
    if (this.shownOnAreaOverview) {
      this.areaId = +this.route.parent.snapshot.paramMap.get('id');
    }
    this.getInstruments();

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(path => {
      if (this.shownOnAreaOverview) {
        this.areaId = +this.route.parent.snapshot.paramMap.get('id');
      }
      this.getInstruments();
    });
  }

  getInstruments() {
    this.error = null;
    this.filterService.getFilters('').subscribe(filters => {
      this.filterList = filters;
      if (filters !== null && filters.length > 0) {
        this.filterList.push({ id: -1, filterText: this.areaId.toString(), filterOption: 'Area.Area', filterDetails: { operator: '==' }, type: 'hidden', readonly: false });
        this.instrumentService.getInstrumentCount(this.getFilterGetArguments()).subscribe(count => this.count = count);
        this.instrumentService.getInstruments(this.getFilterGetArguments()).subscribe(instruments => {
          this.instruments = instruments;
          if (this.instruments !== null && this.instruments.length > 0) {
            this.instruments.forEach(instrument => {
              this.performanceIndicatorService.getPerformanceIndicatorSavedResults(instrument.id).subscribe(pi => {
                instrument.availabilityRate = pi.filter(i => i.name.includes('Availability Rate'))[0].formulaResult + ' ' + pi.filter(i => i.name.includes('Availability Rate'))[0].unit;
                instrument.reliabilityRate = pi.filter(i => i.name.includes('Reliability rate'))[0].formulaResult + ' ' + pi.filter(i => i.name.includes('Reliability rate'))[0].unit;
              });
            });
          }
        }, error => this.error = error.error);
      }
    }, error => this.error = error.error);
  }

  filterEv(event) {
    this.sort = event.substring(1).split('&')[0];
    this.getInstruments();
  }

  getFilterGetArguments(): string {
    return '?index=1&size=10&' + this.sort + '&filters=' + this.cleanString(JSON.stringify(this.filterList));
  }

  cleanString(url: string) {
    return url.replace(/%/gi, '%25').replace(/&/gi, '%26').replace(/#/gi, '%23');
  }
}
