import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialTypeFilterComponent } from './material-type-filter.component';
import { MaterialTypeService } from '../material-type.service';

@NgModule({
  declarations: [MaterialTypeFilterComponent],
  exports: [MaterialTypeFilterComponent],
  imports: [CommonModule, TranslateModule],
  providers: [MaterialTypeService]
})
export class MaterialTypeFilterModule { }
