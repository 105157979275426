import { HistoricalChartEventDescription } from './historical-chart-event-description';
import { Statistics } from '../control-chart/validation-data/statistics'; // TODO: Move statistics to a common (chart) folder?

export class HistoricalChartEvent {
  id: number;
  helperId: number;
  startDateTime: string;
  endDateTime: string;
  eventType: string;
  userName: string;
  plottedValue: number;
  unit: string;
  excluded: boolean;
  outlier: string;
  statistics: Statistics;
  eventDescriptionsList: HistoricalChartEventDescription[];
}
