<div class="header">
  <h1>{{ 'Fidelity.GroupSettings' | translate }}</h1>
</div>
<div class="overview-wrapper">
  <select [(ngModel)]="selectedCompType" name="selectedCompType">
    <option [ngValue]="null"></option>
    <option *ngFor="let compType of compTypes" [ngValue]="compType">{{compType.description}} ({{compType.symbol}}) [{{compType.unit}}]</option>
  </select>
  <button class="button-blue" type="button" (click)="addCompType()">{{ 'Component.Add' | translate }}</button>
  <table class="component-table">
    <thead>
      <tr>
        <td>{{ 'Description' | translate }}</td>
        <td>{{ 'Symbol' | translate }}</td>
        <td>{{ 'UnitOfMeasurement' | translate }}</td>
        <td>{{ 'Action' | translate }}</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let fidelityComponent of fidelityGroup.fidelityComponents">
        <td>{{ fidelityComponent.componentType.description }}</td>
        <td>{{ fidelityComponent.componentType.symbol }}</td>
        <td>{{ fidelityComponent.componentType.unit }}</td>
        <td><button class="button-red" type="button" (click)="deleteCompType(fidelityComponent)">{{ 'Delete' | translate }}</button></td>
      </tr>
    </tbody>
  </table>
  <div class="control-buttons">
    <button class="button-gray" type="reset" (click)="cancel()">{{ 'Cancel' | translate }}</button>
    <submit-button name="Save" (click)="save()"></submit-button>
  </div>
</div>
