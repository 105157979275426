import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private oauthService: OAuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let authToken = this.oauthService.getAccessToken();
    if (authToken === null) {
      authToken = '';
    }
    const authRequest = request.clone({ setHeaders: { Authorization: 'Bearer ' + authToken } });

    return next.handle(authRequest);
  }
}
