<div *ngIf="showHeader" class="grid-header" [ngClass]="{ 'only-buttons': showOnlyButtonsInHeader }">
  <span *ngIf="!showOnlyButtonsInHeader">{{ title | translate }}</span>
  <button *ngIf="name && columns.length" class="button-blue" (click)="configureColumns()"><i class="material-icons">settings</i> {{ 'Columns' | translate }}</button>
  <button *ngIf="showFilters && filterOptions" class="button-blue" (click)="configureFilters()"><i class="material-icons">filter_list</i> {{ 'Filters' | translate }}</button>
</div>
<loader *ngIf="loading"></loader>
<data-filter [filterList]="filterList" [editMode]="false" [showFilters]="showFilters" [filterChanged]="filterChanged"></data-filter>
<grid-table [name]="name" [headers]="headers" [endpoint]="endpoint" [rowActions]="rowActions" [customColumns]="customColumns" [data]="data" [count]="count" [visibleColumns]="visibleColumns"
            [genericDataRetrieval]="genericDataRetrieval" [metaDataChanged]="metaDataChanged" [showAutoRefresh]="showAutoRefresh" [showFooter]="showFooter" [showSortArrows]="showSortArrows"
            [showActionColumn]="showActionColumn" [translateHeader]="translateHeader" [exportData]="exportData" [autoRefresh]="autoRefresh" [autoRefreshTime]="autoRefreshTime"
            [dataArguments]="dataArguments" [editMode]="editMode" (refreshEvent)="refresh()">
  <ng-content></ng-content>
</grid-table>
<error-message [error]="error"></error-message>
<custom-dialog [visible]="columnDialog" *ngIf="columnDialog" (editModeChanged)="columnDialogChanged($event)">
  <grid-configuration [columns]="columns" (editModeChanged)="columnDialogChanged($event)"></grid-configuration>
</custom-dialog>
<custom-dialog [visible]="filterDialog" *ngIf="filterDialog" (editModeChanged)="filterDialogChanged($event)">
  <data-filter [parentId]="parentId" [informationLevelId]="informationLevelId" [instrumentId]="instrumentId" [areaId]="areaId" [equipmentId]="equipmentId"
               [componentStreamId]="componentStreamId" [chartId]="chartId" [controlChartSetId]="controlChartSetId" [fidelityChartId]="fidelityChartId" [ioClientId]="ioClientId"
               [maintenanceItemId]="maintenanceItemId" [viewAsDialog]="true" [filterOptions]="filterOptions" [filterList]="filterList" [editMode]="true"
               (filterChangedEvent)="refresh()" (editModeChanged)="filterDialogChanged($event)"></data-filter>
</custom-dialog>
