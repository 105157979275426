<div class="overview-wrapper">
  <div class="overview-header">Configuration</div>
  <div *ngIf="!columnsChanged">
    <error-message [error]="error"></error-message>
  </div>
  <form *ngIf="columnsChanged" (ngSubmit)="submit(configurationForm)" #configurationForm="ngForm">
    <table class="overview">
      <tr *ngFor="let item of columnsChanged">
        <td>{{ item.header | translate }}</td>
        <td><input type="checkbox" name="enabled" [checked]="item.enabled" (change)="item.enabled = !item.enabled" /></td>
      </tr>
    </table>
    <error-message [error]="error"></error-message>
    <div class="control-buttons">
      <button type="reset" class="button-gray" (click)="cancel()">Cancel</button>
      <button class="button-blue" type="submit" [ngClass]="{'disabled': configurationForm.invalid}">{{ 'Save' | translate }}</button>
    </div>
  </form>
</div>
