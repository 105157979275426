import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ReportComponent } from './report.component';
import { ReportOverviewComponent } from './report-overview.component';

const reportRoutes: Routes = [
  { path: '', component: ReportComponent },
  { path: ':reportId/:datasetId', component: ReportOverviewComponent },
  { path: ':reportId', component: ReportOverviewComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(reportRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class ReportRoutingModule { }
