<reference-material-change *ngIf="referenceMaterialId" [referenceMaterialId]="referenceMaterialId"></reference-material-change>
<control-limits-changed *ngIf="controlLimitId" [controlLimitId]="controlLimitId"></control-limits-changed>
<validation-data-overview *ngIf="validationSessionId && controlChartSetId" [validationSessionId]="validationSessionId" [controlChartSetId]="controlChartSetId"
                          [embedded]="true" [initiatedByReport]="initiatedByReport" (viewModeChanged)="cancel()"></validation-data-overview>
<!--<maintenance-record *ngIf="maintenanceRecordId && equipmentId" [id]="maintenanceRecordId" [equipmentId]="equipmentId" [embedded]="true"></maintenance-record>-->
<ng-container *ngIf="sampleSizeChange">
  <div class="header">
    <h1>{{ 'Chart.Events.SampleSizeChanged' | translate }}</h1>
  </div>
  <table class="overview">
    <tr>
      <td>{{ 'Validation.SessionId' | translate }}:</td>
      <td>{{ historicalChartEvent.id }}</td>
    </tr>
    <tr *ngFor="let eventDescription of historicalChartEvent.eventDescriptionsList">
      <td>{{ eventDescription.translatableString | translate }}:</td>
      <td>{{ eventDescription.valueString }}</td>
    </tr>
  </table>
</ng-container>
<div class="control-buttons">
  <button class="button-gray" (click)="cancel()">{{ 'Cancel' | translate }}</button>
</div>
