import { Component, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { FidelitySession } from './fidelity-session';
import { FidelitySessionService } from './fidelity-session.service';
import { Header } from '../../../../shared/grid/header';
import { ErrorMessage } from '../../../../shared/error/error-message';
import { AppConfig } from '../../../../core/app.config';
import { SubmitButtonComponent } from '../../../../shared/submit-button/submit-button.component';

@Component({
  selector: 'fidelity-session-overview',
  templateUrl: './fidelity-session-overview.component.html'
})
export class FidelitySessionOverviewComponent implements OnInit {
  constructor(
    private fidelitySessionService: FidelitySessionService,
    private appConfig: AppConfig
  ) { }
  @Input() public fidelitySession: FidelitySession;
  @Output() public editModeChanged = new EventEmitter<boolean>();
  @ViewChild(SubmitButtonComponent) submitButton: SubmitButtonComponent;
  public headers: Header[] = [{ header: 'Component.Type', sort: false, width: 'auto' }, { header: 'Symbol', sort: false, width: 'auto' },
    { header: 'Reading', sort: false, width: 'auto' }, { header: 'UnitOfMeasurement', sort: false, width: 'auto' }];
  private editMode: boolean;
  private dateTimeFormat: string;
  public error: ErrorMessage;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.editMode = !(this.fidelitySession && this.fidelitySession.id);
  }

  getFidelitySession() {
    this.error = null;
    this.fidelitySessionService.getFidelitySession(this.fidelitySession.id).subscribe(fidelitySession => this.fidelitySession = fidelitySession, error => this.error = error.error);
  }

  submitFidelitySession(form: NgForm) {
    if (this.submitButton !== undefined && this.submitButton.submit()) {
      if (form.valid) {
        this.error = null;
        if (this.fidelitySession.id > 0) { // PUT
          this.fidelitySessionService.putFidelitySession(this.fidelitySession).subscribe(response => {
            this.submitButton.ready();
            this.editModeChanged.emit(false);
          }, () => this.submitButton.ready());
        } else { // POST
          this.fidelitySessionService.postFidelitySession(this.fidelitySession).subscribe(response => {
            this.submitButton.ready();
            this.editModeChanged.emit(false);
          }, () => this.submitButton.ready());
        }
      } else {
        for (const i of Object.keys(form.controls)) {
          form.controls[i].markAsTouched();
        }
        this.submitButton.ready();
      }
    }
  }

  cancel() {
    this.getFidelitySession();
    this.editModeChanged.emit(false);
  }
}
