import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { IoSelectionComponent } from './io-selection.component';
import { IoService } from '../io.service';

@NgModule({
  declarations: [
    IoSelectionComponent
  ],
  exports: [
    IoSelectionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
  ],
  providers: [
    IoService
  ]
})
export class IoSelectionModule { }
