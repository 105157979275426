<div id="layout-editor-left" class="overview-wrapper" [ngClass]="{'not-in-dialog':!shownInDialog}">
  <div class="overview-header">{{ 'ReportType.LayoutSettings' | translate }}</div>
  <div class="overview-content">
    <table class="overview">
      <tr>
        <td>{{ 'ReportType.PageOrientation' | translate }}:</td>
      </tr>
      <tr>
        <td *ngIf="!editMode">{{ reportType.pageOrientation | translate }}</td>
        <td *ngIf="editMode">
          <select [(ngModel)]="reportType.pageOrientation" name="pageOrientation" required (ngModelChange)="resetPreview(reportType.pageOrientation)" #pageOrientation="ngModel">
            <option [ngValue]="'portrait'">{{ 'portrait' | translate }}</option>
            <option [ngValue]="'landscape'">{{ 'landscape' | translate }}</option>
          </select>
          <form-alert [field]="pageOrientation" name="ReportType.PageOrientation"></form-alert>
        </td>
      </tr>
    </table>
    <div *ngIf="editMode && selectedSection" class="selected-section-settings">
      <div>{{ 'ReportType.SelectedSection' | translate }}</div>
      <span><a (click)="deselectSection()">{{ 'ReportType.DeselectSection' | translate }}</a></span>
      <table class="overview" *ngIf="selectedSection.type === 'detailsTable' || selectedSection.type === 'overviewTableVertical'">
        <tr>
          <td>{{ 'ReportType.HeaderColumnWidth' | translate }}:</td>
        </tr>
        <tr>
          <td>
            <input type="text" [(ngModel)]="selectedSectionColumnWidth" (ngModelChange)="setColumnWidth(selectedSectionColumnWidth, selectedSection.originalSection, 0)" />
            <div><a (click)="resetColumnWidth(selectedSection)">{{ 'ReportType.ResetColumnWidth' | translate }}</a></div>
          </td>
        </tr>
        <tr *ngIf="selectedSection.changed">
          <td><a (click)="applyAndResetPreview(reportType.pageOrientation)">{{ 'ReportType.ReloadPreviewAfterWidthChanges' | translate }}</a></td>
        </tr>
      </table>
      <div *ngIf="selectedSection.type === 'overviewTable'"><a (click)="resetColumnWidth(selectedSection)">{{ 'ReportType.ResetColumnWidth' | translate }}</a></div>
    </div>
  </div>
</div>
<div id="report-editor-main" class="" [ngClass]="{'not-in-dialog':!shownInDialog}">
  <div id="report-preview" class="report" *ngIf="reportType && reportPages">
    <div class="report-header">
      {{ 'ReportType.Preview' | translate }}
    </div>
    <div class="overview-content">
      <div class="report-page-spacer"></div>
      <ng-container *ngFor="let reportPage of reportPages">
        <div class="report-page" [ngStyle]="{ 'width': pageWidth + 'px', 'height': pageHeight + 'px' }">
          <div class="report-page-content">
            <ng-container *ngFor="let section of reportPage">
              <ng-container *ngTemplateOutlet="sectionTemplate; context: { tSection: section }"></ng-container>
            </ng-container>
          </div>
        </div>
        <div class="report-page-spacer"></div>
      </ng-container>
    </div>
  </div>
  <div class="report-export-pages"></div>
</div>

<ng-template #sectionTemplate let-section="tSection">
  <div *ngIf="section.type === 'pageBreak'" class="report-section report-page-break">
    <br />
  </div>
  <div *ngIf="section.type === 'header'" class="page-header">
    <table>
      <tr>
        <td>
          <div class="report-title">{{ reportType.description | translate }}</div>
          <div class="report-sub-title">{{ section.content | translate }}</div>
        </td>
        <td><img class="report-logo" *ngIf="logo" [src]="logo" /></td>
      </tr>
    </table>
  </div>
  <div class="section-wrapper" (click)="sectionClick(section)" [ngClass]="{ 'highlight': section.highlight }">
    <div *ngIf="section.title !== undefined && section.title !== null" class="report-section-title">
      {{ section.title }}
    </div>
    <div *ngIf="section.type === 'detailsTable'" class="report-section report-detailsTable">
      <table class="{{section.type}}_{{section.title}}">
        <tr *ngFor="let item of Array(section.headers.length).fill(0), let i = index">
          <th [ngStyle]="{ 'width': section.originalSection.headers[i].width }">
            <div *ngIf="editMode" class="column-dragger" (mousedown)="columnResizeOnClick($event, section, i)" (drag)="doColumnResize($event, section, i)" draggable="true"></div>
            {{ section.headers[i].header }}
          </th>
          <td *ngIf="section.headers[i].header && section.content.length">: </td>
          <td *ngIf="!section.headers[i].header">&nbsp;</td>
          <td *ngIf="section.content[i]">{{ section.content[i].content }}</td>
          <td *ngIf="!section.content[i]">&nbsp;</td>
        </tr>
      </table>
    </div>
    <div *ngIf="section.type === 'overviewTable'" class="report-section report-overviewTable">
      <table class="{{section.type}}_{{section.title}}" summary="section.title">
        <tr>
          <th *ngFor="let header of section.headers, let i = index" [ngStyle]="{ 'width': section.originalSection.headers[i].width }">
            <div *ngIf="editMode && (i + 1) < section.headers.length" class="column-dragger" (mousedown)="columnResizeOnClick($event, section, i)" (drag)="doColumnResize($event, section, i)" draggable="true"></div>
            {{ header.header }}
          </th>
        </tr>
        <tr *ngFor="let row of section.content">
          <td *ngFor="let content of row">{{ content.content }}</td>
        </tr>
      </table>
    </div>
    <div *ngIf="section.type === 'overviewTableVertical'" class="report-section report-overviewTable">
      <table class="{{section.type}}_{{section.title}}" summary="section.title">
        <tr *ngFor="let item of Array(section.headers.length).fill(0), let i = index">
          <th [ngStyle]="{ 'width': section.originalSection.headers[i].width }">
            <div *ngIf="editMode" class="column-dragger" (mousedown)="columnResizeOnClick($event, section, i)" (drag)="doColumnResize($event, section, i)" draggable="true"></div>
            {{ section.headers[i].header }}
          </th>
          <td *ngFor="let content of section.content">{{ content[i].content }}</td>
        </tr>
      </table>
    </div>
    <div *ngIf="section.type === 'accuracyChart'" #chartSectionTemplate class="report-section report-chart report-accuracy-chart">
      {{ 'Chart.PlaceHolder' | translate }}
    </div>
    <div *ngIf="section.type === 'precisionChart'" #chartSectionTemplate class="report-section report-chart report-precision-chart">
      {{ 'Chart.PlaceHolder' | translate }}
    </div>
    <div *ngIf="section.type === 'historicalChart'" #chartSectionTemplate class="report-section report-chart report-historical-chart">
      {{ 'Chart.PlaceHolder' | translate }}
    </div>
    <div *ngIf="section.type === 'fidelityChart'" #chartSectionTemplate class="report-section report-chart">
      {{ 'Chart.PlaceHolder' | translate }}
    </div>
    <div *ngIf="section.type === 'linearityChart'" #chartSectionTemplate class="report-section report-chart">
      {{ 'Chart.PlaceHolder' | translate }}
    </div>
    <div *ngIf="section.type === 'text'" class="report-section report-text">
      {{ section.content[0].content }}
    </div>
  </div>
  <div *ngIf="section.type === 'footer'" class="page-footer">
    <table>
      <tr>
        <td>{{ 'Report.DateTime' | translate }}: {{ dateTime | date:dateTimeFormat }}</td>
        <td>{{ 'Page' | translate }}: {{ section.content }} {{ 'of' | translate }} {{ pageCount }}</td>
      </tr>
    </table>
  </div>
</ng-template>
