<svg:g *ngFor="let shape of shapes">
  <defs>
    <svg:g ngx-charts-svg-linear-gradient
           orientation="vertical"
           [name]="gradientId"
           [stops]="shape.gradientStops" />
  </defs>
  <svg:polygon *ngIf="shape.params && shape.params.hasNote" [attr.points]="(shape.cx - shape.radius - 1) + ',' + (shape.cy - shape.radius - 1) + ' '
               + (shape.cx + shape.radius + 1) + ',' + (shape.cy - shape.radius - 1) + ' '
               + (shape.cx + shape.radius + 1) + ',' + (shape.cy + shape.radius + 1) + ' '
               + (shape.cx - shape.radius - 1) + ',' + (shape.cy + shape.radius + 1)" [attr.fill]="'#FFFFFF'" [attr.stroke]="'#000000'"></svg:polygon>
  <svg:text *ngIf="shape.title"
            [attr.x]="shape.cx > 15 ? shape.cx - 15 : 0"
            [attr.y]="shape.cy > 20 ? shape.cy - 5 : 15">{{ shape.title }}</svg:text>
  <svg:g aml-charts-shape
         [cx]="shape.cx"
         [cy]="shape.cy"
         [r]="shape.radius"
         [fill]="shape.color"
         [stroke]="shape.stroke"
         [pointerEvents]="'all'"
         [data]="shape.value"
         [shape]="shapeType"
         [classNames]="shape.classNames"
         (select)="onClick($event, shape.label, shape.params)"
         (activate)="activateShape()"
         (deactivate)="deactivateShape()"
         ngx-tooltip
         [tooltipDisabled]="tooltipDisabled"
         [tooltipPlacement]="'top'"
         [tooltipType]="'tooltip'"
         [tooltipTitle]="tooltipTemplate ? undefined : getTooltipText(shape)"
         [tooltipTemplate]="tooltipTemplate"
         [tooltipContext]="shape.params" />
</svg:g>
