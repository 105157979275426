import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ListInputComponent } from './list-input.component';
import { FormAlertModule } from '../form-alert/form-alert.module';

@NgModule({
  declarations: [
    ListInputComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormAlertModule
  ],
  exports: [
    ListInputComponent
  ]
})

export class ListInputModule { }
