import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ReportLevelFilterComponent } from './report-level-filter.component';

@NgModule({
  declarations: [ReportLevelFilterComponent],
  exports: [ReportLevelFilterComponent],
  imports: [CommonModule, TranslateModule]
})
export class ReportLevelFilterModule { }
