import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../../../core/app.config';
import { Stream } from './stream';
import { Generic } from '../../../shared/generic';

@Injectable()
export class StreamService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getStreams(filterArguments: string): Observable<Stream[]> { return this.http.get<Stream[]>(this.apiHost + 'componentstream/get' + filterArguments); }

  public getStreamsByInstrument(id: number, filterArguments: string): Observable<Stream[]> { return this.http.get<Stream[]>(this.apiHost + 'componentstream/getbymeasuringinstrument/' + id + filterArguments); }

  public getStream(id: number): Observable<Stream> { return this.http.get<Stream>(this.apiHost + 'componentstream/get/' + id); }

  public getStreamCount(filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'componentstream/count' + filterArguments); }

  public getStreamCountByInstrument(id: number, filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'componentstream/countbymeasuringinstrument/' + id + filterArguments); }

  public postStream(stream: Stream): Observable<number> { return this.http.post<number>(this.apiHost + 'componentstream/post', stream); }

  public putStream(stream: Stream) { return this.http.put(this.apiHost + 'componentstream/put', stream); }

  public deleteStream(id: number) { return this.http.delete(this.apiHost + 'componentstream/delete/' + id); }

  public addOrUpdateStreams(streams: Stream[]) { return this.http.post(this.apiHost + 'componentstream/addorupdaterange', streams); }

  public newStream(id: number): Stream {
    return { id: -1, description: '', tag: '', measuringInstrumentId: id, instrumentTag: '' };
  }
}
