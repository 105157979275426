import { NgModule } from '@angular/core';

import { SharedModule } from '../../../../shared/shared.module';
import { ValidationDataModule } from './validation-data/validation-data.module';

import { ControlChartViewComponent } from './control-chart-view.component';
import { ControlChartReportAccuracyChartComponent } from './control-chart-report-charts/control-chart-report-accuracy-chart.component';
import { ControlChartReportPrecisionChartComponent } from './control-chart-report-charts/control-chart-report-precision-chart.component';
import { ControlChartService } from './control-chart.service';
import { CompService } from '../../stream/comp/comp.service';
import { DecisionRuleService } from '../../decision-rule/decision-rule.service';
import { ValidationProcedureService } from '../../validation/validation-procedure/validation-procedure.service';
import { HistoricalChartService } from '../historical-chart/historical-chart.service';

@NgModule({
  declarations: [
    ControlChartViewComponent,
    ControlChartReportAccuracyChartComponent,
    ControlChartReportPrecisionChartComponent
  ],
  imports: [
    SharedModule,
    ValidationDataModule
  ],
  providers: [
    ControlChartService,
    CompService,
    DecisionRuleService,
    ValidationProcedureService,
    HistoricalChartService
  ],
  exports: [
    ControlChartViewComponent,
    ControlChartReportAccuracyChartComponent,
    ControlChartReportPrecisionChartComponent
  ]
})

export class ControlChartModule { }
