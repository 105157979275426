import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppConfig {
  private config: Object = null;

  constructor(private http: HttpClient) { }

  public getConfig(key: string) {
    return this.config[key];
  }

  public load() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/app.config.json').subscribe(data => {
        this.config = data;
        resolve(true);
      });
    });
  }

  public inTestMode(): boolean {
    return false;
  }
}
