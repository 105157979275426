import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { ClaimService } from '../../../../core/claim.service';

import { LinearitySession } from './linearity-session';
import { LinearitySessionService } from './linearity-session.service';
import { ErrorMessage } from '../../../../shared/error/error-message';
import { Header } from '../../../../shared/grid/header';
import { FilterOption } from '../../../../shared/data/filter-option';
import { AppConfig } from '../../../../core/app.config';

@Component({
  selector: 'linearity-session',
  templateUrl: './linearity-session.component.html'
})
export class LinearitySessionComponent implements OnInit, OnChanges {
  constructor(
    private linearitySessionService: LinearitySessionService,
    private claimService: ClaimService,
    private appConfig: AppConfig
  ) { }
  @Input() public linearityChartId: number;
  @Input() public dataChange: boolean;
  private selectedLinearitySession: LinearitySession;
  @Output() public selectedLinearitySessionChanged = new EventEmitter<LinearitySession>();
  public headers: Header[] = [{ header: 'DateTime', sort: true, width: '70%' }, { header: 'Action', sort: false, width: '30%' }];
  public filterOptions: FilterOption[] = [{ name: 'DateTime', type: 'datetime' }];
  public linearitySessions: LinearitySession[];
  private dialogSession: LinearitySession;
  public count: number;
  private deleteId: number;
  public updateAccess: boolean;
  public deleteAccess: boolean;
  public editDialog: boolean;
  public deleteDialog: boolean;
  private args = '';
  public error: ErrorMessage;
  private dateTimeFormat: string;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.updateAccess = this.claimService.checkClaimContains('aml.valsession', 'u');
    this.deleteAccess = this.claimService.checkClaimContains('aml.valsession', 'd');
    this.getLinearitySessions(this.args);
  }

  ngOnChanges() {
    this.getLinearitySessions(this.args);
  }

  getLinearitySessions(args: string) {
    this.error = null;
    this.args = args;
    this.linearitySessionService.getLinearitySessionCountByChart(this.linearityChartId, args).subscribe(count => this.count = count);
    this.linearitySessionService.getLinearitySessionsByChart(this.linearityChartId, args).subscribe(linearitySessions => {
      this.linearitySessions = linearitySessions;
      if (!this.selectedLinearitySession && linearitySessions.length > 0) { this.selectedLinearitySession = linearitySessions[0]; }
    }, error => this.error = error.error);
  }

  selectLinearitySession(linearitySession: LinearitySession) {
    this.selectedLinearitySession = linearitySession;
    this.selectedLinearitySessionChanged.emit(linearitySession);
  }

  viewLinearitySession(linearitySession: LinearitySession) {
    this.dialogSession = linearitySession;
    this.editDialog = true;
  }

  deleteLinearitySession(id: number) {
    this.deleteId = id;
    this.deleteDialog = true;
  }

  confirmDelete(confirmed: boolean) {
    this.error = null;
    if (confirmed) {
      this.linearitySessionService.deleteLinearitySession(this.deleteId).subscribe(response => {
        this.getLinearitySessions(this.args);
        this.deleteDialog = false;
      }, error => this.error = error.error);
    } else {
      this.deleteDialog = false;
    }
  }

  dialogChanged(event: boolean) {
    this.editDialog = event;
  }
}
