import { Component, OnInit, OnChanges, Input } from '@angular/core';

import { GaussianChartService } from './gaussian-chart.service';
import { ControlChartService } from '../control-chart/control-chart.service';
import { DataPoint } from '../../../../shared/chart/data-point';
import { ErrorMessage } from '../../../../shared/error/error-message';

@Component({
  selector: 'gaussian-chart',
  templateUrl: './gaussian-chart.component.html',
  styleUrls: ['./gaussian-chart.component.css']
})
export class GaussianChartComponent implements OnInit, OnChanges {
  constructor(
    private gaussianChartService: GaussianChartService,
    private controlChartService: ControlChartService
  ) { }
  @Input() controlChartSetId: number;
  public chart: DataPoint[];
  @Input() start: string = '';
  @Input() end: string = '';
  error: ErrorMessage;

  ngOnInit() {
    const date = new Date();
    this.end = date.toISOString();
    date.setFullYear(date.getFullYear() - 1);
    this.start = date.toISOString();
    this.getChartData();
  }

  ngOnChanges() {
    this.getChartData();
  }

  getChartData(start: any = null, end: any = null) {
    if (typeof (start) !== 'string' && start !== null) {
      this.start = start.toISOString();
    }
    if (typeof (end) !== 'string' && end !== null) {
      this.end = end.toISOString();
    }

    this.gaussianChartService.getGaussianChartData(this.controlChartSetId, this.start, this.end).subscribe(data => {
      this.chart = data.map(dataPoint => <DataPoint>{ name: dataPoint.item1, params: null, value: dataPoint.item2 });
    }, error => this.error = error.error);
  }

  matchControlChart() {
    this.controlChartService.getControlChartReset(this.controlChartSetId).subscribe(resetTime => {
      if (resetTime && resetTime !== '') {
        this.start = resetTime;
        this.end = new Date().toISOString();
        this.getChartData();
      }
    });
  }
}
