import { Component, EventEmitter, OnInit, OnChanges, OnDestroy, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { filter, timeout } from 'rxjs/operators';

import { ClaimService } from '../../core/claim.service';
import { GlobalsService } from '../../core/globals.service';
import { AreaLayoutService } from './area-layout.service';
import { AreaService } from '../area.service';
import { ImageService } from '../../core/image.service';
import { IoService } from '../io/io.service';
import { Area } from '../area';
import { AreaLayoutItem } from './area-layout-item';
import { AreaLayoutDiag } from './area-layout-diag';
import { ErrorMessage } from '../../shared/error/error-message';
import { SubmitButtonComponent } from '../../shared/submit-button/submit-button.component';

@Component({
  selector: 'area-layout',
  templateUrl: './area-layout.component.html',
  styleUrls: ['area-layout.component.css']
})

export class AreaLayoutComponent implements OnInit, OnChanges, OnDestroy {
  constructor(
    private areaLayoutService: AreaLayoutService,
    private areaService: AreaService,
    private ioService: IoService,
    private imageService: ImageService,
    private claimService: ClaimService,
    private globals: GlobalsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }
  @Input() public informationLevelId: number;
  @ViewChild(SubmitButtonComponent) submitButton: SubmitButtonComponent;
  public area: Area;
  private localArea: Area;
  public editMode: boolean;
  private refreshInterval;
  private mouseX: number = 0;
  private mouseY: number = 0;
  public layoutItems: AreaLayoutItem[];
  public layoutDiags: AreaLayoutDiag[] = [];
  public selectedDiag: AreaLayoutDiag;
  public diagnosticDialog: boolean;
  public updateAccess: boolean;
  public error: ErrorMessage;
  private routeSubscription: ISubscription;

  ngOnInit() {
    this.updateAccess = this.claimService.checkClaimContains('aml.area', 'u');

    if (!this.informationLevelId) {
      this.informationLevelId = +this.route.parent.snapshot.paramMap.get('id');
      this.getArea();

      this.routeSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(path => {
        this.informationLevelId = +this.route.parent.snapshot.paramMap.get('id');
        this.getArea();
      });
    }
  }

  ngOnChanges() {
    if ((this.informationLevelId && !this.localArea) || (this.informationLevelId && this.informationLevelId !== this.localArea.id)) { // Do not reload the layout unless the area id has changed.
      this.getArea();
    }
  }

  ngOnDestroy() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  getArea() {
    this.areaService.getArea(this.informationLevelId).subscribe(area => {
      this.area = area;
      this.getLayout();
      this.localArea = this.area;
      this.editMode = false;
    }, error => this.error = error.error);
  }

  getLayout(force: boolean = false) {
    this.areaLayoutService.getAreaLayout(this.area.id, force).subscribe(alis => this.layoutItems = alis);
    this.areaLayoutService.getAreaLayoutDiags(this.area.id, force).subscribe(aliDiags => {
      this.layoutDiags = aliDiags;
      this.getDiagnosticIoReadings();
    });
  }

  /*
   * Retrieve readings from the diagnostic point ids and map the results to the diagnostics.
   */
  getDiagnosticIoReadings() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
    const diagnostics = this.layoutDiags.map(ld => ld.targetDiagnostic);
    if (diagnostics.filter(diagnostic => diagnostic && diagnostic.ioId > 0).length > 0) {
      this.refreshInterval = setInterval(() => {
        this.ioService.getIoReadings(diagnostics.filter(diagnostic => diagnostic.ioId > 0)
          .map(diagnostic => diagnostic.ioId)).pipe(timeout(2500)).subscribe(readings => {
            readings.map(reading => diagnostics.map(diagnostic => {
              if (diagnostic.ioId === reading.ioId) {
                diagnostic.processValue = reading.reading;
              }
            }));
          });
      }, 1000);
    }
  }

  quickLayout() {
    this.areaLayoutService.quickAreaLayout(this.area.id, this.area.imageWidth, this.area.imageHeight).subscribe(alis => this.layoutItems = alis);
  }

  onClick(event: MouseEvent, item: AreaLayoutItem) {
    if (this.editMode) { // Start dragging.
      this.mouseX = (event.clientX > 0) ? event.clientX : this.globals.clientX;
      this.mouseY = (event.clientY > 0) ? event.clientY : this.globals.clientY;
    } else { // Start navigating.
      if (item != null) {
        this.router.navigate([item.levelType + '/' + item.targetLevelId]);
      }
    }

    event.stopPropagation();
  }

  onReleaseItem(item: AreaLayoutItem) { // Put the draggable item back inside the drop area.
    if (item.left < 0) {
      item.left = 0;
    }
    if (item.left > this.area.imageWidth - 10 - item.width) {
      item.left = this.area.imageWidth - 10 - item.width;
    }

    if (item.top < 0) {
      item.top = 0;
    }
    if (item.top > this.area.imageHeight - 10 - item.height) {
      item.top = this.area.imageHeight - 10 - item.height;
    }
  }

  onReleaseDiag(item: AreaLayoutDiag) { // Put the draggable diag back inside the drop area.
    if (item.left < 0) {
      item.left = 0;
    }
    if (item.left > this.area.imageWidth - 110) {
      item.left = this.area.imageWidth - 110;
    }

    if (item.top < 0) {
      item.top = 0;
    }
    if (item.top > this.area.imageHeight - 28) {
      item.top = this.area.imageHeight - 28;
    }
  }

  onResizeRelease(item: AreaLayoutItem) {
    if (item.width < 80) {
      item.width = 80;
    }

    if (item.height < 55) {
      item.height = 55;
    }

    event.stopPropagation();
  }

  drag(event: MouseEvent, item: AreaLayoutItem | AreaLayoutDiag) {
    const clientX: number = (event.clientX > 0) ? event.clientX : this.globals.clientX;
    const clientY: number = (event.clientY > 0) ? event.clientY : this.globals.clientY;

    if (this.editMode && (clientX > 0 || clientY > 0)) {
      item.left += clientX - this.mouseX;
      item.top += clientY - this.mouseY;
      this.onClick(event, null);
    }
  }

  dragStart(event: any) {
    if (event.dataTransfer !== undefined) {
      event.dataTransfer.setData('text', event.target.id);
    }
  }

  dragover(event: any) {
    if (this.editMode) {
      event.preventDefault(); // Default is to deny drop operations.
    }
  }

  drop(event: DragEvent) {
    if (this.editMode) {
      event.preventDefault(); // Default is to open as url.
      const path = event.dataTransfer.getData('amlPath');
      const areaIndex = path.indexOf('area');
      const instrumentIndex = path.indexOf('instrument');
      const levelIndex = areaIndex > instrumentIndex ? areaIndex : instrumentIndex;
      if (levelIndex >= 0) { // User dragged a valid item.
        const id = +path.substring(path.indexOf('/', levelIndex + 3) + 1);
        this.areaLayoutService.newAreaLayoutItem(id).subscribe(item => {
          item.left = (<any>event).layerX - +(item.width / 2).toFixed();
          item.top = (<any>event).layerY - +(item.height / 2).toFixed();
          item.areaId = this.area.id;
          this.layoutItems.push(item);
        });
      }
    }
  }

  resizeDrag(event: MouseEvent, item: AreaLayoutItem, hor: boolean, ver: boolean) {
    if (this.editMode) {
      if (hor) { item.width += ((event.clientX > 0) ? event.clientX : this.globals.clientX) - this.mouseX; }
      if (ver) { item.height += ((event.clientY > 0) ? event.clientY : this.globals.clientY) - this.mouseY; }
      this.onClick(event, null);
    }

    event.stopPropagation();
  }

  resizeViewDrag(event: MouseEvent, hor: boolean, ver: boolean) {
    if (this.editMode) {
      if (hor) { this.area.imageWidth += ((event.clientX > 0) ? event.clientX : this.globals.clientX) - this.mouseX; }
      if (ver) { this.area.imageHeight += ((event.clientY > 0) ? event.clientY : this.globals.clientY) - this.mouseY; }
      this.onClick(event, null);
    }

    event.stopPropagation();
  }

  changeZ(item: AreaLayoutItem | AreaLayoutDiag, increment: boolean) {
    if (increment) {
      item.z++;
    } else {
      item.z--;
    }
  }

  addDiag() {
    const diag = new AreaLayoutDiag();
    diag.id = -1;
    diag.areaId = this.area.id;
    diag.left = 0;
    diag.top = 0;
    diag.z = 0;
    diag.targetDiagnosticId = null;
    this.selectedDiag = diag;
    this.diagnosticDialog = true;
  }

  selectDiag(diag: AreaLayoutDiag) {
    this.selectedDiag = diag;
    this.diagnosticDialog = true;
  }

  deleteItem(item: AreaLayoutItem) {
    this.layoutItems.splice(this.layoutItems.indexOf(item), 1);
  }

  deleteDiag(diag: AreaLayoutDiag) {
    this.layoutDiags.splice(this.layoutDiags.indexOf(diag), 1);
  }

  cancel() {
    this.changeEditMode(false);
    this.getLayout(true);
  }

  saveChanges() {
    if (this.submitButton !== undefined && this.submitButton.submit()) {
      this.error = null;
      this.areaLayoutService.saveOrUpdateAreaLayout(this.layoutItems, this.area.id).subscribe(response => {
        this.areaService.putAreaImage(this.area).subscribe();
        this.areaLayoutService.saveOrUpdateAreaLayoutDiags(this.layoutDiags, this.area.id).subscribe(innerResponse => {
          this.submitButton.ready();
          this.changeEditMode(false);
        }, error => {
          this.error = error.error;
          this.submitButton.ready();
        });
      }, error => {
        this.error = error.error;
        this.submitButton.ready();
      });
    }
  }

  handleFileInput(files: FileList) {
    this.imageService.imageToString(files.item(0)).subscribe(image => this.area.image = image);
  }

  changeEditMode(editMode: boolean) {
    this.editMode = editMode;
    if (!editMode) {
      this.getLayout(true);
    } else {
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval);
      }
    }
  }

  dialogChanged(event: boolean) {
    if (!event && this.selectedDiag.targetDiagnosticId && this.selectedDiag.id === -1) {
      this.layoutDiags.push(this.selectedDiag);
    }
    this.selectedDiag = null;
    this.diagnosticDialog = false;
  }

  getOperationalStateClass(state: string): string { // Copy from operational-state.service. TODO: refactor to prevent copy paste of this function.
    switch (state) {
      case 'Utilised':
        return 'os-utl';
      case 'RoutineMaintenance':
        return 'os-rtn-mnt';
      case 'NonRoutineMaintenance':
        return 'os-nrt-mnt';
      case 'Fault':
        return 'os-flt';
      default:
        return 'os-nrq';
    }
  }
}
