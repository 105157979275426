import { Component, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { LinearityChart } from './linearity-chart';
import { LinearityChartService } from './linearity-chart.service';
import { Stream } from '../../stream/stream';
import { StreamService } from '../../stream/stream.service';
import { ErrorMessage } from '../../../../shared/error/error-message';
import { SubmitButtonComponent } from '../../../../shared/submit-button/submit-button.component';

@Component({
  selector: 'linearity-chart-overview',
  templateUrl: './linearity-chart-overview.component.html'
})
export class LinearityChartOverviewComponent implements OnInit {
  constructor(
    private linearityChartService: LinearityChartService,
    private streamService: StreamService
  ) { }
  @Input() public linearityChartId;
  @Input() public instrumentId: number;
  @Output() public editModeChanged = new EventEmitter<boolean>();
  @ViewChild(SubmitButtonComponent) submitButton: SubmitButtonComponent;
  public linearityChart: LinearityChart;
  private streams: Stream[];
  private editMode = true;
  public error: ErrorMessage;

  ngOnInit() {
    this.error = null;
    if (this.linearityChartId > 0) {
      this.getLinearityChart();
    } else {
      this.linearityChart = this.linearityChartService.newLinearityChart();
    }
    this.streamService.getStreamsByInstrument(this.instrumentId, '?size=9999').subscribe(streams => this.streams = streams);
  }

  getLinearityChart() {
    this.linearityChartService.getLinearityChart(this.linearityChartId).subscribe(linearityChart => this.linearityChart = linearityChart, error => this.error = error.error);
  }

  submitLinearityChart(form: NgForm) {
    if (this.submitButton !== undefined && this.submitButton.submit()) {
      if (form.valid) {
        this.error = null;
        if (this.linearityChartId > 0) { // PUT
          this.linearityChartService.putLinearityChart(this.linearityChart).subscribe(response => {
            this.submitButton.ready();
            this.editModeChanged.emit(false);
          }, error => {
            this.error = error.error;
            this.submitButton.ready();
          });
        } else { // POST
          this.linearityChartService.postLinearityChart(this.linearityChart).subscribe(response => {
            this.submitButton.ready();
            this.editModeChanged.emit(false);
          }, error => {
            this.error = error.error;
            this.submitButton.ready();
          });
        }
      } else {
        for (const i of Object.keys(form.controls)) {
          form.controls[i].markAsTouched();
        }
        this.submitButton.ready();
      }
    }
  }

  cancel() {
    this.editModeChanged.emit(false);
  }
}
