import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AppConfig } from '../../../../core/app.config';
import { ControlChartSet } from '../control-chart-set';
import { VrgInclusion } from './vrg-inclusion';

@Injectable()
export class ControlChartService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  private ccsCache: Map<string, Observable<ControlChartSet>> = new Map();

  public getControlChartSets(filterArguments: string): Observable<ControlChartSet[]> { return this.http.get<ControlChartSet[]>(this.apiHost + 'controlchart/get' + filterArguments); }

  public getControlChartSetCount(filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'controlchart/count/' + filterArguments); }

  public getControlChartSetsByInstrument(filterArguments: string, instrumentId: number): Observable<ControlChartSet[]> {
    return this.http.get<ControlChartSet[]>(this.apiHost + 'controlchart/getbyinstrument/' + instrumentId + filterArguments);
  }

  public getControlChartSetCountByInstrument(filterArguments: string, instrumentId: number): Observable<number> {
    return this.http.get<number>(this.apiHost + 'controlchart/countbyinstrument/' + instrumentId + filterArguments);
  }

  public getControlChartSet(id: number, includeValidationData = false, force = false): Observable<ControlChartSet> {
    const key = id + (includeValidationData ? 't' : 'f');
    if (force || !this.ccsCache.has(key)) {
      this.ccsCache.set(key, this.http.get<ControlChartSet>(this.apiHost + 'controlchart/get/' + id + (includeValidationData ? '?includeValidationData=true' : '')).pipe(shareReplay()));
      setTimeout(() => this.ccsCache.delete(key), 15000);
    }
    return this.ccsCache.get(key);
  }

  public getControlChartReset(id: number): Observable<string> { return this.http.get<string>(this.apiHost + 'controlchart/getreset/' + id, { responseType: 'text' as 'json' }); }

  public getChartTypes(): Observable<string[]> { return this.http.get<string[]>(this.apiHost + 'controlchart/getcharttypes'); }

  public getDeviationTypes(): Observable<string[]> { return this.http.get<string[]>(this.apiHost + 'controlchart/getdeviationtypes'); }

  public postControlChartSet(controlChartSet: ControlChartSet): Observable<number> { return this.http.post<number>(this.apiHost + 'controlchart/post', controlChartSet); }

  public putControlChartSet(controlChartSet: ControlChartSet) { return this.http.put(this.apiHost + 'controlchart/put', controlChartSet); }

  public deleteControlChartSet(id: number) { return this.http.delete(this.apiHost + 'controlchart/delete/' + id); }

  public resetControlCharts(instrumentId: number, validationMethod: string) {
    return this.http.put(this.apiHost + 'controlchart/reset/' + instrumentId + '?validationMethod=' + validationMethod, null);
  }

  public saveAndReevaluateControlChartSet(controlChartSetId: number, vrgivs: VrgInclusion[]) {
    return this.http.put(this.apiHost + 'controlchart/saveandreevaluate/' + controlChartSetId, vrgivs);
  }

  public quickControlCharts(instrumentId: number) { return this.http.post(this.apiHost + 'controlchart/quickcharts/' + instrumentId, null); }

  public newControlChartSet(): ControlChartSet {
    return {
      id: -1, componentId: null, deviationType: '', validationMethod: '', instrumentId: -1,
      accuracyChart: { chartType: '', decisionRules: [], id: -1, lcl: 0, ucl: 0, validationResults: null, statistics: null },
      precisionChart: { chartType: '', decisionRules: [], id: -1, lcl: 0, ucl: 0, validationResults: null, statistics: null },
      componentTag: '', description: '', lastEvaluation: '', lastResult: '', lastViewed: '', symbol: '', unit: '',
      controlLimitsChanged: false, instrumentAdjusted: false, referenceMaterialChanged: false, sampleSizeChanged: false, generateFailEvent: false, referenceStreamId: null, referenceStream: null
    };
  }
}
