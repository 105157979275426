<div *ngIf="!editMode" class="header">
  <h1>{{ 'ReportType.Configuration' | translate }}</h1>
</div>
<div *ngIf="!reportType">
  <error-message [error]="error"></error-message>
</div>
<form *ngIf="reportType" (ngSubmit)="submitReportType(reportTypeForm)" #reportTypeForm="ngForm" class="overview-wrapper" [ngClass]="{'readonly':!editMode, 'not-in-dialog':!shownInDialog}">
  <button *ngIf="!editMode && updateAccess" id="edit-reporttype" (click)="editReportType()" class="button-blue">{{ 'Edit' | translate }}</button>
  <div class="overview-header">{{ 'ReportType.Configuration' | translate }}</div>
  <div class="form-content">
    <table class="overview">
      <tr>
        <td>{{ 'ReportType.Description' | translate }}:</td>
        <td *ngIf="!editMode">{{ reportType.description | translate }}</td>
        <td *ngIf="editMode"><input type="text" name="description" required [(ngModel)]="reportType.description" #description="ngModel" /><form-alert [field]="description" name="ReportType.Description"></form-alert></td>
      </tr>
      <tr>
        <td>{{ 'ReportType.ReportLevel' | translate }}:</td>
        <td *ngIf="!editMode">{{ reportType.reportLevel | translate }}</td>
        <td *ngIf="editMode">
          <select [(ngModel)]="reportType.reportLevel" name="reportLevel" required #reportLevel="ngModel">
            <option *ngFor="let reportLevel of reportLevels" [ngValue]="reportLevel">{{ reportLevel | translate }}</option>
          </select>
          <form-alert [field]="reportLevel" name="ReportType.ReportLevel"></form-alert>
        </td>
      </tr>
      <tr *ngIf="reportType.id === -1">
        <td>{{ 'ReportDataset.Type' | translate }}:</td>
        <td *ngIf="!editMode">{{ datasetType | translate }}</td>
        <td *ngIf="editMode">
          <select [(ngModel)]="datasetType" name="datasetType" required #datasetType1="ngModel">
            <option *ngFor="let item of datasetTypes" [ngValue]="item">{{ item | translate }}</option>
          </select>
          <form-alert [field]="datasetType1" name="ReportDataset.Type"></form-alert>
        </td>
      </tr>
      <tr *ngIf="(reportType.id === -1 && datasetType === 'ReportDataset.Custom') || (reportType.reportEndpoints && !reportType.datasetName)">
        <td>{{ 'ReportType.Dataset' | translate }}:</td>
        <td *ngIf="reportType.id === -1">
          <div class="endpoints-container">
            <ng-container *ngIf="reportType.reportEndpoints">
              <div *ngFor="let reportEndpoint of reportType.reportEndpoints">
                <ng-container *ngTemplateOutlet="endpointTemplate; context: { tReportEndpoint: reportEndpoint, tParentEndpoint: null }"></ng-container>
              </div>
            </ng-container>
            <ng-template #endpointTemplate let-reportEndpoint="tReportEndpoint" let-parentEndpoint="tParentEndpoint">
              <div class="endpoint">
                <div class="endpoint-name">
                  <span>{{ reportEndpoint.name | translate }}</span>
                  <i class="material-icons dataset-delete" (click)="deleteEndpoint(reportEndpoint, parentEndpoint)">delete</i>
                </div>
                <div class="referenced-endpoints">
                  <ng-container *ngIf="reportEndpoint.referencedEndpoints">
                    <div *ngFor="let referencedEndpoint of reportEndpoint.referencedEndpoints">
                      <ng-container *ngTemplateOutlet="endpointTemplate; context: { tReportEndpoint: referencedEndpoint, tParentEndpoint: reportEndpoint }"></ng-container>
                    </div>
                  </ng-container>
                  <div *ngIf="reportEndpoint.availableEndpoints && reportEndpoint.availableEndpoints.length" class="available-endpoints">
                    <select (change)="setEndpoint($event, i, reportEndpoint)">
                      <option value=""></option>
                      <option *ngFor="let endpoint of reportEndpoint.availableEndpoints" [ngValue]="endpoint">{{ endpoint.name | translate }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </ng-template>
            <div *ngIf="!reportType.reportEndpoints || (reportType.reportEndpoints && !reportType.reportEndpoints.length)" class="endpoints">
              <select (change)="setEndpoint($event, i)">
                <option value=""></option>
                <option *ngFor="let endpoint of endpoints" [ngValue]="endpoint">{{ endpoint.name | translate }}</option>
              </select>
            </div>
          </div>
        </td>
        <td *ngIf="reportType.id !== -1">
          <ng-container *ngIf="reportType.reportEndpoints">
            <ng-container *ngFor="let endpoint of reportType.reportEndpoints">
              <ng-container *ngTemplateOutlet="endpointNameTemplate; context: { tReportEndpoint: endpoint }"></ng-container>
            </ng-container>
            <ng-template #endpointNameTemplate let-reportEndpoint="tReportEndpoint">
              <div class="endpoint">
                <div class="endpoint-name readonly">
                  <span>&nbsp;{{ reportEndpoint.name | translate }}</span>
                </div>
                <div class="referenced-endpoints">
                  <ng-container *ngIf="reportEndpoint.referencedEndpoints">
                    <div *ngFor="let referencedEndpoint of reportEndpoint.referencedEndpoints">
                      <ng-container *ngTemplateOutlet="endpointNameTemplate; context: { tReportEndpoint: referencedEndpoint }"></ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </td>
      </tr>
      <tr *ngIf="(reportType.id === -1 && datasetType === 'ReportDataset.Predefined') || reportType.datasetName">
        <td>{{ 'ReportType.Dataset' | translate }}:</td>
        <td *ngIf="reportType.id === -1">
          <select [(ngModel)]="reportType.datasetId" name="dataset" required (ngModelChange)="setReportDataset(reportType.datasetId)" #dataset="ngModel">
            <option *ngFor="let ds of datasets" [ngValue]="ds.id">{{ ds.name | translate }}</option>
          </select>
          <form-alert [field]="dataset" name="ReportType.Dataset"></form-alert>
        </td>
        <td *ngIf="reportType.id !== -1">{{ reportType.datasetName | translate }}</td>
      </tr>
    </table>
    <div *ngIf="datasetProperties" id="report-configuration">
      <div class="tab-headers">
        <div class="tab-header" (click)="showDataEditorTab()" [ngClass]="{'active':dataEditorTabActive}">{{ 'ReportType.DataEditor' | translate }}</div>
        <div class="tab-header" (click)="showLayoutEditorTab()" [ngClass]="{'active':layoutEditorTabActive}">{{ 'ReportType.LayoutEditor' | translate }}</div>
        <div class="tab-header" *ngIf="dataset && dataset.parameters" (click)="showFilterTab()" [ngClass]="{'active':filterTabActive}">{{ 'ReportType.Filters' | translate }}</div>
      </div>
      <div class="tab-wrapper">

        <!-- Start of Data Editor tab -->
        <div *ngIf="dataEditorTabActive" class="report-tab">
          <report-type-data-editor [reportType]="reportType" [shownInDialog]="shownInDialog" [editMode]="editMode" [datasetProperties]="datasetProperties"></report-type-data-editor>
        </div>
        <!-- End of Data Editor tab -->

        <!-- Start of Layout Editor tab -->
        <div *ngIf="layoutEditorTabActive" class="report-tab">
          <report-type-layout-editor [reportType]="reportType" [shownInDialog]="shownInDialog" [editMode]="editMode" [datasetProperties]="datasetProperties"></report-type-layout-editor>
        </div>
        <!-- End of Layout Editor tab -->

        <!-- Start of Filters tab -->
        <div *ngIf="filterTabActive" class="report-tab">
          <div class="overview-wrapper" *ngIf="requiredParams && requiredParams.length">
            <div class="overview-header">{{ 'Required' | translate }} {{ 'ReportType.Dataset' | translate }} {{ 'ReportType.Filters' | translate }}</div>
            <table class="grid simple-grid-table">
              <thead>
                <tr>
                  <td style="width:65px;">{{ 'Enabled' | translate }}</td>
                  <td>{{ 'Description' | translate }}</td>
                  <td>{{ 'ReportType.ParameterType' | translate }}</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let param of requiredParams; let odd = odd" [class.odd]="odd">
                  <td><input name="param{{ param.id }}" type="checkbox" [disabled]="true" [checked]="true" /></td>
                  <td>{{ param.description | translate }}</td>
                  <td>{{ param.type | translate }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="overview-wrapper" *ngIf="optionalParams && optionalParams.length">
            <div class="overview-header">{{ 'Optional' | translate }} {{ 'ReportType.Dataset' | translate }} {{ 'ReportType.Filters' | translate }}</div>
            <table class="grid simple-grid-table">
              <thead>
                <tr>
                  <td style="width:65px;">{{ 'Enabled' | translate }}</td>
                  <td>{{ 'Description' | translate }}</td>
                  <td>{{ 'ReportType.ParameterType' | translate }}</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let param of optionalParams; let odd = odd" [class.odd]="odd" (click)="param.enabled = !param.enabled">
                  <td style="position: relative;"><input name="param{{ param.id }}" type="checkbox" [disabled]="!editMode" [checked]="param.enabled" /><span style="position: absolute; left: 0; right: 0; top: 0; bottom: 0;"></span></td>
                  <td>{{ param.description | translate }}</td>
                  <td>{{ param.type | translate }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- End of Filters tab -->

      </div>
    </div>
    <error-message [error]="error"></error-message>
  </div>
  <div *ngIf="editMode" class="control-buttons" [ngClass]="{'not-in-dialog':!shownInDialog}">
    <button class="button-gray" type="reset" (click)="cancel()">{{ 'Cancel' | translate }}</button>
    <submit-button name="Save" [disabled]="reportTypeForm.invalid"></submit-button>
  </div>
</form>
