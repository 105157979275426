<ngx-charts-chart style="display: none;" [view]="[width, height]"
                  [activeEntries]="activeEntries"
                  [animations]="animations"
                  (legendLabelClick)="onClick($event)"
                  (legendLabelActivate)="onActivate($event)"
                  (legendLabelDeactivate)="onDeactivate($event)">
  <svg:defs>
    <svg:clipPath [attr.id]="clipPathId">
      <svg:rect [attr.width]="dims.width + 10"
                [attr.height]="dims.height + 10"
                [attr.transform]="'translate(-5, -5)'" />
    </svg:clipPath>
  </svg:defs>
  <svg:g [attr.transform]="transform" class="line-chart chart">
    <svg:g ngx-charts-x-axis
           *ngIf="xAxis"
           [xScale]="xScale"
           [dims]="dims"
           [showGridLines]="showGridLines"
           [showLabel]="showXAxisLabel"
           [labelText]="xAxisLabel"
           [tickFormatting]="xAxisTickFormatting"
           [ticks]="xAxisTicks"
           (dimensionsChanged)="updateXAxisHeight($event)">
    </svg:g>
    <svg:g aml-charts-y-axis
           *ngIf="yAxis"
           [yScale]="yScale"
           [dims]="dims"
           [showGridLines]="showGridLines"
           [showLabel]="showYAxisLabel"
           [labelText]="yAxisLabel"
           [tickFormatting]="yAxisTickFormatting"
           [ticks]="yAxisTicks"
           [showRefLabels]="showRefLabels"
           (dimensionsChanged)="updateYAxisWidth($event)">
    </svg:g>
    <svg:g [attr.clip-path]="clipPath">
      <svg:g *ngFor="let group of barResults; let index = index; trackBy: trackBy" [@animationState]="'active'">
        <svg:g ngx-charts-series-horizontal
               type="stacked"
               [xScale]="barXScale"
               [yScale]="barYScale"
               [colors]="colors"
               [series]="group.series"
               [activeEntries]="activeEntries"
               [dims]="dims"
               [gradient]="gradient"
               [tooltipDisabled]="tooltipDisabled"
               [tooltipTemplate]="tooltipTemplate"
               [seriesName]="group.name"
               [animations]="animations"
               [showDataLabel]="false"
               [noBarWhenZero]="false"
               (select)="onClick($event, group)"
               (activate)="onActivate($event, group)"
               (deactivate)="onDeactivate($event, group)" />
      </svg:g>
      <svg:g *ngFor="let series of results">
        <svg:g shape-series
               [xScale]="xScale"
               [yScale]="yScale"
               [data]="series"
               [scaleType]="'time'"
               [tooltipDisabled]="tooltipDisabled"
               [tooltipTemplate]="tooltipTemplate"
               (select)="onClick($event, series)"
               (activate)="onActivate($event)"
               (deactivate)="onDeactivate($event)" />
      </svg:g>
    </svg:g>
  </svg:g>
</ngx-charts-chart>
<div *ngIf="legend" class="custom-legend ngx-charts" style="display: none;">
  <div *ngFor="let series of results">
    <ng-container *ngIf="!series || (series && series.name !== 'hidden')">
      <ng-container *ngIf="series && series.series && series.series[0]; else blackShape"><svg width="14" height="14">
        <svg:g aml-charts-shape [cx]="7" [cy]="7" [r]="6" [shape]="series.shape" [stroke]="series.series[0].params.stroke" [fill]="series.series[0].params.color" (select)="selectSeries.emit(series.name)"></svg:g>
      </svg></ng-container>
      <ng-template #blackShape><svg width="14" height="14"><svg:g aml-charts-shape [cx]="7" [cy]="7" [r]="6" [shape]="series.shape" (select)="selectSeries.emit(series.name)"></svg:g></svg></ng-template>
      = {{ series.name | translate }}
    </ng-container>
  </div>
</div>
