import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { IoClientService } from '../io-client.service';
import { ActiveFilter } from '../../../../shared/data/active-filter';

@Component({
  selector: 'client-type-filter',
  templateUrl: './client-type-filter.component.html',
  styleUrls: ['../../../../shared/data/data-filter.component.css']
})
export class ClientTypeFilterComponent implements OnInit, AfterContentChecked {
  constructor(
    private ioClientService: IoClientService
  ) { }
  @Input() filter: ActiveFilter;
  @Output() filterChanged = new EventEmitter<boolean>();
  public clientTypes: string[];
  @ViewChild('clientTypeElement') clientTypeElementRef: ElementRef;

  ngOnInit() {
    this.ioClientService.getIoClientTypes().subscribe(clientTypes => this.clientTypes = clientTypes);
  }

  ngAfterContentChecked() {
    if (this.filter.filterText !== undefined && this.clientTypeElementRef !== undefined) {
      this.clientTypeElementRef.nativeElement.value = this.filter.filterText;
    }
  }

  changed(ev) {
    this.filter.filterText = ev.target.value;
    this.filterChanged.emit(true);
  }
}
