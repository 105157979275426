import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../../../../core/app.config';
import { Tuple } from './tuple';

@Injectable()
export class GaussianChartService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getGaussianChartData(controlChartId: number, start: string, end: string): Observable<Tuple<string, number>[]> {
    return this.http.get<Tuple<string, number>[]>(this.apiHost + 'statistical/getgaussianchartdata/' + controlChartId + '?start=' + start + '&end=' + end);
  }
}
