import { SelectionItem } from '../selection-item';

export class GridColumn {
  constructor(header: string, property: string, type: string = 'string', requiredInput: boolean = true, translate: boolean = false, valueAsCssClass: boolean = false, editable: boolean = true) {
    this.header = header;
    this.fullHeader = header;
    this.property = property;
    this.type = type;
    this.requiredInput = requiredInput;
    this.translate = translate;
    this.enabled = true;
    this.valueAsCssClass = valueAsCssClass;
    this.editable = editable;
  }

  header: string;
  fullHeader: string;
  property: string;
  type: string;
  requiredInput: boolean;
  translate: boolean;
  sort: boolean;
  enabled: boolean;
  valueAsCssClass: boolean;
  editable: boolean;

  //Only used for grids.
  title: (obj: any) => string;
  content: (obj: any) => string;

  //Only used for forms.
  selectedItem: SelectionItem;
  listInputEndpoint: string;
  selectOptions: SelectionItem[];
}
