import { Component, Input, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css']
})

export class TooltipComponent implements OnInit, AfterViewInit {
  constructor(
    private elRef: ElementRef,
    private translateService: TranslateService
  ) { }
  @Input() text: string;
  @Input() position: string = 'bottom'; //top, bottom, left, right
  private tooltipProps: DOMRect | ClientRect;
  private innerWidth: any;

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    const translated = this.translateService.instant(this.text);
    this.text = (translated !== null) ? translated : this.text;
  }

  ngAfterViewInit() {
    const tooltip: HTMLElement = this.elRef.nativeElement.querySelector('.tooltip');
    this.tooltipProps = tooltip.getBoundingClientRect();
    tooltip.style.display = 'none';
  }

  show() {
    const tooltip: HTMLElement = this.elRef.nativeElement.querySelector('.tooltip');
    const props = this.elRef.nativeElement.getBoundingClientRect();
    let top = (props !== undefined) ? props.top : 0;
    let left = (props !== undefined) ? props.left : 0;
    const width = (this.tooltipProps !== undefined) ? this.tooltipProps.width : 0;
    const height = (this.tooltipProps !== undefined) ? this.tooltipProps.height : 0;

    if (this.position === 'bottom') {
      top += 29;
      left += 10 - (width / 2);
    } else if (this.position === 'top') {
      top += -6 - height;
      left += 10 - (width / 2);
    } else if (this.position === 'left') {
      top += 12 - (height / 2);
      left += -6 - width;
    } else if (this.position === 'right') {
      top += 12 - (height / 2);
      left += 29;
    }

    if (left < 0) {
      left = 0;
    } else if ((left + width) > this.innerWidth) {
      left = this.innerWidth - width;
    }

    tooltip.style.top = top + 'px';
    tooltip.style.left = left + 'px';
    tooltip.style.display = 'block';
  }

  hide() {
    const tooltip: HTMLElement = this.elRef.nativeElement.querySelector('.tooltip');
    tooltip.style.display = 'none';
  }
}
