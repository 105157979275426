import { Component, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

import { Report } from './report';
import { ReportService } from './report.service';
import { ReportExportService } from './report-export.service';
import { ReportType } from './report-type';
import { ReportParameter } from './report-parameter';
import { ReportSection } from './report-section';
import { ReportPeriodSelection } from './report-period-selection';
import { ReportViewService } from './report-view.service';
import { Header } from '../../shared/grid/header';
import { AppConfig } from '../app.config';
import { FilterOption } from '../../shared/data/filter-option';
import { ActiveFilter } from '../../shared/data/active-filter';
import { SubmitButtonComponent } from '../../shared/submit-button/submit-button.component';

@Component({
  selector: 'report-overview',
  templateUrl: './report-overview.component.html',
  styleUrls: ['./report-overview.component.css']
})

export class ReportOverviewComponent implements OnInit {
  constructor(
    private reportService: ReportService,
    private reportViewService: ReportViewService,
    private reportExportService: ReportExportService,
    private route: ActivatedRoute,
    private appConfig: AppConfig
  ) { }
  @Input() public report: Report;
  @Input() public informationLevelId: number;
  @Output() public editModeChanged = new EventEmitter<boolean>();
  @ViewChild(SubmitButtonComponent) submitButton: SubmitButtonComponent;
  public editMode = false;
  public parametersTabActive = true;
  public dataSelectionTabActive = false;
  public exporting = false;
  public reportLoaded = false;
  public pageCount = 0;
  private id = -1;
  private reportLevel: string;
  private reportTypes: ReportType[] = [];
  public reportType: ReportType = null;
  public filterOptions: FilterOption[] = null;
  public filterList: ActiveFilter[] = [];
  public index: number;
  public dataSelectionParameter: ReportParameter = null;
  public periodSelectionParameter: any = null;
  public reportParameters: ReportParameter[] = null;
  private dataSelectionTitle = '';
  private dataSelectionHeaders: Header[] = [{ header: '', sort: false, width: 'auto' }];
  private rawDataSelectionHeaders: string[] = [''];
  public dataSelectionItems: any[] = [];
  public dataSelectionCount = 0;
  public dataSelectionFilterOptions: FilterOption[] = [];
  public dataSelectionActiveFilters: ActiveFilter[] = [];
  private periodSelection: ReportPeriodSelection = null;
  private args = '';
  private dataDateTime: string;
  private dateTimeFormat: string;
  public error: any;

  ngOnInit() {
    this.error = null;
    this.index = 0;
    if (this.report == null) {
      this.id = +this.route.snapshot.paramMap.get('reportId');
      this.getReport();
    } else {
      this.id = this.report.id;
      this.getReport();
      this.changeEditMode(true);
    }
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    if (this.informationLevelId !== undefined) {
      this.dataSelectionActiveFilters = [{ id: 0, filterText: this.informationLevelId.toString(), filterOption: 'InformationLevel', filterDetails: {}, type: 'informationLevel', readonly: true }];
      this.args = '?index=1&size=9999&sort=;asc&filters=[{"id":0,"filterText":"' + this.informationLevelId.toString() + '","filterOption":"InformationLevel","filterDetails":{},"type":"informationLevel","readonly":true}]';
    }
    this.getReportTypes();
  }

  getReport() {
    this.error = null;
    if (this.id > 0) {
      this.reportService.getReport(this.id).subscribe(report => {
        this.report = report;
        this.filterList = (report.filters !== undefined && report.filters !== null && report.filters.length > 2) ? JSON.parse(report.filters) : [];
        this.index = this.filterList.length;

        if (this.report != null) {
          this.setReportType(this.report.reportType);
        }
      }, error => this.error = error.error);
    }
  }

  getReportTypes() {
    this.reportService.getReportLevel(this.informationLevelId).subscribe(reportLevel => {
      this.reportLevel = reportLevel;

      this.reportService.getAllReportTypes(this.reportLevel).subscribe(reportTypes => {
        this.reportTypes = reportTypes;
      });
    });
  }

  showParametersTab() {
    this.parametersTabActive = true;
    this.dataSelectionTabActive = false;
  }

  showDataSelectionTab() {
    this.parametersTabActive = false;
    this.dataSelectionTabActive = true;
  }

  setReportType(reportTypeId: number) {
    if (reportTypeId !== null && reportTypeId > 0) {
      this.reportService.getReportType(reportTypeId).subscribe(reportType => {
        this.reportType = reportType;
        this.dataSelectionParameter = reportType.dataSelection;
        this.periodSelectionParameter = reportType.periodSelection;
        this.reportParameters = reportType.reportParameters;

        if (this.reportType.reportEndpoints !== undefined && this.reportType.reportEndpoints !== null && this.reportType.reportEndpoints.length > 0) {
          this.reportViewService.getReportFilters(this.reportType.reportEndpoints).subscribe(filters => {
            if (filters !== null) {
              this.filterOptions = filters.map(item => new FilterOption(item.name, item.filterType));
            }
          }, error => this.error = error.error);
        } else {
          if (this.editMode) {
            this.setSelectionItems();
          }

          if (this.report !== undefined && this.report.reportParameters !== undefined && this.report.reportParameters !== null && this.report.reportParameters.length > 0) {
            for (let i = 0; i < this.report.reportParameters.length; i++) {
              const param: ReportParameter = this.report.reportParameters[i];

              if (this.dataSelectionParameter !== undefined && this.dataSelectionParameter !== null && this.dataSelectionParameter.id === param.id) {
                this.dataSelectionParameter.values = param.values;
              }
              if (this.periodSelectionParameter !== undefined && this.periodSelectionParameter !== null && this.periodSelectionParameter.id === param.id) {
                this.periodSelectionParameter.values = param.values;
              }

              if (this.reportParameters !== undefined && this.reportParameters !== null && this.reportParameters.length > 0) {
                for (let j = 0; j < this.reportParameters.length; j++) {
                  if (this.reportParameters[j] !== undefined && this.reportParameters[j].id === param.id) {
                    if (param.values === null && param.type === 'selectionCheckboxes') {
                      this.reportParameters[j].values = [];
                    } else {
                      this.reportParameters[j].values = param.values;
                    }
                  }
                }
              }
            }
          } else if (this.reportParameters !== undefined && this.reportParameters !== null && this.reportParameters.length > 0) {
            for (let j = 0; j < this.reportParameters.length; j++) {
              if (this.reportParameters[j].values === null && this.reportParameters[j].type === 'selectionCheckboxes') {
                this.reportParameters[j].values = [];
              }
            }
          }
        }
      }, error => this.error = error.error);
    } else {
      this.reportType = null;
      this.dataSelectionParameter = null;
      this.periodSelectionParameter = null;
      this.reportParameters = null;
    }
  }

  setSelectionItems() {
    if (this.dataSelectionParameter !== undefined && this.dataSelectionParameter !== null) {
      this.reportService.getSelectionMetaData(this.dataSelectionParameter.source + '/getmetadata').subscribe(metaData => {
        this.dataSelectionFilterOptions = metaData.filter(m => m.isFilter && m.filterType !== 'informationlevel' && m.filterType !== 'list' && !m.filterType.includes('id_'))
          .map(m => ({ name: m.name, type: m.filterType }));
      });
      if (this.dataSelectionParameter.description === 'ReportParameter.InformationLevels' || this.dataSelectionParameter.description === 'ReportParameter.Source') {
        this.dataSelectionHeaders = [{ header: '', sort: false, width: '28px' }, { header: 'Path', sort: false, width: '40%' },
          { header: 'Name', sort: true, width: '15%' }, { header: 'Type', sort: true, width: '13%' },
          { header: 'Make', sort: true, width: '13%' }, { header: 'Model', sort: true, width: '13%' }];
      }
      if (this.dataSelectionParameter.description === 'ReportParameter.Components') {
        this.dataSelectionHeaders = [{ header: '', sort: false, width: '28px' }, { header: 'Tag', sort: true, width: '25%' },
          { header: 'ComponentType.Description', sort: true, width: '30%' }, { header: 'ComponentType.Symbol', sort: true, width: '20%' },
          { header: 'ComponentType.UnitOfMeasurement', sort: true, width: '20%' }];
      }
      if (this.dataSelectionParameter.description === 'ReportParameter.Instruments') {
        this.dataSelectionHeaders = [{ header: '', sort: false, width: '28px' }, { header: 'Path', sort: false, width: '35%' },
          { header: 'Tag', sort: true, width: '14%' }, { header: 'Type', sort: true, width: '12%' },
          { header: 'Make', sort: true, width: '12%' }, { header: 'Model', sort: true, width: '12%' },
          { header: 'Criticality.Criticality', sort: true, width: '10%' }];
      }
      if (this.dataSelectionParameter.description === 'ReportParameter.Areas') {
        this.dataSelectionHeaders = [{ header: '', sort: false, width: '28px' }, { header: 'Path', sort: false, width: '35%' },
          { header: 'Name', sort: true, width: '30%' }, { header: 'Area.Type', sort: true, width: '30%' }];
      }
      if (this.dataSelectionParameter.description === 'ReportParameter.Streams') {
        this.dataSelectionHeaders = [{ header: '', sort: false, width: '28px' }, { header: 'Tag', sort: true, width: '35%' },
          { header: 'Stream.Description', sort: true, width: '60%' }];
      }
      if (this.dataSelectionParameter.description === 'ReportParameter.ValidationSessions') {
        this.dataSelectionHeaders = [{ header: '', sort: false, width: '28px' }, { header: 'StartDateTime', sort: true, width: '16%' }, { header: 'EndDateTime', sort: true, width: '16%' },
          { header: 'Validation.Method', sort: true, width: '30%' }, { header: 'Stream.Tag', sort: true, width: '16%' }, { header: 'Reference.StreamTag', sort: true, width: '17%' }
        ];
      }
      if (this.dataSelectionParameter.description === 'ReportParameter.FidelitySessions' || this.dataSelectionParameter.description === 'ReportParameter.LinearitySessions') {
        this.dataSelectionHeaders = [{ header: '', sort: false, width: '28px' }, { header: 'DateTime', sort: true, width: '45%' }, { header: 'Stream.Tag', sort: true, width: '40%' }];
      }
      if (this.dataSelectionParameter.description === 'ReportParameter.PerformanceIndicators') {
        this.dataSelectionHeaders = [{ header: '', sort: false, width: '28px' }, { header: 'Name', sort: true, width: '95%' }];
      }
      this.rawDataSelectionHeaders = this.dataSelectionHeaders.map(dsh => dsh.header);

      this.getDataSelectionItems(this.args);
    }

    if (this.periodSelectionParameter !== undefined && this.periodSelectionParameter !== null) {
      this.reportService.getPeriodSelectionOptions().subscribe(periodSelection => {
        if (periodSelection !== null) {
          if (this.periodSelectionParameter.values !== undefined && this.periodSelectionParameter.values !== null) {
            this.periodSelection = <ReportPeriodSelection>this.periodSelectionParameter.values;
            this.periodSelection.periodOptions = periodSelection.periodOptions;
            this.periodSelection.periodTypes = periodSelection.periodTypes;
          } else {
            this.periodSelection = periodSelection;
          }
        }
      });
    }

    if (this.reportParameters !== undefined && this.reportParameters !== null && this.reportParameters.length > 0) {
      for (let i = 0; i < this.reportParameters.length; i++) {
        if (this.reportParameters[i].source !== null) {
          this.reportService.getSelectionItems(this.reportParameters[i].source).subscribe(items => {
            if (items !== null) {
              this.reportParameters[i].selectionItems = items;

              if (this.reportParameters[i].values === null && this.reportParameters[i].type === 'selectionDropDown' && items.length > 0) {
                if (items[0].id !== undefined) {
                  this.reportParameters[i].values = items[0].id;
                } else {
                  this.reportParameters[i].values = items[0];
                }
              }
            }
          });
        }

        if (this.reportParameters[i].values === null && this.reportParameters[i].type === 'booleanYesNo') {
          this.reportParameters[i].values = '1';
        }

        if (this.reportParameters[i].values === null && this.reportParameters[i].type === 'numericEquation') {
          this.reportParameters[i].values = ['>', -1];
        }
      }
    }
  }

  getDataSelectionItems(args: string) {
    this.args = args;
    this.reportService.getSelectionItems(this.dataSelectionParameter.source + '/get' + args).subscribe(items => {
      if (items !== null) {
        this.dataSelectionParameter.selectionItems = items;
        this.dataSelectionItems = items;

        if (this.dataSelectionParameter.values === null) {
          this.dataSelectionParameter.values = [];
        }
      }
    });
    this.reportService.getSelectionCount(this.dataSelectionParameter.source + '/count' + args).subscribe(count => this.dataSelectionCount = count);
  }

  toggleAllDataSelectionItems() {
    if (this.dataSelectionItems !== undefined && this.dataSelectionItems !== null && this.dataSelectionItems.length > 0) {
      if (this.dataSelectionParameter.values.filter(v => this.dataSelectionItems.filter(i => i.id === v).length > 0).length > 0) { // There are selected items in the current filtered selection.
        this.dataSelectionParameter.values = this.dataSelectionParameter.values.filter(v => this.dataSelectionItems.filter(i => i.id === v).length === 0);
      } else {
        for (let i = 0; i < this.dataSelectionItems.length; i++) {
          this.dataSelectionParameter.values.push(this.dataSelectionItems[i].id);
        }
      }
    }
  }

  toggleDataSelectionItem(id: any) {
    if (this.dataSelectionParameter.values.includes(id)) {
      const index = this.dataSelectionParameter.values.indexOf(id);
      if (index > -1) {
        this.dataSelectionParameter.values.splice(index, 1);
      }
    } else {
      this.dataSelectionParameter.values.push(id);
    }
  }

  toggleParameterSelectionItem(items: any[], id: any) {
    if (items !== null && id !== null) {
      if (items.includes(id)) {
        const index = items.indexOf(id);
        if (index > -1) {
          items.splice(index, 1);
        }
      } else {
        items.push(id);
      }
    }
  }

  submitReport(form: NgForm) {
    if (this.report !== undefined && this.submitButton !== undefined && this.submitButton.submit()) {
      if (form.valid) {
        this.error = null;
        this.report.filters = (this.filterList !== null && this.filterList.length > 0) ? JSON.stringify(this.filterList) : '[]';
        this.report.reportParameters = [];

        if (this.dataSelectionParameter !== null) {
          this.report.reportParameters.push(this.dataSelectionParameter);
        }
        if (this.periodSelectionParameter !== null && this.periodSelection !== null) {
          this.periodSelectionParameter.values = this.periodSelection;
          this.periodSelectionParameter.values.periodOptions = [];
          this.periodSelectionParameter.values.periodTypes = [];
          this.report.reportParameters.push(this.periodSelectionParameter);
        }
        if (this.reportParameters !== null && this.reportParameters.length > 0) {
          for (let i = 0; i < this.reportParameters.length; i++) {
            if (this.reportParameters[i].selectionItems !== null) {
              this.reportParameters[i].selectionItems = [];
            }
            this.report.reportParameters.push(this.reportParameters[i]);
          }
        }

        if (this.report.id < 1) { // POST
          this.reportService.postReport(this.report, this.informationLevelId).subscribe(response => {
            this.submitButton.ready();
            this.changeEditMode(false);
            this.getReport();
          }, error => {
            this.error = error.error;
            this.submitButton.ready();
          });
        } else { // PUT
          this.reportService.putReport(this.report, this.informationLevelId).subscribe(response => {
            this.submitButton.ready();
            this.changeEditMode(false);
            this.getReport();
          }, error => {
            this.error = error.error;
            this.submitButton.ready();
          });
        }
      } else {
        for (const i of Object.keys(form.controls)) {
          form.controls[i].markAsTouched();
        }
        this.submitButton.ready();
      }
    }
  }

  cancel() {
    this.changeEditMode(false);
  }

  close() {
    if (this.report !== undefined && this.report.id > 0) {
      this.getReport();
    }
    this.changeEditMode(false);
  }

  changeEditMode(editMode: boolean) {
    this.editMode = editMode;
    this.editModeChanged.emit(editMode);
  }

  onReportLoaded(pageCount: number) {
    this.reportLoaded = (pageCount > -1);
    this.pageCount = pageCount;
  }

  setDataDateTime(dataDateTime: string) {
    if (dataDateTime !== null) {
      this.dataDateTime = dataDateTime.replace('T', '_');
    } else {
      this.dataDateTime = 'undefined';
    }
  }

  async getPdfFile() {
    this.startExport();
    if (this.report !== undefined && this.report !== null && this.reportType !== undefined && this.reportType !== null) {
      const htmlElements = document.getElementsByClassName('report-export-pages');

      if (htmlElements[0] !== undefined && htmlElements[0] !== null) {
        const reportPages = htmlElements[0].children;
        this.reportExportService.exportToPdf(this.report.description, this.reportType.pageOrientation, reportPages, this.dataDateTime).subscribe(data => {
          this.exporting = false;
        }, error => {
          this.exportFailed();
        });
      } else {
        this.exportFailed();
      }
    } else {
      this.exportFailed();
    }
  }

  async getWordFile() {
    this.startExport();
    const htmlElements = document.getElementsByClassName('report-section');

    if (this.report !== undefined && this.report !== null && this.reportType !== undefined && this.reportType !== null && htmlElements !== null && htmlElements.length > 0) {
      const reportPages = document.getElementsByClassName('report-export-pages');
      this.reportExportService.exportToWord(this.report.description, this.reportType.pageOrientation, reportPages[0], this.dataDateTime).subscribe(data => {
        this.exporting = false;
      }, error => {
        this.exportFailed();
      });
    } else {
      this.exportFailed();
    }
  }

  getExcelFile() {
    this.startExport();
    if (this.report !== undefined && this.report !== null && this.reportType !== null) {
      this.reportExportService.exportToExcel(this.report.description, this.report.id, this.reportType, this.dataDateTime).subscribe(data => {
        this.exporting = false;
      }, error => {
        this.exportFailed();
      });
    } else {
      this.exportFailed();
    }
  }

  getCsvFile() {
    this.startExport();
    if (this.report !== undefined && this.report !== null && this.reportType !== null) {
      this.reportExportService.exportToCsv(this.report.description, this.report.id, this.reportType, this.dataDateTime).subscribe(data => {
        this.exporting = false;
      }, error => {
        this.exportFailed();
      });
    } else {
      this.exportFailed();
    }
  }

  startExport() {
    this.error = null;
    this.exporting = true;
  }

  exportFailed() {
    this.exporting = false;
    this.error = { Message: 'Report.ExportFailed', DateTime: Date.now.toString() };
  }
}
