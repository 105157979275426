/*
 * Modified copy of https://github.com/swimlane/ngx-charts/blob/master/src/common/circle.component.ts
 */
import {
  Component,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  HostListener
} from '@angular/core';

@Component({
  selector: 'g[aml-charts-shape]',
  templateUrl: './shape.component.html',
  styleUrls: ['./shape.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShapeComponent implements OnChanges {

  @Input() cx;
  @Input() cy;
  @Input() r;
  @Input() fill;
  @Input() stroke;
  @Input() data;
  @Input() shape;
  @Input() classNames;
  @Input() shapeOpacity;
  @Input() pointerEvents;

  @Output() select = new EventEmitter();
  @Output() activate = new EventEmitter();
  @Output() deactivate = new EventEmitter();

  @HostListener('click')
  onClick() {
    this.select.emit(this.data);
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.activate.emit(this.data);
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.deactivate.emit(this.data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.classNames = Array.isArray(this.classNames) ?
      this.classNames.join(' ') :
      '';
    this.classNames += this.shape;
  }

}
