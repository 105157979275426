<div class="error" *ngIf="error">
  <div *ngIf="error.title">
    {{ error.title | translate }}
    <span class="error-expand" (click)="toggleExpanded()">
      <i class="material-icons" *ngIf="!expanded">expand_more</i>
      <i class="material-icons" *ngIf="expanded">expand_less</i>
    </span>
    <ul *ngIf="error.detail"><li>{{ error.detail }}</li></ul>
    <div class="error-details" *ngIf="expanded">
      <div *ngIf="error.instance"><b>{{ 'Error.ReferenceCode' | translate }}:</b><br />{{ error.instance }}</div>
    </div>
  </div>
  <div *ngIf="!error.Message">{{ 'Error.Generic' | translate }}</div>
</div>
