import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

import { ActiveFilter } from '../../shared/data/active-filter';

@Component({
  selector: 'area-type-filter',
  templateUrl: './area-type-filter.component.html',
  styleUrls: ['../../shared/data/data-filter.component.css']
})
export class AreaTypeFilterComponent implements AfterContentChecked {
  @Input() filter: ActiveFilter;
  @Output() filterChanged = new EventEmitter<boolean>();
  @ViewChild('areaTypeElement') areaTypeElementRef: ElementRef;

  ngAfterContentChecked() {
    if (this.filter.filterText !== undefined && this.areaTypeElementRef !== undefined) {
      this.areaTypeElementRef.nativeElement.value = this.filter.filterText;
    }
  }

  changed(ev) {
    this.filter.filterText = ev.target.value;
    this.filterChanged.emit(true);
  }
}
