/*
 * Modified copy of https://github.com/swimlane/ngx-charts/blob/master/src/common/circle-series.component.ts
 */
import { Component, Input, Output, OnInit, OnChanges, SimpleChanges, EventEmitter, TemplateRef, ChangeDetectionStrategy } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { id } from '@swimlane/ngx-charts/esm2015/lib/utils/id';
import { formatLabel } from '@swimlane/ngx-charts/esm2015/lib/common/label.helper';

@Component({
  selector: 'g[shape-series]',
  templateUrl: './shape-series.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate(250, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class ShapeSeriesComponent implements OnInit, OnChanges {
  @Input() data;
  @Input() type = 'standard';
  @Input() xScale;
  @Input() yScale;
  @Input() scaleType;
  @Input() tooltipDisabled = false;
  @Input() tooltipTemplate: TemplateRef<any>;

  @Output() select = new EventEmitter();
  @Output() activate = new EventEmitter();
  @Output() deactivate = new EventEmitter();

  public shapes: any[];
  private shapeType: string;
  gradientId: string;
  gradientFill: string;

  ngOnInit() {
    this.gradientId = 'grad' + id().toString();
    this.gradientFill = `url(#${this.gradientId})`;
    this.update();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  update(): void {
    this.shapes = [];
    this.shapeType = this.data.shape;
    for (let i = 0; i < this.data.series.length; i++) {
      this.shapes.push(this.mapDataPointToShape(this.data.series[i], i));
    }
  }

  getTooltipText({ tooltipLabel, value, seriesName, min, max }): string {
    return `
      <span class="tooltip-label">${seriesName} • ${tooltipLabel}</span>
      <span class="tooltip-val">${value.toLocaleString()}${this.getTooltipMinMaxText(min, max)}</span>
    `;
  }

  mapDataPointToShape(d: any, i: number): any {
    const seriesName = this.data.name;

    const value = d.value;
    const label = d.name;
    const tooltipLabel = formatLabel(label);

    let cx;
    if (this.scaleType === 'time') {
      cx = this.xScale(label);
    } else if (this.scaleType === 'linear') {
      cx = this.xScale(Number(label));
    } else {
      cx = this.xScale(label);
    }

    const cy = this.yScale(this.type === 'standard' ? value : d.d1);
    const height = this.yScale.range()[0] - cy;
    const radius = d.params.radius ? d.params.radius : 3;
    const opacity = 1;

    const data = {
      series: seriesName,
      value,
      name: label
    };

    return {
      classNames: [`shape-data-${i}`],
      value,
      label,
      data,
      cx,
      cy,
      radius,
      height,
      tooltipLabel,
      color: d.params.color,
      stroke: d.params.stroke,
      opacity,
      seriesName,
      min: d.min,
      max: d.max,
      title: d.title,
      params: d.params
    };
  }

  getTooltipMinMaxText(min: any, max: any) {
    if (min !== undefined || max !== undefined) {
      let result = ' (';
      if (min !== undefined) {
        if (max === undefined) {
          result += '≥';
        }
        result += min.toLocaleString();
        if (max !== undefined) {
          result += ' - ';
        }
      } else if (max !== undefined) {
        result += '≤';
      }
      if (max !== undefined) {
        result += max.toLocaleString();
      }
      result += ')';
      return result;
    } else {
      return '';
    }
  }

  onClick(value, label, params): void {
    this.select.emit({
      name: label,
      value,
      params
    });
  }

  activateShape(): void {
    this.activate.emit({ name: this.data.name });
  }

  deactivateShape(): void {
    this.shapes.map(shape => shape.opacity = 0);
    this.deactivate.emit({ name: this.data.name });
  }

  contextify(shape: any) {
    return { plottedValue: shape };
  }
}
