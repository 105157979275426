<button *ngIf="editMode" class="button-blue configure" (click)="configure = !configure"><i class="material-icons">settings</i> {{ 'Configure' | translate }}</button>
<div *ngIf="favorites && favorites.length > 0">
  <p *ngFor="let favorite of favorites" [draggable]="editMode" (dragstart)="onDragStart($event, favorite.path)">
    <a [routerLink]="[favorite.path]">{{ favorite.name }}</a>
    <span class="chip">{{ favorite.type | translate }}</span>
    <button *ngIf="configure" class="button-blue small-button" (click)="delete(favorite)">{{ 'Delete' | translate }}</button>
  </p>
</div>
<div *ngIf="favorites && favorites.length === 0" class="overview-content">
  {{ 'Favorite.NoFavoritesAdded' | translate }}
</div>
