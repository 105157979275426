import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { OperationalStateService } from '../operational-state.service';
import { ActiveFilter } from '../../../../shared/data/active-filter';

@Component({
  selector: 'operational-state-filter',
  templateUrl: './operational-state-filter.component.html',
  styleUrls: ['../../../../shared/data/data-filter.component.css']
})
export class OperationalStateFilterComponent implements OnInit, AfterContentChecked {
  constructor(
    private operationalStateService: OperationalStateService
  ) { }
  @Input() filter: ActiveFilter;
  @Output() filterChanged = new EventEmitter<boolean>();
  public stateTypes: string[];
  @ViewChild('operStateElement') operStateElementRef: ElementRef;

  ngOnInit() {
    this.operationalStateService.getOperationalStateTypes().subscribe(stateTypes => this.stateTypes = stateTypes);
  }

  ngAfterContentChecked() {
    if (this.filter.filterText !== undefined && this.operStateElementRef !== undefined) {
      this.operStateElementRef.nativeElement.value = this.filter.filterText;
    }
  }

  changed(ev) {
    this.filter.filterText = ev.target.value;
    this.filterChanged.emit(true);
  }
}
