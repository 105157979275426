<div class="grid-wrapper">
  <grid [name]="'Linearity_Sessions'" [title]="''" [genericDataRetrieval]="false" [headers]="headers" [filterOptions]="filterOptions" [count]="count" [exportData]="linearitySessions" (filterEvent)="getLinearitySessions($event)">
    <tr *ngFor="let linearitySession of linearitySessions; let i = index; let odd = odd" [class.odd]="odd">
      <td [title]="linearitySession.dateTime | date:dateTimeFormat">{{ linearitySession.dateTime | date:dateTimeFormat }}</td>
      <td>
        <button type="button" class="button-blue" [disabled]="selectedLinearitySession && linearitySession.id == selectedLinearitySession.id" (click)="selectLinearitySession(linearitySession)">{{ 'Select' | translate }}</button>
        <button type="button" class="button-blue" (click)="viewLinearitySession(linearitySession)">{{ 'Details' | translate }}</button>
        <button type="button" class="button-red" (click)="deleteLinearitySession(linearitySession.id)">{{ 'Delete' | translate }}</button>
      </td>
    </tr>
  </grid>
  <error-message [error]="error"></error-message>
</div>
<custom-dialog *ngIf="editDialog" [visible]="editDialog" (editModeChanged)="dialogChanged($event)">
  <linearity-session-overview [linearitySession]="dialogSession" (editModeChanged)="dialogChanged($event)"></linearity-session-overview>
</custom-dialog>
<delete-dialog [visible]="deleteDialog" [error]="error" (confirmDelete)="confirmDelete($event)"></delete-dialog>
