import { Component, Input, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { ReportType } from '../report-type';
import { ReportDatasetProperty } from './report-dataset-property';
import { ReportSectionExtended } from './report-section-extended';
import { ReportSectionHeader } from '../report-section-header';
import { ReportSectionContent } from '../report-section-content';
import { ErrorMessage } from '../../../shared/error/error-message';

@Component({
  selector: 'report-type-data-editor',
  templateUrl: './report-type-data-editor.component.html',
  styleUrls: ['./report-type-data-editor.component.css']
})

export class ReportTypeDataEditorComponent {
  constructor(
    private elRef: ElementRef,
    private translate: TranslateService
  ) { }
  @Input() public reportType: ReportType;
  @Input() public shownInDialog: boolean = false;
  @Input() public editMode: boolean = false;
  @Input() public datasetProperties: ReportDatasetProperty[] = [];
  public error: ErrorMessage;
  private deleteSectionItem: ReportSectionExtended;
  public deleteSectionDialog = false;
  private order: number = -1;
  private dragElementName: string = null;
  private dragElementDataPath: string = null;
  private dragColumnIndex: number = null;
  private dragColumnSection: ReportSectionExtended = null;
  private dragEditorSectionIndex: number = null;
  private dragEditorSectionSections: ReportSectionExtended[] = null;
  private dragEditorSection: ReportSectionExtended = null;

  onDragSectionStart(event: DragEvent, sectionType: string) {
    this.dragElementName = 'section';
    if (event.dataTransfer !== null) {
      event.dataTransfer.setData('sectionType', sectionType);
    }
    this.addClass(this.elRef.nativeElement, true, 'section-zone', true, true);
    this.addClass(this.elRef.nativeElement, true, 'editor-section-drop-zone', true, true);
  }

  onDragSectionEnd(event: DragEvent) {
    this.dragElementName = null;
    this.removeClasses(this.elRef.nativeElement, true, 'section-zone');
    this.removeClasses(this.elRef.nativeElement, true, 'editor-section-drop-zone');
  }

  dragEnterSectionZone(event: DragEvent, element, section: ReportSectionExtended) {
    if (this.dragElementName !== null && this.dragElementName === 'section') {
      this.addClass(element, false, null, true, false);
    } else {
      this.addClass(element, false, null, false, false);
    }
  }

  dragLeaveSectionZone(event: DragEvent, element, section: ReportSectionExtended) {
    this.removeClasses(element, false, null);

    if (this.dragElementName !== null && this.dragElementName === 'section') {
      this.addClass(element, false, null, true, true);
    }
  }

  dropSection(event: DragEvent, sections: ReportSectionExtended[], datapath: string, element, sectionIndex: number = null) {
    event.preventDefault();
    this.removeClasses(element, false, null);
    const sectionType = (event.dataTransfer !== null) ? event.dataTransfer.getData('sectionType') : null;

    if (sections !== null && sectionType !== null && sectionType === 'container' || sectionType === 'detailsTable' || sectionType === 'overviewTable'
      || sectionType === 'overviewTableVertical' || sectionType === 'text' || sectionType === 'pageBreak') {
      const section: ReportSectionExtended = {
        id: -1, type: sectionType, enabled: true, title: null, headers: null, content: null, dataTable: '', sectionChildren: null,
        loaded: true, order: this.order, dataPath: datapath, highlightPropertyDropZone: false, highlightListDropZone: false, highlightEditorSectionDropZone: false
      };
      this.order -= 1;

      if (sectionType === 'container') {
        section.sectionChildren = [];
      }

      if (sectionType === 'detailsTable' || sectionType === 'overviewTable' || sectionType === 'overviewTableVertical') {
        section.headers = [];
        section.content = [];
        section.title = (sectionType === 'detailsTable') ? 'Details' : 'Overview';
      }

      if (sectionType === 'text') {
        const content: ReportSectionContent = { content: '', dataType: null, translatable: false };
        section.content = [content];
      }

      if (sectionIndex === null) {
        sections.push(section);
      } else {
        const oldSections = sections.slice();
        if (sections !== null && sections.length > 0) {
          for (let i = 0; i < sections.length; i++) {
            sections.pop();
          }
        }
        if (sectionIndex === 0) {
          if (sections.length === 1) {
            sections.pop();
          }
          sections.push(section);
          oldSections.forEach(item => sections.push(item));
        } else {
          const before: ReportSectionExtended[] = oldSections.slice(0, sectionIndex - 1);
          before.forEach(item => sections.push(item));
          sections.push(section);
          const after: ReportSectionExtended[] = oldSections.slice(sectionIndex);
          after.forEach(item => sections.push(item));
        }
      }
    }
  }

  onDragPropertyStart(event: DragEvent, property: ReportDatasetProperty) {
    this.dragElementName = 'property.' + property.type;
    this.dragElementDataPath = property.dataPath;
    const translatable = (property.translatable) ? 'true' : 'false';
    const translatedTitle = (property.title !== null && property.title.length > 0) ? this.translate.instant(property.title) : null;
    const title = (translatedTitle !== null && translatedTitle.length > 0) ? translatedTitle : property.title;
    const propertyString = property.name + ';' + title + ';' + property.type + ';' + property.dataPath + ';' + translatable;

    if (event.dataTransfer !== null) {
      event.dataTransfer.setData('property', propertyString);
    }

    if (property.type !== 'List') {
      this.setSectionHighlight(property.dataPath, true, false, false);
    } else {
      this.setSectionHighlight(property.dataPath, false, true, false);
    }
  }

  onDragPropertyEnd(event: DragEvent) {
    this.setSectionHighlight(this.dragElementDataPath, false, false, false);
    this.dragElementName = null;
    this.dragElementDataPath = null;
    this.removeClasses(this.elRef.nativeElement, true, 'property-zone');
    this.removeClasses(this.elRef.nativeElement, true, 'list-zone');
  }

  dragEnterPropertyZone(event: DragEvent, section: ReportSectionExtended, element) {
    if (this.dragElementName !== null && this.dragElementName !== 'section' && this.dragElementName !== 'editorSection' && this.dragElementName !== 'property.List' && this.dragElementDataPath === section.dataPath) {
      this.addClass(element, false, null, true, false);
    } else {
      this.addClass(element, false, null, false, false);
    }
  }

  dragLeavePropertyZone(event: DragEvent, section: ReportSectionExtended, element) {
    this.removeClasses(element, false, null);
  }

  dragEnterListZone(event: DragEvent, section: ReportSectionExtended, element) {
    if (this.dragElementName !== null && this.dragElementName === 'property.List' && this.dragElementDataPath === section.dataPath) {
      this.addClass(element, false, null, true, false);
    } else {
      this.addClass(element, false, null, false, false);
    }
  }

  dragLeaveListZone(event: DragEvent, section: ReportSectionExtended, element) {
    this.removeClasses(element, false, null);
  }

  dropProperty(event: DragEvent, section: ReportSectionExtended, element) {
    event.preventDefault();
    this.removeClasses(element, false, null);
    const property = this.getPropertyInfo(event);

    if (property !== null && property.length === 5 && property[2] !== 'List' && property[3] === section.dataPath) {
      const header: ReportSectionHeader = { header: property[1], width: null };
      const translatable: boolean = (property[4] === 'true');
      const content: ReportSectionContent = { content: property[0], dataType: property[2], translatable: translatable };
      section.headers.push(header);
      section.content.push(content);

      if (section.type === 'overviewTable') {
        for (let i = 0; i < section.headers.length; i++) {
          section.headers[i].width = null;
        }
      }
    }
  }

  dropDatasetList(event: DragEvent, section: ReportSectionExtended, element) {
    event.preventDefault();
    this.removeClasses(element, false, null);
    const property = this.getPropertyInfo(event);

    if (property !== null && property.length === 5 && property[2] === 'List' && property[3] === section.dataPath) {
      section.dataTable = property[0];
      section.dataPath = section.dataPath + '/' + property[0];
    }
  }

  getPropertyInfo(event: DragEvent): string[] {
    const propertyString = (event.dataTransfer !== null) ? event.dataTransfer.getData('property') : '';
    const property = propertyString.split(';');
    return property;
  }

  onDragColumnStart(event: DragEvent, index: number, section: ReportSectionExtended) {
    if (section !== null) {
      this.dragColumnIndex = index;
      this.dragColumnSection = section;
    }
  }

  onDragColumnEnd(event: DragEvent, table) {
    this.dragElementName = null;
    this.removeClasses(table, true, 'highlight');
  }

  dragEnterColumn(event: DragEvent, element) {
    if (this.dragElementName === null) {
      this.addClass(element, false, null, true, true);
    }
  }

  dragLeaveColumn(event: DragEvent, element) {
    if (this.dragElementName === null) {
      this.removeClasses(element, false, null);
    }
  }

  dropColumn(event: DragEvent, index: number, section: ReportSectionExtended) {
    if (this.dragColumnSection === section && section.headers !== null && section.headers[index] !== undefined && section.content[index] !== null && section.content[index] !== undefined
      && section.headers[index] !== null && this.dragColumnSection.headers[this.dragColumnIndex] !== undefined && this.dragColumnSection.content[this.dragColumnIndex] !== null
      && this.dragColumnSection.content[this.dragColumnIndex] !== undefined && this.dragColumnSection.headers[this.dragColumnIndex] !== null) {
      const header = this.dragColumnSection.headers[this.dragColumnIndex];
      const content = this.dragColumnSection.content[this.dragColumnIndex];

      if (this.dragColumnIndex > index) { // New index is lower: descend array, moving each element up one position.
        for (let i = this.dragColumnIndex; i > index; i--) {
          section.content[i] = section.content[i - 1];
        }
        section.content[index] = content;
      }

      if (this.dragColumnIndex < index) { // New index is higher: ascend array, moving each element down one position.
        for (let i = this.dragColumnIndex; i < index; i++) {
          section.content[i] = section.content[i + 1];
        }
        section.content[index] = content;
      }

      if (this.dragColumnIndex > index) {
        for (let i = this.dragColumnIndex; i > index; i--) {
          section.headers[i] = section.headers[i - 1];
        }
        section.headers[index] = header;
      }

      if (this.dragColumnIndex < index) {
        for (let i = this.dragColumnIndex; i < index; i++) {
          section.headers[i] = section.headers[i + 1];
        }
        section.headers[index] = header;
      }
    }

    this.dragColumnIndex = null;
    this.dragColumnSection = null;
  }

  onDragEditorSectionStart(event: DragEvent, index: number, sections: ReportSectionExtended[], sectionDiv) {
    if (sections !== null) {
      this.dragElementName = 'editorSection';
      this.dragEditorSectionIndex = index;
      this.dragEditorSectionSections = sections;
      this.dragEditorSection = sections[index];
    }
  }

  onDragEditorSectionEnd(event: DragEvent, sectionDiv) {
    this.dragElementName = null;
    this.removeClasses(this.elRef.nativeElement, true, 'section-zone');
    this.removeClasses(this.elRef.nativeElement, true, 'editor-section-drop-zone');
  }

  dragEnterEditorSection(event: DragEvent, element, section: ReportSectionExtended) {
    if (this.dragElementName !== null && this.dragElementName === 'editorSection') {
      const containsCheck: ReportSectionExtended[] = this.dragEditorSectionSections.filter(s => s === section);
      if (containsCheck !== null && containsCheck.length > 0) {
        this.addClass(element, false, null, true, false);
      } else {
        this.addClass(element, false, null, false, false);
      }
    }
  }

  dragLeaveEditorSection(event: DragEvent, element, section: ReportSectionExtended) {
    if (this.dragElementName !== null && this.dragElementName === 'editorSection') {
      this.removeClasses(element, false, null);
    }
  }

  dropEditorSection(event: DragEvent, sections: ReportSectionExtended[], element, sectionIndex: number = null) {
    event.preventDefault();
    this.removeClasses(element, false, null);

    if (this.dragElementName === 'editorSection' && sectionIndex !== null && sections[sectionIndex] !== undefined && sections[sectionIndex] !== null) {
      const containsCheck: ReportSectionExtended[] = this.dragEditorSectionSections.filter(s => s === sections[sectionIndex]);
      if (containsCheck !== null && containsCheck.length > 0) {
        if (this.dragEditorSectionIndex > sectionIndex) { // New index is lower: descend array, moving each element up one position.
          for (let i = this.dragEditorSectionIndex; i > sectionIndex; i--) {
            sections[i] = sections[i - 1];
          }
          sections[sectionIndex] = this.dragEditorSection;
        }

        if (this.dragEditorSectionIndex < sectionIndex) { // New index is higher: ascend array, moving each element down one position.
          for (let i = this.dragEditorSectionIndex; i < sectionIndex; i++) {
            sections[i] = sections[i + 1];
          }
          sections[sectionIndex] = this.dragEditorSection;
        }
      }
      this.dragEditorSectionSections = null;
      this.dragEditorSectionIndex = null;
      this.dragEditorSection = null;
    }
  }

  addClass(element, elementList: boolean, zoneClass: string, allowed: boolean, highlight: boolean) {
    const className = (allowed) ? (highlight) ? 'highlight' : 'allowed' : 'not-allowed';
    if (elementList) {
      const elements: HTMLElement[] = element.getElementsByClassName(zoneClass);
      if (elements !== null && elements.length > 0) {
        for (let i = 0; i < elements.length; i++) {
          elements[i].className = elements[i].className + ' ' + className;
        }
      }
    } else {
      element.className = element.className + ' ' + className;
    }
  }

  removeClasses(element, elementList: boolean, zoneClass: string) {
    if (elementList) {
      const elements: HTMLElement[] = element.getElementsByClassName(zoneClass);
      if (elements !== null && elements.length > 0) {
        for (let i = 0; i < elements.length; i++) {
          if (elements[i] !== undefined && elements[i].className !== undefined) {
            elements[i].className = elements[i].className.replace(' highlight', '');
          }
          if (elements[i] !== undefined && elements[i].className !== undefined) {
            elements[i].className = elements[i].className.replace(' allowed', '');
          }
          if (elements[i] !== undefined && elements[i].className !== undefined) {
            elements[i].className = elements[i].className.replace(' not-allowed', '');
          }
        }
      }
    } else {
      if (element !== undefined && element.className !== undefined) {
        element.className = element.className.replace(' highlight', '');
      }
      if (element !== undefined && element.className !== undefined) {
        element.className = element.className.replace(' allowed', '');
      }
      if (element !== undefined && element.className !== undefined) {
        element.className = element.className.replace(' not-allowed', '');
      }
    }
  }

  setSectionHighlight(dataPath: string, highlightPropertyDropZone: boolean, highlightListDropZone: boolean, highlightEditorSectionDropZone: boolean, sections: ReportSectionExtended[] = null) {
    sections = (sections === null) ? this.reportType.reportSections as ReportSectionExtended[] : sections;

    if (sections !== null && sections.length > 0) {
      for (let i = 0; i < sections.length; i++) {
        if (sections[i].dataPath === dataPath) {
          sections[i].highlightPropertyDropZone = highlightPropertyDropZone;
          sections[i].highlightListDropZone = highlightListDropZone;
          sections[i].highlightEditorSectionDropZone = highlightEditorSectionDropZone;
        }

        if (sections[i].sectionChildren !== null && sections[i].sectionChildren.length > 0) {
          this.setSectionHighlight(dataPath, highlightPropertyDropZone, highlightListDropZone, highlightEditorSectionDropZone, sections[i].sectionChildren);
        }
      }
    }
  }

  deleteProperty(index: number, section: ReportSectionExtended) {
    if (index !== null && section !== null && section.headers !== undefined && section.headers !== null && section.headers.length > 0 && section.content !== undefined && section.content !== null && section.content.length > 0) {
      section.headers.splice(index, 1);
      section.content.splice(index, 1);

      if (section.type === 'overviewTable') {
        for (let i = 0; i < section.headers.length; i++) {
          section.headers[i].width = null;
        }
      }
    }
  }

  toggleSectionEnabled(section: ReportSectionExtended) {
    section.enabled = !section.enabled;
  }

  deleteSection(section: ReportSectionExtended) {
    this.deleteSectionItem = section;
    this.deleteSectionDialog = true;
  }

  deleteSectionRecursive(section: ReportSectionExtended, sections: ReportSectionExtended[]): ReportSectionExtended[] {
    if (section !== null && sections !== null && sections.length > 0) {
      const prevLength = sections.length;
      sections = sections.filter(obj => obj !== section);
      const currentLength = sections.length;

      if (prevLength === currentLength) {
        for (let i = 0; i < sections.length; i++) {
          if (sections[i].sectionChildren !== null && sections[i].sectionChildren.length > 0) {
            sections[i].sectionChildren = this.deleteSectionRecursive(section, sections[i].sectionChildren);
          }
        }
      }
    }
    return sections;
  }

  confirmDeleteSection(confirmed: boolean) {
    this.deleteSectionDialog = false;
    if (confirmed) {
      if (this.deleteSectionItem !== null && this.reportType !== undefined && this.reportType.reportSections !== null) {
        this.reportType.reportSections = this.deleteSectionRecursive(this.deleteSectionItem, this.reportType.reportSections as ReportSectionExtended[]);
      }
    }
    this.deleteSectionItem = null;
  }
}
