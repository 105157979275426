import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ClientTypeFilterComponent } from './client-type-filter.component';
import { IoClientService } from '../io-client.service';

@NgModule({
  declarations: [ClientTypeFilterComponent],
  exports: [ClientTypeFilterComponent],
  imports: [CommonModule, TranslateModule],
  providers: [IoClientService]
})
export class ClientTypeFilterModule { }
