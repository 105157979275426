import { Component, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

import { ReportManagementService } from './report-management.service';
import { ReportType } from '../report-type';
import { ReportDataset } from './report-dataset';
import { ReportDatasetProperty } from './report-dataset-property';
import { ReportDatasetParameter } from './report-dataset-parameter';
import { ReportParameter } from '../report-parameter';
import { ClaimService } from '../../claim.service';
import { ReportEndpoint } from '../report-endpoint';
import { Endpoint } from '../../../shared/data/endpoint';
import { SubmitButtonComponent } from '../../../shared/submit-button/submit-button.component';

@Component({
  selector: 'report-type-overview',
  templateUrl: './report-type-overview.component.html',
  styleUrls: ['./report-type-overview.component.css']
})

export class ReportTypeOverviewComponent implements OnInit {
  constructor(
    private reportManagementService: ReportManagementService,
    private route: ActivatedRoute,
    private claimService: ClaimService
  ) { }
  @Input() public reportType: ReportType;
  @Input() public userDefined: boolean;
  @Input() public shownInDialog: boolean = false;
  @Output() public editModeChanged = new EventEmitter<boolean>();
  @ViewChild(SubmitButtonComponent) submitButton: SubmitButtonComponent;
  public editMode: boolean = false;
  private id = -1;
  public updateAccess: boolean;
  public datasetType = 'ReportDataset.Custom';
  public datasetTypes = ['ReportDataset.Custom', 'ReportDataset.Predefined'];
  public endpoints: Endpoint[] = [];
  private datasets: ReportDataset[] = [];
  public dataset: ReportDataset = null;
  private reportLevels: string[] = [];
  private datasetProperties: ReportDatasetProperty[] = null;
  private requiredParams: ReportDatasetParameter[] = [];
  private optionalParams: ReportDatasetParameter[] = [];
  private dataEditorTabActive: boolean = true;
  private layoutEditorTabActive: boolean = false;
  private filterTabActive: boolean = false;
  public error: any;

  ngOnInit() {
    this.error = null;
    if (this.userDefined) {
      this.updateAccess = this.claimService.checkClaimContains('aml.report', 'u');
    } else {
      this.updateAccess = this.claimService.checkClaimContains('aml.reportman', 'u');
    }
    if (this.reportType == null) {
      this.id = +this.route.snapshot.paramMap.get('reportTypeId');
      this.getReportType();
    } else {
      this.id = this.reportType.id;
      this.getReportType();
      this.changeEditMode(true);
    }
    this.getEndpoints();
    this.getReportDatasets();
    this.getReportLevels();
  }

  getReportType() {
    this.error = null;
    if (this.id > 0) {
      this.reportManagementService.getFullReportType(this.id).subscribe(reportType => {
        this.reportType = reportType;
        this.getDatasetProperties();
      }, error => this.error = error.error);
    } else {
      this.getDatasetProperties();
    }
  }

  getDatasetProperties() {
    if (this.reportType != null) {
      if (this.reportType.reportEndpoints !== undefined && this.reportType.reportEndpoints !== null && this.reportType.reportEndpoints.length > 0) {
        this.reportManagementService.getReportMetaData(this.reportType.reportEndpoints).subscribe(data => {
          this.datasetProperties = this.reportManagementService.getReportMetaDataProperties(data);
        }, error => console.log(error));
      } else if (this.reportType.datasetId !== undefined && this.reportType.datasetId !== null) {
        this.setReportDataset(this.reportType.datasetId, false);
      }
    }
  }

  getEndpoints() {
    this.reportManagementService.getEndpoints().subscribe(endpoints => this.endpoints = endpoints);
  }

  getReportDatasets() {
    this.reportManagementService.getReportDatasets().subscribe(reportDatasets => {
      this.datasets = reportDatasets;
    });
  }

  getReportLevels() {
    this.reportManagementService.getReportLevels().subscribe(reportLevels => {
      this.reportLevels = reportLevels;
    });
  }

  setEndpoint(event, index, reportEndpoint: ReportEndpoint = null) {
    if (this.reportType !== undefined) {
      this.error = null;
      this.reportType.reportSections = [];
      this.requiredParams = [];
      this.optionalParams = [];
    }
    if (this.reportType.reportEndpoints === undefined || this.reportType.reportEndpoints === null) {
      this.reportType.reportEndpoints = [];
    } else if (this.reportType.reportEndpoints.length > index) {
      for (let i = 0; i < this.reportType.reportEndpoints.length - index; i++) {
        this.reportType.reportEndpoints.pop();
      }
    }
    if (reportEndpoint !== null && (reportEndpoint.referencedEndpoints === undefined || reportEndpoint.referencedEndpoints === null)) {
      reportEndpoint.referencedEndpoints = [];
    }

    const endpoint = (reportEndpoint === null) ? this.endpoints[event.target.selectedIndex - 1] : reportEndpoint.availableEndpoints[event.target.selectedIndex - 1];
    const newReportEndpoint: ReportEndpoint = { id: -1, name: endpoint.name, endpoint: endpoint.endpoint, order: index, referencedEndpoints: null, availableEndpoints: null };

    if (reportEndpoint === null) {
      this.reportType.reportEndpoints.push(newReportEndpoint);
    } else {
      reportEndpoint.referencedEndpoints.push(newReportEndpoint);
      reportEndpoint.availableEndpoints.splice(event.target.selectedIndex - 1, 1);
    }

    this.getReferences(endpoint.endpoint, newReportEndpoint);
    this.getMetaData();
  }

  deleteEndpoint(reportEndpoint: ReportEndpoint, parentEndpoint: ReportEndpoint) {
    if (parentEndpoint === null) {
      const index = this.reportType.reportEndpoints.indexOf(reportEndpoint);
      this.reportType.reportEndpoints.splice(index, 1);
    } else {
      const index = parentEndpoint.referencedEndpoints.indexOf(reportEndpoint);
      parentEndpoint.referencedEndpoints.splice(index, 1);
      parentEndpoint.availableEndpoints.push({ name: reportEndpoint.name, endpoint: reportEndpoint.endpoint });
    }
    this.reportType.reportSections = [];
    this.getMetaData();
  }

  getReferences(endpointName: string, reportEndpoint: ReportEndpoint) {
    this.reportManagementService.getEndpointMetaData(endpointName).subscribe(metaDataLeft => {
      let references = [];
      metaDataLeft.filter(e => e.referenceType !== null && e.referenceEndpoints !== null).forEach(ml => {
        ml.referenceEndpoints.forEach(re => {
          this.reportManagementService.getEndpointMetaData(re).subscribe(metaDataRight => {
            const rightReference = metaDataRight.filter(e => e.referenceType !== null && e.referenceType !== 'hasMany' && e.referenceEndpoints !== null && e.referenceEndpoints.includes(endpointName));
            if (rightReference !== null && rightReference.length > 0 && !references.includes(re)) {
              references.push(re);
              const filteredEndpoints = this.endpoints.filter(e => references.includes(e.endpoint));

              if (filteredEndpoints.length > 0) {
                reportEndpoint.availableEndpoints = filteredEndpoints;
              }
            }
          });
        });
      });
    });
  }

  getMetaData() {
    const reportEndpoints = JSON.parse(JSON.stringify(this.reportType.reportEndpoints));
    this.reportManagementService.getReportMetaData(this.removeAvailableEndpoints(reportEndpoints)).subscribe(data => {
      this.datasetProperties = (data !== null) ? this.reportManagementService.getReportMetaDataProperties(data) : [];
    });
  }

  setReportDataset(reportDatasetId: number, resetSections: boolean = true) {
    if (resetSections && this.reportType !== undefined) {
      this.error = null;
      this.reportType.reportSections = [];
      this.requiredParams = [];
      this.optionalParams = [];
    }

    if (reportDatasetId !== null && reportDatasetId > 0) {
      this.reportManagementService.getReportDataset(reportDatasetId).subscribe(reportDataset => {
        this.dataset = reportDataset;
        this.reportType.reportLevel = reportDataset.reportLevel;

        const parameters = reportDataset.parameters;

        if (parameters !== undefined && parameters !== null && parameters.length > 0) {
          this.requiredParams = parameters.filter(p => p.required);
          this.optionalParams = parameters.filter(p => !p.required);

          if (this.reportType.reportParameters !== null && this.reportType.reportParameters.length > 0 && this.optionalParams !== null && this.optionalParams.length > 0) {
            const ids: number[] = [];
            for (let i = 0; i < this.optionalParams.length; i++) {
              for (let j = 0; j < this.reportType.reportParameters.length; j++) {
                if (this.optionalParams[i].id === this.reportType.reportParameters[j].id) {
                  this.optionalParams[i].enabled = true;
                  break;
                }
              }
            }
          }
        }

        this.reportManagementService.getReportDatasetProperties(reportDatasetId).subscribe(reportDatasetProperties => {
          this.datasetProperties = reportDatasetProperties;
        }, error => this.error = error.error);
      }, error => this.error = error.error);
    } else {
      this.dataset = null;
    }
  }

  showDataEditorTab() {
    this.dataEditorTabActive = true;
    this.layoutEditorTabActive = false;
    this.filterTabActive = false;
  }

  showLayoutEditorTab() {
    this.dataEditorTabActive = false;
    this.layoutEditorTabActive = true;
    this.filterTabActive = false;
  }

  showFilterTab() {
    this.dataEditorTabActive = false;
    this.layoutEditorTabActive = false;
    this.filterTabActive = true;
  }

  submitReportType(form: NgForm) {
    if (this.reportType !== undefined && this.submitButton !== undefined && this.submitButton.submit()) {
      if (form.valid) {
        this.error = null;
        if (this.optionalParams !== null) {
          const enabledParams = this.optionalParams.filter(p => p.enabled);
          const reportParams: ReportParameter[] = [];

          if (enabledParams !== null && enabledParams.length > 0) {
            for (let i = 0; i < enabledParams.length; i++) {
              reportParams.push({ id: enabledParams[i].id, description: null, type: null, selectionItems: [], values: null, source: null });
            }
          }

          this.reportType.reportParameters = reportParams;
        }

        if (this.reportType.id < 1) { // POST
          if (this.userDefined) {
            this.reportManagementService.postUserDefinedReport(this.reportType).subscribe(response => {
              this.submitButton.ready();
              this.changeEditMode(false);
              this.getReportType();
            }, error => {
              this.error = error.error;
              this.submitButton.ready();
            });
          } else {
            this.reportManagementService.postReportType(this.reportType).subscribe(response => {
              this.submitButton.ready();
              this.changeEditMode(false);
              this.getReportType();
            }, error => {
              this.error = error.error;
              this.submitButton.ready();
            });
          }
        } else { // PUT
          if (this.userDefined) {
            this.reportManagementService.putUserDefinedReport(this.reportType).subscribe(response => {
              this.submitButton.ready();
              this.changeEditMode(false);
              this.getReportType();
            }, error => {
              this.error = error.error;
              this.submitButton.ready();
            });
          } else {
            this.reportManagementService.putReportType(this.reportType).subscribe(response => {
              this.submitButton.ready();
              this.changeEditMode(false);
              this.getReportType();
            }, error => {
              this.error = error.error;
              this.submitButton.ready();
            });
          }
        }
      } else {
        for (const i of Object.keys(form.controls)) {
          form.controls[i].markAsTouched();
        }
        this.submitButton.ready();
      }
    }
  }

  editReportType() {
    this.getReportType();
    this.changeEditMode(true);
  }

  cancel() {
    this.changeEditMode(false);
  }

  close() {
    if (this.reportType !== undefined && this.reportType.id > 0) {
      this.getReportType();
    }
    this.changeEditMode(false);
  }

  changeEditMode(editMode: boolean) {
    this.editMode = editMode;
    this.editModeChanged.emit(editMode);
  }

  removeAvailableEndpoints(endpoints: ReportEndpoint[]): ReportEndpoint[] {
    if (endpoints !== null && endpoints.length > 0) {
      endpoints.forEach(endpoint => {
        endpoint.availableEndpoints = null;
        endpoint.referencedEndpoints = this.removeAvailableEndpoints(endpoint.referencedEndpoints);
      });
    }
    return endpoints;
  }
}
