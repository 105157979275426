import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { CustomLayoutSection } from './custom-layout-section';
import { ActiveFilter } from '../../shared/data/active-filter';
import { DataService } from '../../shared/data/data.service';

@Component({
  selector: 'custom-layout-chart',
  templateUrl: './custom-layout-chart.component.html'
})
export class CustomLayoutChartComponent implements OnInit {
  constructor(
    private dataService: DataService
  ) { }

  @Input() section: CustomLayoutSection;
  @Input() editMode = false;
  @Output() sectionChanged = new EventEmitter<void>();
  public chartFilters: ActiveFilter[];
  public instrumentCharts: any[];

  ngOnInit() {
    this.initChartSection();
  }

  updateSection() {
    this.initChartSection();
    this.sectionChanged.emit();
  }

  initChartSection() {
    if (this.section.informationLevelId && this.section.chartType) {
      if (this.section.chartType === 'ControlChart.ControlChart' || this.section.chartType === 'HistoricalChart.HistoricalChart' || this.section.chartType === 'GaussianChart.GaussianChart') {
        const filter = new ActiveFilter();
        filter.type = 'id_long';
        filter.filterOption = 'Instrument.Id';
        filter.filterText = this.section.informationLevelId.toString();
        this.chartFilters = [filter];
      } else {
        this.dataService.getItems('instrumentchart/getbyinstrument/' + this.section.informationLevelId, '').subscribe(charts => this.instrumentCharts = charts);
      }
    }
  }
}
