import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { MaterialTypeService } from '../material-type.service';
import { ActiveFilter } from '../../../../../shared/data/active-filter';
import { Generic } from '../../../../../shared/generic';

@Component({
  selector: 'material-type-filter',
  templateUrl: './material-type-filter.component.html',
  styleUrls: ['../../../../../shared/data/data-filter.component.css']
})
export class MaterialTypeFilterComponent implements OnInit, AfterContentChecked {
  constructor(
    private materialTypeService: MaterialTypeService
  ) { }
  @Input() filter: ActiveFilter;
  @Output() filterChanged = new EventEmitter<boolean>();
  public materialTypes: Generic[];
  @ViewChild('materialTypeElement') materialTypeElementRef: ElementRef;

  ngOnInit() {
    this.materialTypeService.getAllMaterialTypes().subscribe(materialTypes => this.materialTypes = materialTypes);
  }

  ngAfterContentChecked() {
    if (this.filter.filterText !== undefined && this.materialTypeElementRef !== undefined) {
      this.materialTypeElementRef.nativeElement.value = this.filter.filterText;
    }
  }

  changed(ev) {
    this.filter.filterText = ev.target.value;
    this.filterChanged.emit(true);
  }
}
