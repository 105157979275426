import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'time-period',
  templateUrl: './time-period.component.html',
  styleUrls: ['./time-period.component.css']
})

export class TimePeriodComponent implements OnInit, OnChanges {
  constructor() { }

  @Input() timePeriod: number;
  @Input() editMode: boolean;
  @Output() timePeriodChange = new EventEmitter<number>();
  public minutes = 0;
  public hours = 0;

  ngOnInit() {
    this.setHoursAndMinutesFromExternal();
  }

  ngOnChanges() {
    this.setHoursAndMinutesFromExternal();
  }

  setHoursAndMinutesFromExternal() {
    this.hours = Math.floor(this.timePeriod / 60);
    this.minutes = Math.round(this.timePeriod % 60);
  }

  setTimePeriodFromInternalMinutes() { // TODO: fix functionality when minutes >= 60, input does not always seem to update properly.
    if (this.minutes >= 60) {
      this.hours = this.hours + Math.floor(this.minutes / 60);
    }
    this.minutes = Math.round(this.minutes % 60);
    this.timePeriodChanged();
  }

  setTimePeriodFromInternalHours() { // TODO: fix functionality for non-whole hours, input does not seem to update properly.
    if (this.hours % 1 > 0) {
      this.minutes = Math.round((this.hours % 1) * 60);
    }
    this.hours = Math.floor(this.hours);
    this.timePeriodChanged();
  }

  timePeriodChanged() {
    this.timePeriod = this.hours * 60 + this.minutes;
    this.timePeriodChange.emit(this.timePeriod);
  }
}
