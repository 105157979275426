import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { AppConfig } from './app.config';
import { LanguageSelectionComponent } from './i18n/language-selection.component';
import { ClaimService } from './claim.service';
import { AuthGuard } from './auth-guard';
import { RouterExtService } from './router-ext.service';
import { Error401Component } from './errors/error401/error401.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BreadcrumbService } from './breadcrumb/breadcrumb.service';
import { HelpComponent } from './help/help.component';
import { HelpService } from './help/help.service';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarService } from './sidebar/sidebar.service';
import { SearchComponent } from './search/search.component';
import { SearchService } from './search/search.service';
import { UserService } from './user/user.service';
import { ImageService } from './image.service';
import { GlobalsService } from './globals.service';
import { AmlModule } from './aml/aml.module';
import { AboutModule } from './about/about.module';
import { ReportModule } from './report/report.module';

import { httpInterceptorProviders } from './http-interceptors/index';

@NgModule({
  declarations: [
    LanguageSelectionComponent,
    Error401Component,
    BreadcrumbComponent,
    HelpComponent,
    SidebarComponent,
    SearchComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    FormsModule,
    HttpClientModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    AmlModule,
    AboutModule,
    ReportModule
  ],
  exports: [
    AmlModule,
    AboutModule,
    LanguageSelectionComponent,
    Error401Component,
    BreadcrumbComponent,
    HelpComponent,
    SidebarComponent,
    SearchComponent,
    ReportModule
  ],
  providers: [
    DatePipe,
    AppConfig,
    { provide: APP_INITIALIZER, useFactory: (config: AppConfig) => () => config.load(), deps: [AppConfig], multi: true },
    ClaimService,
    AuthGuard,
    RouterExtService,
    BreadcrumbService,
    HelpService,
    SidebarService,
    SearchService,
    UserService,
    ImageService,
    GlobalsService,
    httpInterceptorProviders
  ]
})

export class CoreModule { }
