import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
//import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Observable, Subject, Subscription } from 'rxjs';

import { GridColumn } from './grid-column';
import { GridAction } from './grid-action';
import { Header } from './header';
import { CustomGridColumn } from './custom-grid-column';
import { DataService } from '../data/data.service';
import { GridDataArguments } from './grid-data-arguments';

@Component({
  selector: 'grid-table',
  templateUrl: './grid-table.component.html',
  styleUrls: ['./grid-table.component.css']
})

export class GridTableComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private el: ElementRef,
    private sanitizer: DomSanitizer,
    private dataService: DataService
  ) { }

  @Input() name: string;
  @Input() headers: Header[];
  @Input() endpoint: string;
  @Input() rowActions: GridAction[] = [];
  @Input() customColumns: CustomGridColumn[];
  @Input() data: any[];
  @Input() genericDataRetrieval = true;
  @Input() metaDataChanged: Observable<void>;
  @Input() showSelectionColumn = false;
  @Input() showActionColumn = true;
  @Input() showAutoRefresh = true;
  @Input() showFooter = true;
  @Input() showSortArrows = true;
  @Input() translateHeader = true;
  @Input() exportData: any;
  @Input() autoRefreshTime = 5000;
  @Input() visibleColumns: GridColumn[] = [];
  @Input() dataArguments: GridDataArguments = new GridDataArguments();
  @Input() selectedIds: number[] = [];
  @Input() editMode = false;
  @Output() refreshEvent = new EventEmitter();
  @Output() dataChangedEvent = new EventEmitter<any[]>();
  public selectionChanged: Subject<void> = new Subject<void>();
  public showLeftScrollIndicator = false;
  public showRightScrollIndicator = false;
  private horizontalPadding = 17;
  private autoRefreshInternal = false;
  private autoRefreshInterval;
  private metaDataChangedSubscription: Subscription;
  private gridWidth: number;
  private mouseX = 0;

  /* Use get and set to react to the fact that initial count will be 0.
     Only when the count request gets a response will this component receive the actual count.
     Performing calculatePage on the set allows the grid to display the proper pageCount. */
  private internalCount: number;

  get count(): number {
    return this.internalCount;
  }

  @Input()
  set count(num: number) {
    this.internalCount = num;
    this.calculatePage();
  }

  get autoRefresh(): boolean {
    return this.autoRefreshInternal;
  }

  @Input()
  set autoRefresh(refresh: boolean) { // Start the interval when setting to true, end it when setting to false.
    this.autoRefreshInternal = refresh;
    if (refresh) {
      this.autoRefreshInterval = setInterval(() => {
        this.refresh();
      }, this.autoRefreshTime);
    } else {
      clearInterval(this.autoRefreshInterval);
    }
  }

  ngOnInit() {
    if (this.metaDataChanged !== undefined) {
      this.metaDataChangedSubscription = this.metaDataChanged.subscribe(() => this.onMetaDataChanged());
    }

    if (this.genericDataRetrieval) {
      this.horizontalPadding = 6;
    }
  }

  ngOnDestroy() {
    if (this.metaDataChangedSubscription !== undefined) {
      this.metaDataChangedSubscription.unsubscribe();
    }
    clearInterval(this.autoRefreshInterval);
  }

  async onMetaDataChanged() {
    await this.wait(100);
    this.setColumnsWidth();
  }

  onSelectionChanged() {
    this.selectionChanged.next();
  }

  refresh() {
    this.refreshEvent.emit();
  }

  wait(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  getColumnTitle(column: GridColumn, rowData: any): string {
    if (column.content !== undefined && column.content !== null) {
      return this.dataService.transformToType(column.title(rowData), column.type, column.translate);
    } else {
      return this.dataService.getPropertyValue(rowData, column.property, column.type, column.translate);
    }
  }

  getColumnClass(column: GridColumn, rowData: any, index: number): string {
    let classes = column.valueAsCssClass ? String(this.getColumnContent(column, rowData, false)) : '';
    if (this.visibleColumns[this.visibleColumns.length - 1].header === 'Action' && index === this.visibleColumns.length - 2) {
      classes = (classes.length > 0) ? classes + ' last-column' : 'last-column';
    }
    return classes;
  }

  getColumnContent(column: GridColumn, rowData: any, translate = null): string | SafeHtml {
    if (column.content !== undefined && column.content !== null) {
      if (column.type === 'trustedHtml') {
        return this.sanitizer.bypassSecurityTrustHtml(column.content(rowData));
      } else {
        return this.dataService.transformToType(column.content(rowData), column.type, translate ?? column.translate);
      }
    } else {
      return this.dataService.getPropertyValue(rowData, column.property, column.type, translate ?? column.translate);
    }
  }

  getRouterLink(column: GridColumn, rowData: any): SafeUrl {
    return column.content(rowData);
  }

  ngAfterViewInit() {
    const grid = this.el.nativeElement.getElementsByClassName('grid');
    this.gridWidth = grid[0].parentElement.parentElement.parentElement.parentElement ?
      grid[0].parentElement.parentElement.parentElement.parentElement.clientWidth :
      grid[0].parentElement.parentElement.parentElement.clientWidth;

    if ((this.headers !== undefined && this.headers !== null && this.headers.length > 0) ||
      (this.visibleColumns !== undefined && this.visibleColumns !== null && this.visibleColumns.length > 0)) {
      const gridHeaders = this.el.nativeElement.getElementsByClassName('grid-table-header');

      if (this.headers !== undefined && this.headers !== null && this.headers.length > 0
        && gridHeaders !== undefined && gridHeaders !== null && gridHeaders.length > 0 && gridHeaders.length === this.headers.length) {
        for (let i = 0; i < this.headers.length; i++) {
          if (gridHeaders[i].style !== undefined) {
            const width = this.getHeaderWidth(i);
            gridHeaders[i].style.width = width;
          }
        }
      }
      else if (this.visibleColumns !== undefined && this.visibleColumns !== null && this.visibleColumns.length > 0
        && gridHeaders !== undefined && gridHeaders !== null && gridHeaders.length > 0 && gridHeaders.length === this.visibleColumns.length) {
        for (let i = 0; i < this.visibleColumns.length; i++) {
          if (gridHeaders[i].style !== undefined) {
            const width = this.getHeaderWidth(i);
            gridHeaders[i].style.width = width;
          }
        }
      }
    }
  }

  rowActionClick(event: any, action: GridAction, rowData: any) {
    event.preventDefault();
    action.callback(rowData);
  }

  onScroll() {
    const grid = this.el.nativeElement.getElementsByClassName('table-wrapper');
    this.showLeftScrollIndicator = (grid[0].scrollLeft > 5);
    this.showRightScrollIndicator = ((grid[0].scrollLeft + grid[0].clientWidth + 5) < grid[0].scrollWidth);
  }

  scrollToRight() {
    const grid = this.el.nativeElement.getElementsByClassName('table-wrapper');
    grid[0].scroll(grid[0].scrollWidth, 0);
  }

  scrollToLeft() {
    const grid = this.el.nativeElement.getElementsByClassName('table-wrapper');
    grid[0].scroll(0, 0);
  }

  async onResize(event) {
    this.ngAfterViewInit();

    const gridHeaders = this.el.nativeElement.getElementsByClassName('grid-table-header');
    if (gridHeaders !== null && gridHeaders.length > 0) {
      for (let i = 0; i < (gridHeaders.length - 1); i++) {
        gridHeaders[i].style.width = 'auto';
      }
      await this.wait(100);
    }

    this.setColumnsWidth();
  }

  async setColumnsWidth() {
    if (this.visibleColumns !== null && this.visibleColumns.length > 0 && this.visibleColumns[this.visibleColumns.length - 1].header === 'Action'
      && this.rowActions !== null && this.rowActions.length > 0) {
      const grid = this.el.nativeElement.getElementsByClassName('table-wrapper');
      let largestWidth = 100; //Action column must be minimum 100px width.
      if (this.data !== null && this.data.length > 0) {
        for (let i = 0; i < this.data.length; i++) {
          const actions = this.rowActions.filter(e => e.show && !e.disabled(this.data[i]) && e.condition(this.data[i])).map(e => this.dataService.transformToType(e.name, 'string', true));
          const width = (actions.length > 0) ? actions.map(a => a.length * 7 + 16).reduce((a, b) => a + b, 0) + 16 : 50; //Calculate the width (7px per letter and 16px padding per button).
          largestWidth = (width > largestWidth) ? width : largestWidth;
        }
      }
      largestWidth = (largestWidth < (grid[0].clientWidth / 2)) ? largestWidth : grid[0].clientWidth / 2; //Action column must be maximum 50% width.

      const gridActionColumns = this.el.nativeElement.getElementsByClassName('grid-action-column');
      const lastColumns = this.el.nativeElement.getElementsByClassName('last-column');
      if (gridActionColumns !== null && gridActionColumns.length > 0) {
        for (let i = 0; i < gridActionColumns.length; i++) {
          gridActionColumns[i].style.width = largestWidth + 'px';
          if (i < lastColumns.length) {
            lastColumns[i].style.paddingRight = (largestWidth + 24) + 'px'; //Last content column will be behind the action column if this padding is not added.
          }
        }
        await this.wait(100);
      }

      const gridHeaders = this.el.nativeElement.getElementsByClassName('grid-table-header');
      const gridResize = this.el.nativeElement.getElementsByClassName('resize');
      if (gridHeaders !== null && gridHeaders.length > 0) {
        const width = (grid[0].clientWidth - (largestWidth + 24)) / (gridHeaders.length - 1);
        for (let i = 0; i < (gridHeaders.length - 1); i++) {
          gridHeaders[i].style.width = (width < 120) ? '120px' : (width - 16) + 'px'; //Each column must be minimum 120px width.

          if (i === gridHeaders.length - 2) {
            gridHeaders[i].style.paddingRight = (largestWidth + 24) + 'px'; //Last content column will be behind the action column if this padding is not added.
            gridResize[i].style.right = (largestWidth + 12) + 'px';
            gridResize[i].style.zIndex = '9';
          }
        }
      }
      this.onScroll();
    }
  }

  getHeaderWidth(index: number): string {
    let width: string;
    const gridWidth = (this.gridWidth !== undefined && this.gridWidth !== null && this.gridWidth > 0) ? this.gridWidth - 68 : 1200;

    if (this.headers !== undefined && this.headers !== null && this.headers[index].width !== undefined
      && this.headers[index].width !== null && this.headers[index].width !== 'auto') {
      if (this.headers[index].width.endsWith('%')) {
        const widthPercentage = this.headers[index].width.substr(0, this.headers[index].width.length - 1);
        let calcWidth: number = gridWidth / 100 * +widthPercentage;
        calcWidth -= this.horizontalPadding;
        width = calcWidth.toFixed(0) + 'px';
      } else if (this.headers[index].width.endsWith('px')) {
        const pixels = this.headers[index].width.substr(0, this.headers[index].width.length - 2);
        const calcWidth: number = +pixels - this.horizontalPadding;
        width = calcWidth.toFixed(0) + 'px';
      } else {
        width = 'calc(' + this.headers[index].width + ' - ' + this.horizontalPadding + ')';
      }
    } else {
      let headersLength = (this.headers !== undefined && this.headers !== null) ? this.headers.length : this.visibleColumns.length;
      let calcWidth: number = gridWidth / headersLength;
      calcWidth -= this.horizontalPadding;
      width = calcWidth.toFixed(0) + 'px';
    }
    return width;
  }

  columnResizeOnClick(event: MouseEvent) {
    this.mouseX = event.clientX;
  }

  doColumnResize(event: MouseEvent, index: number) {
    if (event.clientX > 0) {
      const gridHeaders = this.el.nativeElement.getElementsByClassName('grid-table-header');
      const pixelsMoved = event.clientX - this.mouseX;
      let width = this.getHeaderWidth(index);

      if (width !== null) {
        if (width.endsWith('px')) {
          const widthPixels = width.substr(0, width.length - 2);
          let calcWidth: number = +widthPixels + pixelsMoved;
          calcWidth -= this.horizontalPadding;

          if (calcWidth > 15) {
            width = calcWidth + 'px';
          } else {
            width = '15px';
          }
        } else {
          width = 'calc((' + width + ' - ' + this.horizontalPadding + ') + ' + pixelsMoved + 'px)';
        }

        if (gridHeaders[index] !== undefined) {
          gridHeaders[index].style.width = width;
          this.onScroll();
        }
      }
    }
  }

  calculatePage() {
    this.dataArguments.pageCount = Math.ceil(this.count / this.dataArguments.pageSize);
  }

  sort(column: GridColumn) {
    if (!column.sort) {
      return; // Do not sort on columns that do not support it.
    }

    if (column.header === this.dataArguments.sortColumn) {
      if (this.dataArguments.sortOrder === 'asc') {
        this.dataArguments.sortOrder = 'desc';
      } else {
        this.dataArguments.sortOrder = 'asc';
      }
    } else {
      this.dataArguments.sortColumn = column.header;
      this.dataArguments.sortOrder = 'asc';
    }

    this.refresh();
  }

  toggleSelection(rowData: any) {
    if (rowData !== null && rowData.id !== undefined && rowData.id !== null && rowData.id > 0) {
      const index = this.selectedIds.indexOf(rowData.id);
      if (index > -1) {
        this.selectedIds.splice(index, 1);
      } else {
        this.selectedIds.push(rowData.id);
      }
    }
  }

  updatePage(page: string) {
    switch (page) {
      case 'first':
        this.dataArguments.pageIndex = 1;
        break;
      case 'previous':
        if (this.dataArguments.pageIndex > 1) {
          this.dataArguments.pageIndex -= 1;
        }
        break;
      case 'next':
        if (this.dataArguments.pageIndex < this.dataArguments.pageCount) {
          this.dataArguments.pageIndex += 1;
        }
        break;
      case 'last':
        this.dataArguments.pageIndex = this.dataArguments.pageCount;
        break;
      default:
        break;
    }
    this.refresh();
  }

  updatePageIndex(event: any) {
    const val = +(event.target.value);
    this.dataArguments.pageIndex = val > 1 ? val : 1;
    this.refresh();
  }

  updatePageSize(event: any) {
    const val = +(event.target.value);
    this.dataArguments.pageSize = val > 1 ? val : 1;
    this.calculatePage();
    this.refresh();
  }

  getXlsxWorkBook(): XLSX.WorkBook {
    const worksheet: XLSX.WorkSheet = (this.data !== undefined) ? XLSX.utils.json_to_sheet(this.data) : XLSX.utils.json_to_sheet(this.exportData);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    return workbook;
  }

  exportToExcel() {
    const name = (this.name !== undefined && this.name !== null) ? this.name : this.endpoint;
    const file: any = XLSX.writeFile(this.getXlsxWorkBook(), name + '_export_' + new Date().getTime() + '.xlsx', { bookType: 'xlsx', type: 'file' });
  }

  exportToCsv() {
    const name = (this.name !== undefined && this.name !== null) ? this.name : this.endpoint;
    const file: any = XLSX.writeFile(this.getXlsxWorkBook(), name + '_export_' + new Date().getTime() + '.csv', { bookType: 'csv', type: 'file' });
  }

  exportToRtf() {
    const name = (this.name !== undefined && this.name !== null) ? this.name : this.endpoint;
    const file: any = XLSX.writeFile(this.getXlsxWorkBook(), name + '_export_' + new Date().getTime() + '.rtf', { bookType: 'rtf', type: 'file' });
  }

  exportToHtml() {
    const name = (this.name !== undefined && this.name !== null) ? this.name : this.endpoint;
    const file: any = XLSX.writeFile(this.getXlsxWorkBook(), name + '_export_' + new Date().getTime() + '.html', { bookType: 'html', type: 'file' });
  }
}
