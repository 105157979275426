import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialStatusFilterComponent } from './material-status-filter.component';

@NgModule({
  declarations: [MaterialStatusFilterComponent],
  exports: [MaterialStatusFilterComponent],
  imports: [CommonModule, TranslateModule]
})
export class MaterialStatusFilterModule { }
