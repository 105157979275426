import { Component } from '@angular/core';

import { AppConfig } from '../app.config';

@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent {
  constructor(private appConfig: AppConfig) {
    this.email = this.appConfig.getConfig('email');
    this.website = this.appConfig.getConfig('website');
    this.additionalText = this.appConfig.getConfig('additionalText');
    this.version = this.appConfig.getConfig('version');
    this.releaseDate = this.appConfig.getConfig('releaseDate');
  }

  public title = 'AML';
  public email: string;
  public website: string;
  public additionalText: string;
  public version: string;
  public releaseDate: string;
}
