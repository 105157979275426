import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { DataService } from '../data/data.service';
import { ActiveFilter } from '../data/active-filter';
import { GridService } from '../grid/grid.service';
import { SelectionItem } from '../selection-item';
import { ListObjectMetaData } from '../grid/list-object-meta-data';

@Component({
  selector: 'list-input',
  templateUrl: './list-input.component.html',
  styleUrls: ['./list-input.component.css']
})

export class ListInputComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private dataService: DataService,
    private gridService: GridService
  ) { }

  @Input() model: NgModel;
  @Input() name: string; // Must be the same as the specified header in GridService.
  @Input() endpoint: string;
  @Input() item: any;
  @Input() selectedItem: SelectionItem;
  @Input() selectedId: any;
  @Input() items: any[];
  @Input() required = false;
  @Input() dataChanged: Observable<void>;
  @Input() activeFilters: ActiveFilter[];
  @Input() filterName = 'Name';
  @Output() selectionChanged = new EventEmitter<any>();
  public listObjectMetaData: ListObjectMetaData;
  public invalid: boolean;
  public i: number;
  private dataChangedSubscription: Subscription;
  @ViewChild('inputElement') inputElementRef: ElementRef;
  @ViewChild('datalistElement') datalistElementRef: ElementRef;

  ngOnInit() {
    if (this.dataChanged !== undefined) {
      this.dataChangedSubscription = this.dataChanged.subscribe(() => this.getData());
    }
    if (this.endpoint) {
      this.listObjectMetaData = this.gridService.getListObjectByEndpoint(this.endpoint);
      if (this.listObjectMetaData !== null) {
        this.filterName = this.listObjectMetaData.filter;
      }
    } else if (!this.endpoint && this.name) {
      this.listObjectMetaData = this.gridService.getListObject(this.name);
      this.endpoint = this.listObjectMetaData.endpoint;
      this.filterName = this.listObjectMetaData.filter;
    }
    this.i = Math.floor(Math.random() * 999999);
  }

  ngOnDestroy() {
    if (this.dataChangedSubscription !== undefined) {
      this.dataChangedSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    const id = (Math.random() + 1).toString(36).substring(7);
    this.datalistElementRef.nativeElement.setAttribute('id', id);
    this.inputElementRef.nativeElement.setAttribute('list', id);

    setTimeout(() => { // Timeout prevents "NG0100: Expression has changed after it was checked", by updating DOM in the next cycle.
      if (this.selectedItem && this.selectedItem.id !== undefined && this.selectedItem.name !== undefined) {
        this.model.control.setValue(this.selectedItem.id);
        this.inputElementRef.nativeElement.value = this.selectedItem.name;
      }

      this.getData(true);
    }, 0);
  }

  getData(setSelectItem = false) {
    if (this.endpoint) {
      let args = ('?index=1&size=50&sort=;asc');
      if (this.activeFilters && this.activeFilters.length > 0) {
        args += '&filters=' + JSON.stringify(this.activeFilters);
      }
      this.dataService.getItems(this.endpoint, args).subscribe(data => {
        this.setItems(data, setSelectItem);
      });
    }
  }

  setItems(data, setSelectItem: boolean = false) {
    if (this.listObjectMetaData) {
      this.items = data.map(item => {
        if (this.listObjectMetaData.idProperty.property) {
          const name = this.listObjectMetaData.viewProperties.map(property => item[property.property]).join(', ');
          return new SelectionItem(item[this.listObjectMetaData.idProperty.property], name, null);
        } else {
          return new SelectionItem(item, item, null);
        }
      });
      if (setSelectItem && (this.selectedId || (this.item && this.item[this.listObjectMetaData.property]))) {
        const id = (this.selectedId) ? this.selectedId : this.item[this.listObjectMetaData.property];
        const selectedItem = this.items.filter(item => item.id === id)[0];
        this.model.control.setValue(selectedItem.id);
        this.inputElementRef.nativeElement.value = selectedItem.name;
      }
    } else {
      this.items = data;
    }

    if (this.required && this.inputElementRef.nativeElement.value.length === 0) {
      this.invalid = true;
      this.model.control.setErrors({ required: true });
    }
  }

  change(event) {
    if (event.target.value !== null && event.target.value.length > 0) {
      if (this.endpoint) {
        const args = '?index=1&size=50&sort=;asc&filters=[{%22id%22:0,%22filterText%22:%22' + event.target.value.split(', ')[0] + '%22,%22filterOption%22:%22' + this.filterName + '%22,%22filterDetails%22:{},%22type%22:%22string%22}]';
        this.dataService.getItems(this.endpoint, args).subscribe(data => {
          this.setItems(data);
          this.checkValue(event, data);
        });
      } else {
        this.checkValue(event);
      }
    } else {
      this.invalid = false;
      this.model.control.setValue(null);
      this.model.control.setErrors(null);
      this.selectionChanged.emit(this.model.control.value);
      this.getData();
    }
  }

  checkValue(event, data = null) {
    if (this.items && this.items.length > 0) {
      const results = this.items.filter(item => item.name === event.target.value);
      if (results.length > 0) {
        this.invalid = false;
        this.model.control.setValue(results[0].id);
        this.model.control.setErrors(null);

        if (this.item && !this.item.measuringInstrumentId && data && data[0] && data[0].measuringInstrumentId) {
          this.item.measuringInstrumentId = data[0].measuringInstrumentId;
        }

        this.selectionChanged.emit(this.model.control.value);
      } else {
        this.invalid = true;
        this.model.control.setErrors({ invalidListInputValue: true });
      }
    } else {
      this.invalid = true;
      this.model.control.setErrors({ invalidListInputValue: true });
    }
  }

  clearInput() {
    this.invalid = false;
    this.model.control.setValue(null);
    this.model.control.setErrors(null);
    this.getData();

    if (this.inputElementRef !== undefined) {
      this.inputElementRef.nativeElement.value = null;
    }

    this.selectionChanged.emit(this.model.control.value);
  }
}
