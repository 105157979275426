<chart *ngIf="fidelitySession && chartData" [view]="view"
       [title]="'Fidelity.Chart' | translate"
       [allowTypeChange]="false"
       [chartType]="'linechart'"
       [dataSeries]="chartData"
       [xAxis]="'Log(10) ' + ('MolWeight' | translate)"
       [yAxis]="'Log(10) ' + ('ResponseFactor' | translate)">
  <ng-template #tooltipTemplate let-model="model">
    <table *ngIf="model">
      <tr><td colspan="2">{{ model.description }} [{{ model.symbol }}] ({{ model.unit }})</td></tr>
      <tr><td>{{ 'Log(10) ' + ('MolWeight' | translate) }}</td><td>{{ model.lrfValue | number }}</td></tr>
      <tr><td>{{ 'Log(10) ' + ('ResponseFactor' | translate) }}</td><td>{{ model.lmwValue | number }}</td></tr>
    </table>
  </ng-template>
</chart>
