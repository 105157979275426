<div class="header">
  <h1>{{ title }}</h1>
</div>
<div class="overview-wrapper">
  <div class="overview-header">{{ 'aml.About' | translate }}</div>
  <div>
    <img src="/assets/img/Hint_Logo_Bright Blue_RGB.png" alt="Hint logo" id="logo" />
    <p><b>{{ 'aml.ContactDetails' | translate }}:</b></p>
    <div class="overview-wrapper">
      <table class="overview">
        <tr>
          <td>{{ 'aml.Email' | translate }}:</td>
          <td>{{ email }}</td>
        </tr>
        <tr>
          <td>{{ 'aml.Website' | translate }}:</td>
          <td>{{ website }}</td>
        </tr>
      </table>
    </div>
    <p>{{ 'aml.VisitWebsiteForMoreInfo' | translate }}</p>
    <p>{{ additionalText | translate }}</p>
    <div class="overview-wrapper">
      <table class="overview">
        <tr>
          <td>{{ 'aml.Version' | translate }}:</td>
          <td>{{ version }}</td>
        </tr>
        <tr>
          <td>{{ 'aml.ReleaseDate' | translate }}:</td>
          <td>{{ releaseDate }}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
