import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfig } from '../app.config';
import { Report } from './report';
import { ReportType } from './report-type';
import { ReportSection } from './report-section';
import { ReportDatasetData } from './report-dataset-data';
import { ReportSectionHeader } from './report-section-header';
import { ReportSectionContent } from './report-section-content';

@Injectable()
export class ReportViewPageService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;
  private calculatedHeight: number;

  public readonly landscapePageWidth: number = 1056;
  public readonly landscapePageHeight: number = 775;
  public readonly portraitPageWidth: number = 775;
  public readonly portraitPageHeight: number = 1056;
  private readonly marginTop: number = 25;
  private readonly marginBottom: number = 25;
  private readonly marginSides: number = 72;
  private readonly headerHeight: number = 85;
  private readonly footerHeight: number = 21;
  private readonly minimumRequiredAvailableHeight: number = 55;
  private readonly sectionMargin: number = 20;
  private readonly sectionTitleHeight: number = 36;
  private readonly detailsTableRowHeight: number = 26;
  private readonly overviewTableRowHeight: number = 27;
  private readonly tableExtraRowHeight: number = 20;
  private readonly textLineHeight: number = 24;
  private readonly accuracyChartHeight: number = 400;
  private readonly precisionChartHeight: number = 400;
  private readonly historicalChartHeight: number = 520;
  private readonly fidelityChartHeight: number = 450;
  private readonly linearityChartHeight: number = 450;
  private readonly defaultHeaderColumnWidth: number = 300;
  private readonly averageCharacterWidth: number = 6.5;

  getPageWidth(pageOrientation: string): number {
    if (pageOrientation === 'landscape') {
      return this.landscapePageWidth;
    } else if (pageOrientation === 'portrait') {
      return this.portraitPageWidth;
    }
  }

  getPageHeight(pageOrientation: string): number {
    if (pageOrientation === 'landscape') {
      return this.landscapePageHeight;
    } else if (pageOrientation === 'portrait') {
      return this.portraitPageHeight;
    }
  }

  getReportPages(originalSections: ReportSection[], pageOrientation: string): ReportSection[][] {
    const reservedHeight: number = this.marginTop + this.marginBottom + this.headerHeight + this.footerHeight;
    const reportPages: ReportSection[][] = [[]];
    const sections: ReportSection[] = [];
    const pageHeight = this.getPageHeight(pageOrientation);
    const pageWidth = this.getPageWidth(pageOrientation);
    let pageIndex = 0;
    this.calculatedHeight = reservedHeight;

    if (originalSections !== null && originalSections.length > 0) {
      const header: ReportSection = new ReportSection('header', 'AML');
      reportPages[pageIndex].push(header);

      //Split the content of large pages into multiple pages.
      //To determine when to split the content and add a page break, calculate how much space the content has.
      for (let i = 0; i < originalSections.length; i++) {
        const newSections = this.checkSectionHeightAndSplitSection(originalSections[i], reservedHeight, pageHeight, pageWidth);

        if (newSections.length > 0) {
          for (let j = 0; j < newSections.length; j++) {
            sections.push(newSections[j]);
          }
        }
      }

      //Place the sections on pages and add header and footer.
      for (let i = 0; i < sections.length; i++) {
        if (sections[i].type !== 'pageBreak') {
          reportPages[pageIndex].push(sections[i]);
        } else {
          const footer1: ReportSection = new ReportSection('footer', pageIndex + 1);
          reportPages[pageIndex].push(footer1);
          pageIndex += 1;
          reportPages.push([]);
          reportPages[pageIndex].push(header);
        }
      }

      const footer: ReportSection = new ReportSection('footer', pageIndex + 1);
      reportPages[pageIndex].push(footer);
    }

    return reportPages;
  }

  checkSectionHeightAndSplitSection(section: ReportSection, reservedHeight: number, pageHeight: number, pageWidth: number): ReportSection[] {
    const sections: ReportSection[] = [];

    if (section.type !== 'pageBreak') {
      //Go further on new page if space left on the page is smaller then the minimum available height.
      const minimumRequiredAvailableHeight = (section.title !== undefined && section.title !== null && section.title.length > 0) ? this.minimumRequiredAvailableHeight + this.headerHeight : this.minimumRequiredAvailableHeight;
      if ((pageHeight - this.calculatedHeight) < minimumRequiredAvailableHeight) {
        this.calculatedHeight = reservedHeight;
        const pageBreak: ReportSection = new ReportSection('pageBreak');
        sections.push(pageBreak);
      }

      if (section.type !== 'container') {
        //Section types: tables, charts, text
        const sectionHeight = this.calculateSectionHeight(section, pageWidth);

        //If the section fits on the page, add it to the page. Otherwise split the section into multiple sections and add page break between the sections.
        if ((this.calculatedHeight + sectionHeight) < pageHeight) {
          this.calculatedHeight += sectionHeight;
          sections.push(section);
        } else {
          const availableHeight = pageHeight - this.calculatedHeight - this.sectionMargin;
          const newSections = this.splitSection(section, availableHeight, pageHeight, pageWidth);

          if (newSections.length > 0) {
            for (let i = 0; i < newSections.length; i++) {
              sections.push(newSections[i]);
            }

            const height = this.calculateSectionHeight(newSections[newSections.length - 1], pageWidth);
            this.calculatedHeight = reservedHeight + height;
          }
        }
      } else {
        //Section type: container
        if (section.sectionChildren !== undefined && section.sectionChildren !== null && section.sectionChildren.length > 0) {
          const subSections = [...section.sectionChildren];
          section.sectionChildren = [];

          for (let i = 0; i < subSections.length; i++) {
            //Calculate the height of this sections children.
            const splittedSections = this.checkSectionHeightAndSplitSection(subSections[i], reservedHeight, pageHeight, pageWidth);

            if (splittedSections.length > 0) {
              for (let j = 0; j < splittedSections.length; j++) {
                sections.push(splittedSections[j]);
              }
            }
          }
        }
      }
    } else {
      //Section type: page break
      this.calculatedHeight = reservedHeight;
      sections.push(section);
    }

    return sections;
  }

  calculateSectionHeight(section: ReportSection, pageWidth: number): number {
    let calculatedHeight: number = 0;

    if (section !== null && section.type !== 'header' && section.type !== 'footer') {
      calculatedHeight = this.sectionMargin;

      if (section.title !== undefined && section.title !== null && section.title !== '') {
        calculatedHeight += this.sectionTitleHeight;
      }

      switch (section.type) {
        case 'detailsTable':
        case 'overviewTable':
        case 'overviewTableVertical':
          calculatedHeight += this.calculateTableHeight(section, pageWidth);
          break;
        case 'accuracyChart':
          calculatedHeight += this.accuracyChartHeight;
          break;
        case 'precisionChart':
          calculatedHeight += this.precisionChartHeight;
          break;
        case 'historicalChart':
          calculatedHeight += this.historicalChartHeight;
          break;
        case 'fidelityChart':
          calculatedHeight += this.fidelityChartHeight;
          break;
        case 'linearityChart':
          calculatedHeight += this.linearityChartHeight;
          break;
        case 'text':
          calculatedHeight += (this.textLineHeight * 4);
          break;
        default:
          break;
      }
    }

    return calculatedHeight;
  }

  getHeaderColumnWidth(section: ReportSection, index: number, tableWidth: number, defaultWidth: number = null): number {
    let columnWidth: number = defaultWidth;

    if (section !== undefined && section !== null && section.headers !== null && section.headers[index] !== undefined) {
      if (section.headers[index].width !== null) {
        if (section.headers[index].width !== null && section.headers[index].width.length > 2 && section.headers[index].width.slice(section.headers[index].width.length - 2) === 'px') {
          columnWidth = parseInt(section.headers[index].width.slice(0, section.headers[index].width.length - 2), null);
        } else if (section.headers[index].width !== null && section.headers[index].width.length > 1 && section.headers[index].width.slice(section.headers[index].width.length - 1) === '%') {
          const percentage = parseInt(section.headers[index].width.slice(0, section.headers[index].width.length - 1), null);
          columnWidth = Math.round(tableWidth / 100 * percentage);
        }
      } else if (section.type === 'detailsTable') {
        columnWidth = this.defaultHeaderColumnWidth;
      } else if (section.type === 'overviewTable') {
        columnWidth = Math.round((tableWidth / section.headers.length) - 9);
      } else if (section.type === 'overviewTableVertical') {
        let contentRows = (section.content !== null && section.content.length > 0 && section.content[0].length !== undefined) ? section.content.length : 0;

        if (contentRows === 0 && section.content !== null && section.content.length > 0 && section.content[0].content !== undefined) {
          contentRows = 1; //Layout editor content.
        }

        columnWidth = Math.round((tableWidth / (contentRows + 1)) - 9);
      }
    }

    return columnWidth;
  }

  getHeaderColumnLineCount(section: ReportSection, index: number, tableWidth: number): number {
    const columnWidth: number = this.getHeaderColumnWidth(section, index, tableWidth);
    const maxCharsPerLine = Math.ceil(columnWidth / this.averageCharacterWidth);
    const rowCount: number = section.headers[index].header.length / maxCharsPerLine;
    return Math.ceil(rowCount);
  }

  getContentColumnLineCount(section: ReportSection, index: number, tableWidth: number, content: ReportSectionContent): number {
    let columnWidth: number;

    if (section !== undefined && section !== null && section.headers !== null && section.headers[index] !== undefined) {
      if (section.type === 'detailsTable') {
        const headerWidth = this.getHeaderColumnWidth(section, 0, tableWidth);
        columnWidth = tableWidth - headerWidth - 25;
      } else if (section.type === 'overviewTable') {
        columnWidth = this.getHeaderColumnWidth(section, index, tableWidth);
      } else if (section.type === 'overviewTableVertical') {
        const headerWidth = this.getHeaderColumnWidth(section, 0, tableWidth);
        columnWidth = Math.round(((tableWidth - headerWidth) / section.content.length) - 9);
      }
    }

    const table = document.createElement('table');
    table.style.visibility = 'hidden';
    table.style.wordBreak = 'break-word';
    const row = table.insertRow(0);
    const cell = row.insertCell(0);
    cell.style.width = columnWidth + 'px';
    cell.style.lineHeight = '20px';
    cell.style.whiteSpace = 'pre-line';
    cell.style.padding = '3px 4px';
    cell.style.fontSize = '12px';
    cell.innerHTML = content.content;
    document.body.appendChild(table);
    let lineCount = (cell.offsetHeight > 6) ? (cell.offsetHeight - 6) / 20 : 0;
    document.body.removeChild(table);
    lineCount = Math.round(lineCount);

    const maxLines = (section.title !== undefined && section.title !== null && section.title.length > 0) ? 25 : 27;
    if (lineCount > maxLines) {
      //Content is too large to fit on the page! Make the content smaller.
      const newLength = (maxLines / lineCount) * content.content.length;
      content.content = content.content.slice(0, newLength) + '...';
      lineCount = this.getContentColumnLineCount(section, index, tableWidth, content);
    }

    return lineCount;
  }

  getColumnLineCount(section: ReportSection, index: number, tableWidth: number): number {
    let lineCount = this.getHeaderColumnLineCount(section, index, tableWidth);

    if (section.content !== null && section.content[index] !== undefined) {
      if (section.content[index].length === undefined && section.content[index].content !== undefined && section.content[index].content !== null) {
        const contentLineCount = this.getContentColumnLineCount(section, index, tableWidth, section.content[index]);

        if (contentLineCount > lineCount) {
          lineCount = contentLineCount;
        }
      } else if (section.content[index].length !== undefined && section.content[index].length > 0) {
        for (let i = 0; i < section.content[index].length; i++) {
          if (section.content[index][i].content !== undefined && section.content[index][i].content !== null) {
            const contentLineCount = this.getContentColumnLineCount(section, index, tableWidth, section.content[index][i]);

            if (contentLineCount > lineCount) {
              lineCount = contentLineCount;
            }
          }
        }
      }
    }

    return lineCount;
  }

  calculateTableHeight(section: ReportSection, pageWidth: number): number {
    let calculatedHeight: number = 0;
    const tableWidth: number = pageWidth - this.marginSides;

    if (section !== null) {
      if (section.type === 'detailsTable') {
        calculatedHeight += 2; //Table has 2px border-spacing.
      }
      if (section.type === 'detailsTable' && section.headers !== null && section.headers.length !== undefined) {
        let height = 0;
        for (let i = 0; i < section.headers.length; i++) {
          if (height < this.portraitPageHeight) {
            if (section.headers[i] !== undefined) {
              const lineCount = this.getColumnLineCount(section, i, tableWidth);
              height += this.detailsTableRowHeight;

              if (lineCount > 1) {
                height += (lineCount - 1) * this.tableExtraRowHeight;
              }
            } else {
              height += this.detailsTableRowHeight;
            }
          } else {
            break;
          }
        }
        calculatedHeight += height;
      }

      if (section.type === 'overviewTable' && section.content !== null && section.content.length !== undefined) {
        let height = this.overviewTableRowHeight;

        for (let i = 0; i < section.content.length; i++) {
          height += this.overviewTableRowHeight;
          let extraLines = 0;
          if (section.content[i].length !== undefined && section.content[i].length > 0) {
            for (let j = 0; j < section.content[i].length; j++) {
              if (section.content[i][j] !== null && section.content[i][j].content !== undefined && section.content[i][j].content !== null) {
                const lineCount = this.getContentColumnLineCount(section, j, tableWidth, section.content[i][j]);

                if (lineCount > extraLines) {
                  extraLines = lineCount - 1;
                }
              }
            }
          }
          if (extraLines > 0) {
            height += extraLines * this.tableExtraRowHeight;
          }
        }

        calculatedHeight += height;
      }

      if (section.type === 'overviewTableVertical' && section.headers !== null && section.headers.length !== undefined) {
        for (let i = 0; i < section.headers.length; i++) {
          if (calculatedHeight < this.portraitPageHeight) {
            const lineCount = this.getColumnLineCount(section, i, tableWidth);
            calculatedHeight += this.overviewTableRowHeight;

            if (lineCount > 1) {
              calculatedHeight += (lineCount - 1) * this.tableExtraRowHeight;
            }
          } else {
            break;
          }
        }
      }
    }

    return calculatedHeight;
  }

  splitSection(originalSection: ReportSection, availableHeight: number, pageHeight: number, pageWidth: number): ReportSection[] {
    let sections: ReportSection[] = [];
    const pageBreak: ReportSection = new ReportSection('pageBreak');

    if (originalSection.type === 'accuracyChart' || originalSection.type === 'precisionChart' || originalSection.type === 'historicalChart' || originalSection.type === 'fidelityChart'
      || originalSection.type === 'linearityChart' || originalSection.type === 'text') {
      sections.push(pageBreak);
      sections.push(originalSection);
    } else if (originalSection.type === 'detailsTable' || originalSection.type === 'overviewTable' || originalSection.type === 'overviewTableVertical') {
      sections = this.splitTableSection(originalSection, availableHeight, pageHeight, pageWidth);
    }

    return sections;
  }

  splitTableSection(originalSection: ReportSection, availableHeight: number, pageHeight: number, pageWidth: number): ReportSection[] {
    const sections: ReportSection[] = [];
    let calculatedHeight: number = this.sectionMargin;
    const rowHeight = (originalSection.type === 'detailsTable') ? this.detailsTableRowHeight : this.overviewTableRowHeight;
    const pageBreak: ReportSection = new ReportSection('pageBreak');
    const tableWidth: number = pageWidth - this.marginSides;

    const section1: ReportSection = new ReportSection();
    section1.id = originalSection.id;
    section1.title = originalSection.title;
    section1.type = originalSection.type;
    section1.enabled = originalSection.enabled;
    section1.order = originalSection.order;
    section1.dataPath = originalSection.dataPath;
    section1.headers = [];
    section1.content = [];

    if (originalSection.type === 'detailsTable') {
      calculatedHeight += 2; //Table has 2px border-spacing.
    }
    if (originalSection.type === 'accuracyChart' || originalSection.type === 'precisionChart' || originalSection.type === 'historicalChart' || originalSection.type === 'fidelityChart') {
      section1.loaded = false;
    }

    sections.push(section1);

    const section2: ReportSection = new ReportSection();
    section2.id = originalSection.id;
    section2.type = originalSection.type;
    section2.enabled = originalSection.enabled;
    section2.order = originalSection.order;
    section2.dataPath = originalSection.dataPath;
    section2.headers = [];
    section2.content = [];

    if (originalSection.type === 'overviewTable') {
      section1.headers = originalSection.headers;
      section2.headers = originalSection.headers;
      calculatedHeight += this.overviewTableRowHeight;
    }

    if (originalSection.title !== null && originalSection.title !== '') {
      calculatedHeight += this.sectionTitleHeight;
    }

    if (originalSection.content !== null && originalSection.content.length !== undefined && originalSection.content.length > 0) {
      if (originalSection.type !== 'overviewTableVertical') {
        for (let i = 0; i < originalSection.content.length; i++) {
          let newExtraHeight = rowHeight;

          if (originalSection.type === 'detailsTable' && originalSection.headers !== null && originalSection.headers.length === originalSection.content.length) {
            const lineCount = this.getColumnLineCount(originalSection, i, tableWidth);

            if (lineCount > 1) {
              newExtraHeight += (lineCount - 1) * this.tableExtraRowHeight;
            }
          }

          if (originalSection.type === 'overviewTable' && originalSection.content !== null && originalSection.content.length !== undefined) {
            let extraLines = 0;
            if (originalSection.content[i].length !== undefined && originalSection.content[i].length > 0) {
              for (let j = 0; j < originalSection.content[i].length; j++) {
                if (originalSection.content[i][j] !== null && originalSection.content[i][j].content !== undefined && originalSection.content[i][j].content !== null) {
                  let lineCount = this.getContentColumnLineCount(originalSection, j, tableWidth, originalSection.content[i][j]);

                  if (lineCount > extraLines) {
                    extraLines = lineCount - 1;
                  }
                }
              }
            }
            if (extraLines > 0) {
              newExtraHeight += extraLines * this.tableExtraRowHeight;
            }
          }

          if ((calculatedHeight + newExtraHeight) < availableHeight) {
            calculatedHeight += newExtraHeight;
            section1.content.push(originalSection.content[i]);

            if (originalSection.type === 'detailsTable') {
              section1.headers.push(originalSection.headers[i]);
            }
          } else {
            sections.push(pageBreak);

            if (originalSection.type === 'detailsTable') {
              section2.headers = originalSection.headers.slice(i);
            }

            section2.content = originalSection.content.slice(i);
            availableHeight = pageHeight - (this.marginTop + this.marginBottom + this.headerHeight + this.footerHeight);
            const newSections = this.splitTableSection(section2, availableHeight, pageHeight, pageWidth);

            if (newSections.length > 0) {
              for (let j = 0; j < newSections.length; j++) {
                sections.push(newSections[j]);
              }
            }

            break;
          }
        }
      } else {
        //Section type: overviewTableVertical
        if (originalSection.headers !== undefined && originalSection.headers !== null) {
          for (let j = 0; j < originalSection.content.length; j++) {
            section1.content.push([]);
          }
          for (let i = 0; i < originalSection.headers.length; i++) {
            calculatedHeight += rowHeight;

            if (calculatedHeight < availableHeight) {
              const lineCount = this.getColumnLineCount(originalSection, i, tableWidth);
              section1.headers.push(originalSection.headers[i]);

              if (lineCount > 1) {
                calculatedHeight += (lineCount - 1) * this.tableExtraRowHeight;
              }

              for (let j = 0; j < originalSection.content.length; j++) {
                section1.content[j].push(originalSection.content[j][i]);
              }
            } else {
              sections.push(pageBreak);

              section2.headers = originalSection.headers.slice(i);

              for (let j = 0; j < originalSection.content.length; j++) {
                const contentRow = originalSection.content[j].slice(i);
                section2.content.push(contentRow);
              }

              availableHeight = pageHeight - (this.marginTop + this.marginBottom + this.headerHeight + this.footerHeight);
              const newSections = this.splitTableSection(section2, availableHeight, pageHeight, pageWidth);

              if (newSections.length > 0) {
                for (let j = 0; j < newSections.length; j++) {
                  sections.push(newSections[j]);
                }
              }

              break;
            }
          }
        }
      }
    }

    return sections;
  }
}
