<div class="control-buttons">
  {{ 'OnlyIncluded' | translate }}<input type="checkbox" name="onlyIncluded" [checked]="onlyIncluded" (input)="changeInclusionView()" />
  <submit-button *ngIf="updateAccess" name="Save" (click)="evaluateCharts()"></submit-button>
</div>
<error-message [error]="error"></error-message>
<div class="grid-wrapper">
  <grid [name]="'Historical_Chart_Events'" [title]="'HistoricalChart.Events'" [genericDataRetrieval]="false" [headers]="headers" [filterOptions]="filterOptions" [count]="count" [exportData]="historicalChartEvents" (filterEvent)="getHistoricalChartEvents($event)">
    <tr *ngFor="let historicalChartEvent of historicalChartEvents; let i = index; let odd = odd" [class.odd]="odd">
      <td [title]="historicalChartEvent.eventType | translate">{{ historicalChartEvent.eventType | translate }}</td>
      <td [title]="historicalChartEvent.startDateTime ? (historicalChartEvent.startDateTime | date:dateTimeFormat) : ''">{{ historicalChartEvent.startDateTime | date:dateTimeFormat }}</td>
      <td [title]="historicalChartEvent.endDateTime ? (historicalChartEvent.endDateTime | date:dateTimeFormat) : ''">{{ historicalChartEvent.endDateTime | date:dateTimeFormat }}</td>
      <td [title]="historicalChartEvent.userName ? historicalChartEvent.userName : ''">{{ historicalChartEvent.userName }}</td>
      <td>
        <ng-container *ngFor="let eventDescription of historicalChartEvent.eventDescriptionsList">
          {{ eventDescription.translatableString | translate }}<ng-container *ngIf="eventDescription.translatableString !== ''">: </ng-container>
          <ng-container [ngSwitch]="eventDescription.translatableString">
            <ng-container *ngSwitchCase="'Chart.Type'">{{ eventDescription.valueString | translate }}</ng-container>
            <ng-container *ngSwitchCase="'State'">{{ eventDescription.valueString | translate }}</ng-container>
            <ng-container *ngSwitchCase="'ExpiryDate'">{{ eventDescription.valueString | date:dateTimeFormat }}</ng-container>
            <ng-container *ngSwitchCase="'CertificateDate'">{{ eventDescription.valueString | date:dateTimeFormat }}</ng-container>
            <ng-container *ngSwitchDefault>{{ eventDescription.valueString }}</ng-container>
          </ng-container>
          <br />
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="historicalChartEvent.statistics">
          <ng-template #statisticTemplate let-statistic="statistic">
            <ng-container *ngIf="statistic !== 'NaN'">{{ statistic | number:'1.2-2' }}</ng-container><ng-container *ngIf="statistic === 'NaN'">{{ 'NaN' | translate }}</ng-container><br />
          </ng-template>
          {{ 'Count' | translate }} = {{ historicalChartEvent.statistics.count }}<br />
          {{ 'Mean' | translate }} = <ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:historicalChartEvent.statistics.mean}"></ng-container>
          {{ 'Minimum' | translate }} = <ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:historicalChartEvent.statistics.minimum}"></ng-container>
          {{ 'Maximum' | translate }} = <ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:historicalChartEvent.statistics.maximum}"></ng-container>
          {{ 'Range' | translate }} = <ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:historicalChartEvent.statistics.range}"></ng-container>
          {{ 'StandardDeviation' | translate }} = <ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:historicalChartEvent.statistics.standardDeviation}"></ng-container>
        </ng-container>
      </td>
      <td [title]="historicalChartEvent.plottedValue">{{ historicalChartEvent.plottedValue }}</td>
      <td>
        <ng-container *ngIf="historicalChartEvent.eventType == 'Chart.Events.ValidationData'">
          <input type="checkbox" name="exclude{{i}}" [disabled]="!updateAccess" [checked]="historicalChartEvent.excluded" (input)="historicalChartEvent.excluded = !historicalChartEvent.excluded" />
        </ng-container>
      </td>
      <td [title]="historicalChartEvent.outlier">{{ historicalChartEvent.outlier }}</td>
      <td>
        <button class="button-blue" (click)="viewHistoricalChartEventDetails(historicalChartEvent)">{{ 'Details' | translate }}</button>
      </td>
    </tr>
  </grid>
</div>
<custom-dialog [visible]="detailDialog" *ngIf="detailDialog && historicChartEvent" (editModeChanged)="dialogChanged($event)">
  <historical-chart-event-overview [historicalChartEvent]="historicChartEvent" (dialogChanged)="dialogChanged($event)"></historical-chart-event-overview>
</custom-dialog>
