import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../core/app.config';
import { Event } from './event';

@Injectable()
export class EventService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getEvents(filterArguments: string, onlyUnacknowledged: boolean): Observable<Event[]> {
    return this.http.get<Event[]>(this.apiHost + 'event/get' + filterArguments +
      (filterArguments === '' ? '?onlyUnacknowledged=' : '&onlyUnacknowledged=') + onlyUnacknowledged);
  }

  public getEventsByInformationLevel(informationLevelId: number, filterArguments: string, onlyUnacknowledged: boolean): Observable<Event[]> {
    return this.http.get<Event[]>(this.apiHost + 'event/getbyinformationlevel/' + informationLevelId + filterArguments +
      (filterArguments === '' ? '?onlyUnacknowledged=' : '&onlyUnacknowledged=') + onlyUnacknowledged);
  }

  public getEventSourceTypes(): Observable<string[]> { return this.http.get<string[]>(this.apiHost + 'event/getsourcetypes'); }

  public getEvent(id: number): Observable<Event> { return this.http.get<Event>(this.apiHost + 'event/get/' + id); }

  public getEventCount(filterArguments: string, onlyUnacknowledged: boolean): Observable<number> {
    return this.http.get<number>(this.apiHost + 'event/count' + filterArguments +
      (filterArguments === '' ? '?onlyUnacknowledged=' : '&onlyUnacknowledged=') + onlyUnacknowledged);
  }

  public getEventCountByInformationLevel(informationLevelId: number, filterArguments: string, onlyUnacknowledged: boolean): Observable<number> {
    return this.http.get<number>(this.apiHost + 'event/countbyinformationlevel/' + informationLevelId + filterArguments +
      (filterArguments === '' ? '?onlyUnacknowledged=' : '&onlyUnacknowledged=') + onlyUnacknowledged);
  }

  public acknowledgeEvent(id: number) { return this.http.put(this.apiHost + 'event/acknowledge/' + id, null); }

  public deleteEvent(id: number) { return this.http.delete(this.apiHost + 'event/delete/' + id); }
}
