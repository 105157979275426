import { Endpoint } from '../../shared/data/endpoint';

export class ReportEndpoint {
  constructor(name?: string, endpoint?: string, order: number = undefined, referencedEndpoints: ReportEndpoint[] = null) {
    this.name = name;
    this.endpoint = endpoint;
    this.id = undefined;
    this.order = order;
    this.referencedEndpoints = referencedEndpoints.map(re => new ReportEndpoint(re.name, re.endpoint, re.order, re.referencedEndpoints));
  }

  id: number;
  name: string;
  endpoint: string;
  order: number;
  referencedEndpoints: ReportEndpoint[];

  availableEndpoints: Endpoint[];
}
