import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { RouterExtService } from '../../router-ext.service';

@Component({
  selector: 'error401',
  templateUrl: './error401.component.html',
  styleUrls: ['../error.component.css']
})

export class Error401Component implements OnInit {
  constructor(private router: Router, private routerExtService: RouterExtService) { }

  private requestedPageTitle: string;
  public message: string;

  ngOnInit() {
    if (this.router.events !== undefined) {
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(path => {
        this.getRedirectedRoute();
      });
    }
    this.getRedirectedRoute();
  }

  getRedirectedRoute() {
    const route: any = this.routerExtService.getRedirectedRoute();

    if (route) {
      this.requestedPageTitle = route.data.title;
      this.message = 'You do not have access to the "' + this.requestedPageTitle + '" module.';
    } else {
      this.message = 'You do not have access to the requested page.';
    }
  }

  redirectToHome() {
    this.router.navigate(['']);
  }
}
