<loader *ngIf="!sections"></loader>
<div *ngIf="sections">
  <div class="control-buttons-left">
    <button *ngIf="!editMode" class="button-blue" (click)="editMode = !editMode"><i class="material-icons">settings</i> {{ 'Customize' | translate }}</button>
    <button *ngIf="editMode" class="button-blue" (click)="editMode = !editMode"><i class="material-icons">done</i> {{ 'Done' | translate }}</button>
  </div>
  <div class="layout-settings">
    {{ 'Columns' | translate }}
    <span (click)="setLayout('')">Default</span>
    <span (click)="setLayout('one-column')">1</span>
    <span (click)="setLayout('two-columns')">2</span>
    <span (click)="setLayout('three-columns')">3</span>
    <span (click)="setLayout('four-columns')">4</span>
  </div>
  <div *ngIf="editMode && sectionTypes" class="overview-wrapper toolbar">
    <div class="overview-header">{{ 'aml.Sections' | translate }}<tooltip text="aml.SectionsTooltip" position="right"></tooltip></div>
    <span *ngFor="let type of sectionTypes" [draggable]="true" (dragstart)="onDragStart(null, type.name, type.type)" (dragend)="onDragEnd()">{{ type.name | translate }}</span>
  </div>
  <div class="layout-grid" [ngClass]="layoutClass">
    <div *ngFor="let section of sections" [ngSwitch]="section.type" class="section overview-wrapper" [ngClass]="{ 'dragging': dragging, 'collapsed': section.collapsed }" (drop)="onDrop($event, section)">
      <div class="overview-header" [ngClass]="{ 'draggable': editMode, 'dragging': section === dragSection }" [draggable]="editMode" (dragstart)="onDragStart(section)" (dragend)="onDragEnd()">
        <span *ngIf="!editMode">{{ section.name }}</span>
        <span *ngIf="editMode"><input class="section-name" type="text" [(ngModel)]="section.name" (keyup)="updateSection(section)" /></span>
        <span><i *ngIf="!section.collapsed" class="material-icons" title="{{ 'Collapse' | translate }}" (click)="toggleCollapsed(section)">expand_less</i></span>
        <span><i *ngIf="section.collapsed" class="material-icons" title="{{ 'Expand' | translate }}" (click)="toggleCollapsed(section)">expand_more</i></span>
        <button *ngIf="editMode" class="button-red" (click)="deleteSection(section)"><i class="material-icons">delete</i> {{ 'Delete' | translate }}</button>
      </div>
      <ng-container *ngSwitchCase="'favorites'">
        <favorites [editMode]="editMode"></favorites>
      </ng-container>
      <ng-container *ngSwitchCase="'perfIndic'">
        <table *ngIf="editMode && section.screen === 'home'" class="overview">
          <tr>
            <td>{{ 'AreaOrInstrument' | translate }}: </td>
            <td>
              <input type="hidden" name="informationLevelId" [(ngModel)]="section.informationLevelId" #informationLevel="ngModel" />
              <list-input [name]="'InformationLevel'" [model]="informationLevel" [endpoint]="'informationlevel'" [selectedId]="section.informationLevelId" (selectionChanged)="updateSection(section)"></list-input>
            </td>
          </tr>
        </table>
        <perf-indic-calculated *ngIf="section.informationLevelId || section.screen !== 'home'" [informationLevelId]="section.informationLevelId" class="perf-indic-wrapper" [showWrapper]="false" [useSavedResults]="true"></perf-indic-calculated>
        <div *ngIf="!editMode && section.screen === 'home' && !section.informationLevelId">{{ 'aml.SectionNotConfigured' | translate }}</div>
      </ng-container>
      <div class="content-wrapper" *ngSwitchCase="'areaLayout'">
        <table *ngIf="editMode && section.screen === 'home'" class="overview">
          <tr>
            <td>{{ 'Area.Area' | translate }}: </td>
            <td>
              <input type="hidden" name="areaId" [(ngModel)]="section.informationLevelId" #area="ngModel" />
              <list-input [name]="'Area'" [model]="area" [endpoint]="'area'" [selectedId]="section.informationLevelId" (selectionChanged)="updateSection(section)"></list-input>
            </td>
          </tr>
        </table>
        <area-layout *ngIf="section.informationLevelId || section.screen !== 'home'" [informationLevelId]="section.informationLevelId"></area-layout>
        <div *ngIf="!editMode && section.screen === 'home' && !section.informationLevelId">{{ 'aml.SectionNotConfigured' | translate }}</div>
      </div>
      <ng-container *ngSwitchCase="'ioStatus'">
        <io-status [editMode]="editMode" [activeFilters]="section.activeFilters" (filtersChanged)="filtersChanged($event, section)"></io-status>
      </ng-container>
      <ng-container *ngSwitchCase="'table'">
        <custom-layout-table [screen]="screen" [section]="section" [editMode]="editMode" (sectionChanged)="updateSection(section)"></custom-layout-table>
      </ng-container>
      <ng-container *ngSwitchCase="'chart'">
        <custom-layout-chart [section]="section" [editMode]="editMode" (sectionChanged)="updateSection(section)"></custom-layout-chart>
      </ng-container>
      <ng-container *ngSwitchCase="'areaOverview'">
        <area-overview [embedded]="true"></area-overview>
      </ng-container>
      <ng-container *ngSwitchCase="'badActors'">
        <table *ngIf="editMode && section.screen === 'home'" class="overview">
          <tr>
            <td>{{ 'Area.Area' | translate }}: </td>
            <td>
              <input type="hidden" name="areaId" [(ngModel)]="section.informationLevelId" #area="ngModel" />
              <list-input [name]="'Area'" [model]="area" [endpoint]="'area'" [selectedId]="section.informationLevelId" (selectionChanged)="updateSection(section)"></list-input>
            </td>
          </tr>
        </table>
        <bad-actors *ngIf="section.informationLevelId || section.screen !== 'home'" [areaId]="section.informationLevelId" [shownOnAreaOverview]="section.screen !== 'home'"></bad-actors>
        <div *ngIf="!editMode && section.screen === 'home' && !section.informationLevelId">{{ 'aml.SectionNotConfigured' | translate }}</div>
      </ng-container>
      <div *ngIf="section === dragSection" class="dragover-indicator dragging"></div>
      <div *ngIf="section !== dragSection" #dragoverIndicator class="dragover-indicator" (dragenter)="onDragEnter(dragoverIndicator)" (dragleave)="onDragLeave(dragoverIndicator)" (drop)="onDrop($event, section, dragoverIndicator)"></div>
    </div>
    <div #dummySection *ngIf="editMode" class="overview-wrapper dummy" (dragenter)="onDummyDragEnter(dummySection)" (dragleave)="onDummyDragLeave(dummySection)" (drop)="onDrop($event, null, dummySection)">{{ 'aml.Dropzone' | translate }}</div>
  </div>
</div>
