<div *ngIf="editMode" [ngClass]="{'overview-wrapper': viewAsDialog}">
  <div *ngIf="viewAsDialog" class="overview-header">{{ 'Filters' | translate }}</div>
  <div *ngIf="count === 0" class="filter-list">{{ 'Filter.NoFilters' | translate }}</div>
  <div *ngIf="count > 0" class="filter-list">
    <ng-container *ngFor="let filter of filterList">
      <div *ngIf="filter.type !== 'hidden' && filter.type !== 'informationlevel'" class="grid-filter">
        <select [ngModel]="filter.filterOption" [disabled]="filter.readonly" (ngModelChange)="setFilterOption($event, filter.id)" name="filterOption{{filter.id}}">
          <option></option>
          <ng-container *ngFor="let filterOption of filterOptions">
            <option *ngIf="!(instrumentLevel && (filterOption.name === 'Instruments' || filterOption.name ===  'AreasOrInstruments')) && filterOption.name !== 'TimelineDateTime'" [ngValue]="filterOption.name">{{ filterOption.name | translate }}</option>
          </ng-container>
        </select>
        <ng-container *ngIf="filter.type && (filter.type === 'string' || filter.type === 'any_prop_string' || filter.type === 'any_subprop_string')">
          <input type="text" [(ngModel)]="filter.filterText" [disabled]="filter.readonly" (keyup)="setFilterText($event, filter.id)" />
        </ng-container>
        <ng-container *ngIf="filter.type && (filter.type === 'boolean' || filter.type === 'isnotnull')">
          <select [ngModel]="filter.filterText" [disabled]="filter.readonly" (ngModelChange)="setSelectOption($event, filter.id)">
            <option></option>
            <option [ngValue]="'true'">{{ 'Yes' | translate }}</option>
            <option [ngValue]="'false'">{{ 'No' | translate }}</option>
          </select>
        </ng-container>
        <ng-template #comparison>
          <select [(ngModel)]="filter.filterDetails.operator" [disabled]="filter.readonly" (ngModelChange)="refresh()" name="filterOperator{{filter.id}}">
            <option></option>
            <option [ngValue]="'=='">{{ 'Comparison.eq' | translate }}</option>
            <option [ngValue]="'<'">{{ 'Comparison.st' | translate }}</option>
            <option [ngValue]="'>'">{{ 'Comparison.gt' | translate }}</option>
          </select>
        </ng-template>
        <ng-container *ngIf="filter.type && (filter.type === 'numeric' || filter.type === 'sum')">
          <ng-container *ngTemplateOutlet="comparison"></ng-container>
          <input type="number" [(ngModel)]="filter.filterText" [disabled]="filter.readonly" (keyup)="setFilterText($event, filter.id)" />
        </ng-container>
        <ng-container *ngIf="filter.type && filter.type === 'datetime'">
          <ng-container *ngTemplateOutlet="comparison"></ng-container>
          <input [owlDateTime]="filterDateTime" [owlDateTimeTrigger]="filterDateTime" [disabled]="filter.readonly" name="filterDateTime{{filter.id}}" [(ngModel)]="filter.filterText" (ngModelChange)="refresh()" />
          <owl-date-time [pickerType]="'calendar'" #filterDateTime></owl-date-time>
        </ng-container>
        <ng-container *ngIf="filter.type && filter.type === 'list' && !filter.readonly">
          <button class="button-blue" type="button" (click)="selectListItems(filter)">{{ 'Filter.SelectItems' | translate }}</button>
        </ng-container>
        <!-- All custom filters from the custom-filter folder. -->
        <ng-container *ngIf="filter.type">
          <area-type-filter [filter]="filter" (filterChanged)="refresh()"></area-type-filter>
          <audit-action-filter [filter]="filter" (filterChanged)="refresh()"></audit-action-filter>
          <client-type-filter [filter]="filter" (filterChanged)="refresh()"></client-type-filter>
          <criticality-filter [filter]="filter" (filterChanged)="refresh()"></criticality-filter>
          <decision-rule-filter [filter]="filter" (filterChanged)="refresh()"></decision-rule-filter>
          <event-type-filter [filter]="filter" (filterChanged)="refresh()"></event-type-filter>
          <io-server-filter [filter]="filter" (filterChanged)="refresh()"></io-server-filter>
          <material-status-filter [filter]="filter" (filterChanged)="refresh()"></material-status-filter>
          <material-type-filter [filter]="filter" (filterChanged)="refresh()"></material-type-filter>
          <operational-state-filter [filter]="filter" (filterChanged)="refresh()"></operational-state-filter>
          <perf-indic-filter [filter]="filter" (filterChanged)="refresh()"></perf-indic-filter>
          <report-level-filter [filter]="filter" (filterChanged)="refresh()"></report-level-filter>
          <timeline-object-type-filter [filter]="filter" (filterChanged)="refresh()"></timeline-object-type-filter>
          <validation-method-filter [filter]="filter" (filterChanged)="refresh()"></validation-method-filter>
          <validation-procedure-filter [filter]="filter" (filterChanged)="refresh()"></validation-procedure-filter>
          <validation-result-filter [filter]="filter" (filterChanged)="refresh()"></validation-result-filter>
          <validation-state-filter [filter]="filter" (filterChanged)="refresh()"></validation-state-filter>
        </ng-container>
        <button *ngIf="!filter.readonly" class="button-gray" type="button" (click)="removeFilter(filter.id)">{{ 'Remove' | translate }}</button>
        <ng-container *ngIf="filter.type && filter.type === 'list'">
          <data-list-filter-view [filter]="filter" [filterChanged]="filterChanged" [editMode]="true" (filterChangedEvent)="filterChanged.emit(true)"></data-list-filter-view>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div *ngIf="viewAsDialog" class="control-buttons">
    <button class="button-blue margin-right" type="button" (click)="addFilter()">{{ 'Filter.Add' | translate }}</button>
    <button class="button-gray" type="reset" (click)="cancel()">{{ 'Cancel' | translate }}</button>
    <button class="button-blue" type="button" (click)="submit()">{{ 'Save' | translate }}</button>
  </div>
  <div *ngIf="!viewAsDialog" class="control-buttons">
    <button class="button-blue" type="button" (click)="addFilter()">{{ 'Filter.Add' | translate }}</button>
  </div>
</div>
<ng-container *ngIf="!editMode" class="overview-wrapper">
  <div class="grid-no-filters" *ngIf="!showFilters || !filterList.length"></div>
  <div class="grid-filters no-print" *ngIf="showFilters && filterList.length">
    <ng-container *ngFor="let filter of filterList">
      <span *ngIf="filter.type !== 'hidden' && filter.type !== 'informationlevel' && filter.filterOption" class="grid-filter">
        {{ filter.filterOption | translate }}: 
        <ng-container *ngIf="filter.type && filter.type !== 'string' && filter.type !== 'boolean' && filter.type !== 'isnotnull' && filter.type !== 'numeric' && filter.type !== 'sum'
                      && filter.type !== 'datetime' && filter.type !== 'list' && filter.type !== 'perfindic' && filter.type !== 'any_prop_string' && filter.type !== 'any_subprop_string'">
          <span>{{ filter.filterText | translate }}</span>
        </ng-container>
        <ng-container *ngIf="filter.type && (filter.type === 'string' || filter.type === 'any_prop_string' || filter.type === 'any_subprop_string')">
          <span>{{ filter.filterText }}</span>
        </ng-container>
        <ng-container *ngIf="filter.type && (filter.type === 'boolean' || filter.type === 'isnotnull')">
          <span *ngIf="filter.filterText === true || filter.filterText === 'true'">{{ 'Yes' | translate }}</span>
          <span *ngIf="filter.filterText === false || filter.filterText === 'false'">{{ 'No' | translate }}</span>
        </ng-container>
        <ng-template #comparison>
          <span *ngIf="filter.filterDetails.operator === '=='">{{ 'Comparison.eq' | translate }} </span>
          <span *ngIf="filter.filterDetails.operator === '<'">{{ 'Comparison.st' | translate }} </span>
          <span *ngIf="filter.filterDetails.operator === '>'">{{ 'Comparison.gt' | translate }} </span>
        </ng-template>
        <ng-container *ngIf="filter.type && (filter.type === 'numeric' || filter.type === 'sum')">
          <ng-container *ngTemplateOutlet="comparison"></ng-container>
          <span>{{ filter.filterText }}</span>
        </ng-container>
        <ng-container *ngIf="filter.type && filter.type === 'datetime'">
          <ng-container *ngTemplateOutlet="comparison"></ng-container>
          <span>{{ filter.filterText | date:dateTimeFormat }}</span>
        </ng-container>
        <ng-container *ngIf="filter.type && filter.type === 'list'">
          <data-list-filter-view [filter]="filter" [filterChanged]="filterChanged"></data-list-filter-view>
        </ng-container>
        <!-- All custom filters from the custom-filter folder. -->
        <ng-container *ngIf="filter.type && filter.type === 'perfindic'">
          <perf-indic-filter [filter]="filter" [editMode]="false" [filterInputChanged]="filterChanged" (filterChanged)="refresh()"></perf-indic-filter>
        </ng-container>
      </span>
    </ng-container>
  </div>
</ng-container>
<custom-dialog [visible]="listItemsDialog" *ngIf="listItemsDialog" (editModeChanged)="dialogChanged($event)">
  <data-list-filter-select [parentId]="parentId" [informationLevelId]="informationLevelId" [instrumentId]="instrumentId" [areaId]="areaId" [equipmentId]="equipmentId"
                           [componentStreamId]="componentStreamId" [chartId]="chartId" [controlChartSetId]="controlChartSetId" [fidelityChartId]="fidelityChartId"
                           [ioClientId]="ioClientId" [maintenanceItemId]="maintenanceItemId" [filter]="activeFilter" [selectedIds]="selectedIds"
                           (editModeChanged)="dialogChanged($event)"></data-list-filter-select>
</custom-dialog>
