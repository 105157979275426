import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

import { FidelityGroup } from './fidelity-group';
import { CompType } from '../../comp-type/comp-type';
import { CompService } from '../../stream/comp/comp.service';
import { FidelityComp } from './fidelity-comp';

@Component({
  selector: 'fidelity-group-overview',
  templateUrl: './fidelity-group-overview.component.html',
  styleUrls: ['./fidelity-group-overview.component.css']
})
export class FidelityGroupOverviewComponent implements OnInit {
  constructor(
    private compService: CompService
  ) { }
  @Input() public fidelityGroup: FidelityGroup;
  @Input() public componentStreamId: number;
  @Output() public editModeChanged = new EventEmitter<boolean>();
  public selectedCompType: CompType;
  public compTypes: CompType[];
  private oldComposition: FidelityComp[];

  ngOnInit() {
    this.oldComposition = Array.from(this.fidelityGroup.fidelityComponents);
    this.getCompTypes();
  }

  getCompTypes() {
    this.compService.getCompsByCompStream(this.componentStreamId, '?size=9999').subscribe(comps =>
      this.compTypes = comps.map(comp => comp.componentType).filter(compType => !this.fidelityGroup.fidelityComponents.some(fidelityComponent => fidelityComponent.componentTypeId === compType.id)));
  }

  addCompType() {
    this.fidelityGroup.fidelityComponents.push(({ componentType: this.selectedCompType, componentTypeId: this.selectedCompType.id, fidelityGroupId: this.fidelityGroup.id }) as FidelityComp);
    this.compTypes.splice(this.compTypes.indexOf(this.selectedCompType), 1);
    this.selectedCompType = null;
  }

  deleteCompType(fidelityComp: FidelityComp) {
    this.compTypes.push(fidelityComp.componentType);
    this.fidelityGroup.fidelityComponents.splice(this.fidelityGroup.fidelityComponents.indexOf(fidelityComp), 1);
  }

  save() {
    this.oldComposition = null;
    this.changeEditMode(false);
  }

  cancel() {
    this.fidelityGroup.fidelityComponents = this.oldComposition;
    this.changeEditMode(false);
  }

  changeEditMode(editMode: boolean) {
    this.editModeChanged.emit(editMode);
  }
}
