import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ValidationStateFilterComponent } from './validation-state-filter.component';

@NgModule({
  declarations: [ValidationStateFilterComponent],
  exports: [ValidationStateFilterComponent],
  imports: [CommonModule, TranslateModule]
})
export class ValidationStateFilterModule { }
