import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ReportManagementService } from './report-management.service';
import { ReportType } from '../report-type';
import { ClaimService } from '../../claim.service';
import { ErrorMessage } from '../../../shared/error/error-message';
import { Header } from '../../../shared/grid/header';
import { FilterOption } from '../../../shared/data/filter-option';
import { AppConfig } from '../../../core/app.config';

@Component({
  selector: 'report-user-defined',
  templateUrl: './report-user-defined.component.html'
})

export class ReportUserDefinedComponent implements OnInit {
  constructor(
    private reportManagementService: ReportManagementService,
    private route: ActivatedRoute,
    private claimService: ClaimService,
    private appConfig: AppConfig
  ) { }
  public title = 'ReportType.UserDefined';
  private id = -1;
  public headers: Header[];
  public filterOptions: FilterOption[];
  public reportTypes: ReportType[] = [];
  public reportType: ReportType;
  public count = 0;
  private userId: string;
  public editDialog = false;
  public deleteDialog = false;
  public createAccess: boolean;
  public updateAccess: boolean;
  public deleteAccess: boolean;
  private reportManagementReadAccess: boolean;
  private reportManagementUpdateAccess: boolean;
  private reportManagementDeleteAccess: boolean;
  private args = '';
  public error: ErrorMessage;
  private dateTimeFormat: string;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.id = +this.route.parent.snapshot.paramMap.get('id');
    this.userId = this.claimService.getUserId();
    this.createAccess = this.claimService.checkClaimContains('aml.report', 'c');
    this.updateAccess = this.claimService.checkClaimContains('aml.report', 'u');
    this.deleteAccess = this.claimService.checkClaimContains('aml.report', 'd');
    this.reportManagementReadAccess = this.claimService.checkClaimContains('aml.reportman', 'r');
    this.reportManagementUpdateAccess = this.claimService.checkClaimContains('aml.reportman', 'u');
    this.reportManagementDeleteAccess = this.claimService.checkClaimContains('aml.reportman', 'd');

    if (this.reportManagementReadAccess) {
      this.filterOptions = [{ name: 'ReportType.Description', type: 'string' }, { name: 'ReportType.DataSet', type: 'string' }, { name: 'ReportType.ReportLevel', type: 'string' },
        { name: 'Enabled', type: 'boolean' }, { name: 'User.Name', type: 'string' }];
      this.headers = [{ header: 'ReportType.Description', sort: true, width: '24%' }, { header: 'ReportType.Dataset', sort: true, width: '15%' },
        { header: 'ReportType.ReportLevel', sort: true, width: '15%' }, { header: 'Enabled', sort: true, width: '8%' }, { header: 'User.Name', sort: true, width: '9%' },
        { header: 'Report.UpdateDateTime', sort: false, width: '10%' }, { header: 'Action', sort: false, width: '19%' }];
    } else {
      this.filterOptions = [{ name: 'ReportType.Description', type: 'string' }, { name: 'ReportType.DataSet', type: 'string' }, { name: 'ReportType.ReportLevel', type: 'string' },
        { name: 'Enabled', type: 'boolean' }];
      this.headers = [{ header: 'ReportType.Description', sort: true, width: '28%' }, { header: 'ReportType.Dataset', sort: true, width: '22%' },
        { header: 'ReportType.ReportLevel', sort: true, width: '18%' }, { header: 'Enabled', sort: true, width: '11%' }, { header: 'Action', sort: false, width: '21%' }];
    }

    this.getReportTypes(this.args);
  }

  getReportTypes(args: string) {
    this.error = null;
    this.args = args;

    if (this.reportManagementReadAccess) {
      this.reportManagementService.getAllUserDefinedReportCount(args).subscribe(count => this.count = count);
      this.reportManagementService.getAllUserDefinedReports(args).subscribe(reportTypes => this.reportTypes = reportTypes, error => this.error = error.error);
    } else {
      this.reportManagementService.getUserDefinedReportCount(args).subscribe(count => this.count = count);
      this.reportManagementService.getUserDefinedReports(args).subscribe(reportTypes => this.reportTypes = reportTypes, error => this.error = error.error);
    }
  }

  addReportType() {
    this.reportType = this.reportManagementService.newReportType();
    this.editDialog = true;
  }

  editReportType(reportType: ReportType) {
    this.editDialog = true;
    this.reportType = reportType;
  }

  deleteReportType(id: number) {
    this.deleteDialog = true;
    this.id = id;
  }

  toggleEnabled(id: number) {
    this.reportManagementService.toggleUserDefinedReportEnabled(id).subscribe(response => {
      this.getReportTypes(this.args);
    });
  }

  confirmDelete(confirmed: boolean) {
    this.error = null;
    this.deleteDialog = false;
    if (confirmed) {
      this.reportManagementService.deleteUserDefinedReport(this.id).subscribe(response => {
        this.getReportTypes(this.args);
        this.id = -1;
      }, error => this.error = error.error);
    }
  }

  dialogChanged(event: boolean) {
    this.editDialog = event;
    this.getReportTypes(this.args);
  }
}
