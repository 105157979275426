import { Io } from '../io/io';

export class Diagnostic {
  id: number;
  informationLevel: string;
  informationLevelId: number;
  description: string;
  unit: string;
  ioId: number;
  ioName: string;
  io: Io;
  sequenceNumber: number;
  visible: boolean;
  displayParameters: any;
  processValue: string;
  alarmAt: string;
  faultedAt: string;
  eventAt: string;
  qualityBadAt: string;
}
