import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ValidationProcedureFilterComponent } from './validation-procedure-filter.component';
import { ValidationProcedureService } from '../validation-procedure.service';

@NgModule({
  declarations: [ValidationProcedureFilterComponent],
  exports: [ValidationProcedureFilterComponent],
  imports: [CommonModule, TranslateModule],
  providers: [ValidationProcedureService]
})
export class ValidationProcedureFilterModule { }
