import { Component } from '@angular/core';

import { HelpService } from './help.service';

@Component({
  selector: 'help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})

export class HelpComponent {
  constructor(
    private service: HelpService
  ) {
    service.getHelpPageLink().subscribe(pageLink => this.helpPage = pageLink);
  }

  public helpPage: string;

  navigateToHelpFile() {
    if (this.helpPage !== undefined && this.helpPage !== null) {
      window.open(this.helpPage, null, 'menubar=no,location=no,resizable=yes,scrollbars=yes,status=no');
    }
  }
}
