<div class="charts-block overview-wrapper">
  <table style="width: 100%;">
    <tr>
      <td>
        <chart *ngIf="chart"
               (select)="viewDetails($event)"
               (selectSeries)="selectSeries($event)"
               [view]="initiatedByReport ? [820,350] : null"
               [legend]="true"
               [title]="''"
               [allowTypeChange]="false"
               [chartType]="'linechart'"
               [dataSeries]="chart"
               [xAxis]="'Time' | translate"
               [yAxis]="unit"
               [customColors]="colors">
          <ng-template #tooltipTemplate let-model="model">
            <ng-container *ngIf="model">
              <h3>{{ model.eventType | translate }}</h3>
              <table>
                <tr><td>{{ 'StartDateTime' | translate }}:</td><td>{{ model.start | date:dateTimeFormat }}</td></tr>
                <tr *ngIf="model.eventType === 'Chart.Events.ValidationData'"><td>{{ 'EndDateTime' | translate }}:</td><td>{{ model.end | date:dateTimeFormat }}</td></tr>
                <tr *ngIf="model.eventType === 'Chart.Events.ValidationData'"><td>{{ 'Value' | translate }}:</td><td>{{ model.value }}</td></tr>
              </table>
            </ng-container>
          </ng-template>
        </chart>
      </td>
    </tr>
  </table>
  <ng-container *ngIf="!initiatedByReport">
    <div>
      <span class="timepicker">
        {{ 'StartDateTime' | translate }}: <input [owlDateTime]="dtStartDateTime" [owlDateTimeTrigger]="dtStartDateTime" [(ngModel)]="start" (ngModelChange)="getChartData(start, end)" />
        <owl-date-time #dtStartDateTime></owl-date-time>
      </span>
      <span class="timepicker">
        {{ 'EndDateTime' | translate }}: <input [owlDateTime]="dtEndDateTime" [owlDateTimeTrigger]="dtEndDateTime" [(ngModel)]="end" (ngModelChange)="getChartData(start, end)" />
        <owl-date-time #dtEndDateTime></owl-date-time>
      </span>
    </div>
    <table class="overview" *ngIf="statistics">
      <ng-template #statisticTemplate let-statistic="statistic">
        <ng-container *ngIf="statistic !== 'NaN'">{{ statistic | number:'1.4-4' }}</ng-container><ng-container *ngIf="statistic === 'NaN'">{{ 'NaN' | translate }}</ng-container>
      </ng-template>
      <tr><td></td><td>{{ 'Statistics' | translate }}</td></tr>
      <tr><td>{{ 'Count' | translate }}</td><td>{{ statistics.count }}</td></tr>
      <tr><td>{{ 'Mean' | translate }}</td><td><ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:statistics.mean}"></ng-container></td></tr>
      <tr><td>{{ 'Minimum' | translate }}</td><td><ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:statistics.minimum}"></ng-container></td></tr>
      <tr><td>{{ 'Maximum' | translate }}</td><td><ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:statistics.maximum}"></ng-container></td></tr>
      <tr><td>{{ 'Range' | translate }}</td><td><ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:statistics.range}"></ng-container></td></tr>
      <tr><td>{{ 'StandardDeviation' | translate }}</td><td><ng-container *ngTemplateOutlet="statisticTemplate;context:{statistic:statistics.standardDeviation}"></ng-container></td></tr>
    </table>
  </ng-container>
</div>
<div *ngIf="!initiatedByReport && statisticalTestResults && statisticalTestResults.length > 0" class="charts-block overview-wrapper">
  <div class="header"><h1 style="margin-bottom:0; padding-bottom:0;">{{ 'StatisticalTest.Inference' | translate }}</h1></div>
    {{ 'StatisticalTest.DataSetSize' | translate:{n:statisticalTestResults[0].dataSetSize} }}<br /><br />
    <ng-container *ngFor="let statisticalTestResult of statisticalTestResults">
      {{ statisticalTestResult.testName | translate }}: {{ statisticalTestResult.passed ? 'Passed' : 'Failed' | translate }}<br />
      <div *ngIf="!statisticalTestResult.passed" class="failed">
        <ng-container *ngIf="statisticalTestResult.dataSetSize">
          <ng-container *ngFor="let testParameter of statisticalTestResult.testParameters">
            {{ testParameter.item1 | translate }} = {{ testParameter.item2 }}<br />
          </ng-container>
          <br />
        </ng-container>
        {{ statisticalTestResult.error.errorMessage | translate:statisticalTestResult.error.errorMessageParams }}<br />
      </div>
      <br />
    </ng-container>
</div>
<custom-dialog [visible]="detailDialog" *ngIf="detailDialog && historicChartEvent" (editModeChanged)="dialogChanged($event)">
  <historical-chart-event-overview [initiatedByReport]="initiatedByReport" [historicalChartEvent]="historicChartEvent" (dialogChanged)="dialogChanged($event)"></historical-chart-event-overview>
</custom-dialog>
