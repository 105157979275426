<div class="header">
  <h1>{{ title | translate }}</h1>
</div>
<div class="overview-wrapper">
  <div class="overview-header">{{ title | translate }}</div>
  <table class="overview">
    <tr>
      <td>{{ 'Report.Logo' | translate }}</td>
      <td>
        <img class="report-logo" [src]="reportLogo" />
        <label class="button-orange label-button">
          <input type="file" id="file" name="image" class="invisible" (change)="handleFileInput($event.target.files)" onclick="this.value=null;" />
          {{ 'UploadImage' | translate }}
        </label>
      </td>
    </tr>
  </table>
  <error-message *ngIf="error" [error]="error"></error-message>
</div>
