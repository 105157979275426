import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ReportService } from './report.service';
import { Report } from './report';
import { ClaimService } from '../claim.service';
import { ErrorMessage } from '../../shared/error/error-message';
import { Header } from '../../shared/grid/header';
import { FilterOption } from '../../shared/data/filter-option';
import { AppConfig } from '../../core/app.config';

@Component({
  selector: 'report',
  templateUrl: './report.component.html'
})

export class ReportComponent implements OnInit {
  constructor(
    private reportService: ReportService,
    private route: ActivatedRoute,
    private claimService: ClaimService,
    private appConfig: AppConfig
  ) { }
  public title = 'Reports';
  private id = -1;
  public headers: Header[] = [{ header: 'Report.Description', sort: true, width: '28%' }, { header: 'Report.ReportType', sort: true, width: '20%' }, { header: 'Report.UpdateDateTime', sort: true, width: '17%' },
    { header: 'Report.UpdatedByUser', sort: true, width: '14%' }, { header: 'Action', sort: false, width: '20%' }];
  public filterOptions: FilterOption[] = [{ name: 'Report.Description', type: 'string' }, { name: 'Report.ReportType', type: 'string' }, { name: 'Report.UpdateDateTime', type: 'datetime' },
    { name: 'Report.UpdatedByUser', type: 'string' }];
  public reports: Report[] = [];
  public report: Report;
  public count = 0;
  public editDialog = false;
  public historyDialog = false;
  public deleteDialog = false;
  public createAccess: boolean;
  public updateAccess: boolean;
  public deleteAccess: boolean;
  private informationLevelId = -1;
  private args = '';
  public error: ErrorMessage;
  private dateTimeFormat: string;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.id = +this.route.parent.snapshot.paramMap.get('id');
    this.createAccess = this.claimService.checkClaimContains('aml.report', 'c');
    this.updateAccess = this.claimService.checkClaimContains('aml.report', 'u');
    this.deleteAccess = this.claimService.checkClaimContains('aml.report', 'd');
    this.informationLevelId = +this.route.parent.parent.snapshot.paramMap.get('id');

    this.getReports(this.args);
  }

  getReports(args: string) {
    this.error = null;
    this.args = args;
    this.reportService.getReportCountByInformationLevel(args, this.informationLevelId).subscribe(count => this.count = count);
    this.reportService.getReportsByInformationLevel(args, this.informationLevelId, true).subscribe(reports => this.reports = reports, error => this.error = error.error);
  }

  addReport() {
    this.report = this.reportService.newReport();
    this.editDialog = true;
  }

  editReport(report: Report) {
    this.editDialog = true;
    this.report = report;
  }

  openHistory(report: Report) {
    this.historyDialog = true;
    this.report = report;
  }

  deleteReport(id: number) {
    this.deleteDialog = true;
    this.id = id;
  }

  confirmDelete(confirmed: boolean) {
    this.error = null;
    this.deleteDialog = false;
    if (confirmed) {
      this.reportService.deleteReport(this.id).subscribe(response => {
        this.getReports(this.args);
        this.id = -1;
      }, error => this.error = error.error);
    }
  }

  dialogChanged(event: boolean) {
    this.editDialog = event;
    this.getReports(this.args);
  }

  historyDialogChanged(event: boolean) {
    this.historyDialog = event;
    this.getReports(this.args);
  }
}
