import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { AppConfig } from '../app.config';

@Component({
  selector: 'language-select',
  templateUrl: './language-selection.component.html'
})

export class LanguageSelectionComponent {
  constructor(private appConfig: AppConfig, public translate: TranslateService) {
    translate.addLangs(['en', 'nl']);
    this.language = this.appConfig.getConfig('defaultLanguage');
    translate.setDefaultLang(this.language);
  }
  public language: string;

  setLanguage(event: any) {
    this.language = event.target.value;
    this.translate.use(this.language);
  }
}
