<chart *ngIf="linearitySession && chartData" [view]="view"
       [title]="'Linearity.Chart' | translate"
       [allowTypeChange]="false"
       [chartType]="'linechart'"
       [dataSeries]="chartData"
       [xAxis]="' '"
       [yAxis]="' '">
  <ng-template #tooltipTemplate let-model="model">
    <table *ngIf="model">
      <tr><td>{{ model.description }} [{{ model.symbol }}] ({{ model.unit }})</td></tr>
      <tr><td>{{ model.areaValue | number }}</td></tr>
    </table>
  </ng-template>
</chart>
