import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../../../core/app.config';
import { Generic } from '../../../shared/generic';
import { DecisionRule } from './decision-rule';
import { ChartSettings } from '../chart/chart-settings';

@Injectable()
export class DecisionRuleService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getDecisionRuleNames(): Observable<string[]> { return this.http.get<string[]>(this.apiHost + 'decisionrule/getdecisionrulenames'); }

  public getSeverityLevelNames(): Observable<string[]> { return this.http.get<string[]>(this.apiHost + 'decisionrule/getseveritylevelnames'); }

  public getDecisionRules(filterArguments: string): Observable<DecisionRule[]> { return this.http.get<DecisionRule[]>(this.apiHost + 'decisionrule/get' + filterArguments); }

  public getDecisionRule(id: number): Observable<DecisionRule> { return this.http.get<DecisionRule>(this.apiHost + 'decisionrule/get/' + id); }

  public getDecisionRuleCount(filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'decisionrule/count' + filterArguments); }

  public getAllDecisionRules(): Observable<Generic[]> { return this.http.get<Generic[]>(this.apiHost + 'decisionrule/getall'); }

  public getPrecisionChartRules(): Observable<Generic[]> { return this.http.get<Generic[]>(this.apiHost + 'decisionrule/getprecisionchartrules'); }

  public getDeltaChartRules(): Observable<Generic[]> { return this.http.get<Generic[]>(this.apiHost + 'decisionrule/getdeltachartrules'); }

  public getDefaultChartSettings(): Observable<ChartSettings> { return this.http.get<ChartSettings>(this.apiHost + 'decisionrule/getdefaultchartsettings'); }

  public setDefaultChartSettings(chartSettings: ChartSettings) { return this.http.put(this.apiHost + 'decisionrule/setdefaultchartsettings', chartSettings); }

  public newDecisionRule(): DecisionRule {
    return { id: -1, conformSize: 0, setSize: 0, decisionRuleName: '', severityLevel: '', displayName: '' };
  }
}
