import { NgModule } from '@angular/core';
import { OwlDateTimeModule } from '@shtian/ng-pick-datetime';

import { SharedModule } from '../../shared/shared.module';

import { ReportRoutingModule } from './report-routing.module';
import { ReportComponent } from './report.component';
import { ReportService } from './report.service';
import { ReportOverviewComponent } from './report-overview.component';
import { ReportViewComponent } from './report-view.component';
import { ReportHistoryComponent } from './report-history.component';
import { ReportViewService } from './report-view.service';
import { ReportViewPageService } from './report-view-page.service';
import { ReportExportService } from './report-export.service';
import { DataFilterModule } from '../../shared/data/data-filter.module';
import { DataModule } from '../../shared/data/data.module';
import { ControlChartModule } from '../../area/instrument/chart/control-chart/control-chart.module';
import { HistoricalChartEventModule } from '../../area/instrument/chart/historical-chart/historical-chart-event.module';
import { FidelityChartModule } from '../../area/instrument/chart/fidelity/fidelity-chart.module';
import { LinearityChartModule } from '../../area/instrument/chart/linearity/linearity-chart.module';

@NgModule({
  declarations: [
    ReportComponent,
    ReportOverviewComponent,
    ReportViewComponent,
    ReportHistoryComponent
  ],
  imports: [
    ReportRoutingModule,
    SharedModule,
    DataFilterModule,
    DataModule,
    ControlChartModule,
    HistoricalChartEventModule,
    FidelityChartModule,
    LinearityChartModule,
    OwlDateTimeModule
  ],
  providers: [
    ReportService,
    ReportViewService,
    ReportViewPageService,
    ReportExportService
  ]
})

export class ReportModule { }
