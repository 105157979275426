export class ActiveFilter {
  constructor() {
    this.id = -1;
    this.filterText = '';
    this.filterOption = '';
    this.filterDetails = { };
    this.type = '';
    this.readonly = false;
  }

  id: number;
  filterText: string;
  filterOption: string;
  filterDetails;
  type: string;
  readonly: boolean;
}
