<div *ngIf="!note">
  <error-message [error]="error"></error-message>
</div>
<form *ngIf="note" (ngSubmit)="submitNote(noteForm)" #noteForm="ngForm" class="overview-wrapper">
  <div class="overview-header" *ngIf="!editMode">{{ 'Note.View' | translate }}</div>
  <div class="overview-header" *ngIf="editMode && (!note || !note.id || !(note.id > 0))">{{ 'Note.Add' | translate }}</div>
  <div class="overview-header" *ngIf="editMode && note.id > 0">{{ 'Note.Edit' | translate }}</div>
  <table class="overview">
    <tr><td>{{ 'Note.By' | translate }}:</td><td>{{ note.commentBy }}</td></tr>
    <tr><td>{{ 'DateCreated' | translate }}:</td><td>{{ note.dateCreated | date:dateFormat }}</td></tr>
    <tr><td>{{ 'LastModifiedBy' | translate }}:</td><td>{{ note.lastModifiedBy }}</td></tr>
    <tr><td>{{ 'LastModifiedOn' | translate }}:</td><td>{{ note.lastModified | date:dateTimeFormat }}</td></tr>
    <tr>
      <td>{{ 'Note.Note' | translate }}:</td>
      <td>
        <textarea rows="5" cols="52" name="comment" [disabled]="!editMode" [required]="!note || !note.id || note.id < 1" [(ngModel)]="note.comment" #comment="ngModel"></textarea>
        <form-alert [field]="comment" name="Note.Note"></form-alert>
      </td>
    </tr>
  </table>
  <error-message [error]="error"></error-message>
  <div class="control-buttons-left" [ngClass]="{ 'next-to-control-buttons': updateAccess || editMode }">
    <button class="button-gray" type="reset" (click)="close()">{{ 'Close' | translate }}</button>
  </div>
  <div *ngIf="updateAccess && !editMode" class="control-buttons">
    <button class="button-blue" type="button" (click)="editNote()">{{ 'Edit' | translate }}</button>
  </div>
  <div class="control-buttons" *ngIf="editMode">
    <button class="button-gray" type="reset" (click)="cancel()">{{ 'Cancel' | translate }}</button>
    <submit-button name="Save" [disabled]="noteForm.invalid"></submit-button>
  </div>
</form>
