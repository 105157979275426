import { ReportParameter } from './report-parameter';
import { Generic } from '../../shared/generic';

export class Report {
  id: number;
  description: string;
  reportType: number;
  reportTypeDescription: string;
  updateDateTime: string;
  updatedByUser: string;
  filters: string;
  reportParameters: ReportParameter[];
  savedDatasets: Generic[];
}
