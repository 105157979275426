<div class="grid-wrapper">
  <grid [name]="'Fidelity_Sessions'" [title]="''" [genericDataRetrieval]="false" [headers]="headers" [filterOptions]="filterOptions" [count]="count" [exportData]="fidelitySessions" (filterEvent)="getFidelitySessions($event)">
    <tr *ngFor="let fidelitySession of fidelitySessions; let i = index; let odd = odd" [class.odd]="odd">
      <td [title]="fidelitySession.dateTime | date:dateTimeFormat">{{ fidelitySession.dateTime | date:dateTimeFormat }}</td>
      <td>
        <button type="button" class="button-blue" [disabled]="selectedFidelitySession && fidelitySession.id == selectedFidelitySession.id" (click)="selectFidelitySession(fidelitySession)">{{ 'Select' | translate }}</button>
        <button type="button" class="button-blue" (click)="viewFidelitySession(fidelitySession)">{{ 'Details' | translate }}</button>
        <button type="button" class="button-red" (click)="deleteFidelitySession(fidelitySession.id)">{{ 'Delete' | translate }}</button>
      </td>
    </tr>
  </grid>
  <error-message [error]="error"></error-message>
</div>
<custom-dialog *ngIf="editDialog" [visible]="editDialog" (editModeChanged)="dialogChanged($event)">
  <fidelity-session-overview [fidelitySession]="dialogSession" (editModeChanged)="dialogChanged($event)"></fidelity-session-overview>
</custom-dialog>
<delete-dialog [visible]="deleteDialog" [error]="error" (confirmDelete)="confirmDelete($event)"></delete-dialog>
