import { Component, Input, Output, EventEmitter, ElementRef, AfterViewChecked, ChangeDetectorRef } from '@angular/core';

import { GlobalsService } from '../../core/globals.service';

@Component({
  selector: 'custom-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})

export class DialogComponent implements AfterViewChecked {
  constructor(
    private el: ElementRef,
    private cdRef: ChangeDetectorRef,
    private globals: GlobalsService
  ) { }

  @Input() visible: boolean;
  @Output() public editModeChanged = new EventEmitter<boolean>();
  private top: number = 0;
  private topStyle: string = 'calc(50vh - 200px)';
  private left: number = 0;
  private leftStyle: string = 'calc(50vw - 200px)';
  private mouseX: number = 0;
  private mouseY: number = 0;
  private dialogSizes;
  private dragged: boolean = false;
  private previousWidth: number = 0;
  private previousHeight: number = 0;

  ngAfterViewChecked() {
    if (!this.dragged) {
      const dialogWrapper = this.el.nativeElement.getElementsByClassName('dialog-wrapper');
      const dialog = dialogWrapper[0].firstElementChild;

      if (dialog !== null) {
        this.dialogSizes = dialog.getBoundingClientRect();
      }

      if (this.dialogSizes !== undefined) {
        const widthDifference = this.dialogSizes.width - this.previousWidth;
        const heightDifference = this.dialogSizes.height - this.previousHeight;

        if (widthDifference > 50 || widthDifference < -50) {
          const width = this.dialogSizes.width / 2;
          this.leftStyle = 'calc(50vw - ' + width + 'px)';
          this.previousWidth = this.dialogSizes.width;
        }

        if (heightDifference > 25 || heightDifference < -25) {
          const height = this.dialogSizes.height / 2;
          this.topStyle = 'calc(50vh - ' + height + 'px)';
          this.previousHeight = this.dialogSizes.height;
        }
      }

      this.cdRef.detectChanges();
    }
  }

  onClick(event: MouseEvent) {
    this.mouseX = (event.clientX > 0) ? event.clientX : this.globals.clientX;
    this.mouseY = (event.clientY > 0) ? event.clientY : this.globals.clientY;
  }

  dragStart(event: any) {
    if (event.dataTransfer !== undefined) {
      event.dataTransfer.setData('text', event.target.id);
    }
  }

  drag(event: MouseEvent) {
    this.dragged = true;
    const clientX: number = (event.clientX > 0) ? event.clientX : this.globals.clientX;
    const clientY: number = (event.clientY > 0) ? event.clientY : this.globals.clientY;
    const width = (this.dialogSizes !== undefined) ? this.dialogSizes.width / 2 : 0;
    const height = (this.dialogSizes !== undefined) ? this.dialogSizes.height / 2 : 0;

    if (clientX > 0 || clientY > 0) {
      this.left += clientX - this.mouseX;
      this.leftStyle = 'calc(50vw - ' + width + 'px + ' + this.left + 'px)';
      this.top += clientY - this.mouseY;
      this.topStyle = 'calc(50vh - ' + height + 'px + ' + this.top + 'px)';
      this.onClick(event);
    }
  }

  cancel() {
    this.editModeChanged.emit(false);
  }
}
