import { Injectable } from '@angular/core';
import { Route, Router, CanLoad } from '@angular/router';

import { ClaimService } from './claim.service';
import { RouterExtService } from './router-ext.service';

@Injectable()
export class AuthGuard implements CanLoad {
  constructor(private claimService: ClaimService, private router: Router, private routerExtService: RouterExtService) { }

  canLoad(route: Route): boolean {
    if (this.claimService.isLoggedIn()) {
      /* Process route and claims to reach a decision. */
      let authorized = false;
      switch (route.path) {
        case 'administration':
        case 'laboratory':
        case 'security':
          authorized = true;
          break;
        case 'area':
        case 'historian':
        case 'instrument':
        case 'massconfig':
        case 'timeline':
          authorized = this.claimService.checkClaim('scope', 'area') && (this.claimService.checkClaimContains('aml.area', 'r'));
          break;
        case 'activityoverview':
          authorized = this.claimService.checkClaim('scope', 'audittrail') && (this.claimService.checkClaimContains('aml.audittrail', 'r'));
          break;
        case 'configsettings':
        case 'badactors':
          authorized = this.claimService.checkClaim('scope', 'consettings') && this.claimService.checkClaimContains('aml.consettings', 'r');
          break;
        case 'componenttype':
        case 'criticality':
        case 'decisionrule':
        case 'documentextension':
        case 'domain':
        case 'globalsetting':
        case 'ioformulas':
        case 'ioserver':
        case 'maintenance':
        case 'refmattype':
          authorized = this.claimService.checkClaim('scope', 'consettings') && this.claimService.checkClaimContains('aml.consettings', 'r');
          break;
        case 'document':
          authorized = this.claimService.checkClaim('scope', 'document') && this.claimService.checkClaimContains('aml.document', 'r');
          break;
        case 'event':
          authorized = this.claimService.checkClaim('scope', 'event') && this.claimService.checkClaimContains('aml.event', 'r');
          break;
        case 'linesample':
        case 'charting':
          authorized = this.claimService.checkClaim('scope', 'valsession') && this.claimService.checkClaimContains('aml.valsession', 'r');
          break;
        case 'pvt':
          authorized = this.claimService.checkClaim('scope', 'pvt') && this.claimService.checkClaimContains('aml.pvt', 'r');
          break;
        case 'perfindic':
          authorized = this.claimService.checkClaim('scope', 'perfindic') && this.claimService.checkClaimContains('aml.perfindic', 'r');
          break;
        case 'refmat':
          authorized = this.claimService.checkClaim('scope', 'refmats') && (this.claimService.checkClaimContains('aml.refmats', 'r'));
          break;
        case 'report':
          authorized = (this.claimService.checkClaim('scope', 'report') && this.claimService.checkClaimContains('aml.report', 'r'))
            || (this.claimService.checkClaim('scope', 'reportman') && this.claimService.checkClaimContains('aml.reportman', 'r'));
          break;
        case 'import':
        case 'databrowser':
        case 'devops':
          authorized = this.claimService.checkClaim('scope', 'hadm') && this.claimService.checkClaimContains('aml.hadm', 'r');
          break;
        case 'suppliers':
          authorized = this.claimService.checkClaim('scope', 'inventory') && this.claimService.checkClaimContains('aml.inventory', 'r');
          break;
        case 'maintenanceapproval':
          authorized = this.claimService.checkClaim('scope', 'maiapproval') && (this.claimService.checkClaimContains('aml.maiapproval', 'r'));
          break;
        default:
          break;
      }

      if (!authorized) {
        this.routerExtService.setRedirectedRoute(route);
        this.router.navigate(['401']);
      }
      return authorized;
    } else {
      localStorage.setItem('url', window.location.href);
      return false;
    }
  }
}
