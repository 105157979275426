import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { Breadcrumb } from './breadcrumb';
import { BreadcrumbService } from './breadcrumb.service';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})

export class BreadcrumbComponent {
  constructor(
    private breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.getBreadcrumbs().subscribe(breadcrumbs => this.breadcrumbs = breadcrumbs);
    breadcrumbService.getInformationLevelBreadcrumbs().subscribe(breadcrumbs => this.informationLevelBreadcrumbs = breadcrumbs);
    breadcrumbService.getBreadcrumbs2().subscribe(breadcrumbs => this.breadcrumbs2 = breadcrumbs);
  }

  public breadcrumbs: Breadcrumb[];
  public informationLevelBreadcrumbs: Breadcrumb[];
  public breadcrumbs2: Breadcrumb[];
}
