import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ClaimService } from '../../../../../core/claim.service';

import { ControlChartService } from '../control-chart.service';
import { VrgInclusion } from '../vrg-inclusion';
import { ValidationData } from './validation-data';
import { ValidationDataService } from './validation-data.service';
import { ErrorMessage } from '../../../../../shared/error/error-message';
import { AppConfig } from '../../../../../core/app.config';

@Component({
  selector: 'validation-data-overview',
  templateUrl: './validation-data-overview.component.html'
})
export class ValidationDataOverviewComponent implements OnInit {
  constructor(
    private controlChartService: ControlChartService,
    private validationDataService: ValidationDataService,
    private claimService: ClaimService,
    private appConfig: AppConfig
  ) { }
  public title: string = 'Validation Data Overview';
  @Input() public controlChartSetId: number;
  @Input() public validationSessionId: number;
  @Input() public embedded: boolean = false;
  @Input() public initiatedByReport: boolean = false;
  @Output() public viewModeChanged = new EventEmitter<boolean>();
  public validationDataPoint: ValidationData;
  public noteDialog: boolean;
  public createAccess: boolean;
  public updateAccess: boolean;
  public error: ErrorMessage;
  private dateTimeFormat: string;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.createAccess = this.claimService.checkClaimContains('aml.valsession', 'c');
    this.updateAccess = this.claimService.checkClaimContains('aml.valsession', 'u');
    this.getData();
  }

  getData() {
    this.error = null;
    this.validationDataService.getValidationDataPoint(this.controlChartSetId, this.validationSessionId).subscribe(dataPoint => {
      this.validationDataPoint = dataPoint;
      if (!this.validationDataPoint) { // Close the dialog if there is no data to display.
        this.cancel();
      }
    }, error => this.error = error.error);
  }

  reevaluateControlChart() {
    this.error = null;
    this.controlChartService.saveAndReevaluateControlChartSet(this.controlChartSetId,
      [<VrgInclusion>{ exclude: this.validationDataPoint.excluded, id: this.validationDataPoint.validationReadingGroupId, reset: this.validationDataPoint.resetSpc }]).subscribe(
      response => { this.cancel(); }, error => this.error = error.error);
  }

  cancel() {
    this.viewModeChanged.emit(false);
  }

  viewNote() {
    this.noteDialog = true;
  }

  noteDialogChanged(event: boolean) {
    this.noteDialog = event;
    this.getData();
  }
}
