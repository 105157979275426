import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AppConfig } from '../../../../core/app.config';
import { HistoricalChartEvent } from './historical-chart-event';
import { ReferenceMaterialChange } from './reference-material-change';
import { ControlLimitsChanged } from './control-limits-changed';
import { HistoricalChartEventChart } from './historical-chart-event-chart';
import { VrgInclusion } from '../control-chart/vrg-inclusion';

@Injectable()
export class HistoricalChartEventService {
  private static addArgs: string = '';
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  private eventsCache: Map<string, Observable<HistoricalChartEvent[]>> = new Map();
  private chartCache: Map<string, Observable<HistoricalChartEventChart>> = new Map();
  private countCache: Map<string, Observable<number>> = new Map();

  public getHistoricalChartEventsByInstrument(historicalChartId: number, filterArguments: string, onlyIncluded: boolean, force: boolean = false): Observable<HistoricalChartEvent[]> {
    const key = historicalChartId + filterArguments + (onlyIncluded ? 't' : 'f');
    if (force || !this.eventsCache.has(key)) {
      this.eventsCache.set(key, this.http.get<HistoricalChartEvent[]>(this.apiHost + 'statistical/gethistoricalchartevents/'
        + historicalChartId + filterArguments + (filterArguments === '' ? '?onlyincluded=' : '&onlyincluded=') + onlyIncluded + HistoricalChartEventService.addArgs).pipe(shareReplay()));
      setTimeout(() => this.eventsCache.delete(key), 15000);
    }
    return this.eventsCache.get(key);
  }

  public getHistoricalChartEventChartData(historicalChartId: number, start: string, end: string, onlyIncluded: boolean, force: boolean = false) {
    const key = historicalChartId + 's' + start + 'e' + end + (onlyIncluded ? 't' : 'f');
    if (force || !this.chartCache.has(key)) {
      this.chartCache.set(key,
        this.http.get<HistoricalChartEventChart>(this.apiHost + 'statistical/gethistoricalcharteventchartdata/' + historicalChartId +
          '?start=' + start + '&end=' + end + '&onlyIncluded=' + onlyIncluded + HistoricalChartEventService.addArgs));
      setTimeout(() => this.chartCache.delete(key), 15000);
    }
    return this.chartCache.get(key);
  }

  public getHistoricalChartEventCount(historicalChartId: number, filterArguments: string, onlyIncluded: boolean, force: boolean = false): Observable<number> {
    const key = historicalChartId + filterArguments + (onlyIncluded ? 't' : 'f');
    if (force || !this.countCache.has(key)) {
      this.countCache.set(key, this.http.get<number>(this.apiHost + 'statistical/historicalcharteventcount/'
        + historicalChartId + filterArguments + (filterArguments === '' ? '?onlyincluded=' : '&onlyincluded=') + onlyIncluded + HistoricalChartEventService.addArgs).pipe(shareReplay()));
      setTimeout(() => this.countCache.delete(key), 15000);
    }
    return this.countCache.get(key);
  }

  public getReferenceMaterialChange(referenceMaterialId: number): Observable<ReferenceMaterialChange> {
    return this.http.get<ReferenceMaterialChange>(this.apiHost + 'statistical/getreferencematerialchange/' + referenceMaterialId);
  }

  public getControlLimitsChanged(controlLimitId: number): Observable<ControlLimitsChanged> {
    return this.http.get<ControlLimitsChanged>(this.apiHost + 'statistical/getcontrollimitschanged/' + controlLimitId);
  }

  public runHistoricalChart(id: number): Observable<boolean> { return this.http.get<boolean>(this.apiHost + 'statistical/runhistoricalchart/' + id); }

  public updateValidationReadingGroupExclusion(historicalChartId: number, vrgivs: VrgInclusion[]) {
    return this.http.put(this.apiHost + 'statistical/updatevalidationreadinggroupexclusion/' + historicalChartId, vrgivs);
  }

  public setAddArgs(args: string) {
    HistoricalChartEventService.addArgs = args;
  }
}
