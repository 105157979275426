import { Component, Input, OnInit, TemplateRef, ContentChild, Output, EventEmitter, OnChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { GridAction } from './grid-action';
import { AddAction } from './add-action';
import { DataService } from '../data/data.service';
import { ErrorMessage } from '../error/error-message';
import { ClaimService } from '../../core/claim.service';
import { CustomGridColumn } from './custom-grid-column';
import { ActiveFilter } from '../data/active-filter';

@Component({
  selector: 'crud-grid',
  templateUrl: './crud-grid.component.html'
})

export class CrudGridComponent implements OnInit, OnChanges {
  constructor(
    private claimService: ClaimService,
    private dataService: DataService
  ) { }

  //Filter inputs
  @Input() parentId: number;
  @Input() informationLevelId: number;
  @Input() instrumentId: number;
  @Input() areaId: number;
  @Input() equipmentId: number;
  @Input() componentStreamId: number;
  @Input() chartId: number;
  @Input() controlChartSetId: number;
  @Input() fidelityChartId: number;
  @Input() ioClientId: number;
  @Input() maintenanceItemId: number;

  @Input() name: string;
  @Input() title = 'Overview';
  @Input() claims: string[];
  @Input() endpoint: string;
  @Input() addAction: AddAction;
  @Input() additionalRowActions: GridAction[];
  @Input() additionalActions: GridAction[];
  @Input() customColumns: CustomGridColumn[];
  @Input() showAdd = true;
  @Input() showEdit = true;
  @Input() showDelete = true;
  @Input() dataChanged: Subject<void> = new Subject<void>();
  @Input() editDialogChanged: Subject<boolean> = new Subject<boolean>();
  @Input() editButtonName: string = 'Edit';
  @Input() editButtonCondition: (obj: any) => boolean = () => this.updateAccess;
  @Input() editButtonDisabled: (obj: any) => boolean = () => false;
  @Input() deleteButtonDisabled: (obj: any) => boolean = () => false;
  @Input() nestingDialog = false;
  @Input() showFilters = true;
  @Input() showHeader = true;
  @Input() showFooter = true;
  @Input() activeFilters: ActiveFilter[] = [];
  @Input() idProperty: string = 'id';
  @Output() dataChangedEvent = new EventEmitter<any[]>();
  @Output() itemAddedModifiedOrDeletedEvent = new EventEmitter<any[]>();
  @ContentChild('overviewComponent') overviewComponentRef: TemplateRef<any>;
  public createAccess: boolean;
  public updateAccess: boolean;
  public deleteAccess: boolean;
  public rowActions: GridAction[] = [
    { name: 'Edit', color: 'blue', show: true, callback: (item) => this.editItem(item), condition: this.editButtonCondition, disabled: this.editButtonDisabled },
    { name: 'Delete', color: 'red', show: true, callback: (item) => this.deleteItem(item[this.idProperty]), condition: () => this.deleteAccess, disabled: this.deleteButtonDisabled }
  ];
  public item: any;
  public deleteDialog = false;
  public editDialog = false;
  private id: number | string = -1;
  public error: ErrorMessage;
  private editDialogChangedSubscription: Subscription;

  ngOnInit() {
    this.createAccess = !(this.claims !== undefined && this.claims.map(c => this.claimService.checkClaimContains(c, 'c')).includes(false));
    this.updateAccess = !(this.claims !== undefined && this.claims.map(c => this.claimService.checkClaimContains(c, 'u')).includes(false));
    this.deleteAccess = !(this.claims !== undefined && this.claims.map(c => this.claimService.checkClaimContains(c, 'd')).includes(false));

    if (this.editDialogChanged !== undefined) {
      this.editDialogChangedSubscription = this.editDialogChanged.subscribe((event) => this.dialogChanged(event));
    }
  }

  ngOnChanges() {
    this.rowActions[0].name = this.editButtonName;
    this.rowActions[0].show = this.showEdit;
    this.rowActions[1].show = this.showDelete;
    this.rowActions[0].condition = this.editButtonCondition;
    this.rowActions[0].disabled = this.editButtonDisabled;
    this.rowActions[1].disabled = this.deleteButtonDisabled;
    if (this.additionalRowActions !== undefined && this.additionalRowActions.length > 0) {
      this.rowActions = this.additionalRowActions.concat(this.rowActions);
    }
  }

  addItem() {
    this.item = this.addAction.newItem();
    this.editDialog = true;
  }

  deleteItem(id: number | string) {
    this.deleteDialog = true;
    this.id = id;
  }

  confirmDelete(confirmed: boolean) {
    this.error = null;
    this.deleteDialog = false;
    if (confirmed) {
      this.dataService.deleteItem(this.endpoint, this.id).subscribe(response => {
        this.dataChanged.next();
        this.dataChangedEvent.emit();
        this.itemAddedModifiedOrDeletedEvent.emit();
        this.id = -1;
      }, error => this.error = error.error);
    }
  }

  editItem(item: any) {
    this.editDialog = true;
    this.item = item;
  }

  dialogChanged(event: boolean) {
    this.editDialog = event;
    this.dataChanged.next();
    this.dataChangedEvent.emit();
    this.itemAddedModifiedOrDeletedEvent.emit();
  }

  gridDataChanged(data) {
    this.dataChangedEvent.emit(data);
  }
}
