<div class="header">
  <h1>{{ 'Linearity.Session' | translate }}</h1>
</div>
<form *ngIf="linearitySession" (ngSubmit)="submitLinearitySession(linearitySessionForm)" #linearitySessionForm="ngForm">
  <table class="overview">
    <tr>
      <td>{{ 'DateTime' | translate }}:</td>
      <td *ngIf="editMode">
        <input [owlDateTime]="dtDateTime" [owlDateTimeTrigger]="dtDateTime" name="dateTime" [(ngModel)]="linearitySession.dateTime" required #dateTime="ngModel" />
        <form-alert [field]="dateTime" name="DateTime"></form-alert>
        <owl-date-time #dtDateTime></owl-date-time>
      </td>
      <td *ngIf="!editMode">{{ linearitySession.dateTime | date:dateTimeFormat }}</td>
    </tr>
  </table>
        <grid [name]="'Linearity_Readings'" [title]="''" [genericDataRetrieval]="false" [headers]="headers" [showFilters]="false" [showHeader]="false" [showFooter]="false" [exportData]="linearitySession.linearityReadings" (filterEvent)="true" [showSortArrows]="false">
          <tr *ngFor="let linearityReading of linearitySession.linearityReadings; let odd = odd; index as i" [class.odd]="odd">
            <td [title]="linearityReading.componentType.description">{{ linearityReading.componentType.description }}</td>
            <ng-container *ngIf="editMode; else viewMode">
              <td><input type="number" name="leanReading{{i}}" [(ngModel)]="linearityReading.leanReadingValue" required #leanReading="ngModel" /><form-alert [field]="leanReading" name="Value"></form-alert></td>
              <td><input type="number" name="calReading{{i}}" [(ngModel)]="linearityReading.calReadingValue" required #calReading="ngModel" /><form-alert [field]="calReading" name="Value"></form-alert></td>
              <td><input type="number" name="richReading{{i}}" [(ngModel)]="linearityReading.richReadingValue" required #richReading="ngModel" /><form-alert [field]="richReading" name="Value"></form-alert></td>
              <td><input type="number" name="leanArea{{i}}" [(ngModel)]="linearityReading.leanAreaValue" required #leanArea="ngModel" /><form-alert [field]="leanArea" name="Value"></form-alert></td>
              <td><input type="number" name="calArea{{i}}" [(ngModel)]="linearityReading.calAreaValue" required #calArea="ngModel" /><form-alert [field]="calArea" name="Value"></form-alert></td>
              <td><input type="number" name="richArea{{i}}" [(ngModel)]="linearityReading.richAreaValue" required #richArea="ngModel" /><form-alert [field]="richArea" name="Value"></form-alert></td>
            </ng-container>
            <ng-template #viewMode>
              <td>{{ linearityReading.leanReadingValue }}</td>
              <td>{{ linearityReading.calReadingValue }}</td>
              <td>{{ linearityReading.richReadingValue }}</td>
              <td>{{ linearityReading.leanAreaValue }}</td>
              <td>{{ linearityReading.calAreaValue }}</td>
              <td>{{ linearityReading.richAreaValue }}</td>
            </ng-template>
          </tr>
        </grid>
    <error-message [error]="error"></error-message>
    <div *ngIf="editMode" class="control-buttons">
      <button class="button-gray" type="reset" (click)="cancel()">{{ 'Cancel' | translate }}</button>
      <submit-button name="Save" [disabled]="linearitySessionForm.invalid"></submit-button>
    </div>
    <div *ngIf="!editMode" class="control-buttons-left">
      <button class="button-gray" type="reset" (click)="cancel()">{{ 'Close' | translate }}</button>
    </div>
</form>
