import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ValidationProcedureService } from '../validation-procedure.service';
import { ActiveFilter } from '../../../../../shared/data/active-filter';

@Component({
  selector: 'validation-procedure-filter',
  templateUrl: './validation-procedure-filter.component.html',
  styleUrls: ['../../../../../shared/data/data-filter.component.css']
})
export class ValidationProcedureFilterComponent implements OnInit, AfterContentChecked {
  constructor(
    private validationProcedureService: ValidationProcedureService
  ) { }
  @Input() filter: ActiveFilter;
  @Output() filterChanged = new EventEmitter<boolean>();
  public validationProcedures: string[];
  @ViewChild('validationProcedureElement') validationProcedureElementRef: ElementRef;

  ngOnInit() {
    this.validationProcedureService.getValidationProcedureMethods().subscribe(validationProcedures => this.validationProcedures = validationProcedures);
  }

  ngAfterContentChecked() {
    if (this.filter.filterText !== undefined && this.validationProcedureElementRef !== undefined) {
      this.validationProcedureElementRef.nativeElement.value = this.filter.filterText;
    }
  }

  changed(ev) {
    this.filter.filterText = ev.target.value;
    this.filterChanged.emit(true);
  }
}
