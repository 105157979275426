import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared/shared.module';

import { DiagnosticSelectionComponent } from './diagnostic-selection.component';
import { DiagnosticService } from '../diagnostic.service';

@NgModule({
  declarations: [
    DiagnosticSelectionComponent
  ],
  exports: [
    DiagnosticSelectionComponent
  ],
  imports: [
    SharedModule
  ],
  providers: [
    DiagnosticService
  ]
})
export class DiagnosticSelectionModule { }
