<span *ngFor="let breadcrumb of breadcrumbs">
  <i class="separator"></i>
  <a routerLink="{{breadcrumb.path}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">{{ breadcrumb.title | translate }}</a>
</span>
<span *ngFor="let breadcrumb of informationLevelBreadcrumbs; let i = index" [ngClass]="{'no-small-screen': i+1 < breadcrumbs.length}">
  <i class="separator"></i>
  <a routerLink="{{breadcrumb.path}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">{{ breadcrumb.title | translate }}</a>
</span>
<span *ngFor="let breadcrumb of breadcrumbs2">
  <i class="separator"></i>
  <a routerLink="{{breadcrumb.path}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">{{ breadcrumb.title | translate }}</a>
</span>
