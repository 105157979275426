<ng-container *ngIf="item && property" [ngSwitch]="property.type">
  <ng-container *ngSwitchCase="'numeric'">
    <input type="number" name="property{{ i }}" [required]="property.requiredInput" [(ngModel)]="item[property.property]" #numberInput="ngModel" />
    <form-alert [field]="numberInput" [name]="property.header"></form-alert>
  </ng-container>
  <ng-container *ngSwitchCase="'datetime' || 'date'">
    <input [owlDateTime]="dtPropertyInput" [owlDateTimeTrigger]="dtPropertyInput" name="datetimeProperty{{ i }}" [required]="property.requiredInput" [(ngModel)]="item[property.property]" #datetimeInput="ngModel" />
    <owl-date-time #dtPropertyInput></owl-date-time>
    <form-alert [field]="datetimeInput" [name]="property.header"></form-alert>
  </ng-container>
  <ng-container *ngSwitchCase="'boolean'">
    <select name="property{{ i }}" [required]="property.requiredInput" [(ngModel)]="item[property.property]">
      <option [ngValue]="false">{{ 'No' | translate }}</option>
      <option [ngValue]="true">{{ 'Yes' | translate }}</option>
    </select>
  </ng-container>
  <ng-container *ngSwitchCase="'listInput'">
    <input type="hidden" name="propertyId{{ i }}" [(ngModel)]="item[property.property]" #listInput="ngModel" />
    <list-input [name]="property.header" [model]="listInput" [item]="item" [endpoint]="property.listInputEndpoint" [selectedItem]="property.selectedItem" [required]="property.requiredInput" (selectionChanged)="onSelectionChanged()"></list-input>
  </ng-container>
  <ng-container *ngSwitchCase="'selectOptions'">
    <select [(ngModel)]="item[property.property]" name="property{{ i }}" [required]="property.requiredInput" #selection="ngModel">
      <option *ngFor="let selectOption of property.selectOptions" [ngValue]="selectOption.value">
        <ng-container *ngIf="property.translate">{{ selectOption.name | translate }}</ng-container>
        <ng-container *ngIf="!property.translate">{{ selectOption.name }}</ng-container>
      </option>
    </select>
    <form-alert [field]="selection" [name]="property.header"></form-alert>
  </ng-container>
  <ng-container *ngSwitchCase="'ioPoint'">
    <io-selection [(ioId)]="item[property.property]" [informationLevelId]="informationLevelId" [required]="property.requiredInput" [informationLevelIdChanged]="selectionChanged"></io-selection>
  </ng-container>
  <ng-container *ngSwitchCase="'ioPointNumeric'">
    <io-selection [(ioId)]="item[property.property]" [dataType]="'numeric'" [informationLevelId]="informationLevelId" [required]="property.requiredInput" [informationLevelIdChanged]="selectionChanged"></io-selection>
  </ng-container>
  <ng-container *ngSwitchCase="'ioPointBoolean'">
    <io-selection [(ioId)]="item[property.property]" [dataType]="'boolean'" [informationLevelId]="informationLevelId" [required]="property.requiredInput" [informationLevelIdChanged]="selectionChanged"></io-selection>
  </ng-container>
  <ng-container *ngSwitchCase="'ioPointText'">
    <io-selection [(ioId)]="item[property.property]" [dataType]="'text'" [informationLevelId]="informationLevelId" [required]="property.requiredInput" [informationLevelIdChanged]="selectionChanged"></io-selection>
  </ng-container>
  <ng-container *ngSwitchCase="'ioPointDataType'">
    <select name="dataType{{ i }}" [(ngModel)]="item[property.property]" [disabled]="item.ioConfig && item.ioConfig.internalSource" [required]="property.requiredInput" #dataType="ngModel">
      <option value="boolean">{{ 'Boolean' | translate }}</option>
      <option value="int">{{ 'Integer' | translate }}</option>
      <option value="float">{{ 'Float' | translate }}</option>
      <option value="text">{{ 'Text' | translate }}</option>
    </select>
    <form-alert [field]="dataType" [name]="property.header"></form-alert>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <input type="text" name="property{{ i }}" [required]="property.requiredInput" [(ngModel)]="item[property.property]" #stringInput="ngModel" />
    <form-alert [field]="stringInput" [name]="property.header"></form-alert>
  </ng-container>
</ng-container>
