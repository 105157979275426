import { Diagnostic } from '../diagnostic/diagnostic';

export class AreaLayoutDiag {
  id: number;
  areaId: number;
  targetDiagnostic: Diagnostic;
  targetDiagnosticId: number;
  top: number;
  left: number;
  z: number;
  value: string;
}
