import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { OperationalStateFilterComponent } from './operational-state-filter.component';
import { OperationalStateService } from '../operational-state.service';

@NgModule({
  declarations: [OperationalStateFilterComponent],
  exports: [OperationalStateFilterComponent],
  imports: [CommonModule, TranslateModule],
  providers: [OperationalStateService]
})
export class OperationalStateFilterModule { }
