import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfig } from '../app.config';
import { Report } from './report';
import { ReportType } from './report-type';
import { ReportSection } from './report-section';
import { ReportDatasetData } from './report-dataset-data';
import { ReportSectionContent } from './report-section-content';
import { ReportEndpoint } from './report-endpoint';
import { ModelMetaData } from '../../shared/data/model-meta-data';
import { DataService } from '../../shared/data/data.service';

@Injectable()
export class ReportViewService {
  constructor(
    private appConfig: AppConfig,
    private http: HttpClient,
    private dataService: DataService
  ) {
    this.apiHost = this.appConfig.getConfig('apiHost');
  }
  private apiHost: string;
  public reportSections: ReportSection[] = null;

  public getReport(id: number): Observable<Report> { return this.http.get<Report>(this.apiHost + 'report/get/' + id); }

  public getReportType(id: number): Observable<ReportType> { return this.http.get<ReportType>(this.apiHost + 'reporttype/get/' + id); }

  public getReportSections(reportTypeId: number, sectionId: number): Observable<ReportSection[]> { return this.http.get<ReportSection[]>(this.apiHost + 'reporttype/getsections/' + reportTypeId + '?sectionId=' + sectionId); }

  public getReportDataset(reportId: number, datasetId: number): Observable<ReportDatasetData> {
    return this.http.get<ReportDatasetData>(this.apiHost + 'report/dataset/' + reportId + '?datasetId=' + datasetId);
  }

  public getReportData(reportId: number): Observable<ReportDatasetData> { return this.http.get<ReportDatasetData>(this.apiHost + 'reportdata/get?reportId=' + reportId); }

  public getReportFilters(endpoints: ReportEndpoint[]): Observable<ModelMetaData[]> {
    return this.http.get<ModelMetaData[]>(this.apiHost + 'reportdata/getfilters?endpoints=' + JSON.stringify(endpoints));
  }

  public getReportLogo(): Observable<string> { return this.http.get(this.apiHost + 'reporttype/getlogo', { responseType: 'text' }); }

  public setReportSectionsData(reportSections: ReportSection[], reportDataset: ReportDatasetData): ReportSection[] {
    if (reportSections !== null && reportDataset !== null && reportDataset.data !== undefined && reportDataset.data !== null && reportSections.length > 0 && reportDataset.data.length > 0) {
      this.reportSections = [];

      for (let h = 0; h < reportDataset.data.length; h++) {
        if (h > 0) {
          const pageBreak: ReportSection = new ReportSection('pageBreak');
          this.reportSections.push(pageBreak);
        }

        for (let i = 0; i < reportSections.length; i++) {
          if (reportSections[i].enabled) {
            let section: ReportSection = {
              id: reportSections[i].id,
              type: reportSections[i].type,
              enabled: true,
              title: reportSections[i].title,
              headers: reportSections[i].headers,
              content: reportSections[i].content,
              dataTable: reportSections[i].dataTable,
              order: reportSections[i].order,
              dataPath: reportSections[i].dataPath,
              sectionChildren: reportSections[i].sectionChildren,
              loaded: false
            };

            section = this.setReportSectionData(section, reportDataset.data[h]);
            this.reportSections.push(section);
          }
        }
      }

      return this.reportSections;
    } else {
      return null;
    }
  }

  setReportSectionData(section: ReportSection, reportData: any): ReportSection {
    switch (section.type) {
      case 'detailsTable':
        section.content = this.getDetailsTableContent(section, reportData);
        section.loaded = true;
        break;
      case 'overviewTable':
      case 'overviewTableVertical':
        section.content = this.getOverviewTableContent(section, reportData);
        section.loaded = true;
        break;
      case 'accuracyChart':
      case 'precisionChart':
      case 'historicalChart':
      case 'fidelityChart':
      case 'linearityChart':
        section.content = this.getDetailsTableContent(section, reportData);
        break;
      case 'container':
        section = this.setReportSubsectionData(section, reportData);
        section.loaded = true;
        break;
      case 'text':
        section.loaded = true;
        break;
      default:
        break;
    }
    return section;
  }

  setReportSubsectionData(section: ReportSection, reportData: any): ReportSection {
    if (section !== null && section.sectionChildren !== null) {
      const subSections = [...section.sectionChildren];
      const newSections = [];
      section.sectionChildren = [];

      if (reportData[section.dataTable] !== undefined && reportData[section.dataTable] !== null && reportData[section.dataTable].length > 0) {
        for (let j = 0; j < reportData[section.dataTable].length; j++) {
          if (subSections !== null && subSections.length > 0) {
            for (let k = 0; k < subSections.length; k++) {
              if (subSections[k].enabled) {
                let newSection: ReportSection = {
                  id: subSections[k].id,
                  type: subSections[k].type,
                  enabled: true,
                  title: subSections[k].title,
                  headers: subSections[k].headers,
                  content: subSections[k].content,
                  dataTable: subSections[k].dataTable,
                  order: subSections[k].order,
                  dataPath: subSections[k].dataPath,
                  sectionChildren: subSections[k].sectionChildren,
                  loaded: false
                };

                newSection = this.setReportSectionData(newSection, reportData[section.dataTable][j]);
                newSections.push(newSection);
              }
            }
          }
        }
      }

      section.sectionChildren = newSections;
    }

    return section;
  }

  private getDetailsTableContent(section: ReportSection, reportDataset: any): any {
    const columns: ReportSectionContent[] = [];

    if (section.content !== undefined && section.content != null) {
      for (let j = 0; j < section.content.length; j++) {
        if (section.content[j] !== undefined) {
          let content: ReportSectionContent = { content: reportDataset[section.content[j].content], dataType: section.content[j].dataType, translatable: section.content[j].translatable };
          content.content = this.dataService.transformToType(content.content, content.dataType, content.translatable);
          columns.push(content);
        }
      }
    }

    return columns;
  }

  private getOverviewTableContent(section: ReportSection, reportDataset: any): any {
    const table = section.dataTable;
    const rows: any[] = [];

    if (reportDataset[table] !== undefined && reportDataset[table] != null) {
      for (let j = 0; j < reportDataset[table].length; j++) {
        const rowData: ReportSectionContent[] = [];

        for (let k = 0; k < section.content.length; k++) {
          if (section.content[k] !== undefined) {
            let content: ReportSectionContent = { content: reportDataset[table][j][section.content[k].content], dataType: section.content[k].dataType, translatable: section.content[k].translatable };
            content.content = this.dataService.transformToType(content.content, content.dataType, content.translatable);
            rowData.push(content);
          }
        }

        rows.push(rowData);
      }
    }

    return rows;
  }
}
