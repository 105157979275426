import { Component, OnDestroy, OnInit, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { timeout } from 'rxjs/operators';

import { IoPointService } from '../io-point/io-point.service';
import { IoService } from '../io.service';
import { ActiveFilter } from '../../../shared/data/active-filter';
import { IoPoint } from '../io-point/io-point';

@Component({
  selector: 'io-status',
  templateUrl: './io-status.component.html'
})
export class IoStatusComponent implements OnInit, OnDestroy {
  constructor(
    private ioPointService: IoPointService,
    private ioService: IoService
  ) { }

  @Input() editMode = false;
  @Input() activeFilters: ActiveFilter[] = [];
  @Output() filtersChanged: Subject<ActiveFilter[]> = new Subject<ActiveFilter[]>();
  public selectedIds: number[] = [];
  public listItemsDialog = false;
  public ioPoints: IoPoint[];
  private refreshInterval;

  ngOnInit() {
    this.getIoPoints();
  }

  ngOnDestroy() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  selectListItems() {
    this.selectedIds = (this.activeFilters && this.activeFilters[0] && this.activeFilters[0].filterText && this.activeFilters[0].filterText.length > 0) ? JSON.parse(this.activeFilters[0].filterText) : [];
    this.listItemsDialog = true;
  }

  dialogChanged(event: boolean) {
    if (!event) {
      this.listItemsDialog = event;
      this.filtersChanged.next(this.activeFilters);
      this.getIoPoints();
    }
  }

  getIoPoints() {
    if (this.activeFilters && this.activeFilters[0] && this.activeFilters[0].filterText && this.activeFilters[0].filterText.length > 2) {
      const args = '?filters=' + this.cleanString(JSON.stringify([this.activeFilters[0]]));
      this.ioPointService.getIoPoints(args).subscribe(iops => {
        this.ioPoints = iops;
        this.getValues();
      });
    } else {
      this.ioPoints = [];
    }
  }

  getValues() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
    if (this.ioPoints && this.ioPoints.length > 0) {
      this.refreshInterval = setInterval(() => {
        this.ioService.getIoReadings(this.ioPoints.map(iop => iop.id)).pipe(timeout(2500)).subscribe(values => {
          values.map(reading => this.ioPoints.map(iop => {
            if (iop.id === reading.ioId) {
              iop.value = reading.reading;
            }
          }));
        });
      }, 1000);
    }
  }

  cleanString(url: string) {
    return url.replace(/%/gi, '%25')
      .replace(/&/gi, '%26')
      .replace(/#/gi, '%23');
  }
}
