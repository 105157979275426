<div *ngIf="!performanceIndicators">
  <loader *ngIf="!error"></loader>
  <error-message [error]="error"></error-message>
</div>
<div *ngIf="performanceIndicators">
  <div *ngIf="showWrapper" class="overview-wrapper">
    <div class="overview-header">{{ title | translate }}</div>
    <ng-container *ngTemplateOutlet="perfindicContent"></ng-container>
  </div>
  <ng-container *ngIf="!showWrapper" >
    <ng-container *ngTemplateOutlet="perfindicContent"></ng-container>
  </ng-container>
  <ng-template #perfindicContent>
    <div class="tab-headers">
      <div (click)="showCharts()" class="tab-header" [ngClass]="{ 'active': showChartsTab }">{{ 'Chart.Charts' | translate }}</div>
      <div (click)="showTable()" class="tab-header" [ngClass]="{ 'active': showTableTab }">{{ 'Table.Table' | translate }}</div>
    </div>
    <div *ngIf="showChartsTab" class="tab-wrapper">
      <select [(ngModel)]="activeChart" (change)="setActiveChart()">
        <option *ngFor="let chart of performanceIndicatorCharts" [ngValue]="chart">{{ chart.name }}</option>
      </select>
      <div class="overview-wrapper">
        <chart *ngIf="activeChart && !shownOnInstrumentOverview" [title]="activeChart.name" [legend]="true" [view]="[600,300]" [chartType]="activeChart.chartType" [customColors]="colors" (selectChartType)="changeChartType($event)" [dataPoints]="activeChart.dataPoints" (select)="openSubitemChart($event)"></chart>
        <chart *ngIf="activeChart && shownOnInstrumentOverview" [title]="activeChart.name" [legend]="true" [view]="[380,190]" [chartType]="activeChart.chartType" [customColors]="colors" [dataPoints]="activeChart.dataPoints"></chart>
      </div>
      <div class="overview-wrapper" *ngIf="activeChart && !shownOnInstrumentOverview && activeChartSubResults">
        <chart [title]="activeChartSubResultsTitle" [legend]="true" [view]="[600,300]" [chartType]="activeChartSubResultsChartType" [dataPoints]="activeChartSubResults" (select)="navigateToInformationLevel2($event)"></chart>
      </div>
    </div>
    <div *ngIf="showTableTab" class="tab-wrapper">
      <div class="grid-wrapper">
        <grid [name]="'Performance_Indicators'" [title]="title" [genericDataRetrieval]="false" [headers]="headers" [showHeader]="false" [showFooter]="false" [showFilters]="false" [exportData]="performanceIndicators">
          <tr *ngFor="let performanceIndicator of performanceIndicators; let odd = odd" [class.odd]="odd" (click)="toggleEquipmentResults(performanceIndicator)">
            <td [title]="performanceIndicator.name">
              {{ performanceIndicator.name }}
              <div *ngIf="performanceIndicator.showSubResults" class="sub-results">
                <div *ngFor="let subResult of performanceIndicator.subResults" (mouseenter)="toggleFocus(subResult)" (mouseleave)="toggleFocus(subResult)" (click)="navigateToInformationLevel(subResult.name)" class="sub-result" [ngClass]="{ 'focus': subResult.focus }">
                  {{ subResult.name }}
                </div>
              </div>
            </td>
            <td [title]="performanceIndicator.formulaResult">
              {{ performanceIndicator.formulaResult }}
              <div *ngIf="performanceIndicator.showSubResults" class="sub-results">
                <div *ngFor="let subResult of performanceIndicator.subResults" (mouseenter)="toggleFocus(subResult)" (mouseleave)="toggleFocus(subResult)" class="sub-result" [ngClass]="{ 'focus': subResult.focus }">
                  {{subResult.formulaResult}}
                </div>
              </div>
            </td>
            <td [title]="performanceIndicator.unit">{{ performanceIndicator.unit }}</td>
          </tr>
        </grid>
        <loader *ngIf="!performanceIndicators"></loader>
      </div>
    </div>
  </ng-template>
</div>
