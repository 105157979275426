import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { ClaimService } from '../../../../core/claim.service';

import { FidelitySession } from './fidelity-session';
import { FidelitySessionService } from './fidelity-session.service';
import { ErrorMessage } from '../../../../shared/error/error-message';
import { Header } from '../../../../shared/grid/header';
import { FilterOption } from '../../../../shared/data/filter-option';
import { AppConfig } from '../../../../core/app.config';

@Component({
  selector: 'fidelity-session',
  templateUrl: './fidelity-session.component.html'
})
export class FidelitySessionComponent implements OnInit, OnChanges {
  constructor(
    private fidelitySessionService: FidelitySessionService,
    private claimService: ClaimService,
    private appConfig: AppConfig
  ) { }
  @Input() public fidelityChartId: number;
  @Input() public dataChange: boolean;
  public selectedFidelitySession: FidelitySession;
  @Output() public selectedFidelitySessionChanged = new EventEmitter<FidelitySession>();
  public headers: Header[] = [{ header: 'DateTime', sort: true, width: '70%' }, { header: 'Action', sort: false, width: '30%' }];
  public filterOptions: FilterOption[] = [{ name: 'DateTime', type: 'datetime' }];
  public fidelitySessions: FidelitySession[];
  private dialogSession: FidelitySession;
  public count: number;
  private deleteId: number;
  public updateAccess: boolean;
  public deleteAccess: boolean;
  public editDialog: boolean;
  public deleteDialog: boolean;
  private args = '';
  public error: ErrorMessage;
  private dateTimeFormat: string;

  ngOnInit() {
    this.dateTimeFormat = this.appConfig.getConfig('dateTimeFormat');
    this.updateAccess = this.claimService.checkClaimContains('aml.valsession', 'u');
    this.deleteAccess = this.claimService.checkClaimContains('aml.valsession', 'd');
    this.getFidelitySessions(this.args);
  }

  ngOnChanges() {
    this.getFidelitySessions(this.args);
  }

  getFidelitySessions(args: string) {
    this.error = null;
    this.args = args;
    this.fidelitySessionService.getFidelitySessionCountByChart(this.fidelityChartId, args).subscribe(count => this.count = count);
    this.fidelitySessionService.getFidelitySessionsByChart(this.fidelityChartId, args).subscribe(fidelitySessions => {
      this.fidelitySessions = fidelitySessions;
      if (!this.selectedFidelitySession && fidelitySessions.length > 0) { this.selectedFidelitySession = fidelitySessions[0]; }
    }, error => this.error = error.error);
  }

  selectFidelitySession(fidelitySession: FidelitySession) {
    this.selectedFidelitySession = fidelitySession;
    this.selectedFidelitySessionChanged.emit(fidelitySession);
  }

  viewFidelitySession(fidelitySession: FidelitySession) {
    this.dialogSession = fidelitySession;
    this.editDialog = true;
  }

  deleteFidelitySession(id: number) {
    this.deleteId = id;
    this.deleteDialog = true;
  }

  confirmDelete(confirmed: boolean) {
    this.error = null;
    if (confirmed) {
      this.fidelitySessionService.deleteFidelitySession(this.deleteId).subscribe(response => {
        this.getFidelitySessions(this.args);
        this.deleteDialog = false;
      }, error => this.error = error.error);
    } else {
      this.deleteDialog = false;
    }
  }

  dialogChanged(event: boolean) {
    this.editDialog = event;
  }
}
