import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../../../../core/app.config';
import { Generic } from '../../../../shared/generic';

@Injectable()
export class MaterialTypeService {
  constructor(private appConfig: AppConfig, private http: HttpClient) { this.apiHost = this.appConfig.getConfig('apiHost'); }
  private apiHost: string;

  public getMaterialTypes(filterArguments: string): Observable<Generic[]> { return this.http.get<Generic[]>(this.apiHost + 'referenceMaterialType/get' + filterArguments); }

  public getMaterialTypeCount(filterArguments: string): Observable<number> { return this.http.get<number>(this.apiHost + 'referenceMaterialType/count' + filterArguments); }

  public getAllMaterialTypes(): Observable<Generic[]> { return this.http.get<Generic[]>(this.apiHost + 'referenceMaterialType/getall'); }

  public getMaterialType(id: number): Observable<Generic> { return this.http.get<Generic>(this.apiHost + 'referenceMaterialType/get/' + id); }

  public newMaterialType(): Generic { return { id: -1, name: '' }; }
}
