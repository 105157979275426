import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { CriticalityService } from '../criticality.service';
import { ActiveFilter } from '../../../../shared/data/active-filter';
import { Criticality } from '../criticality';

@Component({
  selector: 'criticality-filter',
  templateUrl: './criticality-filter.component.html',
  styleUrls: ['../../../../shared/data/data-filter.component.css']
})
export class CriticalityFilterComponent implements OnInit, AfterContentChecked {
  constructor(
    private criticalityService: CriticalityService
  ) { }
  @Input() filter: ActiveFilter;
  @Output() filterChanged = new EventEmitter<boolean>();
  public criticalities: Criticality[];
  @ViewChild('criticalityElement') criticalityElementRef: ElementRef;

  ngOnInit() {
    this.criticalityService.getCriticalities('?count=9999').subscribe(criticalities => this.criticalities = criticalities);
  }

  ngAfterContentChecked() {
    if (this.filter.filterText !== undefined && this.criticalityElementRef !== undefined) {
      this.criticalityElementRef.nativeElement.value = this.filter.filterText;
    }
  }

  changed(ev) {
    this.filter.filterText = ev.target.value;
    this.filterChanged.emit(true);
  }
}
